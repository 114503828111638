import React, { Component, useEffect, Fragment, useRef, useState } from "react";
import RateTableWithAssignment from "./rateTableWithAssignment";

const RateTable = props => {
  let assignment = [];
  let rateParams = { ...props };
  let assignmentType = "";
  const [tableData, setTableData] = useState(props.currData.tableList || [{}]);
  const [errors, setErrors] = useState([]);
  const [multipleTablesAllowed, setMultipleTablesAllowed] = useState(tableData.length > 1);

  if (props.GlobalVariables.rateAssignmentType) {
    // read assignment type variable
    assignmentType = props.GlobalVariables.rateAssignmentType.filter(row => row.Plan === props.currProps.plan && row.Incentive === props.currProps.incentive)[0];
    if (assignmentType) {
      assignmentType = assignmentType.val[0];
    }
  }
  if (props.GlobalVariables.PlanTitles && assignmentType === "Title") {
    //if title type assignment only then add to assignment array.
    let planTitles = [];
    if (props.GlobalVariables.incentiveTitles) {
      planTitles = props.GlobalVariables.incentiveTitles.filter(row => row.Incentive === props.currProps.incentive);
    } else {
      planTitles = props.GlobalVariables.PlanTitles.filter(row => row.Incentive === props.currProps.incentive);
    }
    let uniqueTitles = new Set([]);
    planTitles.forEach(function (row) {
      uniqueTitles = new Set([...uniqueTitles, ...row.val]);
    });
    if (uniqueTitles) {
      assignment = [...uniqueTitles];
    }
  }

  if (assignmentType !== "Title" && tableData.length > 1) {
    let data = [{ ...tableData[0] }];
    setTableData(data);
  }

  useEffect(() => {
    props.tableData(tableData);
  }, [tableData]);

  const updateTableData = (data, id) => {
    let currData = [...tableData];
    currData[id] = data;
    let err = [];
    rateParams.disableParent(false);
    let names = {},
      assignments = {};

    for (let table of currData) {
      if (table.selectedTitles) {
        for (let title of table.selectedTitles) {
          if (assignments[title]) {
            //validation failed
            rateParams.disableParent(true);
            err.push(`${rateParams.lists.AllObjects[title].name} is defined for more than 1 rate table. Each title can be assigned to only one rate table.`);
          } else {
            assignments[title] = true;
          }
        }
      }

      if (names[table.tableName]) {
        //validation failed
        rateParams.disableParent(true);
        err.push(` Table name : ${table.tableName} is duplicated. Each rate table should have a unique name`);
        break;
      } else {
        names[table.tableName] = true;
      }
    }

    setTableData(currData);
    setErrors(err);
  };

  const deleteTable = id => {
    let currData = [...tableData];
    currData.splice(id, 1);
    setTableData(currData);
  };

  const addRateTable = () => {
    let currTables = [...tableData];
    currTables.push({ id: currTables.length, removable: true });
    setTableData(currTables);
  };
  const allowMultipleTables = event => {
    let val = event.target.checked;
    setMultipleTablesAllowed(val);
  };

  return (
    <span>
      {errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <ul>
            {errors.map(err => (
              <li>{err}</li>
            ))}
          </ul>
        </div>
      )}
      {tableData.map((item, index) => (
        <RateTableWithAssignment
          currData={item || {}}
          answers={rateParams.answers}
          lists={rateParams.lists}
          GlobalVariables={rateParams.GlobalVariables}
          currProps={rateParams.currProps}
          tableData={updateTableData}
          disableParent={rateParams.disableParent}
          id={index}
          key={index}
          removable={index > 0}
          deleteTable={deleteTable}
          titleAssignments={assignment}
          defaultTableName={props.defaultName.Response}
        />
      ))}

      {assignment.length > 0 && (
        <div className="checkbox NewSection">
          <label>
            <input type="checkbox" id="multipleTables" checked={multipleTablesAllowed} onChange={allowMultipleTables}></input>
            <span>Do different titles or roles have varying rate table structures? ( break points or number of tiers )</span>
          </label>
        </div>
      )}

      <div className="row">
        {assignment.length > 0 && multipleTablesAllowed && (
          <button className="btn btn-success btn-padded" onClick={addRateTable}>
            Add Rate Table
          </button>
        )}
      </div>
    </span>
  );
};

export default RateTable;
