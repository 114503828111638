import axios from "axios";
import { API_ROOT, redirectToIAM } from "./api-config";
import store from "store";
import { getSFDCFields } from "./SFDCUtil";

axios.defaults.withCredentials = true;

function iterateObject(obj) {
  if (!(obj instanceof Blob)) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        // If the value is an array, loop through its elements
        obj[key].forEach(element => {
          if (typeof element === "object" && element !== null) {
            iterateObject(element); // Recursively handle objects within arrays
          } else {
            if (typeof element === "string") {
              element = element.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
            }
          }
        });
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // If the value is an object, recursively call iterateObject
        iterateObject(obj[key]);
      } else {
        // If it's neither an array nor an object, just log the value
        if (typeof obj[key] === "string") {
          obj[key] = obj[key].replaceAll("&lt;", "<").replaceAll("&gt;", ">");
        }
      }
    }
  }
}

axios.interceptors.response.use(
  function (response) {
    if (response) iterateObject(response.data);
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 502) {
      //console.log("intercepted 502 response");
      //console.log(error);
      // window.location = "/errorPage";
    }
    if (error.response && error.response.status === 401) {
      //console.log("intercepted 401 error");
      redirectToIAM(store.get("mode"));
    } else if (error.response && error.response.status === 400) {
      //console.log("intercepted 400 error");
      if (!error.response.error) {
        return { status: 400, message: "Bad Request", data: error.response.data };
      } else {
        return { status: 400, message: "Bad Request", originalMessages: error.response.error.messages, error: error.response.data.error };
      }
    } else if (error.response) {
      //console.log(error.response);
      return error.response;
    }
  },
);

class Server {
  status = {
    isAuthenticated: false,
    user: "",
    tabs: [],
  };

  authenticate = () => {
    return new Promise(resolve => {
      axios.post(`${API_ROOT}/isLoggedin`, {}).then(
        response => {
          if (response) {
            this.status.isAuthenticated = response.data.status;
            if (this.status.isAuthenticated) {
              this.status.user = response.data.name;
              this.status.tabs = response.data.tabs;
            }
            resolve(response.data);
          }
        },
        error => {
          //console.log(error);
        },
      );
    });
  };

  login = (user, password, mode) => {
    return new Promise(resolve => {
      axios
        .post(`${API_ROOT}/login`, {
          user: user,
          password: password,
          mode: mode,
        })
        .then(response => {
          if (response) {
            resolve(response.data);
          }
        });
    });
  };

  externalUserLogin = (user, orangeToken) => {
    return new Promise(resolve => {
      axios
        .post(`${API_ROOT}/externalUserLogin`, {
          user: user,
          token: orangeToken,
        })
        .then(response => {
          if (response) {
            resolve(response.data);
          }
        });
    });
  };
  logout = () => {
    return new Promise(resolve => {
      axios.post(`${API_ROOT}/logout`, {}).then(response => {
        resolve(response.data);
      });
    });
  };

  getCustomers = callback => {
    axios.get(`${API_ROOT}/customers`, {}).then(response => {
      callback(response);
    });
  };

  getAllCustomers = callback => {
    axios.get(`${API_ROOT}/customers/allCustomers`, {}).then(response => {
      callback(response);
    });
  };

  getProjects = (customerId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects`, {}).then(response => {
      callback(response);
    });
  };

  getAllProjects = (customerId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/allProjects`, {}).then(response => {
      callback(response);
    });
  };

  getProjectAccess = (customerId, projectId, callback) => {
    console.log(customerId, projectId);
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/assign`).then(response => {
      callback(response);
    });
  };

  getProjectDetails = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/projectDetails`, {}).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  getGeneratedRules = (customerId, projectId, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/rules`, {}).then(response => {
      callback(response);
    });
  };
  validateConfiguration = (customerId, projectId, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/validate`, {}).then(response => {
      callback(response);
    });
  };

  getIncentObjectIds = (environment, useClientId, callback) => {
    axios.post(`${API_ROOT}/getIncentObjectIds`, { environment, useClientId }).then(response => {
      callback(response);
    });
  };

  getConnectToken = (clientId, consumerId, environment, userId, password, useClientId, callback) => {
    axios
      .post(`${API_ROOT}/client/${clientId}/consumer/${consumerId}/getConnectToken`, {
        environment,
        userId,
        password,
        useClientId,
      })
      .then(response => {
        callback(response);
      });
  };

  getRulesXMLFromJSON = (object, projectObj, callback) => {
    axios({
      url: `${API_ROOT}/util/generate/rulexml`,
      method: "POST",
      responseType: "blob",
      data: { object: object, projectObj: projectObj },
    }).then(response => {
      callback(response);
    });
  };

  getQuestionSchema = (ver, callback) => {
    axios.get(`${API_ROOT}/metadata/questions/${ver}`, {}).then(response => {
      callback(response);
    });
  };

  getQuestionControlSchema = (ver, callback) => {
    axios.get(`${API_ROOT}/metadata/questioncontrols/${ver}`, {}).then(response => {
      callback(response);
    });
  };
  createCustomer = (body, callback) => {
    axios.post(`${API_ROOT}/customers`, body).then(response => {
      callback(response);
    });
  };

  assignCustomer = (customerId, userId, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/assign/${userId}`).then(response => {
      callback(response);
    });
  };

  createProject = (customerId, body, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects`, body).then(response => {
      callback(response);
    });
  };

  assignProject = (customerId, projectId, userId, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/assign/${userId}`).then(response => {
      callback(response);
    });
  };

  getFRDJobs = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/frds`).then(response => {
      callback(response);
    });
  };

  getJobs = (customerId, projectId, jobTypes, callback) => {
    const query = jobTypes.map(type => `jobType=${type}`).join("&");
    const url = `${API_ROOT}/customers/${customerId}/projects/${projectId}/jobs?${query}`;
    const encodedUrl = encodeURI(url);
    axios.get(encodedUrl).then(response => {
      callback(response);
    });
  };

  generateFRD = (customerId, projectId, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/frd`,
      method: "POST",
      responseType: "blob",
    }).then(response => {
      callback(response);
    });
  };

  downloadFRD = (customerId, projectId, requestId, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/frds/${requestId}`,
      method: "GET",
      responseType: "blob",
    }).then(response => {
      callback(response);
    });
  };

  submitFRDJob = (customerId, projectId, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/frds`,
      method: "POST",
    }).then(response => {
      callback(response);
    });
  };

  submitJob = (customerId, projectId, jobType, data, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/jobs/${jobType}`,
      method: "POST",
      data: data,
    }).then(response => {
      callback(response);
    });
  };

  downloadJobResult = (customerId, projectId, requestId, jobType, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/jobs/${jobType}/${requestId}`,
      method: "GET",
      responseType: "blob",
    }).then(response => {
      callback(response);
    });
  };

  log = (data, callback) => {
    axios.post(`${API_ROOT}/log`, data).then(response => {
      callback(response);
    });
  };
  generateComponentSummary = (customerId, projectId, body, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/componentSummary`, body).then(response => {
      callback(response);
    });
  };
  searchAnswers = (customerId, projectId, searchParam, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/projectdetails/questions?searchParam=${searchParam}`).then(response => {
      callback(response);
    });
  };
  //new Update API's
  updateUnfitSections = (customerId, projectId, body, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/unfitSections`, body).then(response => {
      callback(response);
    });
  };
  updateFRDSections = (customerId, projectId, param, body, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/frdDetails/${param}`, body).then(response => {
      callback(response);
    });
  };
  updateLists = (customerId, projectId, listData, callback) => {
    Object.keys(listData).forEach(key => {
      if (["Sources", "HRSources"].includes(key)) {
        listData = JSON.parse(JSON.stringify(listData));
        listData[key].forEach(s => {
          if (s.newMappingUpdated && s.newMappingUpdated === true) {
            if (s.connector === "sfdc") {
              if (s.fields && s.fields.data) {
                s.fields.data = s.fields.data.filter(f => f.isMapped === true || f.Mandatory === true || f.usedInFilter === true || f.usedInJoin === true || f.isCustomValidation === true);
              }
            }
            if (s.relationships) {
              s.relationships.forEach(r => {
                if (r.fields) {
                  r.fields = r.fields.filter(f => f.isMapped === true || f.usedInFilter === true || f.usedInJoin === true || f.Mandatory === true || f.isCustomValidation === true);
                }
              });
            }
          }
        });
      }
    });
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/lists`, listData).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  updateLoop = (customerId, projectId, loopData, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/loop`, loopData).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  updateCompMatrixOverride = (customerId, projectId, data, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/compMatrixOverride`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  submitQuestionResponse = (customerId, projectId, qid, data, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/questions/${qid}`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  insertQuestionResponse = (customerId, projectId, qid, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/questions/${qid}`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  getSummary = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/summary`).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  getRequirementSummary = (customerId, projectId, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/projectSummary`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  getRequirementOutput = (customerId, projectId, params, callback) => {
    const { selectedLevel, selectedValue } = params;
    let queryStr = "";
    if (selectedLevel) {
      queryStr = `?level=${selectedLevel}`;
    }
    if (selectedValue) {
      queryStr += `&value=${selectedValue}`;
    }
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/requirementOutput${queryStr}`).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  saveManualEntries = (customerId, projectId, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/orangeOutputManualEdits`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  saveCustomGeneratedSections = (data, callback) => {
    axios.post(`${API_ROOT}/customRequirementSummarySections/update_custom_section`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  staleManualEntries = (customerId, projectId, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/staleManualEdits`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  createComment = (customerId, projectId, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/comments`, data).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };

  getTestData = (customerId, projectId, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/data`,
      method: "POST",
      responseType: "blob",
    }).then(response => {
      callback(response);
    });
  };
  getAppendix = (customerId, projectId, callback) => {
    axios({
      url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/appendix`,
      method: "POST",
      responseType: "blob",
    }).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  getGeneratedConnectObjects = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/connectObjects`).then(response => {
      response = this.transformResponse(response);
      callback(response.data);
    });
  };
  getConnectXSQL = (customerId, projectId, callback) => {
    axios({ url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/generate/connectObjectsXSQL`, method: "GET", responseType: "blob" }).then(response => {
      callback(response);
    });
  };

  getRequirementoutputPdf = (customerId, projectId, callback) => {
    axios({ url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/getRequirementPdf`, method: "GET", responseType: "blob" }).then(response => {
      callback(response);
    });
  };
  getRequirementDocx = (customerId, projectId, callback) => {
    axios({ url: `${API_ROOT}/customers/${customerId}/projects/${projectId}/requirementOutputDocument`, method: "GET", responseType: "blob" }).then(response => {
      callback(response);
    });
  };
  getList = (customerId, projectId, listType, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/lists/${listType}`).then(response => {
      response = this.transformResponse(response);
      callback(response);
    });
  };
  getCommentsForPath = (customerId, projectId, path, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/comments/${path}`).then(response => {
      callback(response);
    });
  };
  updateComment = (customerId, projectId, commentId, data, callback) => {
    axios.put(`${API_ROOT}/customers/${customerId}/projects/${projectId}/comments/${commentId}`, data).then(response => {
      callback(response);
    });
  };
  getDependencies = (customerId, projectId, objectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/dependencies/${objectId}`).then(response => {
      callback(response);
    });
  };
  getAssignedUsers = callback => {
    axios.get(`${API_ROOT}/users/access`).then(response => {
      callback(response);
    });
  };
  removeCustomerAccess = (customerId, userId, callback) => {
    axios.delete(`${API_ROOT}/customers/${customerId}/assign/${userId}`).then(response => {
      callback(response);
    });
  };
  removeProjectAccess = (customerId, projectId, userId, callback) => {
    axios.delete(`${API_ROOT}/customers/${customerId}/projects/${projectId}/assign/${userId}`).then(response => {
      callback(response);
    });
  };

  getApproverGroups = callback => {
    axios.get(`${API_ROOT}/approverGroups/get`, {}).then(response => {
      callback(response);
    });
  };
  createApproverGroup = (data, callback) => {
    axios.post(`${API_ROOT}/approverGroups/create`, data).then(response => {
      callback(response);
    });
  };
  updateApproverGroup = (data, groupId, callback) => {
    axios.put(`${API_ROOT}/approverGroups/${groupId}`, data).then(response => {
      callback(response);
    });
  };
  getFrdSectionList = callback => {
    axios.get(`${API_ROOT}/approverGroups/getFrdSectionList`, {}).then(response => {
      callback(response);
    });
  };

  getFrdUsers = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/FRDApprover/customers/${customerId}/projects/${projectId}`).then(response => {
      callback(response);
    });
  };

  createImpSurveyUser = (customerId, projectId, data, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/accessToken`, data).then(response => {
      callback(response);
    });
  };
  getAccessTokens = (customerId, projectId, data, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/accessToken/${data.type}`, data).then(response => {
      callback(response);
    });
  };

  createFrdApprover = (data, callback) => {
    axios.post(`${API_ROOT}/FRDApprover/add`, data).then(response => {
      callback(response);
    });
  };
  updateFrdApprover = (data, userId, callback) => {
    axios.put(`${API_ROOT}/FRDApprover/${userId}`, data).then(response => {
      callback(response);
    });
  };

  // Approve FRD section data.
  approveFrdSection = (customerId, projectId, sectionData, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/approveSection`, sectionData).then(response => {
      callback(response);
    });
  };

  getFRDSnapshot = (customerId, projectId, sectionPath, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/getSnapshot?path=${sectionPath}`).then(response => {
      callback(response);
    });
  };

  getApprovalSummary = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/getApproveStatus`).then(response => {
      callback(response);
    });
  };

  verifySFDCLogin = (loginInfo, callback) => {
    axios.post(`${API_ROOT}/sfdcSources/login`, loginInfo).then(response => {
      callback(response);
    });
  };

  getSFDCObjectNames = callback => {
    axios.get(`${API_ROOT}/sfdcSources/objectNames`).then(response => {
      callback(response);
    });
  };
  getSFDCObjects = (data, callback) => {
    const { objectNames, primary } = data;
    axios.get(`${API_ROOT}/sfdcSources/objects?names=${objectNames.join(",")}&primary=${primary}`).then(response => {
      callback(response);
    });
  };
  getDeploymentAudits = async (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/customers/${customerId}/projects/${projectId}/connect/deploymentAudits`).then(response => {
      callback(response.data);
    });
  };
  deployConnectContent = (customerId, projectId, connectionInfo, callback) => {
    axios.post(`${API_ROOT}/customers/${customerId}/projects/${projectId}/connect/deploy`, connectionInfo).then(response => {
      callback(response);
    });
  };
  transformResponse = response => {
    const refSources = {};
    if (response && response.data && response.data.object && response.data.object[0] && response.data.object[0].AllLists) {
      if (response.data.object[0].AllLists.ReferenceSources) {
        response.data.object[0].AllLists.ReferenceSources.forEach(r => {
          refSources[r.id] = r;
        });
      }
      if (response.data.object[0].AllLists.Sources || response.data.object[0].AllLists.HRSources) {
        let sourcesList = [];
        if (Array.isArray(response.data.object[0].AllLists.Sources) && response.data.object[0].AllLists.Sources.length > 0) {
          sourcesList.push(...response.data.object[0].AllLists.Sources);
        }
        if (Array.isArray(response.data.object[0].AllLists.HRSources) && response.data.object[0].AllLists.HRSources.length > 0) {
          sourcesList.push(...response.data.object[0].AllLists.HRSources);
        }
        sourcesList.forEach(s => {
          if (s.connector === "sfdc" && s.objectDetails) {
            s.fields.data = getSFDCFields({ primary: s.primaryObject, fieldDetails: s.objectDetails, selectedRelations: s.selectedRelations, existingFields: s.fields.data });
          }
          if (s.relationships) {
            s.relationships.forEach(r => {
              const existingFields = r.fields ? r.fields : [];
              const columns = [];
              if (refSources && refSources[r.referenceSource] && refSources[r.referenceSource].fields && refSources[r.referenceSource].fields.data) {
                refSources[r.referenceSource].fields.data.forEach(f => {
                  const index = existingFields.findIndex(e => e.id === f.id);
                  const fieldName = index > -1 ? existingFields[index].Name : f.Name;
                  const id = index > -1 ? existingFields[index].id : f.id;
                  const isMapped = index > -1 ? existingFields[index].isMapped : false;
                  const usedInFilter = f.usedInFilter;
                  const usedInJoin = index > -1 ? existingFields[index].usedInJoin : f.usedInJoin;
                  const usedInCustomValidation = index > -1 && existingFields[index].isCustomValidation ? existingFields[index].isCustomValidation : false;
                  const dataType = index > -1 ? existingFields[index]["Data Type"] : f["Data Type"];
                  const format = index > -1 ? existingFields[index].Format : f.Format;
                  const mandatory = f.Mandatory;
                  const displayName = f.DisplayName;
                  columns.push({
                    Name: fieldName,
                    "Data Type": dataType,
                    Format: format,
                    id: id,
                    isMapped: isMapped,
                    usedInFilter: usedInFilter,
                    usedInJoin: usedInJoin,
                    usedInCustomValidation: usedInCustomValidation,
                    DisplayName: displayName,
                    Mandatory: mandatory,
                  });
                });
              }
              r.fields = columns;
            });
          }
        });
      }
    }

    return response;
  };
  exportProjectConfigurations = (customerId, projectId, callback) => {
    axios.get(`${API_ROOT}/copyUtility/download_projectConfig/customer/${customerId}/project/${projectId}`).then(response => {
      callback(response.data);
    });
  };
  importProjectConfigurations = (data, callback) => {
    axios.post(`${API_ROOT}/copyUtility/import_projectConfig`, data).then(response => {
      callback(response.data);
    });
  };
}
export default Server;
