import RateTableInput from "./RateTableBuilder";
import React, { Component, useEffect, Fragment, useRef, useState } from "react";
import { DropDown } from "@xactlycorp/xactly-core-components";

const RateTableWithAssignment = props => {
  const titleList = props.titleAssignments.map(item => ({ id: item, name: props.lists.AllObjects[item].name }));
  const [selectedTitles, setTitleSelection] = useState(props.currData.selectedTitles || props.titleAssignments);
  const [tableName, setTableName] = useState(props.currData.tableName || `${props.defaultTableName}${props.id === 0 ? "" : `_${props.id}`}`);
  const [data, setData] = useState(props.currData);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    props.tableData(data, props.id);
  }, [data]);

  useEffect(() => {
    let titleIds = titleList.map(item => item.id);
    let newTitles = selectedTitles.filter(item => titleIds.includes(item));
    setTitleSelection(newTitles);
  }, []);

  // handler for title dropbox change
  const handleTitleSelection = titles => {
    let err = [];
    if (titles.length === 0) {
      props.disableParent(true);
      err.push(`At least one title assignment is required per table`);
    } else {
      setTitleSelection(titles);
      let newData = { ...data };
      newData.selectedTitles = titles;
      setData(newData);
    }
    setErrors(err);
  };

  // handler for table name change

  const handleTableNameChange = (event, save) => {
    let newTableName = event.target.value;
    setTableName(newTableName);
    if (save) {
      let newData = { ...data };
      newData.tableName = newTableName;
      setData(newData);
    }
  };

  // handler for rate table change
  const handleRateDataUpdate = rateData => {
    let newData = { ...data };
    newData.columns = rateData.columns;
    newData.data = rateData.data;
    newData.tableName = tableName;
    newData.selectedTitles = selectedTitles;
    setData(newData);
  };

  return (
    <div className="">
      {errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <ul>
            {errors.map(err => (
              <li>{err}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="SubSection" id={`rateTable_${props.id}`}>
        <div style={{ margin: "10px" }}>
          {props.removable && <button type="button" onClick={() => props.deleteTable(props.id)} className="glyphicon glyphicon-trash btn-padded RightAlign" />}
          {titleList.length > 0 && (
            <DropDown
              className="dynamicContent"
              data={titleList}
              textPropName="name"
              valuePropName="id"
              changeHandler={handleTitleSelection}
              multiselect={true}
              validationMessage={""}
              required={true}
              searchBox={false}
              size="large"
              selectedValue={selectedTitles}
            />
          )}
        </div>
        {props.removable && (
          <div class="form-group field field-string">
            <label>
              <span>Rate Table Name: </span>
              <input
                type="text"
                id={`rateTable_${props.id}_tableName`}
                onChange={event => handleTableNameChange(event, false)}
                onBlur={event => handleTableNameChange(event, true)}
                value={tableName}
              ></input>
            </label>
          </div>
        )}
        <RateTableInput
          currData={props.currData}
          answers={props.answers}
          lists={props.lists}
          GlobalVariables={props.GlobalVariables}
          currProps={props.currProps}
          tableData={handleRateDataUpdate}
          disableParent={props.disableParent}
          titles={selectedTitles}
        />
      </div>
    </div>
  );
};
export default RateTableWithAssignment;
