import React, { Component } from "react";
import ReactTable from "react-table";
import Server from "./Server";
import PlanComponentSelection from "./PlanComponentSelection";
import fileDownload from "js-file-download";
import { Parser } from "json2csv";
import LoadingOverlay from "react-loading-overlay";
import { getNamesForListID, getIdForListName } from "./ManageObjectIDs";
import store from "store";
import matrixFunctions from "./ManageCompMatrix";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import FilterElement from "./tableFilters";
import ComponentSummary from "./componentSummary";
import Modal from "react-modal";

const csvExportFields = [
  { label: "Question", value: "Question" },
  { label: "Answer", value: "Answer" },
  { label: "Section", value: "Path" }
];
const json2csvParser = new Parser({ fields: csvExportFields });

const server = new Server();

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.Summary = { SectionSummary: { SectionSummaryData: [] } };
    this.Project = "";

    this.state = {
      planComponentSelectionVisibility: false,
      compMatrixOverride: {
        compMatrix: { data: [], columns: [] }
      },
      disableCompMatrixSubmit: true,
      overridenMatrix: { columns: [], data: [] },
      compMatrix: { columns: [], data: [] },
      dataReady: false,
      LoadingOverlayMessage: "Loading Dashboard....",
      data: [],
      columns: [],
      RuleColumns: [],
      RuleData: [],
      RuleDataTransform: [],
      projectId: store.get("user")[store.get("currentUser")]["projectId"],
      customerId: store.get("user")[store.get("currentUser")]["customerId"],
      showComponentSummary: false,
      selectedIncentive: ""
    };
  }

  componentDidMount() {
    this.getProjectDetails();
  }

  getProjectDetails = () => {
    let projectId = store.get("user")[store.get("currentUser")]["projectId"];
    let customerId = store.get("user")[store.get("currentUser")]["customerId"];
    server.getProjectDetails(customerId, projectId, response => {
      this.Project = response.data.object[0];

      if (this.Project.Summary) {
        this.updateState();
      } else {
        this.setState({
          dataReady: true
        });
      }
    });
  };
  updateState = () => {
    let compMatrix = this.Project.Summary.compMatrix;
    let override = this.Project.GlobalVariables && this.Project.GlobalVariables.compMatrixOverride ? this.Project.GlobalVariables.compMatrixOverride : undefined;
    this.UnfitSections = this.Project.UnfitSections;
    this.UnfitSectionsWithStatusTrue = this.UnfitSections.filter(item => item.unfit === true);
    this.unfitIncentiveIds = this.UnfitSectionsWithStatusTrue.filter(item => item.unfit === true).map(item => item.incentiveId);
    let compMatrixOverride = matrixFunctions.generateCompMatrixOverride(compMatrix, override, this.UnfitSectionsWithStatusTrue);
    let overridenMatrix = matrixFunctions.overrideCompMatrix(compMatrix, compMatrixOverride);

    this.setState({
      compMatrixOverride: compMatrixOverride,
      disableCompMatrixSubmit: true,
      planComponentSelectionVisibility: true,
      dataReady: true,
      compMatrix: compMatrix,
      overridenMatrix: overridenMatrix,
      RuleColumns: this.Project.Summary.RuleAttributesCols,
      RuleData: this.Project.Summary.RuleAttributesData
    });
  };
  displayMatrix = compMatrix => {
    compMatrix.columns.forEach(col => {
      col.style = { whiteSpace: "unset", overflow: "hidden" };
      if (!["Plans", "Titles"].includes(col.Header)) {
        let incentive = col.accessor;
        col.accessor = col.Header = getNamesForListID(incentive, this.Project.AllLists["Incentives"]);
        if (this.unfitIncentiveIds.includes(incentive)) {
          col.Header = (
            <p>
              {col.Header}
              {"  "}
              <FontAwesomeIcon icon={faLock} />
            </p>
          );
        } else {
          col.Header = (
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({ showComponentSummary: true, selectedIncentive: getIdForListName(incentive, this.Project.AllLists["Incentives"]) });
              }}
            >
              {col.Header}
            </button>
          );
          col.sortable = false;
        }
      } else {
        col["Filter"] = ({ filter, onChange }) => FilterElement(filter, onChange);
      }
    });
    compMatrix.data.forEach(item => {
      item.plan = getNamesForListID(item.plan, this.Project.AllLists["Plans"]);
      item.role = getNamesForListID(item.role, this.Project.AllLists["Titles"]);

      for (let key in item) {
        if (!["plan", "role", "rowLevelProps"].includes(key)) {
          let newKey = getNamesForListID(key, this.Project.AllLists["Incentives"]);
          if (newKey !== key) {
            item[newKey] = item[key];
            delete item[key];
          }
        } else if (key === "rowLevelProps") {
          Object.keys(item.rowLevelProps).forEach(inc => {
            let newKey = getNamesForListID(inc, this.Project.AllLists["Incentives"]);
            if (newKey !== inc) {
              item.rowLevelProps[newKey] = item.rowLevelProps[inc];
              delete item.rowLevelProps[inc];
            }
          });
        }
      }
    });

    return compMatrix;
  };

  TableSubmission = (TableData, callback) => {
    let m = matrixFunctions.overrideCompMatrix(this.state.compMatrix, TableData);
    this.setState({ compMatrixOverride: TableData, overridenMatrix: m }, () => {
      callback();
    });
  };

  submit = () => {
    this.setState({
      dataReady: false,
      LoadingOverlayMessage: "Saving CompMatrix Override...."
    });

    server.updateCompMatrixOverride(this.state.customerId, this.state.projectId, { overridenMatrix: this.state.overridenMatrix, compMatrixOverride: this.state.compMatrixOverride }, response => {
      this.Project.GlobalVariables.overridenMatrix = response.data.object[0].GlobalVariables.overridenMatrix;
      this.Project.GlobalVariables.compMatrixOverride = response.data.object[0].GlobalVariables.compMatrixOverride;
      this.updateState();
    });
  };
  updateDisableSubmit = () => {
    this.setState({ disableCompMatrixSubmit: false });
  };

  handleQuestionSelect = qid => {
    localStorage.setItem("lastRendered", qid);
    this.props.history.replace("/projects");
  };

  render() {
    return (
      <LoadingOverlay
        active={!this.state.dataReady}
        spinner
        text={this.state.LoadingOverlayMessage}
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
            position: "absolute",
            top: 0
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)"
          })
        }}
      >
        <div>
          <div className="NewSection">
            <h5>Plan Incentive Matrix</h5>
            <div className="Tabular">
              <ReactTable
                columns={this.displayMatrix(JSON.parse(JSON.stringify(this.state.overridenMatrix))).columns}
                data={this.displayMatrix(JSON.parse(JSON.stringify(this.state.overridenMatrix))).data}
                filterable
                defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
                className="-striped -highlight"
                defaultPageSize={10}
              />
            </div>
          </div>
          <div className="NewSection">
            <h5>Plan Component Selection</h5>
            <h6>Use this matrix to include/exclude plans, incentives or titles prior to rule generation</h6>
            {this.state.planComponentSelectionVisibility ? (
              <PlanComponentSelection
                unfitSections={this.UnfitSectionsWithStatusTrue}
                compMatrixOverride={JSON.parse(JSON.stringify(this.state.compMatrixOverride))}
                project={this.Project}
                tableData={this.TableSubmission.bind(this)}
                displayMatrix={this.displayMatrix}
                updateDisableSubmit={this.updateDisableSubmit}
              />
            ) : (
              ""
            )}
          </div>

          <div className="NewSection">
            <button className="btn btn-primary btn-padded RightAlign" onClick={this.submit} disabled={this.state.disableCompMatrixSubmit}>
              Save Updated Matrix
            </button>
          </div>
        </div>
        <div id="componentSummary">
          <Modal
            isOpen={this.state.showComponentSummary}
            contentLabel="Component Summary"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => {
              this.setState({ showComponentSummary: false, selectedIncentive: "" });
            }}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "80%"
              }
            }}
          >
            <ComponentSummary
              lists={this.Project.AllLists}
              params={{ level: "Incentive", value: this.state.selectedIncentive }}
              SendQuestionPath={this.handleQuestionSelect} //<TODO>: need to test
              closeModal={() => {
                this.setState({ showComponentSummary: false, selectedIncentive: "" });
              }}
            ></ComponentSummary>
          </Modal>
        </div>
      </LoadingOverlay>
    );
  }
}
