import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";

import React, { PureComponent } from "react";
import Expression from "./expressionBuilder";

const mandatoryStyle = {
  color: "red",
  fontWeight: "bold",
};

class EditableCell extends PureComponent {
  constructor(props) {
    super(props);

    this.className = "";
    this.hasExamples = this.props.cellInfo.column.props.allowExamples;
    let example = "";
    //update this to pull from
    if (this.hasExamples && this.props.cellInfo.original[this.props.cellInfo.column.id]) {
      example = this.props.cellInfo.original[`Example_${this.props.cellInfo.column.id}`] ? this.props.cellInfo.original[`Example_${this.props.cellInfo.column.id}`] : "";
    }

    this.state = {
      value: this.props.cellInfo.value || "",
      cellInfo: this.props.cellInfo,
      showExamples: false,
      example: example,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.cellInfo !== prevState.cellInfo) {
      return { cellInfo: nextProps.cellInfo };
    } else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cellInfo !== this.props.cellInfo) {
      let val = this.transformDataForDisplay(this.props.cellInfo.value);
      this.setState({ cellInfo: this.props.cellInfo, value: val });
    }
  }
  componentDidMount() {
    let val = this.transformDataForDisplay(this.props.cellInfo.value);
    this.setState({ cellInfo: this.props.cellInfo, value: val });
  }
  handleExpressionChange = exp => {
    this.setState({ value: exp });
    this.props.saveCell(this.transformDataForSave(exp), this.state.cellInfo);
  };
  inputChange = e => {
    let val = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    let validation = this.validData(val);
    let parentRowId = this.state.cellInfo.original.ParentRow !== undefined ? `${parseInt(this.state.cellInfo.original.ParentRow) + 1}.` : "";

    if (!validation.status) {
      this.className = "has-error";
      this.props.hasErrors(true, { column: this.state.cellInfo.column.id, row: `${parentRowId}${this.state.cellInfo.index + 1}` }, validation.errorType);
    } else {
      this.className = "";
      this.props.hasErrors(false, { column: this.state.cellInfo.column.id, row: `${parentRowId}${this.state.cellInfo.index + 1}` }, validation.errorType);
    }
    this.setState({ value: val });
  };

  transformDataForDisplay = val => {
    // this function can be used to modify data prior to display. e.g. Change date to yyyy-mm-dd format from mm/dd/yyyy
    if (this.state.cellInfo.column.props.type === "date") {
      val = val.split("/");
      val = [val[2], val[0], val[1]].join("-");
    }
    //design better to pass a default value. Maybe based on question schema file
    if (val === undefined) {
      val = "";
    }
    if (typeof val === "object" && val.hasOwnProperty("val")) {
      val = val.val;
    }

    return val;
  };

  transformDataForSave = val => {
    // this function can be used to modify data prior to save. e.g. Change date to mm/dd/yyyy
    if (this.state.cellInfo.column.props.type === "date") {
      val = val.split("-");
      val = [val[1], val[2], val[0]].join("/");
    }
    return val;
  };

  confirmChange = val => {
    let defaultMessage = "Are you sure you want to make this change?";
    let oldVal = this.props.cellInfo.value;
    //console.log(`Old Value: ${this.props.cellInfo.value}, New Value:${val}`);
    if (oldVal !== val) {
      let confirmation = window.confirm(this.state.cellInfo.column.props.confirmationMessage || defaultMessage);
      if (!confirmation) {
        val = oldVal;
      }
    }
    return val;
  };
  validData = val => {
    // check for null
    let s = { status: true, errorType: "Null Value" };
    //if column is marked nullable then skip the check
    if (!this.state.cellInfo.column.props.nullable) {
      if (!val || val === "") {
        s = { status: false, errorType: "Null Value" };
      }
    }
    return s;
  };

  saveChange = e => {
    let val = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (this.validData(val)) {
      if (this.state.cellInfo.column.props.confirmation) {
        val = this.confirmChange(val);
      }
    }
    if (e.target.id === "example") {
      //save example data only here:
      this.props.saveCellExample(val, this.state.cellInfo);
    } else {
      this.props.saveCell(this.transformDataForSave(val), this.state.cellInfo);
    }
  };

  disableField = () => {
    return (
      this.state.cellInfo.original.rowLevelProps &&
      this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id] &&
      this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id]["Disabled"]
    );
  };

  isMandatory = () => {
    return (
      this.state.cellInfo.original.rowLevelProps &&
      this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id] &&
      this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id]["Mandatory"]
    );
  };

  rowLevelHtmlTag = () => {
    if (this.state.cellInfo.original.rowLevelProps && this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id]) {
      return this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id]["type"];
    }
  };

  rowLevelSelectOpt = () => {
    if (this.state.cellInfo.original && this.state.cellInfo.original.isRequired && this.state.cellInfo.column.props.selectOptions) {
      return this.state.cellInfo.column.props.selectOptions.filter(option => option !== "Leave Blank");
    }
    return this.state.cellInfo.column.props.selectOptions || this.props.selectOptions;
  };

  render() {
    let tag = this.rowLevelHtmlTag() || this.state.cellInfo.column.props.type;
    let selectOptions = this.rowLevelSelectOpt();
    let placeholder = "";
    let defaultValue = "";
    if (this.state.cellInfo.original.rowLevelProps && this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id]) {
      let rowLevelProps = this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id];
      if (rowLevelProps.selectOptions) {
        selectOptions = rowLevelProps.selectOptions;
      }
      if (rowLevelProps.placeholder) {
        placeholder = rowLevelProps.placeholder;
      }
      if (rowLevelProps.defaultValue) {
        defaultValue = rowLevelProps.defaultValue;
      }
    }

    return (
      <div className="editableCell">
        <div className={this.className}>
          {tag === "select" && (
            <select
              disabled={this.disableField()}
              className="form-control"
              value={this.state.value || defaultValue}
              onInput={e => {
                this.inputChange(e);
                this.saveChange(e);
              }}
              onChange={e => {}}
            >
              <option value="" disabled>
                Select..
              </option>
              {selectOptions.map(opt => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          )}
          {tag === "checkbox" && (
            <div key={this.state.value} style={{ display: "flex", flexDirection: "row" }}>
              <p>{this.state.cellInfo.column.props.label ? this.state.cellInfo.column.props.label : ""}</p>
              <input
                type="checkbox"
                key={this.state.value}
                disabled={this.disableField()}
                id={this.state.value.toString()}
                name={this.state.value.toString()}
                checked={this.state.value}
                className="editableCellCheckbox"
                onChange={e => {
                  this.inputChange(e);
                  this.saveChange(e);
                }}
              />
            </div>
          )}
          {["text", "number", "date"].includes(tag) && (
            <input
              className="form-control"
              type={tag || "text"}
              min={this.state.cellInfo.column.props.min}
              max={this.state.cellInfo.column.props.max}
              step={this.state.cellInfo.column.props.step}
              value={this.state.value && this.state.value.hasOwnProperty("expWithName") ? this.state.value.expWithName : this.state.value}
              onChange={e => this.inputChange(e)}
              disabled={this.disableField()}
              onBlur={e => this.saveChange(e)}
              autoComplete="none"
              placeholder={placeholder}
              {...(this.isMandatory() && { style: { ...mandatoryStyle } })}
            />
          )}
          {["textarea"].includes(tag) && (
            <textarea
              className="form-control"
              type={this.state.cellInfo.column.props.type || "text"}
              min={this.state.cellInfo.column.props.min}
              max={this.state.cellInfo.column.props.max}
              step={this.state.cellInfo.column.props.step}
              value={this.state.value && this.state.value.hasOwnProperty("expWithName") ? this.state.value.expWithName : this.state.value}
              onChange={e => this.inputChange(e)}
              disabled={this.disableField()}
              onBlur={e => this.saveChange(e)}
              autoComplete="none"
            />
          )}
          {["expression"].includes(tag) && (
            <Expression
              options={this.state.cellInfo.original.rowLevelProps[this.state.cellInfo.column.id].options}
              value={this.state.value}
              type={this.props.type || ""}
              lists={this.props.lists || this.state.cellInfo.column.props.lists}
              fieldInfo={{ selectedSource: this.state.cellInfo.column.id }}
              handleExpressionChange={this.handleExpressionChange}
            />
          )}
          {this.hasExamples && (
            <div style={{ float: "left" }}>
              {this.state.example && this.state.example !== "" ? (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  onClick={() => {
                    this.setState({ showExamples: !this.state.showExamples });
                    return;
                  }}
                ></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon
                  icon={faCaretSquareDown}
                  onClick={() => {
                    this.setState({ showExamples: !this.state.showExamples });
                    return;
                  }}
                />
              )}
              {this.state.showExamples && (
                <input
                  style={{ float: "right", display: "block", width: "80%" }}
                  type="text"
                  id="example"
                  name="example"
                  onBlur={e => this.saveChange(e)}
                  onChange={evt => {
                    this.setState({ example: evt.target.value });
                  }}
                  placeholder="E.g."
                  defaultValue={this.state.example}
                ></input>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

class EditableCellRateTable extends EditableCell {
  constructor(props) {
    super(props);
    this.baseRate = "";
    this.state = {
      value: this.props.cellInfo.value,
      cellInfo: this.props.cellInfo,
      calculationType: this.props.cellInfo.column.props.calculationType,
    };
  }

  transformDataForDisplay = val => {
    this.baseRate = "";
    // this function can be used to modify data prior to display. e.g. Change date to yyyy-mm-dd format from mm/dd/yyyy
    if (val && isNaN(parseFloat(val))) {
      //split calculated rate expression into base rate and numeric multiplier
      let x = val.split("*");
      this.baseRate = [x[0], "*"].join("");
      val = parseFloat(x[1]);
    }
    // if (this.state.calculationType === "Amount") {
    //   val = val / 100;
    // } else
    else if (val === undefined) {
      val = "";
    }
    return val;
  };

  transformDataForSave = val => {
    //val = this.baseRate ? `${this.baseRate} ${val.trim()}` : this.state.calculationType === "Amount" ? val * 100 : val;
    val = this.baseRate ? `${this.baseRate} ${val.trim()}` : val;
    return val;
  };
  render() {
    return (
      <div className="editableCell">
        <div className={this.className}>
          <div className="form-inline">
            {this.baseRate}
            <input
              className="form-control"
              type={this.state.cellInfo.column.props.type || "text"}
              min={this.state.cellInfo.column.props.min}
              max={this.state.cellInfo.column.props.max}
              step={this.state.cellInfo.column.props.step}
              value={this.state.value}
              onChange={e => this.inputChange(e)}
              disabled={this.disableField()}
              onBlur={e => this.saveChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { EditableCell, EditableCellRateTable };
