/**
 * ### Overview
 * This component is used as a mapping to dynamically render different versions of a component
 */
const ComponentMap = {
  DataMappingTable: {
    default: "DataTableBuilder",
    v2: "DataTableInput",
    v3: "DataTableInput_v1"
  },
  UpdateLists: {
    default: "UpdateLists",
    v2: "updateLists_v2",
    v3: "updateLists_v3",
    v4: "updateLists_v4",
    v5: "updateLists_v5"
  },
  RateTableBuilder: {
    default: "RateTableBuilder",
    v1: "rateTableBuilder_v1"
  }
};

export { ComponentMap };
