import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import Modal from "react-modal";
import Moment from "react-moment";
import store from "store";
import Server from "./Server";
import LoadingOverlay from "react-loading-overlay";
import { Form, FormGroup, ControlLabel, FormControl, Checkbox, Radio, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faCopy, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, PrimaryButton, SecondaryButton } from "@xactlycorp/xactly-core-components";

const server = new Server();

const CopyToClipboard = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return <PrimaryButton text={isCopied ? "Copied!" : "Copy Url"} type="button" clickHandler={handleCopyClick} icon={<FontAwesomeIcon icon={faCopy} />} />;
};

const DateCell = ({ value: initialValue, row: { index }, column: { id } }) => {
  if (initialValue) {
    let dateValue = new Date(initialValue);
    return <Moment format="YYYY/MM/DD HH:mm:ss">{dateValue}</Moment>;
  } else {
    return "";
  }
};

const DISABLED_STYLE = {
  padding: "15px 15px 0px",
  opacity: 0.4,
};

const USER_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "80%",
    height: "80%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const LOADING_OVERLAY_STYLE = {
  wrapper: base => ({
    ...base,
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
    zIndex: "1",
  }),
  spinner: base => ({
    ...base,
    height: "50px",
  }),
  overlay: base => ({
    ...base,
    position: "fixed",
    background: "rgba(0, 0, 0, 0.2)",
  }),
};

const DEFAULT_FORM_STATE = {
  isEditing: false,
  formError: null,
  user: "",
  userId: "",
  userType: "Reviewer",
  selectedGroups: [],
};

export const RenderLoader = () => {
  return <LoadingOverlay spinner active={true} text={"Loading ..."} styles={LOADING_OVERLAY_STYLE}></LoadingOverlay>;
};

const RenderCustomerForm = props => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const { closeModal, fetchFrdUsers, editFrdUser } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  useEffect(() => {
    if (editFrdUser) {
      setFormState({
        ...formState,
        isEditing: true,
        user: editFrdUser.user || "",
        userId: editFrdUser._id,
      });
    } else {
      setFormState(DEFAULT_FORM_STATE);
    }
  }, [editFrdUser]);

  const validation = () => {
    let error = null,
      valid = true;
    if (!formState.user) {
      error = "Customer Email is required";
      valid = false;
    }

    setFormState({
      ...formState,
      formError: error,
    });
    return valid;
  };

  const inputChangeHandler = e => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const submitHandler = event => {
    if (projectId && customerId) {
      if (validation()) {
        setIsLoading(true);
        const form = event.currentTarget;
        const data = {
          user: form.user.value,
          accessibleTabs: ["ImplementationSurvey"],
        };
        server.createImpSurveyUser(customerId, projectId, data, response => {
          if (response.status) {
            closeModal();
            fetchFrdUsers();
          }
          setIsLoading(false);
        });
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <div style={{ position: "absolute", top: "8px", right: "8px" }}>
        <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />} clickHandler={closeModal} iconSize={20} />
        <Tooltip id="closeModal" placement="left">
          Close
        </Tooltip>
      </div>
      <div className="SubSection">
        {isLoading && <RenderLoader />}
        <h4>Customer Details</h4>
        <Form onSubmit={submitHandler}>
          {formState.formError && <Alert bsStyle="danger">{formState.formError}</Alert>}
          <FormGroup className="mb-3" controlId="customerId">
            <ControlLabel id="customerId">Email</ControlLabel>
            <FormControl type="input" name="user" onChange={inputChangeHandler} value={formState.user} placeholder="Customer ID" disabled={formState.isEditing} />
          </FormGroup>
          <FormGroup style={{ paddingTop: "15px" }}></FormGroup>
          <PrimaryButton text={formState.isEditing ? "Update" : "Save"} type="submit" />
        </Form>
      </div>
    </>
  );
};

const ImplementationSurveyDashboard = () => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [isLoading, setIsLoading] = useState(false);
  const [cutomerData, setCutomerData] = useState([]);
  const [editFrdUser, setEditFrdUser] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const columns = [
    {
      Header: "User Id",
      accessor: "user",
    },
    {
      Header: "expiration",
      accessor: "expirationTime",
      Cell: DateCell,
    },
    {
      Header: "Actions",
      accessor: "",
      maxWidth: 250,
      Cell: ({ original }) => {
        let url = `${window.location.origin}/clientAccess?token=${original.tokenId}`;
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <CopyToClipboard copyText={url} />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchFrdUsers();
  }, []);

  //<TODO>: Only fetch the imp tokens
  function fetchFrdUsers() {
    let data = { type: "ImplementationSurvey" };
    if (customerId && projectId) {
      setIsLoading(true);

      server.getAccessTokens(customerId, projectId, data, response => {
        if (response.status) {
          if (response.data && response.data.object && response.data.object.length) {
            setCutomerData(response.data.object);
          } else {
            setCutomerData([]);
          }
        }
        setIsLoading(false);
      });
    }
  }

  function openUserModal() {
    setIsUserModalOpen(true);
  }

  function closeUserModal() {
    setEditFrdUser(null);
    setIsUserModalOpen(false);
  }

  return (
    <div>
      {isLoading && <RenderLoader />}
      <div className="NewSection" style={{ borderWidth: "0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "8px 0" }}>
          <h5>Customer List</h5>
          <PrimaryButton
            text="Add User"
            type="button"
            clickHandler={openUserModal}
            data-tip="react-tooltip"
            data-for={!!(customerId && projectId) ? "" : "addUserBtn"}
            enabled={!!(customerId && projectId)}
          />
          <Tooltip id="addUserBtn" placement="left">
            Please select the Customer and Project.
          </Tooltip>
        </div>
        <div className="Tabular">
          <ReactTable
            columns={columns}
            data={cutomerData}
            className="-striped -highlight"
            defaultPageSize={10}
            filterable={true}
            defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
          />
        </div>
      </div>
      <Modal isOpen={isUserModalOpen} contentLabel="User Modal" onRequestClose={closeUserModal} style={USER_MODAL_STYLE} shouldCloseOnOverlayClick={false}>
        <RenderCustomerForm closeModal={closeUserModal} fetchFrdUsers={fetchFrdUsers} editFrdUser={editFrdUser} />
      </Modal>
    </div>
  );
};

export default ImplementationSurveyDashboard;
