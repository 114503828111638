import React, { useState } from "react";
import FrdApprovers from "./CustomerUserPage";
import FrdApproverGroups from "./frdApproverGroups";
import ProjectManagementDashboard from "./projectManagementDashboardv2";
import ImplementationSurveyDashboard from "./implementationSurveyDashboard";
import ImportProjectConfiguration from "./ImportProjectConfiguration";
import { Tabs, Tab } from "react-bootstrap";

const adminDashboard = props => {
  const [activeTab, setActiveTab] = useState("ProjectManagementDashboard");

  return (
    <div>
      <Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={k => setActiveTab(k)}>
        <Tab eventKey="ProjectManagementDashboard" title="Project Management Dashboard">
          <ProjectManagementDashboard />
        </Tab>
        <Tab eventKey="FrdApprovers" title="FRD Approvers">
          <FrdApprovers />
        </Tab>
        <Tab eventKey="ApproverGroups" title="Approver Groups">
          <FrdApproverGroups />
        </Tab>
        <Tab eventKey="ImplementationSurveyDashboard" title="Implementation Survey">
          <ImplementationSurveyDashboard />
        </Tab>
        {window.location.host !== process.env.PROD_HOST_NAME && (
          <Tab eventKey="ImportProjectConfiguration" title="Copy Projects">
            <ImportProjectConfiguration />
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default adminDashboard;
