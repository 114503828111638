import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import { generateID, getNamesForListID } from "./ManageObjectIDs";
import { getCustomWidgets, getCustomFields, getQueryBuilder, getFilterBuilder } from "./jsonSchemaFormCustomWidgets";
import Server from "./Server";
import ImportSourceFile from "./importSourceFile";
import ImportSFDCMetadata from "./importSFDCMetadata";
import DynamicSelect from "./dynamicSelect";
import { validateNewValue } from "./UpdateLists";
import Modal from "react-modal";
import DeletionModule from "./deletionModule";
import CommonResources from "./CommonResources.json";
import LoadingOverlay from "react-loading-overlay";
import ArrayFieldTemplate from "./arrayFieldTemoplate";

import { addIdToXactlySources, addQualifierAttributes } from "./ManageObjectIDs";
import IncentDefaultSources from "./IncentDefaultSources.json";
var dataSourceDetailsGenericSchema = require("./dataSourceDetailsSchema.json");
const dataSourceDetailsSFDCSchema = require("./dataSourceDetailsSFDCSchema.json");

function DataSourceDetails(props) {
  const server = new Server();
  const [selectedSource, setSelectedSource] = useState(props.selectedSource || "");
  const [sourceList, setsourceList] = useState(
    (props.showReferenceSources ? props.lists["ReferenceSources"] : props.sourceType === "DataMapping" ? props.lists["Sources"] : props.lists["HRSources"]) || [],
  );
  const useRefrenceSources = props.showReferenceSources;

  const [sourceDetails, setsourceDetails] = useState(sourceList.filter(item => item.id === selectedSource)[0]);
  const isSFDC = sourceDetails && sourceDetails.connector && sourceDetails.connector === "sfdc";
  const dataSourceDetailsSchema = isSFDC ? dataSourceDetailsSFDCSchema : dataSourceDetailsGenericSchema;
  const schema = useRefrenceSources ? dataSourceDetailsSchema.refSchema : dataSourceDetailsSchema.sourceSchema;
  const IS_INCENT_SOURCE = sourceDetails && sourceDetails.connector && (sourceDetails.connector === "incent_order_item" || sourceDetails.connector === "incent_participant");
  if (IS_INCENT_SOURCE && schema.properties && schema.properties.fields) {
    // Explicitly disable the add/delete row options for ORDER ITEMS and PARTICIPANTS type of reference sources.
    schema.properties.fields.disableAdditionalRows = true;
  }
  let uiSchema = dataSourceDetailsSchema.uiSchema;
  // disable connector field for following type source.
  if (sourceDetails && sourceDetails.connector && ["ftp", "sfdc", "netsuite", "custom", "incent_order_item", "incent_participant"].includes(sourceDetails.connector)) {
    uiSchema = { ...uiSchema, connector: { "ui:widget": "select", "ui:disabled": true } };
  }
  const [listsForExpression, setlistsForExpression] = useState(props.lists);
  const listType = useRefrenceSources ? "ReferenceSources" : props.sourceType === "DataMapping" ? "Sources" : "HRSources";
  //Custom Widgets and Fields if need be:
  const widgets = getCustomWidgets({ ...schema, lists: listsForExpression, sourceType: props.sourceType });
  widgets.queryBuilder = qprops => getQueryBuilder({ ...qprops, sourceType: props.sourceType, lists: listsForExpression, primarySourceId: sourceDetails.id });
  widgets.filterBuilder = qprops => getFilterBuilder({ ...qprops, sourceType: props.sourceType, lists: listsForExpression, primarySourceId: sourceDetails.id });
  const fields = getCustomFields(schema, { AllLists: props.lists, sourceDetails: sourceDetails, idKey: "SourceColumn" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState("");
  const [dataReady, setDataReadyFlag] = useState(true);
  const [mappedRelationships, setMappedRelationships] = useState([]);

  useEffect(() => {
    const srcDetails = sourceList.filter(item => item.id === selectedSource)[0];
    const usedFields = new Set();
    if (useRefrenceSources) {
      const mappedRefSources = [];
      [...props.lists["Sources"], ...props.lists["HRSources"]].forEach(src => {
        src.relationships &&
          src.relationships.forEach(relationship => {
            if (relationship.referenceSource === selectedSource) {
              mappedRefSources.push({ ...relationship, Name: `${src.name} / ${relationship.name}` });
            }
          });
      });
      setMappedRelationships(mappedRefSources);
      mappedRefSources.forEach(ms => {
        if (ms.fields) {
          ms.fields.forEach(f => {
            if (f.isMapped || f.usedInFilter || f.usedInJoin || f.isCustomValidation) {
              usedFields.add(f.Name);
            }
          });
        }
      });
      if (usedFields.size > 0) {
        srcDetails.fields.data = srcDetails.fields.data.map(f => {
          if (usedFields.has(f.Name)) {
            f.isMapped = true;
          }
          return f;
        });
      }
      setsourceDetails(srcDetails);
    }
  }, [selectedSource]);

  const SubmitResponse = ({ formData }) => {
    setDataReadyFlag(false);
    //server call to updateList
    //Add ID fields
    let data = JSON.parse(JSON.stringify(formData));
    let newAllObjects = props.lists.AllObjects;
    /*
    //Add ID's to source fields:
    if (data.fields && data.fields.data) {
      data.fields.data.forEach(item => {
        item.Name = item.Name.trim();
        newAllObjects[item.id] = { name: item.Name, type: "SourceField" };
      });
    }*/
    for (var key in newAllObjects) {
      if (newAllObjects[key].type === "SourceField") {
        delete newAllObjects[key];
      }
    }

    if (data.fields && data.fields.data) {
      let filterExpression = data.filterExpression;
      try {
        filterExpression = JSON.parse(data.filterExpression);
        data.fields.data = data.fields.data.map(f => {
          const field = { ...f };
          if (data.sourceFilter === true && filterExpression.resolvedQuery.includes(`${data.id}.${f.id}`)) {
            field.usedInFilter = true;
          } else {
            field.usedInFilter = false;
          }
          if (useRefrenceSources) {
            field.isMapped = false;
          }
          return field;
        });
      } catch (err) {
        //console.log(err);
      }
    }
    const relQueries = [];
    let mappingStr = "";
    //Add ID's to join criteria fields
    if (data.relationships) {
      data.relationships = data.relationships.map(item => {
        if (item.name) {
          if (item.id === undefined) {
            item.id = generateID("JoinCondition");
          }
          item.name = item.name.trim();
          const relationshipData = JSON.parse(item.relationship);
          relQueries.push(relationshipData.resolvedQuery);
          const referenceId = relationshipData.referenceSourceId ? relationshipData.referenceSourceId : relationshipData.referenceSource;
          item.referenceSource = referenceId;
        } else {
          item = item.trim();
        }
        return item;
      });
      mappingStr = relQueries.concat(relQueries).join(", ");
      data.relationships = data.relationships.map(item => {
        const relationshipData = JSON.parse(item.relationship);
        const referenceId = relationshipData.referenceSourceId ? relationshipData.referenceSourceId : relationshipData.referenceSource;
        const refObj = props.lists.ReferenceSources.find(s => s.id === referenceId);
        item.connector = refObj.connector;
        let usedFields = [];
        refObj.fields.data.forEach(d => {
          if (data.referenceSourceUsed === true && mappingStr.includes(`${refObj.id}.${d.id}`)) {
            const newField = { ...d };
            newField.usedInJoin = true;
            usedFields = usedFields.filter(obj => obj.id !== newField.id);
            usedFields.push(newField);
          }
        });
        Array.isArray(item.fields) &&
          item.fields.forEach(f => {
            if (f.isMapped || f.usedInFilter || f.usedInJoin || f.isCustomValidation) {
              usedFields.push({ ...f });
            }
          });
        item.fields = usedFields;
        return item;
      });
    }
    if (relQueries.length > 0) {
      if (data.fields && data.fields.data) {
        data.fields.data = data.fields.data.map(d => {
          const newField = { ...d };
          if (data.referenceSourceUsed && mappingStr.includes(`${data.id}.${d.id}`)) {
            newField.usedInJoin = true;
          } else {
            newField.usedInJoin = false;
          }
          return newField;
        });
      }
    } else {
      if (data.fields && data.fields.data) {
        data.fields.data = data.fields.data.map(d => {
          const newField = { ...d };
          newField.usedInJoin = false;
          return newField;
        });
      }
    }

    // If data field names have any special characters. then replace with underscore and add it to new DisplayName field.
    if (!isSFDC && data && data.fields && data.fields.data && data.fields.data.length) {
      const modifiedFieldArr = [];
      data.fields.data.forEach(field => {
        if (field.Name && field.Name.match(/\W/g)) {
          let updatedFieldName = field.Name.trim().replace(/\W/g, "_");
          if (modifiedFieldArr.includes(updatedFieldName)) {
            updatedFieldName = updatedFieldName + modifiedFieldArr.filter(element => element === updatedFieldName).length;
          }
          modifiedFieldArr.push(updatedFieldName);
          field["DisplayName"] = updatedFieldName;
        }
      });
    }

    // update name in AllObjects for source
    newAllObjects[formData.id].name = formData.name;
    let updatedList = {};
    updatedList[listType] = [];
    updatedList.AllObjects = newAllObjects;

    sourceList.forEach(element => {
      if (element.hasOwnProperty("id") && element.id === selectedSource) {
        updatedList[listType].push(data);
      } else {
        updatedList[listType].push(element);
      }
    });
    server.updateLists(props.customerId, props.projectId, updatedList, resp => {
      server.updateLists(props.customerId, props.projectId, { Sources: resp.data.object[0].AllLists.Sources }, response => {
        setDataReadyFlag(true);
        props.closeDataSourceDetailsModule();
        props.saveProjectDetails(response.data.object[0]);
      });
    });
  };
  const updateForm = newSourceDetails => {
    setsourceDetails(updateRowLevelProps(newSourceDetails));
  };
  const updateSFDCData = data => {
    const sourceDetailsCopy = { ...sourceDetails };
    sourceDetailsCopy.connectionInfo = data.connectionInfo;
    sourceDetailsCopy.selectedObjects = data.selectedObjects;
    sourceDetailsCopy.fields = data.fields;
    sourceDetailsCopy.primaryObject = data.primaryObject;
    sourceDetailsCopy.relations = data.relations;
    sourceDetailsCopy.objectDetails = data.objectDetails;
    sourceDetailsCopy.selectedRelations = data.selectedRelations;
    setsourceDetails(sourceDetailsCopy);
  };
  const handleChange = ({ formData }) => {
    //Rerender realtionship custom widget for real time relationship response to changing name fields
    if (formData && sourceDetails) {
      if (formData.referenceSourceUsed && JSON.stringify(formData.relationships) !== JSON.stringify(sourceDetails.relationships)) {
        const dataCopy = JSON.parse(JSON.stringify(formData));
        const resolvedQueries = [];
        dataCopy.relationships = dataCopy.relationships.map((r, index) => {
          if (r.relationship) {
            try {
              const relationship = JSON.parse(r.relationship);
              resolvedQueries.push(relationship.resolvedQuery);
              if (!r.referenceSource) {
                r.referenceSource = relationship.referenceSource;
              }
              if (!r.id) {
                r.id = generateID("JoinCondition");
              }
            } catch (err) {}
          } else {
            if (!r.created) {
              r.name = `DefaultRelationship_${index + 1}`;
              r.created = true;
            }
          }
          return r;
        });
        if (resolvedQueries.length > 0) {
          const mappingStr = resolvedQueries.join(", ");
          dataCopy.relationships = dataCopy.relationships.map((r, index) => {
            if (mappingStr.includes(`${r.id}.`)) {
              r.usedInOtherJoin = true;
            } else {
              r.usedInOtherJoin = false;
            }
            return r;
          });
        }
        let newArr = JSON.parse(JSON.stringify(listsForExpression));
        newArr[listType].forEach((item, index, arr) => {
          if (item.id === selectedSource) {
            arr[index] = dataCopy;
          }
        });
        setlistsForExpression(newArr);
        setsourceDetails(dataCopy);
      }
      if (JSON.stringify(formData.fields) !== JSON.stringify(sourceDetails.fields)) {
        let data = updateRowLevelProps(formData);
        setsourceDetails(data);
      }

      if (useRefrenceSources && JSON.stringify(formData.connector) !== JSON.stringify(sourceDetails.connector)) {
        if (formData.connector === "incent_order_item" || formData.connector === "incent_participant") {
          let IncentSources = addIdToXactlySources(IncentDefaultSources.filter(incent_source => incent_source.name === formData.connector));
          IncentSources = addQualifierAttributes(IncentSources, props.lists.QualifierAttributes);
          formData.fields.data = IncentSources[0].fields.data;
          let data = updateRowLevelProps(formData);
          setsourceDetails(data);
        }
      }
    }
  };

  const updateRowLevelProps = data => {
    let formData = JSON.parse(JSON.stringify(data));
    if (formData && formData.fields && formData.fields.data) {
      [...formData.fields.data].forEach(row => {
        if (row["Data Type"] && (row["Data Type"] === "date" || row["Data Type"].toLowerCase() === "datetime")) {
          let selectOptions = row["Data Type"] === "date" ? CommonResources.dateSelectOptions : CommonResources.dateTimeSelectOptions;
          if (!row["Format"]) {
            row["Format"] = row["defaultValue"];
          }
          if (!selectOptions.includes(row["Format"])) {
            row["Format"] = "";
            row["defaultValue"] = "";
          }
          row.rowLevelProps = {
            Format: {
              type: "select",
              defaultValue: row["defaultValue"],
              selectOptions: selectOptions,
            },
          };
        } else {
          row.Format = "";
          row.rowLevelProps = { Format: { type: "text", defaultValue: "", value: "", Disabled: true } };
        }
        if (IS_INCENT_SOURCE) {
          formData.fields.columns.forEach(col => {
            if (row.rowLevelProps[col.accessor]) {
              row.rowLevelProps[col.accessor]["Disabled"] = true;
            } else {
              row.rowLevelProps[col.accessor] = { Disabled: true };
            }
          });
        }
      });
    }
    return formData;
  };
  const addReferenceSource = source => {
    let key = generateID(listType);
    let listItem = { name: source, id: key, uploadMethod: "native" };
    let newList = props.lists;
    if (!newList.AllObjects) {
      newList.AllObjects = {};
    }
    if (!newList[listType]) {
      newList[listType] = [];
    }
    newList[listType].push(listItem);
    newList.AllObjects[key] = { name: source, type: listType };
    let listData = {};
    listData[listType] = newList[listType];
    listData.AllObjects = newList.AllObjects;
    setSelectedSource(listItem.id);
    setsourceDetails({ ...listItem });
    setsourceList([...newList[listType]]);
    setDataReadyFlag(false);
    server.updateLists(props.customerId, props.projectId, listData, response => {
      props.saveProjectDetails(response.data.object[0]);
      setDataReadyFlag(true);
    });
    return key;
  };

  const validateRefSource = source => {
    return validateNewValue("ReferenceSources", source);
  };
  const openDeletionModal = source => {
    setDeletedItem(source);
    setShowDeleteModal(true);
  };
  const deleteRefrenceSource = sourceId => {
    //1) Update ReferenceSources list
    let newList = [];
    let reqData = {};
    props.lists.ReferenceSources.forEach(source => {
      if (source.id && source.id !== sourceId) {
        newList.push(source);
      }
    });
    reqData[`ReferenceSources`] = newList;
    //2) Update the AllObjects entry:
    if (sourceId) {
      reqData[`AllObjects.${sourceId}.invalid`] = true;
    }
    //3) Make server call with new AllLists object:
    server.updateLists(props.customerId, props.projectId, reqData, response => {
      setSelectedSource("");
      setShowDeleteModal(false);
      props.saveProjectDetails(response.data.object[0]);
      setsourceList(response.data.object[0].AllLists[listType]);
    });
  };

  const transformErrors = errors => {
    return errors.map(error => {
      if (error.name === "pattern" && error.property.startsWith(".relationships[") && error.property.endsWith("].name")) {
        error.message = "Name should be at least 2 characters long, contain only alphanumeric letters and underscore, start with an alphabet, and end with an alphanumeric character.";
      }
      return error;
    });
  };

  const validateOptions = (formData, errors) => {
    //relationships validations
    const selectedSource = formData;
    let relationshipList = {};
    if (formData.relationships) {
      formData.relationships.forEach((relationship, index) => {
        if (relationshipList[relationship.name]) {
          errors.relationships[index].name.addError(`Relationship Name: ${relationship.name} is duplicated`);
        } else {
          relationshipList[relationship.name] = true;
        }
        if (!relationship.hasOwnProperty("relationship")) {
          errors.relationships[index].name.addError(`No Relationship defined for ${relationship.name}`);
        }
        try {
          const parsedRel = JSON.parse(relationship.relationship);
          const referenceSource = props.lists.ReferenceSources.find(r => r.id === parsedRel.referenceSource);
          Object.keys(parsedRel.queries).forEach(q => {
            const query = parsedRel.queries[q];
            const srcFieldSrc = srcField.sourceId === selectedSource ? selectedSource : referenceSource;
            const srcField = srcFieldSrc.fields.data.find(f => f.id === query.firstField.id);
            if (!srcField) {
              errors.relationships[index].relationship.addError(`Invalid source field ${query.firstField.label}`);
            } else if (srcField["Data Type"] !== query.firstField.dataType) {
              errors.relationships[index].relationship.addError(`Data type mismatch for source field ${query.firstField.label}`);
            }
            if (query.secondValue.displayField && query.secondValue.displayField === true) {
              const refField = referenceSource.fields.data.find(f => f.id === query.secondValue.fieldValue.id);
              if (!refField) {
                errors.relationships[index].relationship.addError(`Invalid reference field ${query.secondValue.fieldValue.label}`);
              } else if (refField["Data Type"] !== query.secondValue.fieldValue.dataType) {
                errors.relationships[index].relationship.addError(`Data type mismatch for reference field ${query.secondValue.fieldValue.label}`);
              }
            }
          });
        } catch (error) {}
      });
    }
    //field validations
    let fieldList = {};
    if (formData.fields) {
      formData.fields.data.forEach(field => {
        if (!field.Name) {
          errors.addError(`Field Name is empty`);
        }
        if (fieldList[field.Name]) {
          errors.addError(`Field Name: ${field.Name} is duplicated`);
        } else {
          fieldList[field.Name] = true;
        }
        if (!field.hasOwnProperty("Data Type")) {
          errors.addError(`Field Name: ${field.Name} does not have a valid Data Type`);
        }
        if (field["Data Type"] && field["Data Type"] === "date" && !field["Format"]) {
          errors.addError(`Date Field : ${field.Name} should have format.`);
        }
        if (field["Data Type"] && field["Data Type"].toLowerCase() === "datetime" && !field["Format"]) {
          errors.addError(`Date Time Field : ${field.Name} should have format.`);
        }
      });
    }
    // Source duplicate name validation.
    const index = sourceList.findIndex(element => {
      if (element.id === formData.id) {
        return false;
      }
      return !!(element.name && formData.name) && element.name.trim().toLowerCase() === formData.name.trim().toLowerCase();
    });
    if (index !== -1) {
      errors.addError(`'${formData.name}' already exist in ${listType}`);
    }
    return errors;
  };

  const filterXRefSource = list => {
    return list.filter(item => item.type !== "xactly_incent_source");
  };
  return (
    <div id="dataSourceDetailsForm">
      <LoadingOverlay
        active={!dataReady}
        spinner
        text={useRefrenceSources ? "Saving Reference Source details.." : "Saving Data Source details.."}
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      >
        {useRefrenceSources ? (
          <div>
            <h2>{"Select/Add a Reference Source: "}</h2>
            <DynamicSelect
              data={filterXRefSource(sourceList)}
              labelKey="name"
              valueKey="id"
              onSelectionChange={newSource => {
                setSelectedSource(newSource[0]);
              }}
              caption={"Select/Add Reference Source"}
              addCaption={"Add New Reference Source"}
              multiSelect={false}
              onAdd={addReferenceSource}
              addItemLabel={"Reference Source"}
              validate={validateRefSource}
              onDelete={openDeletionModal}
            />
          </div>
        ) : null}
        {selectedSource !== "" ? (
          <div>
            <h3>{getNamesForListID(selectedSource, sourceList)}</h3>
            {isSFDC ? (
              <ImportSFDCMetadata formData={sourceDetails} mappedRelationships={mappedRelationships} updateData={updateSFDCData} />
            ) : IS_INCENT_SOURCE ? (
              <></>
            ) : (
              <ImportSourceFile formData={sourceDetails} mappedRelationships={mappedRelationships} updateForm={updateForm} />
            )}
            <Form
              schema={schema}
              uiSchema={uiSchema}
              formData={sourceDetails}
              onSubmit={SubmitResponse}
              onChange={handleChange}
              validate={validateOptions}
              widgets={widgets}
              fields={fields}
              autocomplete="none"
              transformErrors={transformErrors}
              ArrayFieldTemplate={ArrayFieldTemplate}
            >
              <button type="submit" className="btn btn-info" style={{ margin: "20px" }}>
                Save
              </button>
              <button type="button" className="btn btn-info" style={{ margin: "20px" }} onClick={e => props.closeDataSourceDetailsModule()}>
                Cancel
              </button>
            </Form>
          </div>
        ) : null}
      </LoadingOverlay>
      <Modal
        style={{
          content: {
            height: "50%",
            width: "75%",
            marginLeft: "10px",
          },
        }}
        isOpen={showDeleteModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        onRequestClose={() => setShowDeleteModal(false)}
      >
        <DeletionModule
          objectId={deletedItem}
          AllLists={props.lists}
          setShowDeleteModal={setShowDeleteModal}
          setSelectedSource={setSelectedSource}
          deleteItem={deleteRefrenceSource}
          SendQuestionPath={props.SendQuestionPath}
        />
      </Modal>
    </div>
  );
}

export default DataSourceDetails;
