import Cover from "./cover";
import React, { useState, useEffect } from "react";
import { DropDown } from "@xactlycorp/xactly-core-components";
import { incentStandardFields } from "./util";
import RenderExpression from "./renderExpression";
import QueryBuilder from "./QueryBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import GenericTable from "./GenericTable";
import objectpath from "object-path";
import classes from "./QueryBuilder.module.css";

const radioButtonWidget = props => {
  const [disabled, setDisabled] = useState(props.disabled === true);
  let currValue = props.value ? props.value[0] : "";
  let labels = props.schema.items.enumNames ? props.schema.items.enumNames : props.schema.items.enum;
  useEffect(() => {
    setDisabled(props.disabled === true);
  }, [props.disabled]);

  return (
    <Cover disabled={props.options.disabled || disabled}>
      <div className="field-radio-group" id="root_Response">
        {props.schema.items.enum.map((item, index) => {
          return (
            <div key={item} className="radio ">
              <label>
                <span>
                  <input type="radio" name={item} required="" value={item} checked={item === currValue} onChange={e => props.onChange([e.target.value])} />
                  <span>{labels[index]}</span>
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </Cover>
  );
};

const dropDownWidget = props => {
  let currValue = props.value ? (props.value.length > 1 ? props.value : props.value[0]) : "";

  const handleChange = selectedValue => {
    let value = props.schema.type === "array" ? (props.multiSelect ? selectedValue : [selectedValue]) : selectedValue;
    //  let value = props.multiSelect ? selectedValue : [selectedValue];
    props.onChange(value);
  };

  const addOptionHandler = () => {
    props.callback(props.schema.bindToList);
  };

  return (
    <Cover disabled={props.options.disabled || props.disabled}>
      <div className="form-group">
        <DropDown
          className="dynamicContent"
          data={props.data}
          textPropName="name"
          valuePropName="id"
          changeHandler={handleChange}
          multiselect={props.multiSelect || false}
          validationMessage={""}
          required={true}
          searchBox={true}
          size="large"
          selectedValue={currValue}
          labels={props.labels}
          addNewButtonClickHandler={addOptionHandler}
        />
      </div>
    </Cover>
  );
};

const jsonSchemaFormToPrismData = props => {
  let names = props.names;
  let data = props.ids.map((item, index) => {
    return { name: names[index], id: item };
  });
  return data;
};
const singleSelectAddable = props => {
  let listName = props.schema.bindToList;
  let data = jsonSchemaFormToPrismData({ names: props.schema.enumNames ? props.schema.enumNames : props.schema.enum, ids: props.schema.enum });
  let dropDownProps = { ...props };
  dropDownProps.searchable = true;
  dropDownProps.data = data;
  dropDownProps.labels = { addNewButtonText: `Add new ${listName}` };
  return dropDownWidget(dropDownProps);
};

const expressionBuilder = props => {
  return (
    <RenderExpression
      exp={props.value}
      lists={props.lists}
      handleInputChange={(exp, isValid) => {
        props.onChange(exp);
      }}
      options={{ expressionType: "query", sourceType: props.sourceType }}
    />
  );
};

const getCustomWidgets = (schema, callback) => {
  let widgets = {};
  if (schema.properties.Response && ["ComputationAttributes", "QualifierAttributes"].includes(schema.properties.Response.bindToList)) {
    widgets.CheckboxesWidget = props => {
      let multiSelect = schema.properties.Response.maxItems !== 1;
      let updatedProps = { searchable: true, multiSelect: multiSelect, ...props };
      let standardFieldIds =
        schema.properties.Response.bindToList === "ComputationAttributes"
          ? incentStandardFields.filter(item => item.dataType === "number" && item.type === "OrderItem" && item.visibleInFormulas === true).map(item => item.id)
          : incentStandardFields.filter(item => item.visibleInFormulas === true).map(item => item.id);
      let standardFieldNames =
        schema.properties.Response.bindToList === "ComputationAttributes"
          ? incentStandardFields.filter(item => item.dataType === "number" && item.type === "OrderItem" && item.visibleInFormulas === true).map(item => item.name)
          : incentStandardFields.filter(item => item.visibleInFormulas === true).map(item => item.name);

      if (schema.properties.Response.bindToList === "QualifierAttributes" && schema.properties.Response.parseTag !== "NewSourceQualifierAttribute") {
        standardFieldIds = incentStandardFields.filter(item => item.dataType === "number" && item.visibleInFormulas === true).map(item => item.id);
        standardFieldNames = incentStandardFields.filter(item => item.dataType === "number" && item.visibleInFormulas === true).map(item => item.name);
      }
      if (schema.properties.Response.parseTag == "NewSourceQualifierAttribute" && schema.properties.Response.bindToList === "QualifierAttributes") {
        standardFieldIds = incentStandardFields.filter(item => item.visibleInFormulas === true).map(item => item.id);
        standardFieldNames = incentStandardFields.filter(item => item.visibleInFormulas === true).map(item => item.name);
      }
      updatedProps.schema.items.enum = [...new Set(standardFieldIds.concat(updatedProps.schema.items.enum))];
      updatedProps.schema.items.enumNames = [...new Set(standardFieldNames.concat(updatedProps.schema.items.enumNames))];

      let labels = props.schema.items.enumNames ? props.schema.items.enumNames : props.schema.items.enum;
      let data = props.schema.items.enum.map((item, index) => {
        let groupName = standardFieldIds.includes(item) ? "Incent Standard Attributes" : "CustomAttributes";
        return { name: labels[index], id: item, groupName };
      });
      updatedProps.data = data;
      updatedProps.labels = { caption: "Select an option" };

      return dropDownWidget(updatedProps);
    };
  } else if (schema.properties.Response && schema.properties.Response.maxItems === 1) {
    widgets.CheckboxesWidget = radioButtonWidget;
  }

  widgets.singleSelectAddable = props => {
    const updatedProps = { ...props };
    updatedProps.callback = callback;
    return singleSelectAddable(updatedProps);
  };
  widgets.customRadio = radioButtonWidget;
  widgets.query = props => {
    const updatedProps = { ...props };
    updatedProps.lists = schema.lists;
    updatedProps.sourceType = schema.sourceType;
    return expressionBuilder(updatedProps);
  };
  return widgets;
};

const genericTable = props => {
  //console.log("generic table rendered");

  if (props.schema.defaultData) {
    if (props.schema.defaultData.source === "objectPath") {
      let sourceData = objectpath.get(props.additionalData, props.schema.defaultData.props.path);
      if (sourceData && props.schema.defaultData.props.filter) {
        Object.entries(props.schema.defaultData.props.filter).forEach(element => {
          sourceData = sourceData.filter(item => item[element[0]] === element[1]);
        });
      }
      let d = sourceData.data.map(row => {
        let newRow = {};
        /////////////////////////////////////////////////////
        if (props.schema.defaultData.conditionalMapping) {
          for (let column of Object.keys(props.schema.defaultData.conditionalMapping)) {
            if (row.hasOwnProperty(column) && row[column] === props.schema.defaultData.conditionalMapping[column].value) {
              //overwrite each col prop here:
              for (let mapping of Object.keys(props.schema.defaultData.conditionalMapping[column].setColumnData)) {
                let mapped_field = props.schema.defaultData.props.columnMapping[mapping];
                row[mapped_field] = props.schema.defaultData.conditionalMapping[column].setColumnData[mapping];
              }
            }
          }
        }
        ////////////////////////////////////////////////////
        for (const col in props.schema.defaultData.props.columnMapping) {
          if (props.schema.defaultData.props.columnMapping.hasOwnProperty(col)) {
            const element = props.schema.defaultData.props.columnMapping[col];
            if (Array.isArray(row[element])) {
              newRow[col] = row[element].join(", ");
            } else {
              newRow[col] = row[element];
            }
          }
        }
        newRow.rowLevelProps = props.schema.rowLevelProps;
        return newRow;
      });

      props.schema.defaultData.data = d;
    }
    props.schema.properties.data.default = props.schema.defaultData.data;
  }

  return (
    <GenericTable
      addRowLabel={props.schema.addRowLabel || "Add Row"}
      mergeKey={props.schema.mergeKey}
      cols={props.schema.columns}
      editableCols={props.schema.editableColumns}
      editableColumnProps={props.schema.editableColumnProps}
      currData={props.formData ? props.formData : {}}
      lists={props.additionalData.AllLists}
      defaultColumnData={props.schema.defaultColumnData}
      defaultData={props.schema.defaultData ? props.schema.defaultData.data : undefined}
      disableAdditionalRows={props.schema.disableAdditionalRows}
      autoRefresh={props.schema.autoRefresh}
      disableRefresh={props.schema.disableRefresh}
      rowLevelProps={props.schema.rowLevelProps}
      tableData={d => {
        props.onChange(d);
      }}
      idKey={props.additionalData.idKey}
      showPagination={false}
      filterable={props.schema.filterable}
      pageSize={props.formData && props.formData.data ? props.formData.data.length : 5}
      conditionalDeleteRow={props.schema.conditionalDeleteRow}
    />
  );
};

const titleAssignmentTable = props => {
  return (
    <div>
      <GenericTable
        mergeKey={props.schema.mergeKey}
        cols={props.schema.columns}
        editableCols={props.schema.editableColumns}
        editableColumnProps={props.schema.editableColumnProps}
        currData={props.formData ? props.formData : {}}
        lists={props.additionalData.AllLists}
        defaultColumnData={props.schema.defaultColumnData}
        defaultData={props.schema.defaultData ? props.schema.defaultData.data : undefined}
        disableAdditionalRows={props.schema.disableAdditionalRows}
        autoRefresh={props.schema.autoRefresh}
        disableRefresh={props.schema.disableRefresh}
        rowLevelProps={props.schema.rowLevelProps}
        tableData={d => {
          props.onChange(d);
        }}
        idKey={props.additionalData.idKey}
        showPagination={false}
        filterable={props.schema.filterable}
        pageSize={props.formData && props.formData.data ? props.formData.data.length : 5}
      />
    </div>
  );
};

//Generalize
const getCustomFields = (schema, data) => {
  let fields = {};
  fields.genericTable = props => {
    const updatedProps = { ...props };
    updatedProps.additionalData = data;

    return genericTable(updatedProps);
  };
  return fields;
};

const QueryBuilderWidget = props => {
  const refrenceSources = props.lists.ReferenceSources;
  const sourceLists = props.sourceType === "DataMapping" ? props.lists.Sources : props.lists.HRSources;
  let primarySource = sourceLists.filter(s => s.id === props.primarySourceId);
  if (primarySource.length == 0) {
    primarySource = props.lists.ReferenceSources.filter(s => s.id === props.primarySourceId);
  }
  const rowId = props.getRowId(props);
  const generateIDMapping = props => {
    const allSources = [...sourceLists, ...refrenceSources];
    const sourceIdToNames = {};
    const fieldIdToNames = {};
    allSources.forEach(item => {
      sourceIdToNames[item.id] = item.name.replace(/[\s "]/g, "");
      if (item.fields && item.fields.data) {
        let sourceFieldMapping = {};
        item.fields.data.forEach(f => {
          if (f.id && f.Name) {
            sourceFieldMapping[f.Name.replace(/[\s "]/g, "")] = f.id;
            fieldIdToNames[f.id] = f.Name.replace(/[\s "]/g, "");
          }
        });
      }
    });
    if (primarySource[0].relationships) {
      primarySource[0].relationships.forEach(r => {
        sourceIdToNames[r.id] = r.name ? r.name.replace(/[\s "]/g, "") : "";
      });
    }
    return { sourceIdToNames, fieldIdToNames };
  };

  const expIdToName = (exp, idMap) => {
    let newExp = exp ? exp : "";
    Object.entries(idMap).forEach(pair => {
      let sRegExInput = new RegExp(`\\b${pair[0]}\\b`, "g");
      newExp = newExp.replace(sRegExInput, pair[1]);
    });
    return newExp;
  };
  const { sourceIdToNames, fieldIdToNames } = generateIDMapping(props);
  let data = props.value;
  let isOldImplementation = false;
  try {
    data = JSON.parse(props.value);
  } catch (error) {
    isOldImplementation = true;
  }
  const [queryData, setQueryData] = useState(!isOldImplementation ? data : {});
  const [value, setValue] = useState(!isOldImplementation ? queryData.resolvedQuery : data);
  const [isQueryBuilderVisible, setQueryBuilderVisible] = useState(false);
  const [queryKeyList, setQueryKeyList] = useState(queryData && queryData.queryKeyList ? queryData.queryKeyList : []);
  const [selectedSource, setSelectedSource] = useState(
    data && data.referenceSourceId ? data.referenceSourceId : data && data.referenceSource ? refrenceSources.find(r => r.name === data.referenceSource || r.id === data.referenceSource).id : "None",
  );
  const displayQueryBuilder = () => {
    setQueryBuilderVisible(true);
  };

  const updateSelectedSource = sourceName => {
    setSelectedSource(sourceName);
  };
  const onUpdate = (queries, queryKeyList) => {
    const queryList = [];
    const variables = new Set();
    const getRHSValue = (firstField, rhs) => {
      if (rhs.displayVariable) {
        variables.add(
          `v_${rhs.variable
            .trim()
            .replace(/^<(.*)>$/, "$1")
            .trim()
            .toLowerCase()
            .replace(/[!@#$%^&*(),.?":{}|-\s]/g, "_")}`,
        );
      }
      const rhsValue = rhs.displayField
        ? rhs.transformFunction && rhs.transformFunction.value === "subString"
          ? `SubString(${rhs.fieldValue.sourceId}.${rhs.fieldValue.id},${rhs.functionData.index})`
          : `${rhs.fieldValue.sourceId}.${rhs.fieldValue.id}`
        : rhs.displayValue
        ? firstField.dataType === "string" || firstField.dataType === "text"
          ? `'${rhs.value.trim()}'`
          : firstField.dataType === "date"
          ? `ToDate('${rhs.value}')`
          : firstField.dataType === "dateTime"
          ? `ToDate('${rhs.value}')`
          : `${rhs.value}`
        : rhs.displayVariable
        ? `:v_${rhs.variable
            .trim()
            .replace(/^<(.*)>$/, "$1")
            .trim()
            .toLowerCase()
            .replace(/[!@#$%^&*(),.?":{}|-\s]/g, "_")}`
        : "";
      console.log(rhsValue);
      return rhsValue;
    };
    queryKeyList.forEach(k => {
      const v = queries[k];
      const { firstField, operator, secondValue } = v;
      let query = "";
      let sValue = "";
      switch (operator.value) {
        case "between":
          query = `${firstField.sourceId}.${firstField.id} BETWEEN ${getRHSValue(firstField, secondValue.firstOption)} AND ${getRHSValue(firstField, secondValue.secondOption)}`;
          break;
        case "in":
          sValue = `(${getRHSValue(firstField, secondValue)})`;
          sValue = firstField.dataType === "string" || firstField.dataType === "text" ? sValue.trim().replace(/\s*,\s*/g, "', '") : sValue;
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${sValue}`;
          break;
        case "not in":
          sValue = `(${getRHSValue(firstField, secondValue)})`;
          sValue = firstField.dataType === "string" || firstField.dataType === "text" ? sValue.trim().replace(/\s*,\s*/g, "', '") : sValue;
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${sValue}`;
          break;
        default:
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${getRHSValue(firstField, secondValue)}`;
          break;
      }
      if (props.enableLogicalOperator) {
        query = queryList.length > 0 ? (v.logicalOperator === "OR" ? `) ${v.logicalOperator} (${query}` : ` ${v.logicalOperator} ${query}`) : `( ${query}`;
      } else {
        query = queryList.length > 0 ? `AND ${query}` : query;
      }
      v.resolvedQuery = query;
      queryList.push(query);
    });
    let resolvedQuery = queryList.join(" ");
    if (props.enableLogicalOperator) {
      resolvedQuery = `${resolvedQuery} )`;
    }
    setValue(resolvedQuery);
    setQueryKeyList(queryKeyList);
    const updatedQueries = {
      queries,
      queryKeyList,
      resolvedQuery,
      primarySource: primarySource[0].name,
      referenceSourceId: selectedSource,
      referenceSource: selectedSource,
      variables: [...variables],
    };
    setQueryData(updatedQueries);
    props.onChange(JSON.stringify(updatedQueries));
    setQueryBuilderVisible(false);
  };
  const validRefs = refrenceSources.filter(r => r.fields);
  const primaryFields =
    primarySource && primarySource.length > 0 && primarySource[0].fields && primarySource[0].fields.data
      ? primarySource[0].fields.data.map(f => {
          return {
            value: `${primarySource[0].name}.${f["Name"]}`,
            label: `${primarySource[0].name}.${f["Name"]}`,
            fieldName: f["Name"],
            dataType: f["Data Type"],
            source: primarySource[0].name,
            isPrimary: true,
            format: f["Format"],
            id: f["id"],
            sourceId: primarySource[0].id,
          };
        })
      : [];
  if (primarySource[0].relationships) {
    primarySource[0].relationships.forEach((rel, index) => {
      if (rel.referenceSource && index < rowId) {
        const refSource = refrenceSources.find(r => r.id === rel.referenceSource);
        refSource.fields.data.forEach(f => {
          primaryFields.push({
            value: `${rel.name}.${f["Name"]}`,
            label: `${rel.name}.${f["Name"]}`,
            fieldName: f["Name"],
            dataType: f["Data Type"],
            source: rel.name,
            isPrimary: true,
            format: f["Format"],
            id: f["id"],
            sourceId: rel.id,
          });
        });
      }
    });
  }
  const refSource = refrenceSources.filter(r => r.id === selectedSource);
  const referenceFields =
    refSource && refSource.length > 0
      ? refSource[0].fields.data.map(f => {
          return {
            value: `${refSource[0].name}.${f["Name"]}`,
            label: `${refSource[0].name}.${f["Name"]}`,
            fieldName: f["Name"],
            dataType: f["Data Type"],
            source: refSource[0].name,
            isPrimary: false,
            id: f["id"],
            format: f["Format"],
            sourceId: refSource[0].id,
          };
        })
      : [];
  const fields = primaryFields.concat(referenceFields);
  const gridTemplateColumns = props.useReferenceSource ? "30% 80% 5px" : "90% 5px";
  return (
    <div style={{ display: "grid", flexDirection: "row", gridTemplateColumns: gridTemplateColumns, columnGap: "10px" }}>
      {props.useReferenceSource && (
        <select
          {...(selectedSource !== "None" && {
            disabled: true,
          })}
          name="refSource"
          id="refSource"
          value={selectedSource}
          className="form-control"
          onChange={e => {
            updateSelectedSource(e.target.value);
          }}
        >
          <option key={-1} value="None">
            Select One
          </option>
          {validRefs.map((r, i) => {
            return (
              <option key={i} value={r.id}>
                {r.name}
              </option>
            );
          })}
        </select>
      )}
      <input className="form-control" type="text" value={expIdToName(value, { ...sourceIdToNames, ...fieldIdToNames })} disabled />
      <FontAwesomeIcon
        icon={faEdit}
        className={["Clickable", props.useReferenceSource === true && selectedSource === "None" ? classes.Disabled : ""].join(" ")}
        style={{ margin: "5px" }}
        {...((!props.useReferenceSource || selectedSource !== "None") && {
          onClick: displayQueryBuilder,
        })}
      />
      {isQueryBuilderVisible ? (
        <Modal
          style={{
            content: {
              height: "80%",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "8px",
            },
          }}
          isOpen={isQueryBuilderVisible}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={true}
          onRequestClose={() => setQueryBuilderVisible(false)}
        >
          <div style={{ margin: "10" }}>
            <QueryBuilder
              queryData={queryData}
              fields={fields}
              onSubmit={onUpdate}
              enableLogicalOperator={props.enableLogicalOperator}
              enableClose={true}
              onClose={() => setQueryBuilderVisible(false)}
              enableSourceFilter={props.enableSourceFilter}
              referenceSourceName={props.enableSourceFilter ? refSource[0].name : ""}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

const getRowId = props => {
  let rowId = 0;
  if (props.id) {
    const idParts = props.id.split("_");
    rowId = idParts.length == 4 ? idParts[2] : 0;
  }
  return rowId;
};

const getQueryBuilder = props => {
  return <QueryBuilderWidget {...props} useReferenceSource={true} enableSourceFilter={true} enableLogicalOperator={false} getRowId={getRowId} />;
};
const getFilterBuilder = props => {
  return (
    <QueryBuilderWidget
      {...props}
      useReferenceSource={false}
      enableSourceFilter={false}
      enableLogicalOperator={true}
      getRowId={function (props) {
        return 0;
      }}
    />
  );
};

export { getCustomWidgets, getCustomFields, getQueryBuilder, getFilterBuilder, QueryBuilderWidget };
