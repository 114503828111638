import React, { useState, useEffect } from "react";
import { PrimaryButton, ConfirmationDialog } from "@xactlycorp/xactly-core-components";

const ExitSection = props => {
  let incentive = props.incentive;
  let incentiveName = props.lists.AllObjects[incentive] ? props.lists.AllObjects[incentive].name : incentive;
  let unfitSections = props.unfitSections;
  const [showEmptyNotesError, setEmptyNoteErrorFlag] = useState(false);
  const [notes, setNotes] = useState("");
  let newUnfitIncentive = unfitSections.filter(item => item.incentiveId === incentive).length === 0; // check if this incentive is a new addition to the list.

  useEffect(() => {
    if (!newUnfitIncentive) {
      let note = unfitSections.filter(item => item.incentiveId === incentive)[0].notes;
      setNotes(note);
    }
  }, []);

  const updateNotes = e => {
    setNotes(e.target.value);
  };

  const reinstateIncentive = () => {
    if (!notes) {
      setEmptyNoteErrorFlag(true);
    } else {
      unfitSections.forEach(element => {
        if (element.incentiveId === incentive) {
          element.notes = notes;
          element.unfit = false;
        }
      });

      props.updateUnfitSections(unfitSections, !props.showReinstateButton);
    }
  };

  const updateUnfitSections = () => {
    if (!notes) {
      setEmptyNoteErrorFlag(true);
    } else {
      if (newUnfitIncentive) {
        unfitSections.push({
          incentiveId: incentive,
          notes: notes,
          unfit: true
        });
      } else {
        unfitSections.forEach(element => {
          if (element.incentiveId === incentive) {
            element.notes = notes;
            element.unfit = true;
          }
        });
      }

      props.updateUnfitSections(unfitSections, !props.showReinstateButton);
    }
  };
  const handleMissingNotesErrorModal = () => {
    setEmptyNoteErrorFlag(false);
  };

  return (
    <React.Fragment>
      <h4>Notes for Incentive: {incentiveName}</h4>

      <textarea style={{ height: "50%", width: "100%" }} placeholder="Enter incentive notes here" onChange={updateNotes} value={notes}></textarea>
      <div>
        {props.showReinstateButton && <PrimaryButton className="RightAlign btn-padded" text="Unlock" clickHandler={reinstateIncentive} iconSize={20} />}
        <PrimaryButton className="RightAlign btn-padded" text="Save" clickHandler={updateUnfitSections} iconSize={20} />
        <PrimaryButton className="RightAlign btn-padded" text="Cancel" clickHandler={() => props.toggleExitNotesDisplay()} iconSize={20} />
      </div>
      <ConfirmationDialog
        message="Notes cannot be empty"
        show={showEmptyNotesError}
        rightPanePrimaryButtonClickHandler={handleMissingNotesErrorModal}
        labels={{ headerText: "Error", rightPanePrimaryButtonText: "Ok" }}
        type="info"
      />
    </React.Fragment>
  );
};

export default ExitSection;
