import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { DropDown, PrimaryButton, Tooltip, ThemeProvider, XDropdownInput, XDropdownItem } from "@xactlycorp/xactly-core-components";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./dynamicSelect.css";
import ConfirmDialog from "../components/ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function DynamicSelect(props) {
  const { onSelectionChange, addCaption, caption, multiSelect, defaultSelect, labelKey, valueKey, onAdd, addItemLabel, onDelete, saveTableWarning = false, warningMsg } = props;
  const [data, setData] = useState();
  const [valueLables, setValueLables] = useState();
  const [selectedValue, setSelectedValue] = useState(defaultSelect || "");
  const [showInput, setShowInput] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isError, setIsError] = useState(true);
  const [errors, setErrors] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const defaults = {
    caption: multiSelect ? "Select option(s)" : "Select an option",
    addCaption: "Add new option",
    addItemLabel: "New Value",
  };
  const labels = {
    addNewButtonText: addCaption ? addCaption : caption.addCaption,
  };

  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      props.data.forEach(item => {
        getLabel(item);
      });
      setValueLables(props.data.map(x => x[labelKey]));
    }
  }, [props.data]);

  useEffect(() => {
    if (props.defaultSelect && !props.preventRender) {
      handleChange(props.defaultSelect);
    }
  }, [props.defaultSelect]);

  function handleChange(value, e) {
    setSelectedValue(value);
    var updateValues = value;
    if (!multiSelect) {
      updateValues = [updateValues];
    }
    if (onSelectionChange) {
      onSelectionChange(updateValues);
    }
  }
  function getLabel(item) {
    if (onDelete) {
      item["label"] = (
        <div title={item[labelKey]} style={{ "margin-left": "-16px", "padding-left": "16px" }}>
          <FontAwesomeIcon
            onClick={() => {
              props.onDelete(item.id);
            }}
            icon={faWindowClose}
            data-for="closeModal"
            data-tip="react-tooltip"
          />
          <div style={{ display: "inline-block", "padding-left": "10px" }}>{`${item[labelKey] ? item[labelKey] : item}`}</div>
        </div>
      );
    } else {
      item["label"] = item[labelKey];
    }
    return item;
  }

  function handleAddNew() {
    console.log("HANDLE ADD NEW ROW");
    if (onAdd) {
      setShowInput(true);
    }
  }

  function handleNewItemChange(evt) {
    setNewValue(evt.target.value);
    let errorList = errors;
    if (props.validate) {
      errorList = props.validate(evt.target.value);
      setIsError(errorList.length > 0);
      setErrors(errorList);
    }
    const index =
      valueLables &&
      valueLables.findIndex(element => {
        return new String(element).toLowerCase() === new String(evt.target.value).trim().toLowerCase();
      });
    if (index !== -1) {
      setIsError(true);
      setErrors([...errorList, "Please provide unique value for " + (addItemLabel ? addItemLabel : defaults.addItemLabel)]);
    }
  }

  function closeInputBox(saved) {
    if (saved) {
      let selectedSourceObj;
      data.forEach(source => {
        if (source.id === selectedSource) {
          selectedSourceObj = source;
        }
      });
      var key = onAdd(newValue, selectedSourceObj);
      let listItem = {};
      listItem["label"] = newValue;
      listItem[valueKey] = key;
      multiSelect ? setSelectedValue([...selectedValue, key]) : setSelectedValue(key);
      setData([...data, listItem]);
      //multiSelect ? setData([...data, listItem]) : setData(listItem);

      setValueLables([...valueLables, newValue]);
    }
    setNewValue("");
    setShowInput(false);
  }

  const errorTheme = {
    colorButtonPrimary: "red",
    fontWeightRegular: "bold",
    textSize: "1.3rem",
  };
  return (
    <>
      <DropDown
        className="dynamicContent"
        caption={caption ? caption : defaults.caption}
        labels={onAdd ? labels : {}}
        data={data}
        textPropName={"label"}
        valuePropName={valueKey}
        selectedValue={selectedValue}
        changeHandler={handleChange}
        addNewButtonClickHandler={saveTableWarning ? () => setShowWarning(true) : handleAddNew}
        multiselect={multiSelect}
        validationMessage={""}
        required={true}
        searchBox={false}
        size="large"
      />
      {/* <XDropdownInput
        onChange={function noRefCheck() {}}
        options={[
          <XDropdownItem key="1234JHGJH" value="Dog">
            Dog
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH3" value="Cat">
            Cat
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH4" value="Rabbit">
            Rabbit
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH5" value="Marlin">
            Marlin
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH6" value="Urchin">
            Urchin
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH7" value="Mermaid">
            Mermaid
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH8" value="Shipwreck">
            Shipwreck
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH9" value="Shark">
            Shark
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH10" value="Octopus">
            Octopus
          </XDropdownItem>,
          <XDropdownItem key="1234JHGJH11" value="Submarine">
            Submarine
          </XDropdownItem>
        ]}
        placeHolder="Select an Animal"
        value={null}
      /> */}
      <Modal
        isOpen={showInput}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        onRequestClose={() => setShowInput(false)}
        style={{
          content: {
            position: "relative",
            top: "41vh",
            height: "fit-content",
            left: "30vw",
            width: "40vw",
          },
        }}
      >
        <form className="dynamicselect_custom_form-wrapper">
          <FormGroup controlId="newItemForm" validationState={errors.length > 0 ? "error" : "success"} bsSize="small">
            <ControlLabel>{addItemLabel ? addItemLabel : defaults.addItemLabel}</ControlLabel>
            <FormControl autoFocus type="text" placeholder={"Enter " + (addItemLabel ? addItemLabel : defaults.addItemLabel)} value={newValue} onChange={handleNewItemChange} />
            {addItemLabel === "Data Source" && data && data.length > 0 && (
              <div className="copy-source-wrapper">
                <label>Copy Details from existing</label>
                <select
                  value={selectedSource}
                  onChange={e => {
                    setSelectedSource(e.target.value);
                  }}
                >
                  <option value="">None</option>
                  {data.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <FormControl.Feedback />
          </FormGroup>
          <PrimaryButton enabled={!isError} className="RightAlign btn-padded" data-tip="react-tooltip" data-for="errors" text="Save" clickHandler={() => closeInputBox(true)} iconSize={20} />
          <PrimaryButton className="RightAlign btn-padded" text="Cancel" clickHandler={() => closeInputBox(false)} iconSize={20} />
          {errors.length > 0 && (
            <ThemeProvider theme={errorTheme}>
              {errors.map(err => {
                return <div style={{ color: "#a94442" }}>{err}</div>;
              })}
              <Tooltip id="errors">{errors}</Tooltip>
            </ThemeProvider>
          )}
        </form>
      </Modal>
      <ConfirmDialog open={showWarning} setOpen={val => setShowWarning(val)} onConfirm={handleAddNew}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FontAwesomeIcon icon={faTriangleExclamation} color="#f0ad4e" size="2xl" />
          <div style={{ "padding-left": "10px" }}>
            <b>STOP!</b> {warningMsg || "Make sure that all changes to data mapping table is saved before proceeding further. Failed to do so may result in loss of unsaved mapping."}
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default DynamicSelect;
