import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const FilterElement = (filter, onChange) => {
  return (
    <div style={{ position: "relative" }}>
      <FontAwesomeIcon icon={faSearch} style={{ width: "10%" }} />
      <input
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ""}
        style={{
          width: "90%",
          backgroundColor: "white",
          color: "black"
        }}
      />
    </div>
  );
};
//<TODO>: Need to fix/change the filters
const subRowFilter = (filter, row) => {
  return row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false;
};

export { subRowFilter };
export default FilterElement;
