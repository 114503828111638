import listProps_v2 from "./listPropertiesConfig_v2.json";
import UpdateLists_v2, { validateNewValue } from "./updateLists_v2.js";

class UpdateLists_v3 extends UpdateLists_v2 {
  constructor(props) {
    super(props);
    this.listProps = listProps_v2;
  }
}

export { validateNewValue };
export default UpdateLists_v3;
