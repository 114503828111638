const MemberTypes = {
  step: {
    label: "Steps",
    key: "step"
  },
  pipeline: {
    label: "Pipelines",
    key: "pipeline"
  }
};
const ErrorColumns = [
  { Header: "Type", accessor: "type", maxWidth: "150" },
  { Header: "Area", accessor: "object", maxWidth: "250" },
  { Header: "Details", accessor: "msg" }
];
const DeploymentAuditColumns = [
  { Header: "Start Time", accessor: "startTime", maxWidth: "200" },
  { Header: "End Time", accessor: "endTime", maxWidth: "200" },
  {
    id: "isSuccess",
    Header: "Status",
    accessor: d => {
      return d.isSuccess ? "Success" : "Failure";
    },
    maxWidth: "100"
  },
  { Header: "Orange User", accessor: "user", maxWidth: "200" },
  { Header: "Connect Environment", accessor: "targetEnv", maxWidth: "300" },
  { Header: "Connect User", accessor: "targetUser", maxWidth: "300" }
];
const DeploymentAuditSubRowColumns = [
  { Header: "Pre Snapshot Id", accessor: "preSnapshotId", maxWidth: "300" },
  { Header: "Post Snapshot Id", accessor: "postSnapshotId", maxWidth: "300" },
  { Header: "Deployment Content", accessor: "content", maxWidth: "400", style: { whiteSpace: "unset" } },
  {
    Header: "Errors",
    id: "errorDetails",
    accessor: d => {
      return d.errorDetails.join(", ");
    },
    maxWidth: "500",
    style: { whiteSpace: "unset" }
  }
];
const MembersTableColumns = [
  { Header: "Name", accessor: "name", maxWidth: "400" },
  { Header: "Command", accessor: "command" }
];

const PipelineMemberColumns = [
  { Header: "Name", accessor: "name", maxWidth: "400" },
  { Header: "Connect Condition", accessor: "connectCondition", maxWidth: "400" },
  { Header: "Abort if False", accessor: "abortOnConditionFalse", maxWidth: "400" },
  { Header: "On False MemberName", accessor: "onConditionFalsememberName", maxWidth: "400" }
];

const MemberTableTitleKeys = [
  { Header: "Name", accessor: "name" },
  { Header: "Short Name", accessor: "shortName" },
  { Header: "Source Type", accessor: "sourceType" }
];

const MemberTableInnerKeys = [
  { Header: "Start Month", accessor: "startmonth" },
  { Header: "End Month", accessor: "endMonth" },
  { Header: "Batch Size", accessor: "batchSize" }
];

export { MemberTypes, MembersTableColumns, MemberTableTitleKeys, MemberTableInnerKeys, PipelineMemberColumns, ErrorColumns, DeploymentAuditColumns, DeploymentAuditSubRowColumns };
