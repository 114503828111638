import React from "react";

class errorPage extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className="NotFound">
        <h3>Server Error!</h3>
        <h4>There seems to be an issue with connecting to the server. Please contact Admin.</h4>
      </div>
    );
  }
}

export default errorPage;
