export const extractSchemaPath = (id, Section_Hierarchy) => {
  let pathArray = id.split(".");
  let schemaSection = pathArray.filter(p => Section_Hierarchy[p] !== undefined).join(".");
  let loopSection = [...pathArray].splice(0, pathArray.indexOf("Questions")).join(".");
  let qid = pathArray[pathArray.length - 1];
  let loopItem = loopSection.split(".")[loopSection.split(".").length - 1];
  let enclosingLoopPath = [...pathArray].splice(0, pathArray.indexOf("Questions") - 1).join(".");
  let parentLoopPath = [...pathArray].splice(0, pathArray.indexOf("Questions") - 3).join(".");
  if(pathArray.indexOf("NewSourceQualifier") > -1){
    parentLoopPath = [...pathArray].splice(0, pathArray.indexOf("Questions") - 1).join(".");
  }
  return {
    sectionPath: schemaSection,
    fullSchemaPath: `${schemaSection}.${qid}`,
    loopPath: loopSection,
    loopItem: loopItem,
    enclosingLoopPath: enclosingLoopPath,
    parentLoopPath: parentLoopPath
  };
};
