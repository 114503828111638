import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import objectpath from "object-path";
import { extractSchemaPath } from "./ExtractSchemaPath";
import Server from "./Server";
import LoadingOverlay from "react-loading-overlay";
import { Button } from "react-bootstrap";
import { Section_Hierarchy, isValidPath } from "./util";
import { getNamesForListID, getIdFromAllObjects } from "./ManageObjectIDs";
import ES from "es6-template-render";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { TableActions } from "./util";
import { DropDown } from "@xactlycorp/xactly-core-components";

const server = new Server();
const stringFormattingMap = {
  false: "No",
  true: "Yes",
};

const MenuMap = [
  { id: "summary", name: "All Questions" },
  { id: "parkingLot", name: "Parking Lot" },
  { id: "notes", name: "Notes" },
];
const searchOptionsMap = {
  GenericInfo: "Implementation Survey",
  Organization: "Organization",
  TermsConditions: "Terms and Conditions",
  Data: "Data",
};

function QuestionList(props) {
  //State Variables:
  const [flag, setFlag] = useState(props.flag ? props.flag : "summary"); //defaults to summary
  const [table, setTable] = useState({ columns: [{}], data: [{}] });
  const [dataReady, setDataReady] = useState(false);
  const [showDownloadButton, setDownloadButtonVisibility] = useState("hidden");
  const [filtered, setFiltered] = useState([]); //used to reset filters everytime the react table changes

  //add redirect flag
  const redirectFlag = props.redirectFlag;

  //State Variables:
  const [projDetails, setProjDetails] = useState({});
  //Global Variables (Read from Local Storage):
  const currentUser = localStorage.getItem("currentUser").replace(/['"]+/g, "");
  const customerId = JSON.parse(localStorage.getItem("user"))[currentUser].customerId;
  const projectId = JSON.parse(localStorage.getItem("user"))[currentUser].projectId;
  const customerName = JSON.parse(localStorage.getItem("user"))[currentUser].customerName;

  //<TODO>: Enable these 2 flags:
  let tempList = [];
  if (props.allowedSearchMenus) {
    MenuMap.forEach(map => {
      if (props.allowedSearchMenus.includes(map.id)) {
        tempList.push(map);
      }
    });
  } else {
    tempList = MenuMap;
  }
  const dropDownList = tempList;
  const allowedSections = props.allowedSections;
  //const searchOptions = ["all", "GenericInfo", "Organization", "TermsConditions", "Data"];
  const searchOptions = props.allowedSearchMenus;
  const searchOptionsEnums = [];
  props.allowedSections.forEach(item => {
    searchOptionsEnums.push(searchOptionsMap[item]);
  });
  //Methods for creating active links:
  function createLink(row) {
    return (
      <a className="Clickable" onClick={() => handleSelect(row.row.path)}>
        {row.value}
      </a>
    );
  }
  function handleSelect(qid) {
    props.onSelect(qid);
    if (redirectFlag) {
      localStorage.setItem("lastRendered", qid);
      //special logic to route to the implementation survey instead
      if (qid.startsWith("GenericInfo")) {
        props.history.replace("/implementationSurvey");
      } else {
        props.history.replace("/projects");
      }
    }
  }
  function getSectionName(path, AllLists = projDetails.AllLists) {
    let pathArray = path.split(".");
    let Current_Section = pathArray[0];
    let Current_SuccessFactor = pathArray.indexOf("SuccessFactors") >= 0 ? pathArray[pathArray.indexOf("SuccessFactors") + 1] : "";
    let Current_Plan = pathArray.indexOf("Plans") >= 0 ? pathArray[pathArray.indexOf("Plans") + 1] : "";
    let Current_Incentive = pathArray.indexOf("Incentives") >= 0 ? pathArray[pathArray.indexOf("Incentives") + 1] : "";
    let Current_Role = pathArray.indexOf("Roles") >= 0 ? pathArray[pathArray.indexOf("Roles") + 1] : "";

    let Current_Incentive_Attribute =
      pathArray.indexOf("Qualifier") >= 0
        ? pathArray[pathArray.indexOf("Qualifier") + 1]
        : pathArray.indexOf("Computation") >= 0
        ? pathArray[pathArray.indexOf("Computation") + 1]
        : pathArray.indexOf("Rate") >= 0
        ? pathArray[pathArray.indexOf("Rate") + 1]
        : "";
    let Current_TransactionType = pathArray.indexOf("TransactionType") >= 0 ? pathArray[pathArray.indexOf("TransactionType") + 1] : "";

    let Current_NewSourceQualifier = pathArray.indexOf("NewSourceQualifier") >= 0 ? pathArray[pathArray.indexOf("NewSourceQualifier") + 1] : "";

    let fullPathName = `${[
      Current_Section,
      getNamesForListID(Current_SuccessFactor, AllLists["SuccessFactors"]),
      getNamesForListID(Current_Plan, AllLists["Plans"]),
      getNamesForListID(Current_Incentive, AllLists["Incentives"]),
      getNamesForListID(Current_Role, AllLists["Titles"]),
      getNamesForListID(Current_TransactionType, AllLists["Sources"]),
      getNamesForListID(Current_Incentive_Attribute, AllLists["AllAttributes"]),
      getNamesForListID(Current_NewSourceQualifier, AllLists["AllAttributes"]),
    ]
      .filter(Boolean)
      .join("/")}`;
    return fullPathName;
  }

  ///////////////// Filter Methods  ////////////////
  function selectColumnFilter(filter, onChange, optionsList = {}) {
    let parentFilterValue = filter ? filter.value : "all";
    let childFilterValue = "all";
    optionsList.options.forEach(function (option) {
      if (filter && filter.value.startsWith(option)) {
        parentFilterValue = option;
      }
    });
    if (parentFilterValue && optionsList.subLists[parentFilterValue]) {
      if (filter.value.replace(parentFilterValue, "") !== "") {
        childFilterValue = filter.value.replace(`${parentFilterValue}/`, "");
      }
    }
    return (
      <div>
        <select
          value={parentFilterValue}
          onChange={event => {
            parentFilterValue = event.target.value;
            onChange(parentFilterValue);
          }}
        >
          {optionsList.options.map((option, i) => (
            <option key={i} value={option}>
              {optionsList.optionEnums[i]}
            </option>
          ))}
        </select>
        {filter ? ( //controls the sub filter
          optionsList.subLists[parentFilterValue] && optionsList.subLists[parentFilterValue].options.length > 0 ? (
            <select
              value={childFilterValue}
              onChange={event => {
                let newFilter = `${parentFilterValue}`;
                childFilterValue = event.target.value;
                if (event.target.value !== "all") {
                  newFilter = `${parentFilterValue}/${childFilterValue}`;
                }
                onChange(newFilter);
              }}
            >
              {optionsList.subLists[parentFilterValue].options.map((option, i) => (
                <option key={i} value={option}>
                  {optionsList.subLists[parentFilterValue].optionEnums[i]}
                </option>
              ))}
            </select>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }
  function sectionFilterMethod(filter, row) {
    if (filter.value === "all") {
      return true;
    } else {
      if (row[filter.id].startsWith(filter.value)) {
        return true;
      } else {
        return false;
      }
    }
  }
  function universalFilterMethod(filter, row) {
    let stringifiedObj = JSON.stringify(row[filter.id]).toLowerCase();
    if (stringifiedObj.includes(filter.value.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }
  /////////////////////////////////////////////////

  function generateTable(flag) {
    let table = { columns: [], data: [] };
    //options: ["all", "GenericInfo", "Organization", "TermsConditions", "Data"],
    //optionEnums: ["All Sections", "GenericInfo", "Organization", "TermsConditions", "Data"]
    let optionsList = {
      options: props.allowedSections,
      optionEnums: searchOptionsEnums,
      subLists: {
        Organization: {
          options: ["all"],
          optionEnums: ["All"],
        },
      },
    };
    let searchParam = flag === "notes" ? "Notes" : "Response"; //Defaults to Response
    server.searchAnswers(customerId, projectId, searchParam, response => {
      if (response.status === 200) {
        let newProjDetails = response.data.object.projDetails;
        setProjDetails(newProjDetails);
        let results = response.data.object.results;
        results = results.filter(item => isValidPath(item.path, newProjDetails.AllLists.AllObjects));
        switch (flag) {
          case "parkingLot":
            table.columns = [
              {
                accessor: "question",
                Header: "Question",
                Cell: row => createLink(row),
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "path",
                Header: "FullPath",
                show: false,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "sectionName",
                Header: "Section",
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
                Filter: ({ filter, onChange }) => selectColumnFilter(filter, onChange, optionsList),
                filterMethod: (filter, row) => sectionFilterMethod(filter, row),
              },
            ];
            newProjDetails.AllLists["ParkingLot"].forEach(function (path) {
              let row = {};
              row["path"] = path;
              row["sectionName"] = getSectionName(path, newProjDetails.AllLists);
              if (objectpath.get(newProjDetails.ConfigDetails, path)) {
                row["question"] = objectpath.get(newProjDetails.ConfigDetails, path).text;
              } else {
                let pathArray = path.split(".");
                let Current_Plan = pathArray.indexOf("Plans") >= 0 ? pathArray[pathArray.indexOf("Plans") + 1] : "";
                let Current_Incentive = pathArray.indexOf("Incentives") >= 0 ? pathArray[pathArray.indexOf("Incentives") + 1] : "";
                let Current_Incentive_Attribute = pathArray.indexOf("Attributes") >= 0 ? pathArray[pathArray.indexOf("Attributes") + 2] : "";
                let Current_Role = pathArray.indexOf("Roles") >= 0 ? pathArray[pathArray.indexOf("Roles") + 1] : "";
                let Current_SuccessFactor = pathArray.indexOf("SuccessFactors") >= 0 ? pathArray[pathArray.indexOf("SuccessFactors") + 1] : "";
                let Questions = newProjDetails.QuestionSchemaObj || {};
                let question_text = evaluateString(
                  {
                    Plan: getNamesForListID(Current_Plan, newProjDetails.AllLists["Plans"]),
                    Incentive: getNamesForListID(Current_Incentive, newProjDetails.AllLists["Incentives"]),
                    Attribute: getNamesForListID(Current_Incentive_Attribute, newProjDetails.AllLists["AllAttributes"]),
                    Role: getNamesForListID(Current_Role, newProjDetails.AllLists["Titles"]),
                    SuccessFactor: getNamesForListID(Current_SuccessFactor, newProjDetails.AllLists["SuccessFactors"]),
                  },
                  objectpath.get(Questions, extractSchemaPath(path, Section_Hierarchy).fullSchemaPath).title,
                );

                row["question"] = question_text;
              }
              table.data.push(row);
              row = {};
            });
            break;
          case "notes":
            table.columns = [
              {
                accessor: "question",
                Header: "Question",
                Cell: row => createLink(row),
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "path",
                Header: "FullPath",
                show: false,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "sectionName",
                Header: "Section",
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
                Filter: ({ filter, onChange }) => selectColumnFilter(filter, onChange, optionsList),
                filterMethod: (filter, row) => sectionFilterMethod(filter, row),
              },
              {
                accessor: "note",
                Header: "Note",
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
            ];
            results.forEach(function (row) {
              let newPath = row.path.replace(".answer.Notes", "");
              var temporaryElementToDecode = document.createElement("textarea");
              temporaryElementToDecode.innerHTML = row.question.Notes;
              let tableValue = typeof row.question.Notes == "object" || typeof row.question.Notes == "number" ? row.question.Notes : temporaryElementToDecode.value;
              table.data.push({
                question: objectpath.get(newProjDetails.ConfigDetails, newPath).text,
                path: newPath,
                sectionName: getSectionName(newPath, newProjDetails.AllLists),
                note: tableValue,
              });
            });
            break;
          case "summary":
            table.columns = [
              {
                accessor: "question",
                Header: "Question",
                Cell: row => createLink(row),
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "path",
                Header: "FullPath",
                show: false,
                style: { whiteSpace: "unset", overflow: "hidden" },
              },
              {
                accessor: "sectionName",
                Header: "Section",
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
                Filter: ({ filter, onChange }) => selectColumnFilter(filter, onChange, optionsList),
                filterMethod: (filter, row) => sectionFilterMethod(filter, row),
              },
              {
                accessor: "answer",
                Header: "Answer",
                show: true,
                style: { whiteSpace: "unset", overflow: "hidden" },
                filterMethod: (filter, row) => universalFilterMethod(filter, row),
              },
            ];
            for (const row of results) {
              let new_path = row.path.replace(".answer.Response", "");
              let questionResponse = getValue(row.question.Response, newProjDetails.AllLists);
              let value = {
                question: objectpath.get(newProjDetails.ConfigDetails, new_path).text,
                path: new_path,
                sectionName: getSectionName(new_path, newProjDetails.AllLists),
                answer: questionResponse,
              };
              let approve = false;
              props.allowedSections.forEach(section => {
                if (value.path.startsWith(section)) {
                  approve = true;
                }
              });
              if (approve) {
                table.data.push(value);
              }
            }
            break;
          default:
            break;
        }
        //Update the OptionsList for Section Navigation:
        if (newProjDetails.GlobalVariables && newProjDetails.GlobalVariables.PlanIncentives) {
          newProjDetails.GlobalVariables.PlanIncentives.forEach(function (row) {
            row.val.forEach(function (row2) {
              optionsList.subLists.Organization.options.push(`${getIdFromAllObjects(row.Plan, newProjDetails.AllLists.AllObjects)}/${getIdFromAllObjects(row2, newProjDetails.AllLists.AllObjects)}`);
              optionsList.subLists.Organization.optionEnums.push(
                `${getIdFromAllObjects(row.Plan, newProjDetails.AllLists.AllObjects)}/${getIdFromAllObjects(row2, newProjDetails.AllLists.AllObjects)}`,
              );
            });
          });
        }
        setTable(table);
        if (flag == "notes") {
          setDownloadButtonVisibility("visible");
        } else {
          setDownloadButtonVisibility("hidden");
        }
        setDataReady(true);
      } else {
        table = { columns: [{ Header: "ERROR CODE", accessor: "error" }], data: [{ error: "ERROR LOADING PROJECT DETAILS" }] };
        setTable(table);
        setDownloadButtonVisibility("hidden");
        setDataReady(true);
      }
    });
  }

  //Converts Objects/arrays into readble strings
  function getValue(rawValue, AllLists = projDetails.AllLists) {
    //Arrays:
    //<TODO>: This is failing for certain objects such as the aggregation question.
    if (Array.isArray(rawValue)) {
      let formattedValue = "";
      for (const index in rawValue) {
        let item = rawValue[index];
        Object.keys(AllLists.AllObjects).forEach(listItem => {
          if (rawValue[index].includes(listItem)) {
            var regex = new RegExp(listItem, "g");
            item = item.replace(regex, AllLists.AllObjects[listItem].name);
          }
        });
        if (index === "0") {
          formattedValue = item;
        } else {
          formattedValue = `${formattedValue}, ${item}`;
        }
      }
      // let affectedQuestions = ["Comp_Incentive47_2"];
      // affectedQuestions.forEach(ques => {
      //   if (new_path.endsWith(ques)) {
      //     let [lhs, rhs] = formattedValue.split("From");
      //     formattedValue = `${getIdFromAllObjects(lhs.trim(), AllLists.AllObjects)} From ${getIdFromAllObjects(rhs.trim(), AllLists.AllObjects)}`;
      //   }
      // });
      return formattedValue;
    }
    //Tabular Objects:
    if (rawValue && typeof rawValue === "object" && !Array.isArray(rawValue) && (rawValue.hasOwnProperty("tableName") || rawValue.hasOwnProperty("tableList"))) {
      if (rawValue.hasOwnProperty("tableList")) {
        rawValue = rawValue.tableList[0];
      }
      let formattedValue = { columns: rawValue.columns, data: rawValue.data };
      let tableName = rawValue.tableName;
      let maxRowCount = 3;
      if (formattedValue.data.length <= maxRowCount) {
        maxRowCount = formattedValue.data.length;
      }
      //Remove unnessary columns:
      let newCols = [];
      formattedValue.columns.forEach(function (row, index) {
        let columnBlacklist = new Set(["RemoveItem"]);
        if (!(columnBlacklist.has(row.accessor) || row.accessor.startsWith("Mapping_"))) {
          newCols.push(row);
        }
      });
      formattedValue.columns = newCols;

      //Format the Table Data:
      formattedValue.data.forEach(function (row, index) {
        Object.keys(row).forEach(function (key) {
          //Handling Derived Fields:

          if (typeof row[key] === "object") {
            if (row[key].hasOwnProperty("exp")) {
              formattedValue.data[index][key] = parseExpression(row[key].exp, AllLists.AllObjects);
            } else {
              formattedValue.data[index][key] = getIdFromAllObjects(JSON.stringify(row[key]), AllLists.AllObjects);
            }
          } else {
            formattedValue.data[index][key] = getIdFromAllObjects(row[key], AllLists.AllObjects);
          }
        });
      });
      return (
        <div>
          <b>{`${tableName}:`}</b>
          <ReactTable columns={formattedValue.columns} data={formattedValue.data} defaultPageSize={maxRowCount} filterable={false} showPagination={false} />
        </div>
      );
    }
    //Non-Tabular Objects:
    if (rawValue && typeof rawValue === "object" && !Array.isArray(rawValue) && !(rawValue.hasOwnProperty("tableName") || rawValue.hasOwnProperty("tableList"))) {
      let columns = [
        { Header: "Field", accessor: "key" },
        { Header: "Value", accessor: "value" },
      ];
      let data = [];
      for (const [key, value] of Object.entries(rawValue)) {
        data.push({ key: key, value: getIdFromAllObjects(value.toString(), AllLists.AllObjects) });
      }

      if (data.length > 1) {
        return <ReactTable columns={columns} data={data} defaultPageSize={data.length} filterable={false} showPagination={false} />;
      } else {
        let formattedValue = data[0][columns[1].accessor];
        formattedValue = stringFormattingMap[formattedValue] || formattedValue;

        return `${formattedValue}`;
      }
    }
    //All Other Literals:
    return JSON.stringify(getIdFromAllObjects(rawValue, AllLists.AllObjects));
  }
  function parseExpression(exp, AllObjects = projDetails.AllLists.AllObjects) {
    let expression = exp;
    for (let id in AllObjects) {
      if (expression.includes(id)) {
        expression = expression.split(id).join(AllObjects[id].name);
      }
    }
    return expression;
  }
  //Evaluates the template string format of the question title and replaces variables with actual values.
  function evaluateString(param, title_string) {
    var x = ES(title_string, param);
    return x;
  }

  ///////////////// CSV Functions /////////////////
  function generateCSV(table) {
    let csv_file = TableActions.generateCSV(table, ["path"]);
    const url = window.URL.createObjectURL(new Blob(csv_file, { type: "application/csv" }));
    const link = document.createElement("a");
    link.href = url;
    let filename = customerName + "_Questions" + ".csv";
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
  ////////////////////////////////////////////////
  function changeList(listType) {
    setFiltered([]);
    setFlag(listType);
  }
  useEffect(() => {
    setDataReady(false);
    generateTable(flag);
  }, [flag]);
  return (
    <div
      style={{
        display: "block",
        overflow: "hidden",
        padding: "30px",
      }}
    >
      {dropDownList.length > 1 && (
        <DropDown
          labels={{
            caption: "Search Questions List",
          }}
          data={dropDownList}
          textPropName="name"
          valuePropName="id"
          multiselect={false}
          changeHandler={id => changeList(id)}
          selectedValue={flag}
          required={true}
          searchBox={false}
          size="medium"
          width={"50px"}
          style={{ display: "block", float: "left", width: "25%" }}
        />
      )}
      <LoadingOverlay
        active={!dataReady}
        spinner
        text="Loading Question List..."
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      >
        <a
          className="Clickable"
          onClick={e => generateCSV(table)}
          style={{
            visibility: showDownloadButton,
            float: "right",
          }}
        >
          <Button className="btn btn-primary RightAlign btn-padded">
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10" }} />
            {"Download Questions"}
          </Button>
        </a>
        <ReactTable
          columns={[...table.columns]}
          data={[...table.data]}
          filterable={true}
          filtered={filtered}
          onFilteredChange={filtered => {
            setFiltered(filtered);
          }}
          defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
          defaultPageSize={table.data <= 10 ? table.data.length : 10}
          showPagination={true}
          style={{
            position: "relative",
            //height: "50%",
            width: "90%",
            height: "calc(80vh - 42px)",
            whiteSpace: "unset",
            overflow: "hidden", // This will force the table body to overflow and scroll, since there is not enough room
            clear: "both",
          }}
        />
      </LoadingOverlay>
    </div>
  );
}

export default QuestionList;
