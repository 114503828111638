import store from "store";
const apiPort = 8001;

const hostname = window && window.location && window.location.hostname;
// export const API_ROOT = `http://${hostname}:${apiPort}/api`;
// export const WS_ROOT = `ws://${hostname}:${apiPort}/api`;
export const API_ROOT = `https://${hostname}/api`;
export const WS_ROOT = `wss://${hostname}/api`;
export const NOTIFICATION_TIMEOUT = 15000;

function getExternalLoginToken() {
  const currentUser = store.get("user");
  if (currentUser) {
    const currentUserDetails = store.get("user")[store.get("currentUser")];
    if (currentUserDetails && currentUserDetails.token) {
      return currentUserDetails.token;
    }
  }
  return false;
}

export function redirectToIAM(mode) {
  const token = getExternalLoginToken();
  if (token) {
    window.location = "/clientAccess?token=" + token;
    return token;
  }
  if (mode === "ko") {
    window.location = process.env.REACT_APP_KO_LOGIN;
  } else {
    window.location = process.env.REACT_APP_USER_LOGIN;
  }
}
