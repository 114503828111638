import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { PrimaryButton, SecondaryButton } from "@xactlycorp/xactly-core-components";
import LoadingOverlay from "react-loading-overlay";
import SelectSearch from "react-select-search";
import "./react-select-search.css";
import classes from "./importSFDCMetadata.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Server from "./Server";
import { getSFDCFields } from "./SFDCUtil";

const POPUP_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    height: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const BOX_STYLE = {
  padding: "14px",
};

const popupModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "80%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const popupModalStyleMain = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const closeStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "-10px",
  opacity: "50%",
};
const chipStyle = {
  width: "auto",
  height: "30px",
  padding: "5px 15px 5px 15px",
  margin: "5px",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  textAlign: "center",
  boxShadow: "8px 8px 5px rgb(0 0 0 / 15%)",
};

const chipStylePrimary = {
  ...chipStyle,
  backgroundColor: "#f0ad4e",
};

const containerStyle = {
  position: "relative",
  left: "10px",
  display: "flex",
  flexFlow: "row wrap",
  width: "80%",
  height: "55vH",
  marginLeft: "10px",
  marginRight: "10px",
  padding: "10px",
  border: "2px solid #f2dede",
  borderRadius: "16px",
  boxShadow: "0 0.0625rem 0.125rem rgb(0 0 0 / 15%)",
  overflow: "scroll",
};

const relationsContainerStyle = {
  position: "relative",
  left: "10px",
  display: "flex",
  flexFlow: "column wrap",
  width: "70%",
  height: "65vH",
  marginLeft: "10px",
  marginRight: "10px",
  padding: "10px",
  border: "2px solid #f2dede",
  borderRadius: "16px",
  boxShadow: "0 0.0625rem 0.125rem rgb(0 0 0 / 15%)",
  overflow: "scroll",
};

const server = new Server();

const ImportSFDCMetadata = props => {
  const [isOpen, setOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isObjectModalOpen, setObjectModalOpen] = useState(false);
  const [isRelationtModalOpen, setRelationtModalOpen] = useState(false);
  const [sfdcObjs, setSFDCObjs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [objDetailsLoading, setObjDetailsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("Getting server response...");
  const [objDetailsLoadingMsg, setObjDetailsLoadingMsg] = useState("Getting server response...");
  const [selectedNames, setSelectedNames] = useState(props.formData.selectedObjects);
  const [primary, setPrimary] = useState(props.formData.primaryObject);
  const updateData = props.updateData;
  const [connectionInfo, setConnectionInfo] = useState();
  const [selectObjError, setSelectObjError] = useState("");
  const [relations, setRelations] = useState([]);
  const [fieldDetails, setFieldDetails] = useState([]);
  const [selectedRelations, setSelectedRelations] = useState(props.formData.selectedRelations ? props.formData.selectedRelations : []);
  const [mappedFields, setMappedFields] = useState([]);
  const { formData } = props;

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { connectionInfo: props.formData.connectionInfo } });

  useEffect(() => {
    if (props.formData && props.formData.fields && props.formData.fields.data) {
      const mappedItems = [];
      props.formData.fields.data.forEach(row => {
        if (row.isMapped || row.usedInFilter || row.usedInJoin || row.isCustomValidation) {
          mappedItems.push(row);
        }
      });
      setMappedFields(mappedItems);
    }
  }, [props.formData || props.mappedRelationships]);

  const handleImportSfdcClick = event => {
    if ((mappedFields && mappedFields.length > 0) || (props.mappedRelationships && props.mappedRelationships.length > 0)) {
      setShowConfirmDialog(true);
    } else {
      setOpen(true);
    }
  };

  const NameChip = props => {
    const handleClose = () => {
      props.handler(props.name);
    };
    const handleClick = () => {
      props.clickHandler(props.name);
    };
    return (
      <div>
        <div style={closeStyle}>
          <FontAwesomeIcon
            onClick={() => {
              handleClose();
            }}
            icon={faWindowClose}
          />
        </div>
        <div
          style={primary === props.name ? chipStylePrimary : chipStyle}
          onClick={() => {
            handleClick();
          }}
        >
          <p>{props.name}</p>
        </div>
      </div>
    );
  };

  const ObjectRelationsSelect = props => {
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Generating metadata...");
    const [selectedObjects, setSelectedObjects] = useState([primary]);
    const [checkedState, setCheckedState] = useState(
      relations.map(
        r => selectedRelations.filter(s => s.source_object === r.source_object && s.field === r.field && s.target_object === r.target_object && s.relationship == r.relationship).length > 0,
      ),
    );
    useEffect(() => {
      const objects = new Set();
      objects.add(primary);
      checkedState.forEach((v, i) => {
        if (v === true) {
          if (relations[i].target_object === primary) {
            objects.add(relations[i].source_object);
          }
          if (relations[i].source_object === primary) {
            objects.add(relations[i].target_object);
          }
        }
      });
      let remainingObjs = [];
      do {
        remainingObjs = [];
        checkedState.forEach((v, i) => {
          if (v === true) {
            if (objects.has(relations[i].source_object)) {
              objects.add(relations[i].target_object);
            } else {
              remainingObjs.push(relations[i].target_object);
            }
          }
        });
      } while (remainingObjs.length > 0);
      setSelectedObjects([...objects]);
    }, [checkedState]);

    const handleOnChange = position => {
      const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
      setCheckedState(updatedCheckedState);
    };
    const processSelection = event => {
      event.preventDefault();
      const selRels = [];
      checkedState.forEach((v, i) => {
        if (v === true) {
          selRels.push(relations[i]);
        }
      });
      setSelectedRelations(selRels);
      const newFormData = {
        connectionInfo: connectionInfo,
        selectedObjects: selectedNames,
        primaryObject: primary,
        objectDetails: fieldDetails,
        relations: relations,
        selectedRelations: selRels,
        fields: {},
      };
      newFormData.fields.columns = [
        { Header: "Name", accessor: "Name" },
        { Header: "Data Type", accessor: "Data Type" },
        { Header: "Format", accessor: "Format" },
      ];
      newFormData.fields.data = getSFDCFields({ primary, fieldDetails, selectedRelations: selRels, existingFields: formData.fields.data });
      updateData(newFormData);
      setRelationtModalOpen(false);
      setObjectModalOpen(false);
      setOpen(false);
    };

    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text={loadingMsg}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
              position: "relative",
              top: 0,
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <h1 className={classes.h1}>Select object relations</h1>
          <form className={classes.form} onSubmit={processSelection}>
            <div style={{ display: "flex", margin: "auto", width: "96%" }}>
              <div style={relationsContainerStyle}>
                {relations.map((r, i) => {
                  const key = `${r.relationship}(${r.source_object}.${r.field}->${r.target_object})`;
                  return (
                    <div key={key} style={chipStyle}>
                      <label style={{ fontWeight: 400 }} key={key} htmlFor={key}>
                        {key}
                      </label>
                      <input
                        style={{ marginLeft: "10px" }}
                        disabled={!selectedObjects.includes(r.source_object) && r.target_object !== primary}
                        type="checkbox"
                        id={key}
                        name={key}
                        value={key}
                        checked={checkedState[i]}
                        onChange={() => handleOnChange(i)}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ ...relationsContainerStyle, width: "28%", height: "65vh" }}>
                {selectedNames &&
                  selectedNames.map((name, index) => {
                    return (
                      <div key={name} style={selectedObjects.includes(name) ? { ...chipStylePrimary, backgroundColor: "#33FF96" } : { ...chipStylePrimary, backgroundColor: "#33FFFF" }}>
                        <p>{name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={classes.textCenterContainer}>
              <input
                className={classes.inputSubmit}
                type="button"
                onClick={e => {
                  setRelationtModalOpen(false);
                }}
                value="Cancel"
              />
              <input className={classes.inputSubmit} type="submit" />
            </div>
          </form>
        </LoadingOverlay>
      </>
    );
  };

  const getObjectDetails = data => {
    if (data.length === 0) {
      setSelectObjError({ message: "Please select atleast one object to proceed." });
    } else {
      setObjDetailsLoadingMsg(`Getting object details from Salesforce...`);
      setObjDetailsLoading(true);
      const objectNames = data;
      server.getSFDCObjects({ objectNames, primary }, response => {
        if (response.status === 200) {
          setObjDetailsLoading(false);
          setRelations(response.data.data.relations);
          setFieldDetails(response.data.data.objects);
          setRelationtModalOpen(true);
        } else {
          const msg = response.origionalMessages;
          switch (response.error.type) {
            default:
              alert(`Unexpected Error : ${msg}`);
              break;
          }
          setObjDetailsLoading(false);
        }
      });
    }
  };

  const SFDCObjectSelect = props => {
    const [options, setOptions] = useState(props.options);
    useEffect(() => {
      setOptions(props.options);
    }, [props.options]);
    useEffect(() => {
      if (selectedNames) {
        if (selectedNames.length === 0) {
          setPrimary("");
        } else if (selectedNames.length === 1) {
          setPrimary(selectedNames[0]);
        }
      } else {
        setPrimary("");
      }
    }, [selectedNames]);
    const removeName = name => {
      const filtered = selectedNames.filter((value, index, arr) => {
        return value !== name;
      });
      if (primary === name && filtered.length > 0) {
        setPrimary(filtered[0]);
      }
      setSelectedNames(filtered);
    };
    const selectPrimary = name => {
      setPrimary(name);
    };
    return (
      <>
        <LoadingOverlay
          active={objDetailsLoading}
          spinner
          text={objDetailsLoadingMsg}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
              position: "relative",
              top: 0,
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <h1 className={classes.h1}>Select Objects</h1>
          {selectObjError && selectObjError.message && <p role="alert">{selectObjError.message}</p>}
          <div style={{ display: "flex", margin: "auto", width: "90%" }}>
            <SelectSearch
              multiple={true}
              placeholder="Search and select Objects"
              value={selectedNames}
              search={true}
              options={options}
              onChange={e => {
                setSelectObjError({});
                setSelectedNames(e);
              }}
              style={{ borderRadius: "16px" }}
            />
            <div style={containerStyle}>
              {selectedNames &&
                selectedNames.map((name, index) => {
                  return <NameChip key={`namechip_${index}`} name={name} handler={removeName} clickHandler={selectPrimary} />;
                })}
            </div>
          </div>
          <div className={classes.textCenterContainer}>
            <input
              className={classes.inputSubmit}
              type="button"
              onClick={e => {
                setObjectModalOpen(false);
              }}
              value="Cancel"
            />
            <input className={classes.inputSubmit} type="button" onClick={e => getObjectDetails(selectedNames)} value="Get Relations" />
          </div>
        </LoadingOverlay>
        <Modal isOpen={isRelationtModalOpen} contentLabel="Object Selection Modal" style={popupModalStyle} ariaHideApp={false} shouldCloseOnOverlayClick={false}>
          <ObjectRelationsSelect />
        </Modal>
      </>
    );
  };
  const SFDCInfo = props => {
    const typeOptions = ["Connect", "SFDC"];
    const sfdcEnvOptions = ["Sandbox", "Production"];
    const connectEnvOptions = ["Implement1", "Sandbox", "Sandbox3", "Sandbox4", "Secure1", "Secure2", "Secure3", "Secure4", "Secure5", "Eu1", "EuSb1", "EUImplement1"];
    const showSFDC = watch("connectionInfo.connectionType") === "SFDC";
    const labelPrefix = watch("connectionInfo.connectionType") === "SFDC" ? "Salesforce" : "Connect";

    const getObjectNames = data => {
      const dataCopy = { ...data.connectionInfo };
      dataCopy.password = "";
      setConnectionInfo(dataCopy);
      setLoadingMsg(`Verifying ${data.connectionInfo.connectionType.toLowerCase() === "connect" ? "Connect & " : ""} Salesforce login information...`);
      setLoading(true);
      //setObjectModalOpen(true);
      server.verifySFDCLogin(data.connectionInfo, response => {
        if (response.status === 200) {
          setLoadingMsg("Getting list of Salesforce objects...");
          server.getSFDCObjectNames(response => {
            if (response.status === 200) {
              let sfdcObjectNames = response.data.sfdcObjectNames;
              sfdcObjectNames = sfdcObjectNames.map(item => {
                return { name: item, value: item };
              });
              setSFDCObjs(sfdcObjectNames);
              setLoading(false);
              setObjectModalOpen(true);
            } else {
              const msg = response.origionalMessages;
              switch (response.error.type) {
                default:
                  alert(`Unexpected Error : ${msg}`);
                  break;
              }
              setLoading(false);
            }
          });
        } else {
          let msg = response.originalMessages;
          msg = msg.replace("user", "Salesforce User Name");
          msg = msg.replace("password", "Salesforce Password");
          msg = msg.replace("connectEnv", "Connect Environment");
          msg = msg.replace("sfdcCredentialName", "Salesforce Credential Name");
          msg = msg.replace("sfdcEnv", "Salesforce Environment");
          msg = msg.replace("endPoint", "Salesforce EndPoint");
          switch (response.error.type) {
            case "ConnectLoginError":
              setError("connectionInfo.user", { type: "serverError", message: msg });
              setError("connectionInfo.password", { type: "serverError", message: msg });
              setError("connectionInfo.connectEnv", { type: "serverError", message: msg });
              break;
            case "InvalidInputError":
              setError("connectionInfo.sfdcCredentialName", { type: "serverError", message: msg });
              break;
            case "SFDCCredNameError":
              setError("connectionInfo.sfdcEnv", { type: "serverError", message: msg });
              setError("connectionInfo.sfdcCredentialName", { type: "serverError", message: msg });
              if (data.connectionInfo.endPoint) {
                setError("connectionInfo.endPoint", { type: "serverError", message: msg });
              }
              break;
            case "SFDCCredError":
              setError("connectionInfo.user", { type: "serverError", message: msg });
              setError("connectionInfo.password", { type: "serverError", message: msg });
              setError("connectionInfo.sfdcEnv", { type: "serverError", message: msg });
              if (data.connectionInfo.endPoint) {
                setError("connectionInfo.endPoint", { type: "serverError", message: msg });
              }
              break;
            default:
              alert(`Unexpected Error : ${msg}`);
              break;
          }
          setLoading(false);
        }
      });
    };

    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text={loadingMsg}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
              position: "relative",
              top: 0,
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <h1 className={classes.h1}>Salesforce Connection Information</h1>
          <form className={classes.form} onSubmit={handleSubmit(getObjectNames)}>
            <label className={classes.label} htmlFor="connectionInfo.sfdcEnv">
              Salesforce Environment
            </label>
            <select className={classes.input} {...register("connectionInfo.sfdcEnv")}>
              {sfdcEnvOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {errors.connectionInfo && errors.connectionInfo.sfdcEnv && <p role="alert">{errors.connectionInfo.sfdcEnv.message}</p>}
            <label className={classes.label} htmlFor="connectionInfo.connectionType">
              Credentials Type
            </label>
            <select className={classes.input} {...register("connectionInfo.connectionType")}>
              {typeOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            <label className={classes.label} htmlFor="connectionInfo.endPoint">
              Endpoint URL
            </label>
            <input
              type="url"
              className={classes.input}
              {...register("connectionInfo.endPoint", { required: false })}
              aria-invalid={errors.connectionInfo && errors.connectionInfo.endPoint ? "true" : "false"}
            />
            {errors.connectionInfo && errors.connectionInfo.endPoint && errors.connectionInfo.endPoint.type !== "required" && <p role="alert">{errors.connectionInfo.endPoint.message}</p>}
            <label className={classes.label} htmlFor="connectionInfo.user">
              {labelPrefix} User Name
            </label>
            <input className={classes.input} {...register("connectionInfo.user", { required: true })} aria-invalid={errors.connectionInfo && errors.connectionInfo.user ? "true" : "false"} />
            {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type === "required" && <p role="alert">{labelPrefix} User Name is required.</p>}
            {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type !== "required" && <p role="alert">{errors.connectionInfo.user.message}</p>}
            <label className={classes.label} htmlFor="password">
              {labelPrefix} Password
            </label>
            <input
              type="password"
              className={classes.input}
              {...register("connectionInfo.password", { required: true })}
              aria-invalid={errors.connectionInfo && errors.connectionInfo.password ? "true" : "false"}
            />
            {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type === "required" && <p role="alert">{labelPrefix} Password is required.</p>}
            {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type !== "required" && <p role="alert">{errors.connectionInfo.password.message}</p>}
            {!showSFDC && (
              <>
                <label className={classes.label} htmlFor="connectionInfo.connectEnv">
                  Connect Environment
                </label>
                <select className={classes.input} {...register("connectionInfo.connectEnv")}>
                  {connectEnvOptions.map(value => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>
                {errors.connectionInfo && errors.connectionInfo.connectEnv && <p role="alert">{errors.connectionInfo.connectEnv.message}</p>}
                <label className={classes.label} htmlFor="connectionInfo.sfdcCredentialName">
                  Salesforce Credential Name
                </label>
                <input
                  className={classes.input}
                  {...register("connectionInfo.sfdcCredentialName", { required: true })}
                  aria-invalid={errors.connectionInfo && errors.connectionInfo.sfdcCredentialName ? "true" : "false"}
                />
                {errors.connectionInfo && errors.connectionInfo.sfdcCredentialName && errors.connectionInfo.sfdcCredentialName.type === "required" && (
                  <p role="alert">Salesforce Credential Name is required.</p>
                )}
                {errors.connectionInfo && errors.connectionInfo.sfdcCredentialName && errors.connectionInfo.sfdcCredentialName.type !== "required" && (
                  <p role="alert">{errors.connectionInfo.sfdcCredentialName.message}</p>
                )}
              </>
            )}
            <div className={classes.textCenterContainer}>
              <input className={classes.inputSubmit} type="button" onClick={e => setOpen(false)} value="Cancel" />
              <input className={classes.inputSubmit} type="submit" value="Get Metadata" />
            </div>
          </form>
        </LoadingOverlay>
        <Modal isOpen={isObjectModalOpen} contentLabel="Object Selection Modal" style={popupModalStyle} ariaHideApp={false} shouldCloseOnOverlayClick={false}>
          <SFDCObjectSelect options={sfdcObjs} />
        </Modal>
      </>
    );
  };
  return (
    <>
      <PrimaryButton text="Import SFDC Info" type="button" className="btn-src-upload" data-tip data-for="importSFDCSourceTip" clickHandler={handleImportSfdcClick} />
      <ReactTooltip id="importSFDCSourceTip" place="bottom" effect="solid">
        {"Use this feature to import Object and their relationship info from Salesforce."}
      </ReactTooltip>
      <Modal isOpen={isOpen} contentLabel="Salesforce Informtion Modal" style={popupModalStyleMain} ariaHideApp={false} shouldCloseOnOverlayClick={false}>
        <SFDCInfo />
      </Modal>
      <Modal style={POPUP_MODAL_STYLE} isOpen={showConfirmDialog} shouldCloseOnOverlayClick={false}>
        <>
          <Box style={BOX_STYLE}>
            <h4>Warning</h4>
            {props.mappedRelationships && props.mappedRelationships.length > 0 ? (
              <div>
                <strong>Fields from the reference source are used in following relationships.</strong>
                <List component="nav" aria-label="main mailbox folders">
                  {props.mappedRelationships.map(field => (
                    <ListItemButton>{field.Name || field.name}</ListItemButton>
                  ))}
                </List>
                <hr />
              </div>
            ) : null}
            {mappedFields && mappedFields.length > 0 ? (
              <div>
                <strong>The following fields are being used in Data Mapping / Join Criteria / Filter Condition.</strong>
                <List component="nav" aria-label="main mailbox folders">
                  {mappedFields.map(field => (
                    <ListItemButton>{field.Name || field.name}</ListItemButton>
                  ))}
                </List>
              </div>
            ) : null}
            <p style={{ color: "#ff5252", padding: "12px" }}>
              <strong>Note : </strong> There will be errors on Data Mapping / Data Source screen, if you choose to “Continue”. Those errors will need to be resolved manually to create connect code.
            </p>
            <SecondaryButton
              clickHandler={() => {
                setShowConfirmDialog(false);
              }}
              inline
              text="Cancel"
              className="btn-padded"
            />
            <PrimaryButton
              clickHandler={() => {
                setShowConfirmDialog(false);
                setOpen(true);
              }}
              inline
              text="Continue"
              className="btn-padded"
            />
          </Box>
        </>
      </Modal>
    </>
  );
};

export default ImportSFDCMetadata;
