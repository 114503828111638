import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./containers/ProjectSelection";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Dashboard from "./containers/Home";
import Server from "./containers/Server";
import Questions from "./containers/Questions";
import Projects from "./containers/ProjectSelection";
import AdminDashboard from "./containers/adminDashboard";
import implementationSurvey from "./containers/implementationSurvey";
import ConfigurationDashboard from "./containers/ConfigurationDashboard.js";
import ConnectDependentRules from "./containers/ConnectDependentRules";
import ConnectAutomationDashboard from "./containers/ConnectAutomationDashboard";
import errorPage from "./containers/errorPage";
import RequirementSummary from "./containers/requirementSummary";
import { redirectToIAM } from "./containers/api-config";
import clientAccessPage from "./containers/clientAccessPage";

const server = new Server();

const context = "";
class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: ""
  };
  constructor(props) {
    super(props);
    this.pathPrefix = props.props.pathPrefix;
  }
  componentDidMount() {
    this.authenticate();
  }
  authenticate() {
    server.authenticate().then(response => {
      this.props.props.setAuthenticationData(response);
      if (response.status) {
        this.setState({
          loaded: true,
          isAuthenticated: response.status
        });
      } else {
        redirectToIAM(this.props.props.mode);
      }
    });
  }
  render() {
    const { component: Component, props: cProps, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded || !isAuthenticated) return null;
    const allowedTabs = this.props.props.allowedTabs;
    const componentName = this.props.component.name;
    const isAllowed = componentName === "Home" || (allowedTabs ? allowedTabs.includes(componentName) : true);
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            isAllowed ? (
              <Component {...props} {...cProps} />
            ) : (
              // <Redirect
              //   to={{
              //     pathname: `${this.pathPrefix}${this.modePath}`
              //   }}
              // />
              <Component {...props} {...cProps} />
            )
          ) : null;
        }}
      />
    );
  }
}
class AppliedRoute extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { component: Component, props: cProps, ...rest } = this.props;
    return <Route {...rest} render={props => <Component {...props} {...cProps} />} />;
  }
}
PrivateRoute = withRouter(PrivateRoute);

export default ({ childProps }) => (
  <Switch>
    <PrivateRoute exact path={`${context}/`} component={Home} props={childProps} />
    <PrivateRoute exact path={`${context}/dashboard`} component={Dashboard} props={childProps} />
    <PrivateRoute exact path={`${context}/projects`} component={Projects} props={childProps} />
    <PrivateRoute exact path={`${context}/adminDashboard`} component={AdminDashboard} props={childProps} />
    <PrivateRoute exact path={`${context}/rulesDashboard`} component={ConfigurationDashboard} props={childProps} />
    <PrivateRoute exact path={`${context}/connectDependentRules`} component={ConnectDependentRules} props={childProps} />
    <PrivateRoute exact path={`${context}/requirementSummary/:selectedLevel?/:selectedValue?`} component={RequirementSummary} props={childProps} />
    <PrivateRoute exact path={`${context}/m/:mode`} component={Home} props={childProps} />
    <PrivateRoute exact path={`${context}/connectAutomationDashboard`} component={ConnectAutomationDashboard} props={childProps} />
    <PrivateRoute exact path={`${context}/implementationSurvey`} component={implementationSurvey} props={childProps} />
    <AppliedRoute exact path={`${context}/login/m/:mode`} component={Login} props={childProps} />
    <Route exact path={`${context}/errorPage`} component={errorPage} />
    <Route exact path={`${context}/clientAccess`} component={clientAccessPage} props={childProps} />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} props={childProps} />
  </Switch>
);
