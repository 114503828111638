import React, { useState, useEffect } from "react";
import store from "store";
import Server from "./Server";
import ComponentProvider from "./ComponentProvider";
import LoadingOverlay from "react-loading-overlay";
import Questions from "./Questions";

const implementationSurvey = props => {
  const server = new Server();
  const currentUser = store.get("currentUser");
  const projectId = store.get("user")[currentUser].projectId;
  const customerId = store.get("user")[currentUser].customerId;
  const user = store.get("user")[currentUser].name;
  //<TODO>: Need to update the projectDetails
  const [projectDetails, setProjectDetails] = useState();
  const [dynamicComponentMapping, setDynamicComponentMapping] = useState({});
  const [dataReady, setDataReady] = useState(false);
  //how to take advantage of new lastRendered question:
  const [qid, setQid] = useState(props.qid);

  //   useEffect(() => {
  //     setDataReady(false);
  //     updateProjectDetails();
  //   }, [projectId]);
  useEffect(() => {
    updateProjectDetails();
  }, []);
  useEffect(() => {}, [dataReady]);

  const updateProjectDetails = () => {
    server.getProjectDetails(customerId, projectId, async response => {
      if (response.status === 400) {
        this.setState({
          projectId: "",
          projectName: ""
        });
        store.remove("lastRendered");
        store.update("user", user => {
          user[this.currentUser]["projectId"] = "";
          user[this.currentUser]["projectName"] = "";
        });
      } else {
        //1) Set Project Details
        //this.ProjectDetails = response.data.object[0];
        setProjectDetails(response.data.object[0]);
        let quesMetadata = response.data.object[0].QuestionSchemaObj.Metadata;
        //2) Set Dynamic Comonent Mappings:
        if (quesMetadata && quesMetadata.componentVersionMapping) {
          Object.entries(quesMetadata.componentVersionMapping).forEach(async component => {
            let newMapping = dynamicComponentMapping;
            newMapping[component[0]] = await ComponentProvider.getComponent(component[0], component[1]);
            setDynamicComponentMapping(newMapping);
          });
        }
        //3) Is this design needed?
        //<TODO>: Explore the idea of a new lastRendered
        store.remove("lastRendered");
        //TODO: Ask Engineering?
        //this.props.showProjectTabs();
        setDataReady(true);
      }
    });
  };
  return (
    <LoadingOverlay
      active={!dataReady}
      spinner
      text="Loading Questions..."
      styles={{
        spinner: base => ({
          ...base,
          width: "80px"
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)"
        })
      }}
    >
      {dataReady ? (
        <div>
          <Questions
            projectId={projectId}
            customerId={customerId}
            projectDetails={projectDetails}
            user={user}
            dynamicComponentMapping={dynamicComponentMapping}
            saveProjectDetails={projectData => {
              setProjectDetails(projectData);
            }}
            qid={qid}
            allowedSections={["GenericInfo"]}
            allowParkingLot={false}
            allowNotes={false}
            allowSearch={false}
          />
        </div>
      ) : (
        <span />
      )}
    </LoadingOverlay>
  );
};

export default implementationSurvey;
