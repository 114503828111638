import React, { useState, useEffect } from "react";
import { systemFunctions, operators } from "./expressionBuilderConfig";
import "./copyDatamapping.css";

const CopyDataMapping = props => {
  const [fromSources, setFromSources] = useState([]);
  const [toSources, setToSources] = useState([]);
  const [selectedFrom, setSelectedFrom] = useState("");
  const [selectedTo, setSelectedTo] = useState("");
  const [systemFieldsArray, setSystemFieldsArray] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const sources = JSON.parse(JSON.stringify(props.allLists.Sources));
    sources.forEach(source => {
      source.value = source.id;
    });
    setFromSources(sources);
    setToSources(sources);
    const fieldsArray = [];
    Object.keys(systemFunctions).forEach(systemKey => {
      fieldsArray.push(systemFunctions[systemKey].label);
    });
    setSystemFieldsArray(fieldsArray);
  }, []);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const checkIfFieldAvailable = (selectedFromData, selectedToData, mappingRow) => {
    let fieldAvailable = {
      fromId: "",
      toId: "",
      fromSource: "",
      toSource: "",
    };
    if (mappingRow.source === selectedFromData.id) {
      fieldAvailable.fromSource = selectedFromData.id;
      fieldAvailable.toSource = selectedToData.id;
      if (selectedFromData && selectedFromData.fields && selectedFromData.fields.data && selectedToData && selectedToData.fields && selectedToData.fields.data) {
        let fromFieldName = "";
        selectedFromData.fields.data.forEach(fromRow => {
          if (mappingRow.field === fromRow.id) {
            fieldAvailable.fromId = fromRow.id;
            fromFieldName = fromRow.Name;
          }
        });
        selectedToData.fields.data.forEach(toRow => {
          if (fromFieldName === toRow.Name) {
            fieldAvailable.toId = toRow.id;
          }
        });
      }
    } else {
      // for Joint relationships
      if (Array.isArray(selectedFromData.relationships) && Array.isArray(selectedToData.relationships)) {
        let fromId;
        let toId;
        let selectedFromField = {};
        let selectedToField = {};
        selectedFromData.relationships.forEach(relation => {
          if (relation.id === mappingRow.source && Array.isArray(relation.fields)) {
            fromId = relation.id;
            relation.fields.forEach(relField => {
              if (relField.id === mappingRow.field) {
                selectedFromField = relField;
              }
            });
          }
        });
        if (fromId && selectedFromField.id) {
          selectedToData.relationships.forEach(relation => {
            if (relation.id === mappingRow.source && Array.isArray(relation.fields)) {
              toId = relation.id;
              relation.fields.forEach(relField => {
                if (relField.id === mappingRow.field) {
                  selectedToField = relField;
                }
              });
            }
          });
        }
        if (selectedFromField.id && selectedToField.id) {
          fieldAvailable = {
            fromId: fromId,
            toId: toId,
            fromSource: selectedFromField.id,
            toSource: selectedToField.id,
          };
        }
      }
    }
    return fieldAvailable;
  };

  // const extractSourceAndFields = (text, startBracket = "(", endBracket = ")") => {
  //   const sourcesAndFields = [];
  //   if (text.includes("(")) {
  //     const stack = [];
  //     const result = [];

  //     for (let i = 0; i < text.length; i++) {
  //       const char = text[i];
  //       if (char === startBracket) {
  //         stack.push(i);
  //       } else if (char === endBracket) {
  //         if (stack.length > 0) {
  //           const startIndex = stack.pop();
  //           let content = text.slice(startIndex + 1, i);
  //           if (!content.includes("(")) {
  //             Object.keys(operators).forEach(opr => {
  //               if (content.includes(operators[opr].symbol)) {
  //                 content = content.split(operators[opr].symbol)[0];
  //               }
  //             });
  //             result.push(content);
  //           }
  //         }
  //       }
  //     }
  //     result.forEach(res => {
  //       const splitArray = res.split(".");
  //       let sourceName = splitArray[0];
  //       let fieldsArray = splitArray.slice(1);
  //       sourcesAndFields.push({ source: sourceName, field: fieldsArray.join(".") });
  //     });
  //   } else {
  //     const splitArray = text.split(".");
  //     let sourceName = splitArray[0];
  //     let fieldsArray = splitArray.slice(1);
  //     sourcesAndFields.push({ source: sourceName, field: fieldsArray.join(".") });
  //   }
  //   return sourcesAndFields;
  // };

  const extractSourceAndFields = (text, allSources, startBracket = "(", endBracket = ")") => {
    const sourcesAndFields = [];
    const regexp = /\w+.\w+/g;
    if (text.includes("(")) {
      const stack = [];
      const result = [];

      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        if (char === startBracket) {
          stack.push(i);
        } else if (char === endBracket) {
          if (stack.length > 0) {
            const startIndex = stack.pop();
            let content = text.slice(startIndex + 1, i);
            if (!content.includes("(")) {
              const findAllExp = content.match(regexp);
              if (findAllExp && findAllExp.length > 0) {
                findAllExp.forEach(expression => {
                  result.push(expression);
                });
              }
            }
          }
        }
      }
      result.forEach(res => {
        const splitArray = res.split(".");
        let sourceId = splitArray[0];
        let fieldsId = splitArray[1];
        const fromSrc = allSources.find(src => src.id === sourceId);
        if (fromSrc && fromSrc.fields && fromSrc.fields.data) {
          if (fromSrc.fields.data.find(field => field.id === fieldsId)) {
            sourcesAndFields.push({ source: sourceId, field: fieldsId });
          }
        }
      });
    } else {
      const splitArray = text.split(".");
      let sourceId = splitArray[0];
      let fieldsId = splitArray.slice(1);
      sourcesAndFields.push({ source: sourceId, field: fieldsId.join(".") });
    }
    return sourcesAndFields;
  };

  const copyDataSourceToTarget = () => {
    const tabularAnswer = props.tabularAnswer;
    const allLists = props.allLists;
    let allSources = [];
    let selectedFromData;
    let selectedToData;
    if (allLists.Sources && allLists.Sources.length > 0) {
      allLists.Sources.forEach(source => {
        if (source.id === selectedFrom) {
          selectedFromData = source;
        }
        if (source.id === selectedTo) {
          selectedToData = source;
        }
      });
    }
    if (selectedFromData) {
      allSources = [selectedFromData];
      if (selectedFromData.relationships) {
        const refSources = selectedFromData.relationships.map(r => {
          const refSource = { ...allLists.ReferenceSources.find(x => x.id === r.referenceSource) };
          refSource.id = r.id;
          refSource.name = r.name;
          return refSource;
        });
        allSources = allSources.concat(refSources);
      }
    }
    tabularAnswer.data.forEach(row => {
      if (row[`Mapping_${selectedFrom}`] === "SetTo" || row[`Mapping_${selectedFrom}`] === "Leave Blank" || row[`Mapping_${selectedFrom}`] === "Other") {
        let replaceData = false;
        if (typeof row[selectedFrom] === "undefined") {
          row[selectedFrom] = "";
        }
        if (typeof row[selectedFrom] === "object" && row[selectedFrom].hasOwnProperty("expWithName")) {
          row[selectedFrom] = row[selectedFrom].expWithName || "";
        }
        if (checked || !row[selectedTo]) {
          row[selectedTo] = row[selectedFrom].replace(new RegExp(selectedFrom, "g"), selectedTo);
          replaceData = true;
        }
        if (replaceData) {
          row[`Mapping_${selectedTo}`] = row[`Mapping_${selectedFrom}`];
        }
      } else if (row[selectedFrom] && row[selectedFrom].exp) {
        const sourceAndField = extractSourceAndFields(row[selectedFrom].exp, allSources);
        let updatedExp = row[selectedFrom].exp;
        let fieldAvailableCount = 0;
        sourceAndField.forEach(field => {
          let targetField = checkIfFieldAvailable(selectedFromData, selectedToData, field);
          if (targetField.fromId && targetField.toId) {
            fieldAvailableCount += 1;
            updatedExp = updatedExp.replace(new RegExp(targetField.fromId, "g"), targetField.toId);
            updatedExp = updatedExp.replace(new RegExp(targetField.fromSource, "g"), targetField.toSource);
          }
        });
        if (!row[selectedTo]) {
          row[selectedTo] = {};
        }
        if (checked || !row[selectedTo].exp) {
          if (fieldAvailableCount === sourceAndField.length) {
            let updatedExpWithName = row[selectedFrom].expWithName;
            updatedExpWithName = updatedExpWithName.replace(new RegExp(selectedFromData.name, "g"), selectedToData.name);
            row[selectedTo] = JSON.parse(JSON.stringify(row[selectedFrom]));
            row[selectedTo].exp = updatedExp;
            row[selectedTo].expWithName = updatedExpWithName;
            row[`Mapping_${selectedTo}`] = row[`Mapping_${selectedFrom}`];
          } else {
            row[`Mapping_${selectedTo}`] = row[`Mapping_${selectedFrom}`];
            row[selectedTo] = "";
          }
        }
      }
    });
    props.updateTabularAnswers(tabularAnswer);
  };

  const cancelCopying = () => {
    props.cancelCopying();
  };

  return (
    <>
      <div className="copy-datamapping">
        <h3>Copy Datamapping and source</h3>
        <div className="content-wrapper">
          <div>
            <label>Copy From Source</label>
            <select
              value={selectedFrom}
              onChange={e => {
                setSelectedFrom(e.target.value);
                const newSelectedTo = fromSources.filter(toSource => toSource.id !== e.target.value);
                setToSources(newSelectedTo);
              }}
            >
              <option value="">Select</option>
              {fromSources.map(option => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Copy To Source</label>
            <select
              value={selectedTo}
              onChange={e => {
                setSelectedTo(e.target.value);
              }}
            >
              <option value="">Select</option>
              {toSources.map(option => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>
              <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
              <span className="pad-l-10">Overwrite existing mappings</span>
            </label>
          </div>
        </div>
        <div className="btn-wrapper">
          <button className="btn btn-outline-primary btn-padded" onClick={cancelCopying}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary btn-padded" onClick={copyDataSourceToTarget}>
            Copy Datamapping
          </button>
        </div>
      </div>
    </>
  );
};

export default CopyDataMapping;
