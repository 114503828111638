import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactTable from "react-table";
import { DropDown } from "@xactlycorp/xactly-core-components";
import "./sectionNavigation.css";

function SectionNavigation(props) {
  const [isOpen, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [incentivePlan, setIncentivePlan] = useState({});
  const labels = {
    caption: "Select a plan"
  };

  const columns = [
    {
      accessor: "plan",
      Header: "Plan",
      Cell: row => createPlanLink(row)
    },
    {
      accessor: "incentive",
      Header: "Incentive",
      Cell: row => createLink(row)
    }
  ];
  function handleSelect(qid, planId, incentiveId, isNewIncentive, isNewPlan) {
    setOpen(false);
    if (props.onSelect) {
      props.onSelect({ qid, planId, incentiveId, isNewIncentive, isNewPlan });
    }
  }
  function handlePlanSeletion(plan, index) {
    let selectedPlans = { ...incentivePlan };
    selectedPlans[index] = plan;
    setIncentivePlan(selectedPlans);
  }
  function createPlanLink(row) {
    if (row.original.ids["plan"]) {
      return createLink(row);
    } else if (props.planIncentives) {
      const activePlans = props.planIncentives.map(function (item) {
        return {
          id: item.Plan,
          name: props.plans.filter(plan => plan.id === item.Plan)[0].name
        };
      });
      return (
        <DropDown
          labels={labels}
          data={activePlans}
          textPropName="name"
          valuePropName="id"
          multiselect={false}
          changeHandler={(plan, e) => handlePlanSeletion(plan, row.index)}
          selectedValue={incentivePlan[row.index]}
          required={true}
          searchBox={true}
        />
      );
    } else {
      return "";
    }
  }
  function createLink(row) {
    return row.original.ids["plan"] || incentivePlan[row.index] ? (
      <a
        className="Clickable"
        onClick={() =>
          handleSelect(row.original.paths[row.column.id], row.original.ids["plan"] || incentivePlan[row.index], row.original.ids["incentive"], row.original.isNewIncentive, row.original.isNewPlan)
        }
      >
        {row.value}
      </a>
    ) : (
      row.value
    );
  }
  useEffect(() => {
    if (props) {
      let data = [];
      let usedIncentives = [];
      props.plans.forEach(plan => {
        let planPaths = props.paths.filter(path => path && path.includes(`.Plans.${plan.id}.`));
        let planPath = "";
        if (planPaths.length > 0) {
          planPath = planPaths.pop();
        }
        let planIncentives = props.planIncentives ? props.planIncentives.filter(planIncentive => planIncentive.key === plan.id) : [];
        if (planIncentives.length > 0) {
          planIncentives[0].val.forEach(id => {
            let incentive = props.incentives.filter(incent => incent.id === id)[0];
            if (incentive) {
              usedIncentives.push(incentive.id);
              let incentivePaths = props.paths.filter(path => path.includes(`.Plans.${plan.id}.Incentives.${incentive.id}.`));
              let incentivePath = "";
              if (incentivePaths.length > 0) {
                incentivePath = incentivePaths.pop();
              }
              data.push({
                plan: plan.name,
                incentive: incentive.name,
                ids: {
                  plan: plan.id,
                  incentive: incentive.id
                },
                paths: {
                  plan: planPath,
                  incentive: incentivePath
                },
                isNewIncentive: false,
                isNewPlan: false
              });
            }
          });
        } else {
          data.push({
            plan: plan.name,
            incentive: "",
            ids: {
              plan: plan.id
            },
            paths: { plan: planPath },
            isNewIncentive: false,
            isNewPlan: planPath === ""
          });
        }
      });
      let unusedIncetives = props.incentives.filter(incentive => !usedIncentives.includes(incentive.id));
      unusedIncetives.forEach(incentive => {
        data.push({
          incentive: incentive.name,
          ids: {
            incentive: incentive.id
          },
          paths: {},
          isNewIncentive: true
        });
      });
      setData(data);
      setOpen(true);
    }
  }, [props]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setOpen(false);
          props.onClose();
        }}
        shouldCloseOnEsc={true}
        style={{
          content: {
            position: "relative",
            top: "12vh",
            height: "76vh",
            left: "25vw",
            width: "50vw"
          }
        }}
      >
        <ReactTable
          columns={columns}
          data={data}
          filterable
          defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
          pageSize={data.length}
          style={{
            maxHeight: "calc(76vh - 42px)" // This will force the table body to overflow and scroll, since there is not enough room
          }}
          showPageSizeOptions={false}
          showPagination={false}
        />
      </Modal>
    </>
  );
}

export default SectionNavigation;
