import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Server from "./Server";
import ReactTable from "react-table";
import store from "store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import LoadingOverlay from "react-loading-overlay";

const server = new Server();
const MAX_ROW = 10;

//<TODO>: Create an Overaly for the Module, during process/load time, or exit module?
//<TODO>: Who handles the notifications, me or req summ?
//<TODO>: Add job validation here? these are run time errors?
const QuotaValueModule = props => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [quotas, setQuotas] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isFRDSubmitted, setIsFRDSubmitted] = useState();
  //<TODO>: Check if job is in progress? -> controlled via the notification store
  const [isJobInProgress, setjobProgress] = useState(false);
  const [frdJobs, setFRDJobs] = useState([]);

  useEffect(() => {
    getQuotas();
    //get prev jobs as well
  }, []);

  useEffect(() => {}, [quotas]);

  const getQuotas = () => {
    setIsDataReady(false);
    server.getList(customerId, projectId, "Quotas", response => {
      if (response && response.status === 200) {
        let quotas = response.data.object[0].AllLists.Quotas;
        setQuotas(quotas);
        setIsDataReady(true);
      }
    });
  };

  ///////////////////////////////////////////////////////////////////////
  const submitJob = () => {
    setIsDataReady(false);
    server.submitFRDJob(customerId, projectId, response => {
      if (response.status != 200 && response.status != 201) {
        //<TODO>: How to pass/handle the error?
        //setSubmitErrorMsg(response.data.error.messages);
        setIsDataReady(true);
        props.updateJobCreationStatus({ status: false, message: response.data.error.messages });
        props.closeQuotaModule();
      } else {
        props.closeQuotaModule();
      }
    });
  };

  const updateLists = () => {
    //<TODO>: Confirm this is proper format for listData?
    let listData = { Quotas: quotas };
    server.updateLists(customerId, projectId, listData, response => {
      submitJob();
    });
  };

  ///////////////////////////////////////////////////////////////////////
  const renderTable = () => {
    let quotasTable = {
      columns: [
        { Header: "Quota Name", accessor: "name" },
        { Header: "Sample Quota Value", accessor: "value" }
      ],
      data: []
    };
    if (quotas) {
      quotas.forEach(quota => {
        let dataRow = {};
        dataRow.name = quota.name;
        dataRow.value = renderEditableCell(quota);
        quotasTable.data.push(dataRow);
      });
    }
    return <ReactTable columns={quotasTable.columns} data={quotasTable.data} minRows={5} showPagination={false} />;
  };
  const renderEditableCell = quota => {
    if (!quota.hasOwnProperty("value")) {
      quota.value = 1000;
    }
    return (
      <input
        type="number"
        defaultValue={quota.value || 1000}
        onChange={e => {
          return e.target.value;
        }}
        onBlur={e => changeQuotaValue(e, quota)}
      />
    );
  };
  const changeQuotaValue = (e, quotaObj) => {
    quotaObj.value = e.target.value;
    let newQuotas = [];
    quotas.forEach(quota => {
      let newRow = { ...quota };
      if (newRow.hasOwnProperty("id") && newRow.id === quotaObj.id) {
        newRow.value = e.target.value;
      }
      newQuotas.push(newRow);
    });
    setQuotas(newQuotas);
    return e.target.value;
  };

  return (
    <LoadingOverlay
      active={!isDataReady}
      spinner
      text={"Loading Quotas"}
      styles={{
        spinner: base => ({
          ...base,
          width: "80px",
          position: "absolute",
          top: 0
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)"
        })
      }}
    >
      <div style={{ textAlign: "center", margin: "auto", height: "50%", width: "100%" }}>
        <SecondaryButton
          icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
          clickHandler={() => {
            props.closeQuotaModule();
          }}
          iconSize={20}
        />
        <Tooltip id="closeModal">Close</Tooltip>
        <div>
          <div></div>
          {renderTable()}
        </div>
        <Button
          variant="secondary"
          disabled={isJobInProgress}
          onClick={() => {
            setIsDataReady(false);
            updateLists();
          }}
        >
          {"Run Job"}
        </Button>
      </div>
    </LoadingOverlay>
  );
};

export default QuotaValueModule;
