import React, { useState, useEffect } from "react";
import Server from "./Server";
import Modal from "react-modal";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import CustomerModal from "./CustomerModal";
import ProjectModal from "./ProjectModal";
import UserAssignmentModal from "./UserAssignmentModal";
import LoadingOverlay from "react-loading-overlay";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
const server = new Server();

const ProjectManagementDashboard = props => {
  const [customerList, setCustomerList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [selectedCustomer, setSelecetedCustomer] = useState();
  const [selectedProject, setSelectedProject] = useState();

  const [showCustomerAddModal, setShowCustomerAddModal] = useState(false);
  const [showProjectAddModal, setShowProjectAddModal] = useState(false);
  const [showUserAssignmentModal, setShowUserAssignmentModal] = useState(false);

  const [dataIsReady, setDataisReady] = useState(true);

  useEffect(() => {
    getAllCustomers();
  }, []);
  useEffect(() => {
    setSelectedProject();
    getAllProjects(selectedCustomer);
    setUserList([]);
  }, [selectedCustomer]);
  useEffect(() => {
    if (selectedProject) {
      setDataisReady(false);
      getAllUsers();
      getAssignedUsers(selectedCustomer, selectedProject);
    }
  }, [selectedProject]);

  const getAllCustomers = () => {
    setDataisReady(false);
    server.getAllCustomers(response => {
      if (response.status === 200) {
        setCustomerList(response.data.object);
      } else {
        console.log("Cannot Get Customers");
      }
      setDataisReady(true);
    });
  };

  const getAllProjects = customerId => {
    if (customerId) {
      setDataisReady(false);
      server.getAllProjects(customerId, response => {
        if (response.status === 200) {
          setProjectList(response.data.object);
        } else {
          console.log("Cannot Get Projects");
        }
        setDataisReady(true);
      });
    }
  };
  const getAssignedUsers = (customerId, projectId) => {
    console.log("Running geet assigned Users");
    server.getProjectAccess(customerId, projectId, response => {
      if (response.status === 200) {
        response.data.object.map(user => user.user);
        setUserList(response.data.object.map(user => user.user));
        setDataisReady(true);
      } else {
        console.log("Cannot Get Assigned Users");
      }
    });
  };
  const getAllUsers = () => {
    server.getAssignedUsers(response => {
      if (response.status == 200) {
        let newOptions = [];
        response.data.object.forEach(user => {
          newOptions.push(user.user);
        });
        setAllUsers(newOptions);
      } else {
        let error = { message: Object.values(response.originalMessages)[0], alertHeader: "Failed to Get All Users" };
      }
    });
  };
  const handleToggle = value => () => {
    setDataisReady(false);

    const currentIndex = userList.indexOf(value);
    const newChecked = [...userList];

    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }
    //<TODO>: run backedn service to update the assignments before running:
    //do wee add or remove the user
    if (currentIndex === -1) {
      console.log("Assigning User: ", value);
      newChecked.push(value);
      server.assignCustomer(selectedCustomer, value, response => {
        if (response.status === 200) {
          server.assignProject(selectedCustomer, selectedProject, value, resp => {
            if (resp.status === 200) {
            } else {
              let error = { message: Object.values(resp.originalMessages)[0], alertHeader: `Failed to Assign ${value} to Project ${selectedProject}` };
            }
            setUserList(newChecked);
            setDataisReady(true);
          });
        } else {
          let error = { message: Object.values(response.originalMessages)[0], alertHeader: `Failed to Assign ${value} to Customer ${selectedCustomer}` };
        }
      });
    } else {
      console.log("Removing User: ", value);
      newChecked.splice(currentIndex, 1);
      server.removeProjectAccess(selectedCustomer, selectedProject, value, response => {
        if (response.status === 200) {
          server.removeCustomerAccess(selectedCustomer, value, resp => {
            if (resp.status === 200) {
            } else {
              let error = { message: Object.values(resp.originalMessages)[0], alertHeader: `Failed to Unassign ${value} to Project: ${selectedProject}` };
            }
            setUserList(newChecked);
            setDataisReady(true);
          });
        } else {
          let error = { message: Object.values(response.originalMessages)[0], alertHeader: `Failed to Unassign ${value} to Customer: ${selectedCustomer}` };
        }
      });
    }
  };

  return (
    <div>
      <h3>{"Project Admin Dashboard"}</h3>
      <LoadingOverlay
        active={!dataIsReady}
        spinner
        text={"Loading"}
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
            position: "absolute",
            top: 0
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)"
          })
        }}
      >
        <div style={{ display: "flex" }}>
          <div className="customerSelection" style={{ width: "33%" }}>
            <button
              onClick={() => {
                setShowCustomerAddModal(true);
              }}
            >
              {"Add Customer"}
            </button>
            <div style={{ border: "1px solid black", overflow: "scroll", height: "450px" }}>
              <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <List component="nav" aria-label="main mailbox folders">
                  {customerList.map(customer => (
                    <ListItemButton selected={customer._id === selectedCustomer} onClick={event => setSelecetedCustomer(customer._id)}>
                      {customer.name}
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </div>
          </div>
          <div className="projectSelection" style={{ width: "33%" }}>
            {selectedCustomer && (
              <button
                onClick={() => {
                  setShowProjectAddModal(true);
                }}
              >
                {"Add Project"}
              </button>
            )}
            <div style={{ border: "1px solid black", overflow: "scroll", height: "450px" }}>
              <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <List component="nav" aria-label="main mailbox folders">
                  {projectList.map(project => (
                    <ListItemButton selected={project._id === selectedProject} onClick={event => setSelectedProject(project._id)}>
                      {project.name}
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </div>
          </div>
          {selectedProject && (
            <div className="userSelection" style={{ width: "33%" }}>
              <div style={{ border: "1px solid black", overflow: "scroll", height: "450px" }}>
                <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                  <List component="nav" aria-label="main mailbox folders">
                    {allUsers.map(user => (
                      <ListItemButton selected={false} onClick={handleToggle(user)}>
                        <ListItemIcon>
                          <Checkbox edge="start" checked={userList.includes(user)} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={user} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </div>
            </div>
          )}
        </div>
      </LoadingOverlay>
      <Modal
        isOpen={showCustomerAddModal}
        contentLabel="addCustomer"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => {
          setShowCustomerAddModal(false);
        }}
        style={{
          content: {
            top: "40px",
            left: "40px",
            height: "70%"
          }
        }}
      >
        <SecondaryButton
          icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
          clickHandler={() => {
            setShowCustomerAddModal(false);
          }}
          iconSize={20}
        />
        <Tooltip id="closeModal">Close</Tooltip>
        <CustomerModal
          handleSubmit={() => {
            setShowCustomerAddModal(false);
            getAllCustomers();
          }}
        />
      </Modal>
      <Modal
        isOpen={showProjectAddModal}
        contentLabel="addProject"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => {
          setShowProjectAddModal(false);
        }}
        style={{
          content: {
            top: "40px",
            left: "40px",
            height: "70%"
          }
        }}
      >
        <SecondaryButton
          icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
          clickHandler={() => {
            setShowProjectAddModal(false);
          }}
          iconSize={20}
        />
        <Tooltip id="closeModal">Close</Tooltip>
        <ProjectModal
          selectedCustomer={selectedCustomer}
          handleSubmit={() => {
            setShowProjectAddModal(false);
            getAllProjects(selectedCustomer);
          }}
        />
      </Modal>
      <Modal
        isOpen={showUserAssignmentModal}
        contentLabel="assignUser"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => {
          setShowUserAssignmentModal(false);
        }}
        style={{
          content: {
            top: "40px",
            left: "40px",
            height: "70%"
          }
        }}
      >
        <SecondaryButton
          icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
          clickHandler={() => {
            setShowUserAssignmentModal(false);
          }}
          iconSize={20}
        />
        <Tooltip id="closeModal">Close</Tooltip>
        <UserAssignmentModal
          assignedUsers={userList}
          customerId={selectedCustomer}
          projectId={selectedProject}
          handleSubmit={() => {
            setShowUserAssignmentModal(false);
            console.log("RUNNING GET ASSIGNEED USERS");
            getAssignedUsers(selectedCustomer, selectedProject);
          }}
        />
      </Modal>
    </div>
  );
};

export default ProjectManagementDashboard;
