import React, { useState, useEffect } from "react";
import RenderExpression from "./renderExpression";

const Expression = props => {
  let value = props.value;
  const [exp, setExp] = useState(value && value.hasOwnProperty("exp") ? value.exp : value || "");
  const [expWithName, setExpWithName] = useState(value && value.hasOwnProperty("expWithName") ? value.expWithName : exp);
  const [isValid, setValidFlag] = useState(true);
  const listType = props.options.sourceType === "DataMapping" ? "Sources" : "HRSources";
  const selectedSourceDetails = props.lists[listType].filter(item => item.id === props.fieldInfo.selectedSource)[0];
  const sourceRelationships = selectedSourceDetails ? (selectedSourceDetails.relationships && selectedSourceDetails.referenceSourceUsed ? selectedSourceDetails.relationships : []) : [];
  const [rel, setRel] = useState(value ? (value.relation ? value.relation : sourceRelationships[0] ? sourceRelationships[0].id : "") : ""); // || sourceRelationships[0] ? sourceRelationships[0].id : "") : "");

  // useEffect(() => {
  //   props.handleExpressionChange({ exp: exp, expWithName : expWithName, relation: rel, isValid: isValid });
  // }, [exp, expWithName, rel]);

  useEffect(() => {
    if (props.value) {
      if (props.value.hasOwnProperty("exp")) {
        setExp(props.value.exp);
      } else {
        setExp(props.value);
      }
      if (props.value.hasOwnProperty("expWithName")) {
        setExpWithName(props.value.expWithName);
      } else {
        setExpWithName(props.value.exp);
      }
    } else {
      setExp("");
    }
  }, [props.value]);

  const handleInputChange = (exp, expWithName, validFlag) => {
    setExp(exp);
    setExpWithName(expWithName);
    setValidFlag(validFlag);
    props.handleExpressionChange({ exp: exp, expWithName: expWithName, relation: rel, isValid: validFlag });
  };
  const handleRelationChange = e => {
    setRel(e.target.value);
  };

  return (
    <div id="expression" className="flexContainer">
      {<RenderExpression type={props.type} exp={exp} lists={props.lists} handleInputChange={handleInputChange} options={props.options} selectedSource={props.fieldInfo.selectedSource} />}
      {/*\\sourceRelationships.length > 1 && (
        <div className="flexItem">
          <label className="flexContainer">
            <span style={{ margin: "0px 5px 0px 35px", width: "auto" }}>Relationship: </span>
            <select value={rel} onChange={handleRelationChange} className="form-control">
              {sourceRelationships.map(item => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
        </div>
              )*/}
    </div>
  );
};

export default Expression;
