import React, { useState, useEffect } from "react";
import { generateID } from "./ManageObjectIDs";
import Server from "./Server";
import LoadingOverlay from "react-loading-overlay";
import store from "store";
import FilterWidget from "./filterWidget";
const server = new Server();

function AddSourceValidation(props) {
  //Defining State Variables:
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [dataIsReady, setIsDataReady] = useState(true);
  const [selectedValidationId, setSelectedValidationId] = useState(props.selectedValidation.id ? props.selectedValidation.id : "");
  const [validationType, setValidationType] = useState(props.selectedValidation.type ? props.selectedValidation.type : "WARNING");
  const [expression, setExpression] = useState(props.selectedValidation.exp ? props.selectedValidation.exp : {});
  const [rejectReason, setRejectReason] = useState(props.selectedValidation.rejectReason ? props.selectedValidation.rejectReason : "");
  const [validationsList, setValidationsList] = useState(props.validationsList);
  const [allLists, setAllLists] = useState(props.allLists);

  useEffect(() => {}, []);
  const submitValidations = () => {
    setIsDataReady(false);
    let newData = { source: props.selectedSource.id, type: validationType, exp: expression, rejectReason: rejectReason };
    let data = [];
    if (selectedValidationId && selectedValidationId !== "") {
      validationsList.forEach(item => {
        if (item.hasOwnProperty("id") && item.id !== selectedValidationId) {
          data.push(item);
        }
      });
      newData.id = selectedValidationId;
    } else {
      data = validationsList ? validationsList : [];
      newData.id = generateID("Validations");
      setSelectedValidationId(newData.id);
    }
    data.push(newData);

    let usedFields = new Set([]);
    data.forEach(validation => {
      if (validation.exp && validation.exp.queries) {
        Object.keys(validation.exp.queries).forEach(query => {
          if (validation.exp.queries[query].firstField && validation.exp.queries[query].firstField.id) {
            usedFields.add(validation.exp.queries[query].firstField.id);
          }
        });
      }
    });
    let newAllLists = allLists;
    newAllLists.Validations = data;
    newAllLists.Sources.forEach(src => {
      if (src.id && src.fields && src.id === props.selectedSource.id) {
        src.fields.data.forEach(field => {
          if (field.id && usedFields.has(field.id)) {
            field.isCustomValidation = true;
          }
        });
      }
    });
    server.updateLists(customerId, projectId, newAllLists, response => {
      if (response && response.status === 200) {
        setSelectedValidationId("");
        props.onModalClose();
        setIsDataReady(true);
      }
    });
  };

  return (
    <LoadingOverlay
      active={!dataIsReady}
      spinner
      text={"Loading Validations"}
      styles={{
        spinner: base => ({
          ...base,
          width: "80px",
          position: "absolute",
          top: 0,
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)",
        }),
      }}
    >
      <div style={{ height: "100%", padding: "10px" }}>
        {
          <div>
            <h2>{"Add Validation"}</h2>
            <form>
              <div>
                {`What type of validation is this?`}
                <br />
                <label>
                  <input
                    type="radio"
                    value="WARNING"
                    checked={validationType === "WARNING"}
                    onChange={e => {
                      if (e.target) {
                        setValidationType("WARNING");
                      }
                    }}
                  />
                  {`WARNING`}
                </label>
                <label>
                  <input
                    type="radio"
                    value="REJECT"
                    checked={validationType === "REJECT"}
                    onChange={e => {
                      if (e.target) {
                        setValidationType("REJECT");
                      }
                    }}
                  />
                  {`REJECT`}
                </label>
                <br />
              </div>
              <br />
              <div>
                {`Validation Expression: `}
                <FilterWidget
                  source={props.selectedSource}
                  enableSourceFilter={false}
                  enableLogicalOperator={true}
                  includeReferences={true}
                  value={JSON.stringify(expression)}
                  onChange={v => {
                    setExpression(v);
                  }}
                />
              </div>
              <br />
              <div>
                <label>
                  {`Reject Reason:`}
                  <input
                    style={{ minWidth: "200%", maxWidth: "100%", minHeight: "20%", maxHeight: "20%" }}
                    type="text"
                    name="name"
                    value={rejectReason}
                    onChange={e => {
                      setRejectReason(e.target.value);
                    }}
                  />
                </label>
              </div>
            </form>
            <button
              className="btn btn-success flexItem"
              style={{ flexBasis: "5%" }}
              type="button"
              onClick={row => {
                submitValidations(row);
              }}
            >
              {"Submit"}
            </button>
          </div>
        }
      </div>
    </LoadingOverlay>
  );
}

export default AddSourceValidation;
