import React, { useState, useEffect } from "react";
import { getIdFromAllObjects } from "./ManageObjectIDs";
import Server from "./Server";
import Modal from "react-modal";
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "store";
import AddSourceValidation from "./AddSourceValidation";
import { expIdToName, generateIDMapping } from "./util";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { faWindowClose, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

const server = new Server();

function DataSourceValidations(props) {
  //Defining State Variables:
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [showAddValidationModal, setShowAddValidationModal] = useState(false);
  const [showRemoveValidationModal, setShowRemoveValidationModal] = useState(false);
  const [dataIsReady, setIsDataReady] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentValidation, setCurrentValidation] = useState({});
  const [selectedSource, setSelectedSource] = useState(props.selectedSource);
  const [validationsList, setValidationsList] = useState(props.AllLists.Validations ? props.AllLists.Validations : []);
  const { sourceIdToNames, fieldIdToNames } = generateIDMapping([props.selectedSource]);
  const [allLists, setAllLists] = useState(props.AllLists);

  const defaultColumns = [
    {
      Header: "",
      accessor: "action",
      style: { whiteSpace: "unset", overflow: "hidden" },
      maxWidth: 50,
      filterable: false,
    },
    { Header: "Type", accessor: "type" },
    { Header: "Validation", accessor: "exp" },
    {
      Header: "",
      accessor: "editCell",
      style: { whiteSpace: "unset", overflow: "hidden" },
      maxWidth: 50,
      filterable: false,
    },
  ];

  useEffect(() => {
    getValidations();
  }, []);

  const getValidations = () => {
    setIsDataReady(false);
    let list = [];
    server.getList(customerId, projectId, "Validations", response => {
      if (response && response.status === 200) {
        setValidationsList(response.data.object[0].AllLists.Validations);
        list = response.data.object[0].AllLists.Validations ? response.data.object[0].AllLists.Validations : [];
        let newList = [];
        list.forEach(validation => {
          if (selectedSource && selectedSource.id === validation.source) {
            newList.push({
              exp: parseExpression(validation.exp.resolvedQuery ? validation.exp.resolvedQuery : ""),
              type: validation.type,
              action: <FontAwesomeIcon icon={faTrash} id={validation.id} onClick={() => handleRemoveValidation(validation)} className="Clickable"></FontAwesomeIcon>,
              editCell: <FontAwesomeIcon icon={faEdit} id={validation.id} onClick={() => handleUpdateValidation(validation)} className="Clickable"></FontAwesomeIcon>,
            });
          }
        });
        setTableData(newList);
        setIsDataReady(true);
      }
    });
  };
  const parseExpression = exp => {
    return expIdToName(exp, { ...sourceIdToNames, ...fieldIdToNames });
  };
  const handleAddValidation = () => {
    setCurrentValidation({});
    getValidations();
    setShowAddValidationModal(false);
  };
  const handleRemoveValidation = validation => {
    setCurrentValidation(validation);
    setShowRemoveValidationModal(true);
    return;
  };

  const removeValidation = () => {
    if (currentValidation) {
      setIsDataReady(false);
      let data = [];
      if (validationsList && validationsList.length > 0) {
        validationsList.forEach(validation => {
          if (validation.id !== currentValidation.id) {
            data.push(validation);
          }
        });
      }
      let usedFields = new Set([]);
      data.forEach(validation => {
        if (validation.exp && validation.exp.queries) {
          Object.keys(validation.exp.queries).forEach(query => {
            if (validation.exp.queries[query].firstField && validation.exp.queries[query].firstField.id) {
              usedFields.add(validation.exp.queries[query].firstField.id);
            }
          });
        }
      });
      let newAllLists = allLists;
      newAllLists.Validations = data;
      newAllLists.Sources.forEach(src => {
        if (src.id && src.fields && src.id === props.selectedSource.id) {
          src.fields.data.forEach(field => {
            if (field.id && usedFields.has(field.id)) {
              field.isCustomValidation = false;
            }
          });
        }
      });
      server.updateLists(customerId, projectId, newAllLists, response => {
        if (response && response.status === 200) {
          setValidationsList(response.data.object[0].AllLists.Validations);
          setCurrentValidation({});
          getValidations();
          setShowRemoveValidationModal(false);
          setIsDataReady(true);
        }
      });
    }
  };
  const handleUpdateValidation = validation => {
    setCurrentValidation(validation);
    setShowAddValidationModal(true);
  };

  //1) Fix Data here:
  return (
    <LoadingOverlay
      active={!dataIsReady}
      spinner
      text={"Loading Validations"}
      styles={{
        spinner: base => ({
          ...base,
          width: "80px",
          position: "absolute",
          top: 0,
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)",
        }),
      }}
    >
      <div style={{ height: "100%", padding: "10px" }}>
        <h2>{`${getIdFromAllObjects(props.selectedSource.id, props.AllLists.AllObjects)} Validations`}</h2>

        <button
          className="btn btn-success flexItem"
          style={{ flexBasis: "5%" }}
          type="button"
          onClick={() => {
            setCurrentValidation({});
            setShowAddValidationModal(true);
          }}
        >
          {"Add Validation"}
        </button>
        <ReactTable columns={defaultColumns} data={tableData} className="-striped -highlight" defaultPageSize={5} filterable />

        {
          <Modal
            style={{ content: { height: "10%", width: "30%", margin: "auto", "text-align": "center" } }}
            isOpen={showRemoveValidationModal}
            onRequestClose={() => {
              setShowRemoveValidationModal(false);
            }}
          >
            {"Are you sure you want to delete this validtion?"}
            <br />
            <button
              className="btn btn-success flexItem"
              style={{ flexBasis: "5%" }}
              type="button"
              onClick={row => {
                removeValidation();
              }}
            >
              {"Submit"}
            </button>
          </Modal>
        }
      </div>
      {
        <Modal
          style={{ content: { height: "60%", width: "75%", margin: "auto" } }}
          isOpen={showAddValidationModal}
          onRequestClose={() => {
            setShowAddValidationModal(false);
          }}
        >
          <div>
            <SecondaryButton
              icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
              clickHandler={() => {
                setShowAddValidationModal(false);
              }}
              iconSize={20}
            />
            <Tooltip id="closeModal">Close</Tooltip>
            <AddSourceValidation
              validationsList={validationsList}
              allLists={props.AllLists}
              selectedSource={props.selectedSource}
              onModalClose={handleAddValidation}
              selectedValidation={currentValidation}
              sourceType={"DataMapping"}
            />
          </div>
        </Modal>
      }
    </LoadingOverlay>
  );
}

export default DataSourceValidations;
