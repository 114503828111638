import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import QueryBuilder from "./QueryBuilder";
import { expIdToName, generateIDMapping } from "./util";

const FilterWidget = props => {
  const allSources = [props.source];
  const { sourceIdToNames, fieldIdToNames } = generateIDMapping(allSources);
  const data = props.value ? JSON.parse(props.value) : "";
  const [queryData, setQueryData] = useState(data);
  const [value, setValue] = useState(data.resolvedQuery);
  const [isQueryBuilderVisible, setQueryBuilderVisible] = useState(false);
  const [queryKeyList, setQueryKeyList] = useState(queryData && queryData.queryKeyList ? queryData.queryKeyList : []);
  const displayQueryBuilder = () => {
    setQueryBuilderVisible(true);
  };

  const onUpdate = (queries, queryKeyList) => {
    const queryList = [];
    const variables = new Set();
    const getRHSValue = (firstField, rhs) => {
      if (rhs.displayVariable) {
        variables.add(
          `v_${rhs.variable
            .replace(/^<(.*)>$/, "$1")
            .trim()
            .toLowerCase()
            .replace(/[!@#$%^&*(),.?":{}|-\s]/g, "_")}`,
        );
      }
      const rhsValue = rhs.displayField
        ? rhs.transformFunction && rhs.transformFunction.value === "subString"
          ? `SubString(${rhs.fieldValue.sourceId}.${rhs.fieldValue.id},${rhs.functionData.index})`
          : `${rhs.fieldValue.sourceId}.${rhs.fieldValue.id}`
        : rhs.displayValue
        ? firstField.dataType === "string" || firstField.dataType === "text"
          ? `'${rhs.value.trim()}'`
          : firstField.dataType === "date"
          ? `ToDate('${rhs.value}')`
          : firstField.dataType === "dateTime"
          ? `ToDate('${rhs.value}')`
          : `${rhs.value}`
        : rhs.displayVariable
        ? `:v_${rhs.variable
            .replace(/^<(.*)>$/, "$1")
            .trim()
            .toLowerCase()
            .replace(/[!@#$%^&*(),.?":{}|-\s]/g, "_")}`
        : "";
      return rhsValue;
    };
    queryKeyList.forEach(k => {
      const v = queries[k];
      const { firstField, operator, secondValue } = v;
      let query = "";
      let sValue = "";
      switch (operator.value) {
        case "between":
          query = `${firstField.sourceId}.${firstField.id} BETWEEN ${getRHSValue(firstField, secondValue.firstOption)} AND ${getRHSValue(firstField, secondValue.secondOption)}`;
          break;
        case "in":
          sValue = `(${getRHSValue(firstField, secondValue)})`;
          sValue = firstField.dataType === "string" || firstField.dataType === "text" ? sValue.trim().replace(/\s*,\s*/g, "', '") : sValue;
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${sValue}`;
          break;
        case "not in":
          sValue = `(${getRHSValue(firstField, secondValue)})`;
          sValue = firstField.dataType === "string" || firstField.dataType === "text" ? sValue.trim().replace(/\s*,\s*/g, "', '") : sValue;
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${sValue}`;
          break;
        default:
          query = `${firstField.sourceId}.${firstField.id} ${operator.value} ${getRHSValue(firstField, secondValue)}`;
          break;
      }
      if (props.enableLogicalOperator) {
        query = queryList.length > 0 ? (v.logicalOperator === "OR" ? `) ${v.logicalOperator} (${query}` : ` ${v.logicalOperator} ${query}`) : `( ${query}`;
      } else {
        query = queryList.length > 0 ? `AND ${query}` : query;
      }
      v.resolvedQuery = query;
      queryList.push(query);
    });
    let resolvedQuery = queryList.join(" ");
    if (props.enableLogicalOperator) {
      resolvedQuery = `${resolvedQuery} )`;
    }
    setValue(resolvedQuery);
    setQueryKeyList(queryKeyList);
    const updatedQueries = {
      queries,
      queryKeyList,
      resolvedQuery,
      variables: [...variables],
    };
    setQueryData(updatedQueries);
    props.onChange(updatedQueries);
    setQueryBuilderVisible(false);
  };
  const fields = [];
  allSources.forEach(s => {
    if (s.fields && s.fields.data) {
      s.fields.data.forEach(f => {
        fields.push({
          value: `${s.name}.${f["Name"]}`,
          label: `${s.name}.${f["Name"]}`,
          fieldName: f["Name"],
          dataType: f["Data Type"],
          source: s.name,
          isPrimary: true,
          format: f["Format"],
          id: f["id"],
          sourceId: s.id,
        });
      });
    }

    if (props.includeReferences && props.includeReferences === true) {
      if (s.relationships) {
        s.relationships.forEach(r => {
          if (r.fields) {
            r.fields.forEach(f => {
              fields.push({
                value: `${r.name}.${f["Name"]}`,
                label: `${r.name}.${f["Name"]}`,
                fieldName: f["Name"],
                dataType: f["Data Type"],
                source: r.name,
                isPrimary: true,
                format: f["Format"],
                id: f["id"],
                sourceId: r.id,
              });
            });
          }
        });
      }
    }
  });
  const gridTemplateColumns = props.useReferenceSource ? "30% 80% 5px" : "90% 5px";
  return (
    <div style={{ display: "grid", flexDirection: "row", gridTemplateColumns: gridTemplateColumns, columnGap: "10px" }}>
      <input className="form-control" type="text" value={expIdToName(value, { ...sourceIdToNames, ...fieldIdToNames })} disabled />
      <FontAwesomeIcon icon={faEdit} className="Clickable" style={{ margin: "5px" }} onClick={displayQueryBuilder} />
      {isQueryBuilderVisible ? (
        <Modal
          style={{
            content: {
              height: "80%",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "8px",
            },
          }}
          isOpen={isQueryBuilderVisible}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={true}
          onRequestClose={() => setQueryBuilderVisible(false)}
        >
          <div style={{ margin: "10" }}>
            <QueryBuilder
              queryData={queryData}
              fields={fields}
              onSubmit={onUpdate}
              enableLogicalOperator={props.enableLogicalOperator}
              enableClose={true}
              onClose={() => setQueryBuilderVisible(false)}
              enableSourceFilter={props.enableSourceFilter}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
export default FilterWidget;
