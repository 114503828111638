import React, { Component } from "react";
import { XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, LineSeries } from "react-vis";
import PropTypes from "prop-types";

/**
 * ### Overview
 * This component is used to create charts and plots wherever applicable.
 * Currently there is only 1 class called: PayCurveChart which accepts chart data points and returns a rendered plot.
 */
class PayCurveChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ x: 0, y: 0 }],
      ticks: [0]
    };
  }
  componentDidMount() {
    this.updateCharts();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.chartData !== prevState.chartData) return { chartData: nextProps.chartData };
    else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.chartData !== this.props.chartData) {
      this.updateCharts();
    }
  }

  updateCharts = () => {
    let ticks = [];

    let newData = this.props.chartData.map(tier => {
      ticks.push(parseFloat(tier.Max));
      return { x: parseFloat(tier.Max), y: parseFloat(tier.Value) };
    });
    newData = [{ x: 0, y: 0 }, ...newData];

    this.setState({ data: newData, ticks: ticks });
  };

  render() {
    return (
      <div className="tabular">
        <h3>Pay Curve Plot</h3>
        <link rel="stylesheet" href="https://unpkg.com/react-vis/dist/style.css"></link>
        <XYPlot width={700} height={500}>
          <VerticalGridLines style={{ stroke: "#B7E9ED" }} />
          <HorizontalGridLines style={{ stroke: "#B7E9ED" }} />
          <XAxis
            title="Attainment %"
            tickValues={this.state.ticks}
            style={{
              line: { stroke: "#ADDDE1" },
              ticks: { stroke: "#ADDDE1" },
              text: { stroke: "none", fill: "#6b6b76", fontWeight: 600 }
            }}
          />

          <YAxis title="Personal Target %" />
          <LineSeries className="first-series" data={this.state.data} />
        </XYPlot>
      </div>
    );
  }
}

PayCurveChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.exact({ x: PropTypes.number, y: PropTypes.number }))
};

export default PayCurveChart;
