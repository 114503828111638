import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { EditableCell } from "./EditableCells";
import { getIdForListName } from "./ManageObjectIDs";

class PlanComponentSelection extends Component {
  constructor(props) {
    super(props);
    this.TableWithIDs = { data: { compMatrix: [] }, columns: { compMatrix: [] } };
    this.displayMatrix = this.props.displayMatrix.bind(this);
    this.Project = this.props.project;
    this.state = { data: { incentive: [], compMatrix: [] }, columns: { compMatrix: [] }, expanded: {} };
    this.unfitSections = props.unfitSections;
    this.unfitIncentiveIds = this.unfitSections.filter(item => item.unfit === true).map(item => item.incentiveId);
  }

  componentDidMount() {
    let { data, columns } = this.generateTables();
    this.TableWithIDs = { data: JSON.parse(JSON.stringify(data)), columns: JSON.parse(JSON.stringify(columns)) };

    this.setState({ data, columns });
  }
  renderEditableCell = info => {
    if (info.original.hasOwnProperty(info.column.id)) {
      return <EditableCell cellInfo={info} saveCell={this.saveCellData.bind(this)} hasErrors={this.errorStatus.bind(this)} />;
    } else {
      return <div></div>;
    }
  };
  errorStatus = () => {};

  generateTables = () => {
    let { data, columns } = JSON.parse(JSON.stringify(this.props.compMatrixOverride));
    //let topRow = {};
    //<TODO>: Need to fix this
    let topRow = {
      //plan: "Select All",
      role: "Select All",
      filterable: false,
      Cell: row => {
        return <div>{100 - Math.floor((1 - row.row.role / row.row.plan) * 100)}%</div>;
      }
    };
    //columns.compMatrix.splice(0, 0, { Header: "", accessor: "include_plan" });
    columns.compMatrix.forEach(element => {
      if (!["plan", "role"].includes(element.accessor)) {
        element.Cell = this.renderEditableCell;
        element.props = { type: "checkbox", selectOptions: [""], obj: "compMatrix" };
        element.aggregate = vals => vals[0];
        element.Aggregated = row => <span>{row.value} .....</span>;
        element.filterable = false;
        topRow[element.accessor] = false;
      }
    });

    data.compMatrix.forEach(element => {
      Object.keys(element).forEach(incentive => {
        if (element[incentive] === true) {
          topRow[incentive] = true;
        }
        if (this.unfitIncentiveIds.includes(incentive)) {
          if (!element.rowLevelProps) element.rowLevelProps = {};
          if (!topRow.rowLevelProps) topRow.rowLevelProps = {};
          element.rowLevelProps[incentive] = { Disabled: true };
          topRow.rowLevelProps[incentive] = { Disabled: true };
        }
      });
    });
    data.compMatrix.splice(0, 0, topRow);

    return { data: data, columns: columns };
  };

  saveCellData = (val, cellInfo) => {
    let incentive = getIdForListName(cellInfo.column.id, this.Project.AllLists["Incentives"]);
    let ObjectKey = cellInfo.column.props.obj;
    let d = JSON.parse(JSON.stringify(this.state.data));
    if (cellInfo.index === 0) {
      //handle top row change
      d[ObjectKey].forEach((row, index) => {
        if (row.hasOwnProperty(cellInfo.column.id)) {
          row[cellInfo.column.id] = val;
          this.TableWithIDs.data[ObjectKey][index][incentive] = val;
        }
      });
    } else {
      d[ObjectKey][cellInfo.index][cellInfo.column.id] = val;
      this.TableWithIDs.data[ObjectKey][cellInfo.index][incentive] = val;

      // check if top row needs to be updated. If all table rows are false then top row should be false as well.
      d[ObjectKey][0][cellInfo.column.id] = false;
      for (let r = 0; r < d[ObjectKey].length; r++) {
        if (d[ObjectKey][r][cellInfo.column.id]) {
          d[ObjectKey][0][cellInfo.column.id] = true;
          break;
        }
      }
    }
    this.setState({ data: d, columns: this.state.columns });
    this.props.tableData(this.TableWithIDs, () => {
      this.props.updateDisableSubmit();
    });
  };

  render() {
    return (
      <div className="Tabular">
        <ReactTable
          columns={this.displayMatrix({ columns: this.state.columns.compMatrix, data: this.state.data.compMatrix }).columns}
          data={this.displayMatrix({ columns: this.state.columns.compMatrix, data: this.state.data.compMatrix }).data}
          filterable
          defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
          // pivotBy={this.state.data.compMatrix.length > 0 ? ["plan"] : []}
          className="-striped -highlight"
          defaultPageSize={10}
          expanded={this.state.expanded}
          onExpandedChange={expanded => this.setState({ expanded })}
          sortable={false}
        />
      </div>
    );
  }
}

export default PlanComponentSelection;
