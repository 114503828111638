import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { PrimaryButton, SecondaryButton } from "@xactlycorp/xactly-core-components";
import Modal from "react-modal";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { generateID } from "./ManageObjectIDs";
import { DeepCopyObject } from "./util";
import CommonResources from "./CommonResources.json";

const XLSX = require("xlsx");
const papa = require("papaparse");
const jschardet = require("jschardet");

const POPUP_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    height: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const BOX_STYLE = {
  padding: "14px",
};

const fileTypeMappings = {
  "text/plain": "text",
  "text/csv": "csv",
  "text/tab-separated-values": "tsv",
  "text/psv": "psv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};
//<NOTE>: Currently Not Used:
const delimiterMappings = {
  "/t": "tab",
  "&nbsp": "non-breaking space",
  "/n": "New Line",
  ",": "Comma (,)",
  "|": "Pipe (|)",
  ":": "Colon (:)",
  ";": "SemiColon (;)",
  "'": "Quote (')",
  '"': 'Double Quote (")',
  "/": "Slash (/)",
  "\\": "Backwards Slash (\\)",
  ".": "Period (.)",
  "{}": "Braces ( {} )",
  "()": "Parentheses ( () )",
  "[]": "Square Brackets ( [] )",
};
const encodingMappings = {
  Big5: "big5",
  GB2312: "gb2312",
  GB18030: "gb18030",
  "EUC-TW": "euctw",
  "HZ-GB-2312": "hzgb2312",
  "ISO-2022-CN": "iso2022cn",
  "EUC-JP": "eucjp",
  SHIFT_JIS: "shiftjis",
  "ISO-2022-JP": "iso2022jp",
  "EUC-KR": "euckr",
  "ISO-2022-KR": "iso2022kr",
  "KOI8-R": "koi8r",
  MacCyrillic: "maccyrillic",
  IBM855: "ibm855",
  IBM866: "ibm866",
  "ISO-8859-5": "iso88595",
  "windows-1251": "windows1251",
  "ISO-8859-2": "iso88592",
  "windows-1250": "windows1250",
  "ISO-8859-5": "iso88595",
  "windows-1251": "windows1251",
  "windows-1252": "windows1252",
  "ISO-8859-7": "iso88597",
  "windows-1253": "windows1253",
  "ISO-8859-8": "iso88598",
  "windows-1255": "windows1255",
  "TIS-620": "tis620",
  "UTF-32 BE": "utf32be",
  "UTF-32 LE": "utf32le",
  "3412-ordered": "3412ordered",
  "2143-ordered": "2143ordered",
  "UTF-16 BE": "utf16be",
  "UTF-16 LE": "utf16le",
  "UTF-8": "utf8",
  ASCII: "ascii",
};

function ImportSourceFile(props) {
  const hiddenFileInput = React.useRef(null);
  const [mappedFields, setMappedFields] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  let fileType = props.formData ? props.formData.fileType : "csv";
  let delimiter = props.formData ? props.formData.delimiter : ",";
  let encoding = props.formData ? props.formData.encoding : "utf8";
  let fields = props.formData ? (props.formData.fields ? props.formData.fields.data : []) : [];
  let selectedFile;

  useEffect(() => {
    if (props.mappedRelationships && props.mappedRelationships.length > 0) {
      setMappedFields(props.mappedRelationships);
    } else if (props.formData && props.formData.fields && props.formData.fields.data) {
      const mappedItems = [];
      props.formData.fields.data.forEach(row => {
        if (row.isMapped || row.usedInFilter || row.usedInJoin || row.isCustomValidation) {
          mappedItems.push(row);
        }
      });
      setMappedFields(mappedItems);
    }
  }, [props.formData || props.mappedRelationships]);

  const handleClick = event => {
    if (mappedFields && mappedFields.length > 0) {
      setShowConfirmDialog(true);
    } else {
      hiddenFileInput.current.click();
    }
  };

  function handleChange(e) {
    selectedFile = e.target.files[0];
    fileType = fileTypeMappings[e.target.files[0].type] || "text";
    switch (fileType) {
      case "csv":
        parseCSV(selectedFile);
        break;
      case "text":
        parseCSV(selectedFile);
        break;
      case "tsv":
        parseCSV(selectedFile);
        break;
      case "xlsx":
        parseXLSX(selectedFile);
        break;
      default:
        break;
    }
  }
  //Parser Functions:
  function parseCSV(selectedFile) {
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = function (event) {
      var csvData = event.target.result;
      encoding = getEncoding(csvData);
      var data = papa.parse(csvData, { header: true });
      delimiter = data.meta.delimiter;
      let rawColumns = {};
      for (let i = 0; i < data.data.length; i++) {
        for (const [key, value] of Object.entries(data.data[i])) {
          if (rawColumns.hasOwnProperty(key)) {
            rawColumns[key].add(value);
          } else {
            rawColumns[key] = new Set([value]);
          }
        }
      }
      //<TODO>: Parse out and get data type
      fields = getFields(rawColumns);
      updateFormData();
    };
    reader.onerror = function () {
      alert("Unable to read " + selectedFile.fileName);
    };
  }
  function parseXLSX(selectedFile) {
    delimiter = "";
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = function (event) {
      const bstr = event.target.result;
      encoding = getEncoding(bstr);
      const wb = XLSX.read(bstr, { type: "array" });
      const sheetNames = wb.SheetNames;
      let rawFields = {};
      sheetNames.forEach(function (sheetName) {
        let sheetObj = wb.Sheets[sheetName];
        let rawData = XLSX.utils.sheet_to_json(sheetObj, { raw: false });
        rawData.forEach(function (row) {
          for (let key in row) {
            if (rawFields.hasOwnProperty(key)) {
              rawFields[key].add(row[key]);
            } else {
              rawFields[key] = new Set([row[key]]);
            }
          }
        });
      });
      fields = getFields(rawFields);
      encoding = "utf8";
      updateFormData();
    };
  }

  //Helper Functions:
  function getFields(rawColumns) {
    let columns = [];
    for (const [key, value] of Object.entries(rawColumns)) {
      let dataType = getDataType([...value]);
      let col = { Name: key, "Data Type": dataType, id: generateID("SourceColumn") };
      if (dataType === "date") {
        let formatObj = getDateTimeFormat([...value]);
        col["Data Type"] = formatObj.type;
        col.defaultValue = formatObj.format;
      }
      columns.push(col);
    }
    return columns;
  }
  function getDataType(examples) {
    let typeCounter = {};
    for (let i = 0; i < examples.length; i++) {
      let type = "string";
      //1) Check for number:
      if (examples[i] !== "" && !isNaN(examples[i])) {
        type = "number";
      } else {
        //2) Check for date: (here using moment to conevrt date, because native Date function was giving invalid for "25/11/2022" formats )
        let momentDate = moment(examples[i], [...CommonResources.dateSelectOptions, ...CommonResources.dateTimeSelectOptions]);
        if (momentDate.isValid()) {
          type = "date";
        }
      }

      if (typeCounter.hasOwnProperty(type)) {
        typeCounter[type]++;
      } else {
        typeCounter[type] = 1;
      }
    }
    //Take greatest Count:
    let primaryType = { type: "", count: 0 };
    for (const [key, value] of Object.entries(typeCounter)) {
      if (primaryType.type === "" || primaryType.count < value) {
        primaryType.type = key;
        primaryType.count = value;
      }
    }
    return primaryType.type;
  }

  function getDateTimeFormat(examples) {
    let example = examples[0];
    let obj = { type: "date", format: "" };
    CommonResources.dateTimeSelectOptions.forEach(format => {
      let momentObj = moment(example, format, true);
      if (momentObj.isValid()) {
        obj = { type: "dateTime", format: format };
      }
    });
    if (obj.format === "") {
      CommonResources.dateSelectOptions.forEach(format => {
        let momentObj = moment(example, format, true);
        if (momentObj.isValid()) {
          obj = { type: "date", format: format };
        }
      });
    }
    return obj;
  }

  function getEncoding(rawFile) {
    let encodingObject = jschardet.detect(rawFile.toString(), { minimumThreshold: 0 });
    if (encodingObject.encoding === null) {
      return encoding;
    } else {
      return encodingMappings[encodingObject.encoding] || encoding;
    }
  }

  function updateFormData() {
    let newFormData = props.formData ? DeepCopyObject(props.formData) : {};
    //manually set these two fields
    newFormData.uploadMethod = "automated";
    newFormData.connector = "ftp";
    //Update the formData with such:
    newFormData.fileType = fileType;
    newFormData.delimiter = delimiter;
    newFormData.encoding = encoding;
    if (newFormData.fields) {
      newFormData.fields.data = fields;
    } else {
      newFormData.fields = {};
      newFormData.fields.columns = [
        { Header: "Name", accessor: "Name" },
        { Header: "Data Type", accessor: "Data Type" },
      ];
      newFormData.fields.data = fields;
    }

    props.updateForm(newFormData);
  }

  return (
    <>
      <PrimaryButton text="Upload a file" type="button" className="btn-src-upload" data-tip data-for="importSourceTip" clickHandler={handleClick} />
      <input type="file" name="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
      <ReactTooltip id="importSourceTip" place="bottom" effect="solid">
        {"Use this feature to import a sample source file to automaticaly fill the following form."}
      </ReactTooltip>
      <Modal style={POPUP_MODAL_STYLE} isOpen={showConfirmDialog} shouldCloseOnOverlayClick={false}>
        <>
          <Box style={BOX_STYLE}>
            <h4>Warning</h4>
            <strong>
              {props.mappedRelationships && props.mappedRelationships.length > 0
                ? "Fields from this reference Source are used in following relationships. Click continue to upload new file"
                : "The following fields are being used in Data Mapping / Join Criteria / Filter Condition. Click continue to upload new file"}
            </strong>
            <List component="nav" aria-label="main mailbox folders">
              {mappedFields.map(field => (
                <ListItemButton>{field.Name || field.name}</ListItemButton>
              ))}
            </List>
            <p style={{ color: "#ff5252", paddingTop: "12px" }}>
              <strong>Note : </strong> There will be errors on Data Mapping / Data Source screen, if you choose to “Continue”. Those errors will need to be resolved manually to create connect code.
            </p>
            <SecondaryButton
              clickHandler={() => {
                setShowConfirmDialog(false);
              }}
              inline
              text="Cancel"
              className="btn-padded"
            />
            <PrimaryButton
              clickHandler={() => {
                setShowConfirmDialog(false);
                hiddenFileInput.current.click();
              }}
              inline
              text="Continue"
              className="btn-padded"
            />
          </Box>
        </>
      </Modal>
    </>
  );
}

export default ImportSourceFile;
