import React, { useState, useRef } from "react";
import Server from "./Server";
import { RenderLoader } from "./CustomerUserPage";
import "./importProjectConfiguration.css";

const server = new Server();

const ImportProjectConfiguration = () => {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [newCustomerName, setNewCustomerName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const importProjectConfiguration = () => {
    if (!newCustomerName || !newProjectName || !selectedFile) {
      setErrorMessage("All the fields are required");
      return;
    }
    setErrorMessage("");
    setIsLoading(true);
    const reader = new FileReader();
    try {
      reader.onload = event => {
        const base64String = event.target.result.replace(/^data:.*,/, "");
        server.importProjectConfigurations({ project: newProjectName, customer: newCustomerName, file: base64String }, resp => {
          if (resp && resp.status) {
            alert(resp.data && resp.data.messages ? resp.data.messages : "Project Imported Successfully");
          } else {
            alert(resp.error && resp.error.messages ? resp.error.messages : "Failed: Invalid File Import");
          }
          setIsLoading(false);
          setNewCustomerName("");
          setNewProjectName("");
          setSelectedFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = null; // Reset the value of the file input
          }
        });
      };
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }

    reader.readAsDataURL(selectedFile); // Read the selected file as data URL
  };

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);

    if (!event.target.files[0]) {
      console.log("Please select a file to upload.");
    }
  };

  return (
    <div className="copyProjectWrapper">
      {isLoading && <RenderLoader />}
      <div className="container">
        <div className="content-wrapper">
          <div className="input-wrapper">
            <label>New Customer Name</label>
            <input className="input-field" type="text" value={newCustomerName} onChange={e => setNewCustomerName(e.target.value)} />
          </div>
          <div className="input-wrapper">
            <label>New Project Name</label>
            <input className="input-field" type="text" value={newProjectName} onChange={e => setNewProjectName(e.target.value)} />
          </div>
          <div className="input-wrapper">
            <input type="file" ref={fileInputRef} onChange={handleFileChange} />
          </div>
          <p className="error-message">{errorMessage}</p>
          <button className="btn-warning btn" onClick={importProjectConfiguration}>
            Import
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportProjectConfiguration;
