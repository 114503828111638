import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TableInput from "./TableBuilder";
import { generateID, getNamesForListID, getIdFromAllObjects } from "./ManageObjectIDs";
import DynamicSelect from "./dynamicSelect";
import { validateNewValue, validations } from "./UpdateLists";
import FilterElement from "./tableFilters";
import { updateAttributes } from "./updateLists_v2";
import LoadingOverlay from "react-loading-overlay";
import Server from "./Server";
import Modal from "react-modal";
import DataSourceDetails from "./dataSourceDetails";
import SourceValidations from "./SourceValidations";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faInfoCircle, faPlusCircle, faMinusCircle, faTrash, faTable, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { EditableCell } from "./EditableCells";
import { isObject } from "./util";
import ConfirmDialog from "../components/ConfirmDialog";
import { standardFields } from "./CommonResources.json";

const server = new Server();
const configUnitTypes = ["USD", "CAD", "EUR", "AUD", "GBP", "JPY", "POINTS", "PERCENT", "QUANTITY"];

const mandatoryFields = ["Order Code", "Item Code", "Batch Name", "Batch Type", "Amount", "Amount UnitType", "Incentive Date", "Employee ID", "Split Amount (%)"];
const tableTypeToIncentFieldType = {
  DataMapping: "OrderItem",
  HRDataMapping: "People",
};
const defaultHRDataSource = "Customer HR Data";

class DataTableInput_v1 extends TableInput {
  constructor(props) {
    super(props);
    this.updateTableAnswers = this.updateTableAnswers.bind(this);
    this.dataTableForm = "";
    this.sourceNames = [];
    this.errorMsg = "";

    this.AllUnitTypes = [...configUnitTypes];
    this.attributes = [];
    this.NewLists = {};

    this.state = {
      showIntegrationOptions: (this.props.hrIntegration && this.props.tableType === "HRDataMapping") || this.props.tableType === "DataMapping",
      helpTextVisible: true,
      dataReady: true,
      showIntegrationModule: false,
      selectedSource: "",
      showReferenceSources: false,
      showValidationModule: false,
      selectedRow: null,
      useDefaultHRSource: false,
      visibleColumns: [],
      isTableDataModified: false,
      saveTableWarningModal: {
        saveTableWarning: false,
        onConfirm: () => {},
      },
      removeTableRowModule: {
        rowId: "",
        isRemoving: false,
        allowDelete: false,
        errors: [],
        warnings: [],
      },
      columns: [
        {
          Header: "",
          accessor: "action",
          style: { whiteSpace: "unset", overflow: "hidden" },
          maxWidth: 50,
          filterable: false,
        },
        {
          Header: "Xactly",
          accessor: "xactly",
          style: { whiteSpace: "unset", overflow: "hidden" },
          props: { type: "text" },
          Cell: this.renderEditableCell,
          maxWidth: 200,
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
        {
          Header: "DataType",
          accessor: "DataType",
          style: { whiteSpace: "unset", overflow: "hidden" },
          props: { type: "select", selectOptions: ["date", "string", "number"] },
          Cell: this.renderEditableCell,
          maxWidth: 150,
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      ],
      data:
        this.props.tableType === "DataMapping"
          ? [
              { id: "Order Code", xactly: "Order Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Item Code", xactly: "Item Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Batch Name", xactly: "Batch Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Batch Type", xactly: "Batch Type", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Product Name", xactly: "Product Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Geography Name", xactly: "Geography Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Customer Name", xactly: "Customer Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Quantity", xactly: "Quantity", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Amount", xactly: "Amount", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Amount UnitType", xactly: "Amount UnitType", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Incentive Date", xactly: "Incentive Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Order Date", xactly: "Order Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Order Type", xactly: "Order Type", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Discount", xactly: "Discount", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Description", xactly: "Description", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Related Order Code", xactly: "Related Order Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Related Item Code", xactly: "Related Item Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              {
                id: "Employee ID",
                xactly: "Employee ID",
                DataType: "string",
                rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } },
                empCount: 0,
              },
              { id: "Split Amount (%)", xactly: "Split Amount (%)", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
            ]
          : [
              { id: "First Name", xactly: "First Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Middle Name", xactly: "Middle Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Last Name", xactly: "Last Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prefix", xactly: "Prefix", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prefix", xactly: "Employee ID", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Employee Status", xactly: "Employee Status", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Region", xactly: "Region", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Hire Date", xactly: "Hire Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Termination Date", xactly: "Termination Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Personal Target", xactly: "Personal Target", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prorated Personal Target", xactly: "Prorated Personal Target", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Personal Currency", xactly: "Personal Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Payment Currency", xactly: "Payment Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Salary", xactly: "Salary", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prorated Salary", xactly: "Prorated Salary", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Salary Currency", xactly: "Salary Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Business Group", xactly: "Business Group", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
            ],
    };
  }

  componentDidMount() {
    console.log("v1 loaded");
    this.props.saveTablularanswers(this.updateTableAnswers);
    let sourcelist = this.props.lists.Sources;
    let useDefaultHRSource = false;
    this.NewLists = JSON.parse(JSON.stringify(this.props.lists));
    // if currData sent to the component has valid data then assign that as intial data and set state.data as the same else use default statedata.

    let initialData = this.props.currData.data === undefined ? [...this.state.data] : [...this.props.currData.data];
    //clean iniital data here
    let newData = [];
    initialData.forEach(row => {
      if (
        row.hasOwnProperty("id") &&
        this.props.lists &&
        this.props.lists.AllObjects &&
        this.props.lists.AllObjects.hasOwnProperty(row.id) &&
        this.props.lists.AllObjects[row.id].invalid &&
        this.props.lists.AllObjects[row.id].invalid === true
      ) {
      } else if (
        row.hasOwnProperty("id") &&
        row.id.endsWith("_UnitTypeName") &&
        this.props.lists &&
        this.props.lists.AllObjects &&
        this.props.lists.AllObjects.hasOwnProperty(row.id.split("_UnitTypeName")[0]) &&
        this.props.lists.AllObjects[row.id.split("_UnitTypeName")[0]].invalid &&
        this.props.lists.AllObjects[row.id.split("_UnitTypeName")[0]].invalid === true
      ) {
        // ignore this row data.
      } else {
        if (mandatoryFields.includes(row.id) && row.rowLevelProps && row.rowLevelProps.xactly) {
          row.rowLevelProps.xactly["Mandatory"] = true;
        }
        newData.push(row);
      }
    });
    initialData = newData;
    const sources = [];

    if (this.props.tableType === "HRDataMapping" && sources.length === 0) {
      if (this.props.hrIntegration) {
        useDefaultHRSource = false;
        sourcelist = this.props.lists.HRSources;
      } else {
        useDefaultHRSource = true;
        sourcelist = [{ id: defaultHRDataSource, name: defaultHRDataSource }];
      }
    }
    sourcelist.forEach(item => {
      const sourceID = item.id || item;
      if (this.props.tableType === "HRDataMapping") {
        this.sourceNames.push(getNamesForListID(sourceID, this.props.lists["HRSources"]));
        sources.push(sourceID);
      } else {
        this.sourceNames.push(getNamesForListID(sourceID, this.props.lists["Sources"]));
        sources.push(sourceID);
      }
    });

    if (this.NewLists && this.NewLists.UnitTypes && this.NewLists.UnitTypes.length) {
      this.NewLists.UnitTypes.forEach(unitType => {
        this.AllUnitTypes.push(unitType.name);
      });
    }

    let dataWithIncentiveAttributes = this.manageIncentiveAttributes(initialData);
    let initialColumns = [...this.state.columns];
    sources.forEach(source => {
      initialColumns.push(
        {
          Header: "",
          accessor: `Mapping_${source}`,
          show: true,
          props: { type: "select", selectOptions: ["Leave Blank", "Derived", "SetTo", "FieldMapping", "Other"], nullable: true },
          Cell: this.renderEditableCell,
          maxWidth: 150,
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
        {
          Header: this.props.tableType === "HRDataMapping" ? getNamesForListID(source, this.props.lists["HRSources"]) : getNamesForListID(source, this.props.lists["Sources"]),
          accessor: source,
          show: true,
          props: { type: "text", nullable: true, allowExamples: true },
          Cell: row => {
            const editableCellAttrs = {};
            if (row.original[`Mapping_${source}`] === "SetTo" && (row.original.id.endsWith("_UnitTypeName") || row.original.id === "Amount UnitType")) {
              row.original.rowLevelProps[`${source}`] = {
                ...row.original.rowLevelProps[`${source}`],
                ["type"]: "select",
              };
              editableCellAttrs["selectOptions"] = JSON.parse(JSON.stringify(this.AllUnitTypes));
            }
            if (row.original[`Mapping_${source}`] && ["Derived", "FieldMapping"].includes(row.original[`Mapping_${source}`])) {
              editableCellAttrs["lists"] = JSON.parse(JSON.stringify(this.props.lists));
              editableCellAttrs["type"] = row.original[`Mapping_${source}`];
            }
            return <>{this.renderEditableCell(row, editableCellAttrs)}</>;
          },
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      );
    });
    // this.defaultSelected = sources ? (sources.length > 2 ? sources.splice(0, 2) : sources) : [];
    this.defaultSelected = [];
    sourcelist.forEach(item => {
      if (item.disabled !== true) {
        this.defaultSelected.push(item.id);
      }
    });
    //<TODO>: default HR Data Sources dont work on defaultSelected
    if (useDefaultHRSource) {
      this.setState({ useDefaultHRSource: true, visibleColumns: [defaultHRDataSource] }, () => this.saveTable(dataWithIncentiveAttributes, initialColumns));
    } else {
      this.saveTable(dataWithIncentiveAttributes, initialColumns);
    }
  }
  updateTableAnswers(newData) {
    this.saveTable(newData.data, false);
  }
  componentDidUpdate(prevProps, prevState) {
    //overriding the  function from TableBuilder
  }
  resetTableWarning(isModify = false) {
    this.setState({ isTableDataModified: isModify });
  }
  changeColumnVisibility = (formData, updateAPI = true) => {
    let columns = [...this.state.columns];

    columns.forEach(col => {
      if (col.hasOwnProperty("show")) {
        if (formData.includes(col.accessor) || formData.includes(col.accessor.split("Mapping_")[1])) {
          col["show"] = true;
        } else {
          col["show"] = false;
        }
      }
    });
    this.setState({ columns: columns, visibleColumns: formData });
    let listType = "";
    if (this.props.tableType === "HRDataMapping") {
      listType = "HRSources";
    } else {
      listType = "Sources";
      if (updateAPI) {
        let callAPI = false;
        this.NewLists[listType].forEach(source => {
          if (formData.indexOf(source.id) > -1) {
            // condition to perform API call only if data is changed
            if (source.disabled === true) {
              source.disabled = false;
              callAPI = true;
            }
          } else {
            // condition to perform API call only if data is changed
            if (source.disabled !== true) {
              source.disabled = true;
              callAPI = true;
            }
          }
        });
        if (callAPI) {
          this.setState({ dataReady: false });
          let listData = {};
          listData[listType] = this.NewLists[listType];
          server.updateLists(this.props.customerId, this.props.projectId, listData, response => {
            this.setState({ dataReady: true });
            this.props.saveProjectDetails(response.data.object[0]);
          });
        }
      }
    }
  };

  manageIncentiveAttributes = initialData => {
    let initialFields = [];
    for (let f = 0; f < initialData.length; f++) {
      initialFields.push(initialData[f]["id"] || initialData[f]["xactly"]);
    }
    // push splitAmount into this array as well so that it does not show up as a custom field

    const standardFieldIds = this.NewLists.AllAttributes.filter(item => item.hasOwnProperty("visibleInFormulas") && item.type === tableTypeToIncentFieldType[this.props.tableType]).map(
      item => item.id,
    );
    initialFields.push(...standardFieldIds);
    this.props.lists.QualifierAttributes.forEach(item => {
      if ((this.props.tableType === "DataMapping" && item.type === "OrderItem") || (this.props.tableType === "HRDataMapping" && item.type === "People")) {
        if (!(initialFields.includes(item.id) || initialFields.includes(item.name) || item.name === "All")) {
          initialData.push({
            id: item.id,
            xactly: item.name,
            rowLevelProps: { xactly: { Disabled: false }, DataType: { Disabled: false } },
            DataType: item.dataType || "string",
            IncentFieldType: item.type || "OrderItem",
          });
        }
      }
    });
    for (var row = 0; row < initialData.length; row++) {
      if (!standardFields[this.props.tableType].includes(initialData[row]["xactly"])) {
        const attrDet = this.props.lists.AllAttributes.filter(r => r.id === initialData[row].id)[0];
        if (attrDet) {
          initialData[row]["xactly"] = attrDet.name;
          initialData[row]["DataType"] = attrDet.dataType;
          initialData[row].IncentFieldType = attrDet.type;
        }
        if (initialData[row].IncentFieldType !== tableTypeToIncentFieldType[this.props.tableType]) {
          initialData.splice(row, 1);
        }
      }
    }
    return initialData;
  };

  validateData = (data, visibleColumns) => {
    let names = {},
      customFields = [];

    let d = JSON.parse(JSON.stringify(data));
    let charLength_MIN = validations.fieldLengthBounds.QualifierAttributes.min;
    let charLength_MAX = validations.fieldLengthBounds.QualifierAttributes.max;
    let charLength_MAX_NUM = validations.fieldLengthBounds.QualifierAttributes.maxNum;

    for (let row = 0; row < d.length; row++) {
      //validate only the custom fields
      if (!standardFields[this.props.tableType].includes(d[row]["xactly"])) {
        if (!d[row]["id"].includes("_UnitTypeName")) {
          d[row]["deletable"] = true;
        }
        //check for numeric fields
        if (d[row].DataType === "number") {
          let unitTypeRow = d.filter(r => r.id === `${d[row].id}_UnitTypeName`)[0];
          if (!unitTypeRow) {
            d.splice(row + 1, 0, {
              id: `${d[row].id}_UnitTypeName`,
              xactly: `${d[row].xactly}_UnitTypeName`,
              DataType: "string",
              IncentFieldType: tableTypeToIncentFieldType[this.props.tableType],
              rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } },
            });
            ++row;
          } else {
            unitTypeRow.xactly = `${d[row].xactly}_UnitTypeName`;
          }
        } else if (d.filter(r => r.id === `${d[row].id}_UnitTypeName`)[0]) {
          d.splice(row + 1, 1); // remove unitType row if data type is not number
          --row;
        }
      }
      if (visibleColumns && visibleColumns.length > 0) {
        visibleColumns.forEach(src => {
          if (["SetTo", "Other"].includes(d[row][`Mapping_${src}`]) && d[row][`${src}`] && d[row][`${src}`].hasOwnProperty("expWithName")) {
            d[row][`${src}`] = d[row][`${src}`]["expWithName"];
          }
        });
      }
    }

    for (let row = 0; row < d.length; row++) {
      //validate only the custom fields
      if (!standardFields[this.props.tableType].includes(d[row]["xactly"])) {
        customFields.push({ name: d[row]["xactly"], id: d[row]["id"], dataType: d[row].DataType });

        if (d[row]["xactly"].match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
          this.errorStatus(true, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        } else {
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        }
        // Check Character length for all custom field.
        if (d[row].DataType === "number") {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX_NUM) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
        } else {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
        }
      }

      names[d[row]["xactly"]] = names[d[row]["xactly"]] || [];
      if (!d[row].excludeDuplicateCheck) {
        names[d[row]["xactly"]].push(d[row]["xactly"]);
      }
    }
    //check for duplicate names
    for (let name in names) {
      if (names[name].length > 1) {
        this.errorStatus(true, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      } else {
        this.errorStatus(false, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      }
    }

    if (Object.keys(this.errors).length === 0) {
      ["QualifierAttributes", "ComputationAttributes"].forEach(list => {
        this.NewLists[list].forEach(row => {
          customFields.forEach(field => {
            if (row.id === field.id) {
              row.name = field.name;
              row.dataType = field.dataType;
              row.type = this.props.tableType === "DataMapping" ? "OrderItem" : "People";
            }
          });
        });
      });
    }

    return d;
  };

  updateRowLevelProps = data => {
    let newData = [];
    data.forEach(row => {
      if (
        row.hasOwnProperty("id") &&
        this.props.lists &&
        this.props.lists.hasOwnProperty("AllObjects") &&
        this.props.lists.AllObjects.hasOwnProperty(row.id) &&
        this.props.lists.AllObjects[row.id].invalid &&
        this.props.lists.AllObjects[row.id].invalid === true
      ) {
      } else {
        this.state.visibleColumns.forEach(source => {
          if (row[`Mapping_${source}`] && ["Derived", "FieldMapping"].includes(row[`Mapping_${source}`])) {
            if (!row.rowLevelProps) {
              row.rowLevelProps = {};
            }
            if (row.rowLevelProps && !row.rowLevelProps[source]) {
              row.rowLevelProps[source] = { type: "expression", options: { expressionType: row[`Mapping_${source}`] === "Derived" ? "evaluation" : "mapping", sourceType: this.props.tableType } };
            } else {
              row.rowLevelProps[source].type = "expression";
              row.rowLevelProps[source].options = { expressionType: row[`Mapping_${source}`] === "Derived" ? "evaluation" : "mapping", sourceType: this.props.tableType };
            }
          }
        });

        if (row.id === "Employee ID") {
          row["action"] = <FontAwesomeIcon icon={faPlusCircle} onClick={this.addNewEmployeeIDRow} className="Clickable"></FontAwesomeIcon>;
        }
        if (row.deletable) {
          row["action"] = <FontAwesomeIcon icon={faTrash} id={row.id} onClick={() => this.handleRemoveTableItems(row)} className="Clickable"></FontAwesomeIcon>;
        }
        if (mandatoryFields.includes(row.id)) {
          row.isRequired = true;
        }
        newData.push(row);
      }
    });
    return newData;
  };

  addNewEmployeeIDRow = () => {
    let newData = [...this.state.data];
    for (let [i, val] of newData.entries()) {
      if (val.xactly === "Employee ID") {
        val.empCount = val.empCount ? val.empCount + 1 : 1;
        let newRowCombo = [
          {
            id: `employee_id${val.empCount}`,
            xactly: `Employee ID`,
            DataType: "string",
            rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } },
            deletable: true,
            excludeDuplicateCheck: true,
          },
          {
            id: `split_amount_pct${val.empCount}`,
            xactly: `Split Amount (%)`,
            DataType: "number",
            rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } },
            excludeDuplicateCheck: true,
          },
        ];
        newData.splice(i + 2, 0, ...newRowCombo);
        this.setState({ isTableDataModified: true });
        break;
      }
    }

    this.saveTable(newData, this.state.columns);
  };

  saveCellData = (val, cellInfo) => {
    let d = JSON.parse(JSON.stringify(this.state.data));
    let oldVal = d[cellInfo.index][cellInfo.column.id];
    if (cellInfo.original.ParentRow !== undefined) {
      d[cellInfo.original.ParentRow]["subComponent"]["data"][cellInfo.index][cellInfo.column.id] = val;
    } else {
      d[cellInfo.index][cellInfo.column.id] = val;
    }
    if (["DataType", "xactly"].includes(cellInfo.column.id)) {
      if (oldVal !== val && this.state.isTableDataModified) {
        this.setState({
          saveTableWarningModal: {
            saveTableWarning: true,
            onConfirm: () => {
              this.saveTable(d);
              this.saveAttributeLists(d);
            },
          },
        });
      } else {
        this.saveTable(d);
        this.saveAttributeLists(d);
      }
    } else {
      try {
        if (isObject(oldVal) && isObject(val)) {
          if (oldVal.exp !== val.exp) {
            this.setState({ isTableDataModified: true });
          }
        } else if (JSON.stringify(oldVal) !== JSON.stringify(val)) {
          if (!(oldVal === undefined && val === "")) {
            this.setState({ isTableDataModified: true });
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.saveTable(d);
    }
  };

  saveCellExample = (val, cellInfo) => {
    let d = JSON.parse(JSON.stringify(this.state.data));
    d[cellInfo.index][`Example_${cellInfo.column.id}`] = val;
    this.setState({ isTableDataModified: true });
    this.saveTable(d);
  };
  renderEditableCell = (info, Attrs) => {
    return <EditableCell {...Attrs} cellInfo={info} saveCell={this.saveCellData.bind(this)} saveCellExample={this.saveCellExample.bind(this)} hasErrors={this.errorStatus.bind(this)} />;
  };

  saveTable = (data, col = this.state.columns, visibleColumns = this.state.visibleColumns) => {
    data = this.validateData(data, visibleColumns);
    if (col === false) {
      this.setState(
        {
          data: data,
          dataReady: true,
        },
        () => {
          //this.changeColumnVisibility(visibleColumns, false);
          this.props.tableData({
            data: data,
          });
        },
      );
    } else {
      this.setState(
        {
          data: data,
          columns: col,
          dataReady: true,
        },
        () => {
          //this.changeColumnVisibility(visibleColumns, false);
          this.props.tableData({
            columns: col,
            data: data,
          });
        },
      );
    }
  };

  handleRemoveCustomFields = objectId => {
    this.setState({ dataReady: false });
    server.getDependencies(this.props.customerId, this.props.projectId, objectId, async response => {
      let errorArray = response.data.object;
      if (errorArray.length <= 0) {
        this.setState(
          {
            removeTableRowModule: {
              rowId: objectId,
              isRemoving: true,
              allowDelete: true,
              errors: [],
              warnings: [],
            },
          },
          () => this.deleteRow(this.state.removeTableRowModule),
        );
      } else {
        if (errorArray && errorArray.length) {
          let errors = [];
          let warnings = [];
          errorArray.forEach(item => {
            if (item.hasOwnProperty("type") && item.type == "error") {
              errors.push(item);
            } else {
              warnings.push(item);
            }
          });
          this.setState({
            removeTableRowModule: {
              rowId: objectId,
              isRemoving: true,
              allowDelete: errors.length > 0 ? false : true,
              errors: errors,
              warnings: warnings,
            },
          });
        }
      }
      this.setState({ dataReady: true });
    });
  };

  handleRemoveTableItems = row => {
    if (!standardFields[this.props.tableType].includes(row["xactly"])) {
      if (this.state.isTableDataModified) {
        this.setState({ saveTableWarningModal: { saveTableWarning: true, onConfirm: () => this.handleRemoveCustomFields(row.id) } });
      } else {
        this.handleRemoveCustomFields(row.id);
      }
    } else {
      let confirmation = window.confirm(`You want to delete this field ?`);
      if (confirmation) {
        var newdata = [...this.state.data];
        for (var i = 0; i < newdata.length; i++) {
          if (newdata[i].id === "Employee ID") {
            newdata[i].empCount -= 1;
          }

          if (newdata[i].id == row.id) {
            break;
          }
        }
        newdata.splice(i, 2);
        this.setState({ isTableDataModified: true });
        this.saveTable(newdata, this.state.columns);
      }
    }
  };

  addRow = () => {
    const newData = [...this.state.data];
    let key = generateID("QualifierAttributes");
    let defaultName = "New_Custom_Field";
    const IncentFieldType = this.props.tableType === "DataMapping" ? "OrderItem" : "People",
      dataType = "string";
    newData[this.state.data.length] = {
      id: key,
      xactly: defaultName,
      DataType: dataType,
      IncentFieldType: IncentFieldType,
    };

    const listItem = { name: defaultName, id: key, type: IncentFieldType, dataType: dataType };
    this.NewLists.QualifierAttributes.push(listItem);
    this.saveTable(newData);
    this.saveAttributeLists(newData);
  };

  deleteRow = removeRowObj => {
    let newData = [...this.state.data];
    newData = newData.filter(item => {
      if (item.id === removeRowObj.rowId || item.id === `${removeRowObj.rowId}_UnitTypeName`) {
        return false;
      }
      return true;
    });

    let QualifierAttributesList = [...this.NewLists.QualifierAttributes];
    QualifierAttributesList = QualifierAttributesList.filter(item => item.id !== removeRowObj.rowId);
    this.NewLists.QualifierAttributes = QualifierAttributesList;
    this.saveAttributeLists(newData, removeRowObj.rowId);
    this.saveTable(newData);
    this.setState({
      removeTableRowModule: {
        rowId: "",
        isRemoving: false,
        errors: [],
        warnings: [],
      },
    });
  };

  saveAttributeLists = (tableData, deleteId) => {
    if (Object.values(this.errors).length === 0) {
      this.setState({ dataReady: false });
      const reqData = updateAttributes(this.NewLists, this.props.variables);
      if (deleteId) {
        reqData[`AllObjects.${deleteId}.invalid`] = true;
      }
      server.updateLists(this.props.customerId, this.props.projectId, reqData, response => {
        this.setState({ isTableDataModified: false });
        this.props.saveProjectDetails(response.data.object[0]);
        this.saveTable(tableData);
      });
    }
  };

  checkJSONParseable = string => {
    try {
      JSON.parse(string);
      return true;
    } catch (error) {
      return false;
    }
  };
  addSource = (source, copyFrom) => {
    let listType = "";
    if (this.props.tableType === "HRDataMapping") {
      listType = "HRSources";
    } else {
      listType = "Sources";
    }
    let key = generateID(listType);
    let listItem = {};
    if (listType === "HRSources") {
      listItem = { name: source, id: key };
    } else {
      if (copyFrom) {
        listItem = JSON.parse(JSON.stringify(copyFrom));
        listItem.name = source;
        listItem.id = key;
        const oldIdNewIdMappings = [];
        if (listItem.connector !== "sfdc") {
          if (listItem.fields && listItem.fields.data && listItem.fields.data.length > 0) {
            listItem.fields.data.forEach(dataRow => {
              const oldId = dataRow.id;
              dataRow.id = generateID("SourceColumn");
              oldIdNewIdMappings.push({
                oldId: oldId,
                newId: dataRow.id,
              });
            });
          }
        }
        if (Array.isArray(listItem.relationships)) {
          listItem.relationships.forEach(relationship => {
            if (this.checkJSONParseable(relationship.relationship)) {
              const parsedData = JSON.parse(relationship.relationship);
              // if (parsedData.primarySource === copyFrom.name) {
              oldIdNewIdMappings.push({
                oldId: copyFrom.id,
                newId: listItem.id,
              });
              oldIdNewIdMappings.push({
                oldId: copyFrom.name,
                newId: listItem.name,
              });
              oldIdNewIdMappings.forEach(mapping => {
                const replacementValue = mapping.newId;
                const regex = new RegExp(mapping.oldId, "g");
                relationship.relationship = relationship.relationship.replace(regex, replacementValue);
              });
              // }
            }
          });
        }
        if (listItem.sourceFilter && this.checkJSONParseable(listItem.filterExpression)) {
          const parsedFilter = JSON.parse(listItem.filterExpression);
          // if (parsedFilter.primarySource === copyFrom.name) {
          oldIdNewIdMappings.forEach(mapping => {
            const replacementValue = mapping.newId;
            const regex = new RegExp(mapping.oldId, "g");
            listItem.filterExpression = listItem.filterExpression.replace(regex, replacementValue);
          });
          // }
        }
      } else {
        listItem = { name: source, id: key, uploadMethod: "native" };
      }
    }
    if (!this.NewLists.AllObjects) {
      this.NewLists.AllObjects = {};
    }
    this.NewLists[listType].push(listItem);
    this.NewLists.AllObjects[key] = { name: source, type: listType };
    this.setState({ dataReady: false });
    let listData = {};
    listData[listType] = this.NewLists[listType];
    listData.AllObjects = this.NewLists.AllObjects;
    server.updateLists(this.props.customerId, this.props.projectId, listData, response => {
      this.props.saveProjectDetails(response.data.object[0]);
      this.props.updatedLists(this.NewLists);
      let columns = [
        ...this.state.columns,
        {
          Header: "",
          accessor: `Mapping_${key}`,
          show: true,
          props: { type: "select", selectOptions: ["Leave Blank", "Derived", "SetTo", "FieldMapping", "Other"], nullable: true },
          Cell: this.renderEditableCell,
          maxWidth: 130,
        },
        {
          Header: this.props.tableType === "HRDataMapping" ? getNamesForListID(source, this.props.lists["HRSources"]) : getNamesForListID(source, this.props.lists["Sources"]),
          accessor: key,
          show: true,
          props: { type: "text", nullable: true },
          Cell: row => {
            const editableCellAttrs = {};
            if (row.original[`Mapping_${source}`] === "SetTo" && (row.original.id.endsWith("_UnitTypeName") || row.original.id === "Amount UnitType")) {
              row.original.rowLevelProps[`${source}`] = {
                ...row.original.rowLevelProps[`${source}`],
                ["type"]: "select",
              };
              editableCellAttrs["selectOptions"] = JSON.parse(JSON.stringify(this.AllUnitTypes));
            }
            if (row.original[`Mapping_${source}`] && ["Derived", "FieldMapping"].includes(row.original[`Mapping_${source}`])) {
              editableCellAttrs["lists"] = JSON.parse(JSON.stringify(this.props.lists));
            }
            return <>{this.renderEditableCell(row, editableCellAttrs)}</>;
          },
        },
      ];
      this.setState({
        columns: columns,
        dataReady: true,
      });
      this.props.tableData({
        columns: columns,
        data: this.state.data,
      });
    });
    return key;
  };

  validateSource = source => {
    return validateNewValue("Sources", source);
  };
  displayData = () => {
    let d = this.updateRowLevelProps(JSON.parse(JSON.stringify(this.state.data)));
    return d;
  };

  displayColumns = () => {
    let col = this.state.columns.map(c => {
      let column = { ...c };
      let systemColumns = ["action", "xactly", "DataType"];

      if (!systemColumns.includes(column.accessor) && !column.accessor.startsWith("Mapping_")) {
        column.Header = () => {
          return (
            <div style={{ display: "inline-block" }}>
              {this.props.tableType === "HRDataMapping" ? getNamesForListID(column.accessor, this.props.lists["HRSources"]) : getNamesForListID(column.accessor, this.props.lists["Sources"])}
              {this.state.showIntegrationOptions && (
                <div>
                  <FontAwesomeIcon
                    style={{ margin: "0px 0px 0px 5px" }}
                    icon={faInfoCircle}
                    className="Clickable LeftAlign "
                    onClick={() => {
                      if (this.state.isTableDataModified) {
                        this.setState({
                          saveTableWarningModal: {
                            saveTableWarning: true,
                            onConfirm: () => {
                              this.setState({
                                showIntegrationModule: true,
                                selectedSource: column.accessor,
                              });
                            },
                          },
                        });
                      } else {
                        this.setState({
                          showIntegrationModule: true,
                          selectedSource: column.accessor,
                        });
                      }
                    }}
                  />
                  {this.props.tableType === "DataMapping" && (
                    <FontAwesomeIcon
                      style={{ margin: "0px 0px 0px 5px" }}
                      icon={faTable}
                      className="Clickable LeftAlign "
                      onClick={() => {
                        this.setState({
                          selectedSource: column.accessor,
                          showValidationModule: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        };
      }

      if (column.props && column.props.lists) {
        column.props.lists = this.props.lists;
      }
      return column;
    });
    return col;
  };
  closeDataSourceDetailsModule = () => {
    this.setState({
      showReferenceSources: false,
      showIntegrationModule: false,
      showValidationModule: false,
    });
  };
  closeValidationsModule = () => {
    this.setState({
      showReferenceSources: false,
      showIntegrationModule: false,
      showValidationModule: false,
    });
  };
  toggleHelpText = () => {
    this.setState({ helpTextVisible: !this.state.helpTextVisible });
  };

  render() {
    let newData = this.displayData();
    return (
      <div>
        {this.state.showIntegrationOptions && (
          <div className="SubSection Clickable" onClick={this.toggleHelpText}>
            {
              <label className="Clickable">
                {!this.state.helpTextVisible && <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>}
                {this.state.helpTextVisible && <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>}
                <span> Mapping Instructions</span>
              </label>
            }
            {this.state.helpTextVisible && (
              <ol className="HelpSection">
                <li key={5}>Add new data sources using the drop down menu below.</li>
                <li key={0}>Click "Manage Reference Sources" button on the right to define any required reference sources.</li>
                <li key={1}>
                  {
                    <span>
                      Click on <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> icon next to column heading to add source details and define relationships to other reference sources.
                    </span>
                  }
                </li>
                <li key={2}> For each Xactly field, select one of the four mapping types: "SetTo", "Field Mapping", "Derivation", or "Other".</li>
                <li key={3}>
                  {" "}
                  For "Field Mapping" or "Derivation", an expression box will be displayed. In order to build an expression, use "@s" to select from defined sources and @t (only for 'Derived') to
                  select a transformation.
                </li>
                <li key={4}> If multiple relationships are defined for a source, then select the relationship that is applicable for the derivation.</li>
                <li key={6}>{'For fields marked as "SetTo" or "Other", use < > notation for defining parameters. E.g. Booking_<periodName>_<periodStartDate>_<periodEndDate> as "Batch Name".'} </li>
              </ol>
            )}
          </div>
        )}
        <div className="flexContainer">
          {!this.state.useDefaultHRSource ? (
            <DynamicSelect
              className="flexItem"
              data={this.props.tableType === "HRDataMapping" ? this.props.lists.HRSources : this.props.lists.Sources}
              defaultSelect={this.defaultSelected}
              labelKey="name"
              valueKey="id"
              onSelectionChange={this.changeColumnVisibility}
              caption={this.props.tableType === "HRDataMapping" ? "Select HR Sources(s)" : "Select Data Sources(s)"}
              addCaption={this.props.tableType === "HRDataMapping" ? "Add new HR Source" : "Add new Data Source"}
              multiSelect={true}
              onAdd={this.addSource}
              addItemLabel={this.props.tableType === "HRDataMapping" ? "HR Data Source" : "Data Source"}
              validate={this.validateSource}
              saveTableWarning={(this.props.tableType === "DataMapping" || this.props.tableType === "HRDataMapping") && this.state.isTableDataModified}
            />
          ) : (
            ""
          )}
          {this.state.showIntegrationOptions && (
            <div className="flexContainer" style={{ "justify-content": "right" }}>
              <div style={{ padding: "10px" }}>
                <button
                  className="btn btn-success flexItem"
                  type="button"
                  onClick={() => {
                    if (this.state.isTableDataModified) {
                      this.setState({
                        saveTableWarningModal: {
                          saveTableWarning: true,
                          onConfirm: () => {
                            this.setState({
                              showIntegrationModule: true,
                              showReferenceSources: true,
                              showValidationModule: false,
                              selectedSource: "",
                            });
                          },
                        },
                      });
                    } else {
                      this.setState({
                        showIntegrationModule: true,
                        showReferenceSources: true,
                        showValidationModule: false,
                        selectedSource: "",
                      });
                    }
                  }}
                >
                  {"Manage Reference Sources"}
                </button>
              </div>
            </div>
          )}
          <br />
        </div>
        <div className="Tabular">
          {Object.values(this.errors).length > 0 ? (
            <div className="alert alert-danger" role="alert">
              {this.displayErrorMessages()}
            </div>
          ) : (
            ""
          )}
          <LoadingOverlay
            active={!this.state.dataReady}
            spinner
            text="loading ..."
            styles={{
              spinner: base => ({
                ...base,
                width: "80px",
              }),

              overlay: base => ({
                ...base,
                background: "rgba(0, 0, 0, 0.2)",
              }),
            }}
          >
            <ReactTable
              sortable={false}
              columns={this.displayColumns()}
              data={newData}
              className="-striped -highlight"
              pageSize={newData.length}
              filterable
              defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
              showPagination={false}
            />
          </LoadingOverlay>
        </div>
        <button
          className="btn btn-success btn-padded"
          onClick={this.state.isTableDataModified ? () => this.setState({ saveTableWarningModal: { saveTableWarning: true, onConfirm: this.addRow } }) : this.addRow}
        >
          Add New Field
        </button>
        <ConfirmDialog
          open={this.state.saveTableWarningModal.saveTableWarning}
          setOpen={val => {
            this.setState({ saveTableWarningModal: { saveTableWarning: val, onConfirm: () => {} } });
          }}
          onConfirm={this.state.saveTableWarningModal.onConfirm}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FontAwesomeIcon icon={faTriangleExclamation} color="#f0ad4e" size="2xl" />
            <div style={{ "padding-left": "10px" }}>
              <b>STOP!</b> Make sure that all changes to data mapping table is saved before proceeding further. Failed to do so may result in loss of unsaved mapping.
            </div>
          </div>
        </ConfirmDialog>
        {
          <Modal
            isOpen={this.state.showIntegrationModule}
            contentLabel="Data Source Details"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            onRequestClose={() => {
              this.setState({
                showIntegrationModule: false,
                selectedSource: "",
              });
            }}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "70%",
              },
            }}
          >
            <div>
              <SecondaryButton
                icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                clickHandler={() => {
                  this.setState({
                    showIntegrationModule: false,
                    showReferenceSources: false,
                  });
                }}
                iconSize={20}
              />
              <Tooltip id="closeModal">Close</Tooltip>
            </div>
            <DataSourceDetails
              lists={this.props.lists}
              selectedSource={this.state.selectedSource}
              sourceType={this.props.tableType}
              customerId={this.props.customerId}
              projectId={this.props.projectId}
              closeDataSourceDetailsModule={this.closeDataSourceDetailsModule}
              saveProjectDetails={updatedData => {
                this.NewLists = updatedData.AllLists;
                this.props.saveProjectDetails(updatedData);
              }}
              showReferenceSources={this.state.showReferenceSources}
              SendQuestionPath={this.props.SendQuestionPath}
            />
          </Modal>
        }
        {
          <Modal
            style={{ content: { height: "60%", width: "75%", margin: "auto" } }}
            isOpen={this.state.removeTableRowModule.isRemoving}
            onRequestClose={() => {
              this.setState({
                removeTableRowModule: {
                  rowId: "",
                  isRemoving: false,
                  errors: [],
                  warnings: [],
                },
              });
            }}
          >
            <div style={{ padding: "20px" }}>
              <div>
                <SecondaryButton
                  icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                  clickHandler={() => {
                    this.setState({
                      removeTableRowModule: {
                        rowId: "",
                        isRemoving: false,
                        errors: [],
                        warnings: [],
                      },
                    });
                  }}
                  iconSize={20}
                />
                <Tooltip id="closeModal">Close</Tooltip>
              </div>
              {this.state.removeTableRowModule.errors.length > 0 && (
                <div>
                  <h4 style={{ color: "red" }}>{"Errors: "}</h4>
                  <div>{`Please address the following errors before deleting ${getIdFromAllObjects(this.state.removeTableRowModule.rowId, this.NewLists.AllObjects)}:`}</div>
                  <span />
                  <ul>
                    {this.state.removeTableRowModule.errors.map(error => (
                      <li>
                        <a className="Clickable" onClick={e => this.handleSelect(error.link)}>
                          {error.message}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <hr />
                </div>
              )}
              {this.state.removeTableRowModule.warnings.length > 0 && (
                <div>
                  <h4>{"Warnings: "}</h4>
                  <div>{`Note: Deleting this object will lose any related captured requirements.`}</div>
                  <span />
                  <ul>
                    {this.state.removeTableRowModule.warnings.map(warning => (
                      <li>
                        {warning.link !== null ? (
                          <a className="Clickable" onClick={e => this.handleSelect(warning.link)}>
                            {warning.message}
                          </a>
                        ) : (
                          <span>{warning.message}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                  <hr />
                </div>
              )}
              {this.state.removeTableRowModule.allowDelete ? (
                <button
                  style={{ left: "50%", right: "50%" }}
                  onClick={e => {
                    this.deleteRow(this.state.removeTableRowModule);
                  }}
                >
                  Confirm Delete
                </button>
              ) : (
                <button style={{ left: "50%", right: "50%", color: "red" }} disabled={!this.state.removeTableRowModule.allowDelete}>
                  Cannot Delete
                </button>
              )}
            </div>
          </Modal>
        }
        {
          <Modal
            style={{ content: { height: "75%", width: "75%", margin: "auto" } }}
            isOpen={this.state.showValidationModule}
            onRequestClose={() => {
              this.setState({
                showValidationModule: false,
              });
            }}
          >
            <div>
              <SecondaryButton
                icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                clickHandler={() => {
                  this.setState({
                    showValidationModule: false,
                  });
                }}
                iconSize={20}
              />
              <Tooltip id="closeModal">Close</Tooltip>
              <SourceValidations AllLists={this.props.lists} selectedSource={this.props.lists.Sources.filter(item => item.id === this.state.selectedSource)[0]} sourceType={"DataMapping"} />
            </div>
          </Modal>
        }
      </div>
    );
  }
}

export default DataTableInput_v1;
