import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import Modal from "react-modal";
import Server from "./Server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup, ControlLabel, FormControl, Alert } from "react-bootstrap";
import { Tooltip, PrimaryButton, SecondaryButton, DropDown } from "@xactlycorp/xactly-core-components";
import { RenderLoader } from "./CustomerUserPage";
import "./frdApproverGroup.css";

const server = new Server();

const DEFAULT_FORM_STATE = {
  formError: null,
  isEditing: false,
  groupName: "",
  groupId: "",
  selectedSections: []
};

const POPUP_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "80%",
    height: "80%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const RenderApproverGroupsForm = props => {
  const { closeModal, fetchApproverGroups, editGroup } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [frdSectionList, setFrdSectionList] = useState([]);
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  useEffect(() => {
    fetchFrdSectionList()
  }, []);

  useEffect(() => {
    if (editGroup) {
      const selectedSections = (editGroup && editGroup.requirementSections) || [];
      setFormState({
        ...formState,
        isEditing: true,
        groupName: editGroup.groupName,
        groupId: editGroup._id,
        selectedSections: selectedSections
      });
    } else {
      setFormState(DEFAULT_FORM_STATE);
    }
  }, [editGroup]);

  const fetchFrdSectionList = () => {
    setIsLoading(true);
    server.getFrdSectionList(response => {
      if (response.status) {
        if (response.data && response.data.object) {
          setFrdSectionList(response.data.object);
        } else {
          setFrdSectionList([]);
        }
      }
      setIsLoading(false);
    });
  }

  const inputChangeHandler = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const dropdownChangeHandler = selectedItems => {
    setFormState({
      ...formState,
      selectedSections: selectedItems
    });
  };

  const removeItemHandler = removeItems => {
    const resultArr = formState.selectedSections.filter(item => item !== removeItems.key);
    setFormState({
      ...formState,
      selectedSections: resultArr
    });
  };

  const validation = () => {
    let error = null,
      valid = true;
    if (!formState.groupName) {
      error = "Group Name is required.";
      valid = false;
    } else if (formState.selectedSections.length <= 0) {
      error = "Please select sections for this group.";
      valid = false;
    }
    setFormState({
      ...formState,
      formError: error
    });
    return valid;
  };

  const submitHandler = event => {
    if (validation()) {
      setIsLoading(true);
      if (formState.isEditing) {
        const data = {
          requirementSections: formState.selectedSections
        };
        server.updateApproverGroup(data, formState.groupId, response => {
          if (response.status) {
            fetchApproverGroups();
            closeModal();
          }
          setIsLoading(false);
        });
      } else {
        const data = {
          groupName: formState.groupName,
          requirementSections: formState.selectedSections
        };
        server.createApproverGroup(data, response => {
          if (response.status) {
            fetchApproverGroups();
            closeModal();
          }
          setIsLoading(false);
        });
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <div style={{ position: "absolute", top: "8px", right: "8px" }}>
        <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />} clickHandler={closeModal} iconSize={20} />
        <Tooltip id="closeModal" placement="left">
          Close
        </Tooltip>
      </div>
      <div className="SubSection">
        {isLoading && <RenderLoader />}
        <h4>Create Approver Group</h4>
        <Form onSubmit={submitHandler}>
          {formState.formError && <Alert bsStyle="danger">{formState.formError}</Alert>}
          <FormGroup className="mb-3" controlId="groupNameId">
            <ControlLabel id="groupNameId">Group Name</ControlLabel>
            <FormControl type="input" name="groupName" onChange={inputChangeHandler} placeholder="Group Name" value={formState.groupName} disabled={formState.isEditing} />
          </FormGroup>
          <FormGroup className="mb-3" controlId="formBasicEmail">
            <ControlLabel>Select Sections</ControlLabel>
            <DropDown
              className="dynamicContent"
              caption="Requirement Sections"
              data={frdSectionList || []}
              textPropName="value"
              valuePropName="key"
              selectedValue={formState.selectedSections}
              changeHandler={dropdownChangeHandler}
              removeItemHandler={removeItemHandler}
              validationMessage={""}
              multiselect={true}
              searchBox={true}
              required={true}
              size="large"
            />
          </FormGroup>
          <PrimaryButton text={formState.isEditing ? "Update" : "Create"} type="submit" />
        </Form>
      </div>
    </>
  );
};

const FrdApproverGroups = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [approverGroups, setApproverGroups] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchApproverGroups();
  }, []);

  function fetchApproverGroups() {
    setIsLoading(true);
    server.getApproverGroups(response => {
      if (response.status) {
        if (response.data && response.data.object) {
          setApproverGroups(response.data.object);
        } else {
          setApproverGroups([]);
        }
      }
      setIsLoading(false);
    });
  }

  function openApproverModal() {
    setIsOpen(true);
  }

  function closeApproverModal() {
    setIsOpen(false);
    setEditGroup(null);
  }

  const columns = [
    {
      Header: "Group Name",
      accessor: "groupName",
    },
    {
      Header: "Sections",
      accessor: "requirementSections",
      Cell: ({ original }) => {
        return original.requirementSections.join(", ");
      }
    },
    {
      Header: "Actions",
      accessor: "",
      maxWidth: 150,
      Cell: ({ original }) => (
        <PrimaryButton
          text="Edit"
          type="button"
          clickHandler={() => {
            setEditGroup(original);
            openApproverModal();
          }}
          icon={<FontAwesomeIcon icon={faEdit} />}
        />
      )
    }
  ];

  return (
    <div>
      {isLoading && <RenderLoader />}
      <div className="NewSection" style={{ borderWidth: "0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "8px 0" }}>
          <h5>Approver Groups</h5>
          <PrimaryButton text="Create Group" type="button" clickHandler={openApproverModal} />
        </div>
        <div className="Tabular">
          <ReactTable
            columns={columns}
            data={approverGroups}
            className="-striped -highlight"
            defaultPageSize={10}
            filterable={true}
            defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
          />
        </div>
      </div>
      <Modal isOpen={isOpen} contentLabel="Approver Groups Modal" onRequestClose={closeApproverModal} style={POPUP_MODAL_STYLE} shouldCloseOnOverlayClick={false}>
        <RenderApproverGroupsForm closeModal={closeApproverModal} fetchApproverGroups={fetchApproverGroups} editGroup={editGroup} />
      </Modal>
    </div>
  );
};

export default FrdApproverGroups;
