import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import Server from "./Server";
import store from "store";
const server = new Server();

const clientAccessPage = props => {
  const formSchema = { type: "object", properties: { email: { title: "Enter your email address:", type: "string" } }, required: ["email"] };

  server.authenticate().then(response => {
    if (response.status) {
      window.location = "/requirementSummary";
    }
  });

  const handleError = err => {
    alert(err.error.messages);
  };

  const handleSubmit = ({ formData }) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    server.externalUserLogin(formData.email, token).then(response => {
      if (response.status) {
        store.set("mode", response.mode);
        store.set("currentUser", response.userId);
        store.set("user", {
          [response.userId]: {
            name: response.name,
            role: response.roles,
            access_map: response.access_map,
            id: response.userId,
            projectId: response.projectId,
            customerId: response.customerId,
            customerName: response.customerName,
            projectName: response.projectName,
            approvalSections: response.approvalSections,
            token: response.token
          }
        });
        //<TODO>: Change this here depends on the token
        if (response.tabs.length > 0 && response.tabs.includes("ImplementationSurvey")) {
          window.location = "/implementationSurvey";
        } else {
          window.location = "/requirementSummary";
        }
      } else {
        handleError(response);
      }
    });
  };

  return (
    <div style={{}}>
      <div style={{ width: "50%", margin: "auto", padding: "100px", "border-style": "solid" }}>
        <Form schema={formSchema} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default clientAccessPage;
