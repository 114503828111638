import React, { Component } from "react";
import store from "store";
import Server from "./Server";
import ReactTable from "react-table";
import ConnectAuthentication from "./ConnectAuthentication";
import LoadingOverlay from "react-loading-overlay";

const useClientId = false;
const server = new Server();

class ConnectDependentRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: store.get("currentUser"),
      projectName: store.get("user")[store.get("currentUser")]["projectName"],
      projectId: store.get("user")[store.get("currentUser")]["projectId"],
      customerId: store.get("user")[store.get("currentUser")]["customerId"],
      tokenId: "",
      displayLoginForm: false,
      connectUserId: store.get("user")[store.get("currentUser")]["connectUserId"],
      connectEnvironment: store.get("user")[store.get("currentUser")]["connectEnvironment"],
      dataReady: false,
      incentObjects: {},
      columns: {
        quotaRelationship: [
          {
            Header: "QuotaRelationship",
            columns: [
              { Header: "Quota Relationship Name", accessor: "Name" },
              { Header: "Quota Table Referenced", accessor: "QuotaName" },
              { Header: "Quota Table Available in Target?", accessor: "Available" }
            ]
          }
        ]
      },
      data: {
        quotaRelationship: []
      }
    };
  }
  componentDidMount() {
    //console.log("ConnectDependentRules component mounted");

    this.getIncentObjects(this.state.connectUserId, this.state.connectEnvironment, useClientId);
  }

  getIncentObjects = (userId, env, useClient) => {
    server.getIncentObjectIds(env, useClient, response => {
      if (response && response.status === 200) {
        store.update("user", user => {
          user[this.state.currentUser]["connectUserId"] = userId;
          user[this.state.currentUser]["connectEnvironment"] = env;
        });
        this.setState({ connectUserId: userId, displayLoginForm: false, connectEnvironment: env, incentObjects: response.data.object.data });
        this.getRules();
      } else {
        this.setState({ displayLoginForm: true, connectUserId: "", connectEnvironment: "", dataReady: true });
      }
    });
  };

  matchQuotaId = qr => {
    let quotaRelationship = JSON.parse(JSON.stringify(qr));
    quotaRelationship.forEach(element => {
      //console.log(element);
      element.Available = "No";
      for (let r = 0; r < this.state.incentObjects.rows.length; r++) {
        if (element.QuotaName === this.state.incentObjects.rows[r][1]) {
          element.Available = "Yes";
          element.QuotaId = parseFloat(this.state.incentObjects.rows[r][0]);
          break;
        }
      }
    });
    return quotaRelationship;
  };

  getRules = () => {
    this.setState({ dataReady: false });
    server.getGeneratedRules(this.state.customerId, this.state.projectId, response => {
      if (response && response.status === 200) {
        //console.log("*** this is the response from getRules *****");
        //console.log(response);

        // update quota relationship data with the ids retrived from connect
        let updatedQuotaRelationships = this.matchQuotaId(response.data.object.rules.ConfigObjects.QuotaRelationships);

        this.setState({
          dataReady: true,
          data: {
            quotaRelationship: updatedQuotaRelationships
          }
        });
      }
    });
  };
  toggleLoginSection = () => {
    this.setState({
      displayLoginForm: !this.state.displayLoginForm
    });
  };

  getRuleXML = () => {
    //console.log("sending request for converting rule json to xml");
    let exportList = this.state.data.quotaRelationship.filter(row => row.Available === "Yes");
    //console.log(exportList);
    server.getRulesXMLFromJSON(
      {
        quotaRelationship: exportList
      },
      { customerId: this.state.customerId, projectId: this.state.projectId },
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GeneratedRules.zip");
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  render() {
    return (
      <LoadingOverlay
        active={!this.state.dataReady}
        spinner
        text="Getting Incent Data..."
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
            position: "absolute",
            top: 0
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.3)"
          })
        }}
      >
        <div className="">
          {this.state.displayLoginForm ? (
            <ConnectAuthentication useClientId={useClientId} projectName={this.state.projectName} getObjects={this.getIncentObjects.bind(this)}></ConnectAuthentication>
          ) : (
            ""
          )}
          <div>
            {this.state.connectUserId ? (
              <div className="NewSection">
                <h4>
                  {`Logged into Incent ${this.state.connectEnvironment} environment as: ${this.state.connectUserId} `}
                  <button className="btn btn-success RightAlign" onClick={this.toggleLoginSection}>
                    Change Target
                  </button>
                </h4>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {this.state.connectUserId ? (
              <ReactTable
                columns={this.state.columns.quotaRelationship}
                data={this.state.data.quotaRelationship}
                className="-striped -highlight"
                defaultPageSize={5}
                filterable
                defaultFilterMethod={(filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())}
              />
            ) : (
              ""
            )}
          </div>
          <button type="button" onClick={this.getRuleXML} className="btn btn-primary RightAlign btn-padded">
            Export Rules
          </button>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ConnectDependentRules;
