import React, { useState, useEffect } from "react";
import { expDataTypes, systemFunctions, operators, errorCodes } from "./expressionBuilderConfig";
import { create, all } from "mathjs";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const math = create(all);

const allEqual = arr => arr.every(val => val === arr[0]);
const RenderExpression = props => {
  let expressionInput = React.createRef();
  const [exp, setExp] = useState(props.exp || "");
  const [validationErrors, setValidationErrors] = useState([]);
  const [currentActiveSource, setCurrentActiveSource] = useState("");
  const [activeSourceColumns, setActiveSourceColumns] = useState([]);
  const argumentTypeLabels = {
    OperatorNode: "Relational Expression",
    AccessorNode: "Source Field",
    ConstantNode: "Constant Value",
    FunctionNode: "Valid Fucntion",
  };
  const booleanCheckFunctions = ["if", "when"];
  const dataTypeOperationseMapping = {
    text: ["==", "!="],
    number: ["==", "!=", "<", ">", "<=", ">=", "-", "+", "/", "*"],
    date: ["==", "!=", "<", ">", "<=", ">=", "-", "+", "/", "*"],
    boolen: ["==", "!="],
  };
  let errors = [],
    operatorsUsed = [];

  const sourceIdToNames = {},
    sourceNameToIds = {},
    fieldNameToIds = {},
    fieldIdToNames = {},
    availableFunctions = Object.values(systemFunctions)
      .filter(item => !item.hidden)
      .map(item => item.label),
    functionDetails = {},
    dataSource = props.options.sourceType === "DataMapping" ? props.lists.Sources.filter(s => s.id === props.selectedSource) : props.lists.HRSources.filter(s => s.id === props.selectedSource);
  // let allSources = addQualifierAttributes([...dataSource, ...props.lists.ReferenceSources], [...props.lists.QualifierAttributes]);
  let allSources = [...dataSource];
  dataSource.forEach(d => {
    if (d.relationships) {
      const refSources = d.relationships.map(r => {
        const refSource = { ...props.lists.ReferenceSources.find(x => x.id === r.referenceSource) };
        refSource.id = r.id;
        refSource.name = r.name;
        return refSource;
      });
      allSources = allSources.concat(refSources);
    }
  });
  let availableSources = allSources.map(item => item.name.replace(/[\s "]/g, ""));

  Object.values(systemFunctions).forEach(item => {
    functionDetails[item.label] = item;
  });

  const getDisplayName = field => (field.DisplayName ? field.DisplayName : field.Name);

  const updateIdsNames = () => {
    allSources.forEach(item => {
      sourceIdToNames[item.id] = item.name.replace(/[\s "]/g, "");
      sourceNameToIds[item.name.replace(/[\s "]/g, "")] = item.id;
      if (item.fields && item.fields.data) {
        let sourceFieldMapping = {};
        item.fields.data.forEach(f => {
          if (f.id && f.Name) {
            sourceFieldMapping[getDisplayName(f)] = f.id;
            fieldIdToNames[f.id] = getDisplayName(f);
          }
        });
        fieldNameToIds[item.id] = sourceFieldMapping;
      }
    });
  };
  updateIdsNames();
  useEffect(() => {
    //convert ids to names the first time module is loaded or anytime source list or mapping type changes

    updateIdsNames();
    try {
      let newExp = expIdToName(props.exp, { ...sourceIdToNames, ...fieldIdToNames });
      setExp(newExp);
      validateExp(newExp);
      errors = [...new Set(errors)];
      setValidationErrors(errors);
      let expressionTree = math.parse(props.exp);
    } catch (e) {
      errors.push(e.message);
      setValidationErrors(errors);
    }
    setValidationErrors(errors);
  }, [props.options.expressionType, props.lists]);

  useEffect(() => {
    if (props.exp) {
      let newExp = expIdToName(props.exp, { ...sourceIdToNames, ...fieldIdToNames });
      setExp(newExp);

      // Convert the cell value to expression object for the following type.
      if (["Derived", "FieldMapping"].includes(props.type)) {
        validateExp(newExp);
        let expWithIds = expNameToId(newExp, sourceNameToIds, fieldNameToIds);
        errors = [...new Set(errors)];
        setValidationErrors(errors);
        props.handleInputChange(expWithIds, newExp, errors.length === 0);
      }
    } else {
      setExp("");
    }
  }, [props.exp]);

  const displaySelection = (trigger, slug) => {
    let val = slug;
    if (trigger === "@s") {
      val = `${slug}`;
    } else if (trigger === ".") {
      val = (trigger + slug).replace(/[\s "&-]/g, "");
    } else if (trigger === "@t") {
      val = Object.values(systemFunctions).filter(item => item.label === slug)[0].exp;
    }

    return val;
  };
  const handleSelect = val => {
    const sourceName = getActiveSourceName(val);
    if (sourceName && !sourceName.includes(".")) {
      mapActiveSourceColumns(val);
      setTimeout(() => {
        // Adding dot through native javascript
        // Dispatching the event since programmical value change does not trigger react event
        const input = expressionInput;
        let lastValue = input.value;
        input.value = lastValue + ".";
        let event = new Event("input", { bubbles: true });
        let tracker = input._valueTracker;
        if (tracker) {
          tracker.setValue(lastValue);
        }
        input.dispatchEvent(event);
        setExp(lastValue + ".");
      });
    }
  };
  const handleRequestOptions = part => {};

  const handleBlur = e => {
    //validate and send exp to parent
    validateExp(exp);
    let expWithIds = expNameToId(exp, sourceNameToIds, fieldNameToIds);
    errors = [...new Set(errors)];
    setValidationErrors(errors);
    props.handleInputChange(expWithIds, exp, errors.length === 0);
  };
  const handleChange = e => {
    setExp(e);
  };

  const validateNode = (node, parentNode) => {
    let expressionType = props.options.expressionType;
    switch (node.type) {
      case "OperatorNode":
        errors = errors.concat(validateOperatorNode(node));
        if (expressionType === "mapping") {
          errors.push(errorCodes.fieldMappingOperatorDetected);
        }
        operatorsUsed.push(node.op);
        for (let i = 0; i <= 1; i++) {
          validateNode(node.args[i], node);
        }
        break;
      case "ParenthesisNode":
        validateNode(node.content, parentNode);
        break;
      case "FunctionNode":
        if (expressionType === "mapping") {
          errors.push(errorCodes.fieldMappingOperatorDetected);
        }
        errors = errors.concat(validateTransformation(node, parentNode));
        break;
      case "AccessorNode":
        errors = errors.concat(validateSourceReference(node));
        break;
      case "SymbolNode":
        errors = errors.concat(validateSymbol(node));
        break;
      case "BlockNode":
        errors = errors.concat(["Invalid Expression"]);
        break;
    }
    return errors;
  };
  const validateSymbol = node => {
    let err = [];
    let expressionType = props.options.expressionType;

    if (expressionType === "mapping") {
      err.push(errorCodes.fieldMappingOperatorDetected);
    } else if (expressionType === "evaluation" && !["CASE", "END"].includes(node.name.toUpperCase())) {
      err.push(`Cannot recognize the symbol "${node}"`);
    } else if (expressionType === "query" && !["AND", "OR", "NOT"].includes(node.name.toUpperCase())) {
      err.push(`Only the following symbols are allowed: AND, OR, NOT`);
    }
    return err;
  };

  const expIdToName = (exp, dict) => {
    let newExp = exp;
    Object.entries(dict).forEach(pair => {
      let sRegExInput = new RegExp(`\\b${pair[0]}\\b`, "g");
      newExp = newExp.replace(sRegExInput, pair[1]);
    });
    return newExp;
  };
  const expNameToId = (exp, srcDict, fieldsDict) => {
    const pattern = /\b((\w+)?\.+\w+)+\b/g;
    const matches = exp.matchAll(pattern);
    let newExp = exp;
    for (const match of matches) {
      const matchStr = match[0];
      const index = matchStr.indexOf(".");
      if (index > -1) {
        let srcName = matchStr.substring(0, index);
        let fieldName = matchStr.substring(index + 1);
        const newRegEx = new RegExp(`\\b${srcName}\\.${fieldName}\\b`, "g");
        if (srcDict[srcName]) {
          srcName = srcDict[srcName];
          if (fieldsDict[srcName][fieldName]) {
            fieldName = fieldsDict[srcName][fieldName];
          }
        }
        newExp = newExp.replace(newRegEx, `${srcName}.${fieldName}`);
      }
    }
    return newExp;
  };

  const compareValues = (comparison, valA, valB) => {
    let result = true;
    switch (comparison) {
      case "equals":
        result = valA === valB;
        break;
      case "notEquals":
        result = valA !== valB;
        break;
      case "between":
        result = valB[1] >= valA && valA >= valB[0];
        break;
    }
    return result;
  };
  const validateDateArgs = argList => {
    const errList = [];
    if (argList.length === 2) {
      let textValue = undefined;
      if (argList[0].returnType === "date") {
        textValue = argList[1];
      } else {
        textValue = argList[0];
      }
      switch (textValue.nodeType) {
        case "ConstantNode":
          let dateVal = moment(textValue.value, "YYYY-MM-DD", true).format();
          if (dateVal === "Invalid date") {
            errList.push(`Invalid date value ${textValue.value}. It should be a valid date in 'yyyy-MM-dd' format.`);
          }
          break;
        case "FunctionNode":
          break;
        case "AccessorNode":
          break;
      }
    }
    return errList;
  };
  const validateOperatorNode = node => {
    let err = [];
    if (node.type == "OperatorNode") {
      let opArgTypes = [];
      node.args.forEach(a => {
        switch (a.type) {
          case "FunctionNode":
            let argConfig = Object.values(systemFunctions).filter(item => item.label === a.name)[0];
            if (argConfig) {
              opArgTypes.push({ nodeType: "FunctionNode", returnType: argConfig.validations.resultType });
            }
            break;
          case "ConstantNode":
            let aType = typeof a.value;
            aType = aType === "string" ? "text" : aType;
            opArgTypes.push({ nodeType: "ConstantNode", returnType: aType, value: a.value });
            break;
          case "AccessorNode":
            let dataTypeDetails = getDataType(a);
            if (dataTypeDetails) {
              opArgTypes.push({ nodeType: "AccessorNode", returnType: dataTypeDetails.type, format: dataTypeDetails.format });
            }
            break;
          case "OperatorNode":
            err = err.concat(validateOperatorNode(a));
            break;
        }
      });
      if (opArgTypes.length === 2 && ((opArgTypes[0].returnType === "date" && opArgTypes[1].returnType === "text") || (opArgTypes[0].returnType === "date" && opArgTypes[1].returnType === "text"))) {
        err = err.concat(validateDateArgs(opArgTypes));
      } else if (!allEqual(opArgTypes.map(a => a.returnType))) {
        err.push(`All parts of an operation should be of the same type. Instead found mixed types ${opArgTypes.map(a => a.returnType).join(" / ")} for operator "${node.op}".`);
      } else if (opArgTypes[0].returnType) {
        if (!dataTypeOperationseMapping[opArgTypes[0].returnType].includes(node.op)) {
          err.push(`Invalid operator "${node.op}" used for data type ${opArgTypes[0].returnType}.`);
        }
      }
      return err;
    }
  };
  const validateTransformation = (node, parentNode) => {
    let err = [];
    let funcName = node.fn.name;
    let funcConfig = Object.values(systemFunctions).filter(item => item.label === funcName)[0];
    if (funcConfig) {
      let validations = funcConfig.validations;
      Object.entries(validations).forEach(pair => {
        switch (pair[0]) {
          case "argLength":
            let numArgPassed = node.args.length,
              comparison = pair[1].comparison,
              compareValue = pair[1].value;
            let valid = compareValues(comparison, numArgPassed, compareValue);
            if (!valid) {
              err.push(`Number of values passed to the transformation ${funcName} should be :${pair[1].value}`);
            }
            break;
          case "argType":
            node.args.forEach((arg, index) => {
              let targetArgType = pair[1][index] ? pair[1][index] : pair[1].default;
              let targetFieldType = targetArgType.fieldDataTypes || [];
              let targetNodeType = targetArgType.nodeType || ["AccessorNode"];
              let validArgType = targetNodeType.includes(arg.type);
              if (!validArgType) {
                err.push(`Value passed to transformation "${funcName}" at position : ${index} should be of the type: ${targetNodeType.map(t => argumentTypeLabels[t]).join(" / ")}`);
              }
              if (arg.type === "AccessorNode") {
                err = err.concat(validateSourceReference(arg, targetFieldType, index));
              }
              if (arg.type === "OperatorNode") {
                err = err.concat(validateOperatorNode(arg));
                err = err.concat(validateNode(arg, node));
              }
              if (arg.type === "ConstantNode") {
                let setValue = arg.value !== undefined ? arg.value : "";
                if (targetArgType.allowableValues) {
                  let allowableValues = targetArgType.allowableValues ? targetArgType.allowableValues : [];
                  if (!allowableValues.includes(setValue)) {
                    err.push(`The Value passed into the formula has an incorrect format. Expecting a valid date format but got ${setValue} instead.`);
                  }
                }
                let fieldType = typeof setValue;
                fieldType = fieldType === "string" ? "text" : fieldType;
                if (targetFieldType.length > 0 && !targetFieldType.includes(fieldType)) {
                  err.push(
                    `The Value passed to "${funcName}", at position : ${index}, has an incorrect data type. Expecting ${
                      targetFieldType.length > 1 ? `one of: ${targetFieldType.join(", ")}` : targetFieldType[0]
                    } but got ${fieldType} instead.`,
                  );
                }
              }

              if (arg.type === "FunctionNode") {
                if (booleanCheckFunctions.includes(funcName.toLowerCase())) {
                  let argConfig = Object.values(systemFunctions).filter(item => item.label === arg.name)[0];
                  if (!(argConfig && argConfig.validations.resultType === "boolean")) {
                    err = err.concat(`Invalid use of function "${arg.name}" within ${funcName}. The expression / function within "${funcName}" should evaluate to a boolean value.`);
                  }
                }
                err = err.concat(validateTransformation(arg, node));
              }
            });
            break;
          case "parentFunctionNodes":
            if (pair[1] && pair[1].length > 0) {
              if (parentNode === null || parentNode.type !== "FunctionNode" || !pair[1].includes(parentNode.fn.name.toLowerCase())) {
                err.push(`This function, ${funcName}, can only be used within functions : ${pair[1].join(" / ")}.`);
              }
            }
            break;
        }
      });
    } else {
      err.push(`Invalid transformation ${funcName}`);
    }
    return err;
  };

  const getFullName = node => {
    if (node.object) {
      return getFullName(node.object) + "." + node.index.dimensions[0].value;
    } else {
      return node.name;
    }
  };

  const validateSourceReference = (node, targetFieldTypes = [], index = 0) => {
    const err = [];
    const fullName = getFullName(node);
    const sourceName = fullName.split(".")[0];
    const fieldName = fullName.substring(sourceName.length + 1);
    const sourceDetails = allSources.filter(s => s.name.replace(/[\s "]/g, "") === sourceName)[0];
    if (!availableSources.includes(sourceName)) {
      err.push(`'${sourceName}' is an invalid source name`);
    } else {
      const fields = sourceDetails.fields;
      if (!fields) {
        err.push(`No fields found for the source: '${sourceName}'.`);
      } else {
        let field = fields.data.find(e => getDisplayName(e) == fieldName);
        if (!field) {
          err.push(`Field '${fieldName}' not found for source '${sourceName}'.`);
        } else {
          let fieldType = field["Data Type"] === "string" ? "text" : field["Data Type"];
          if (targetFieldTypes.length > 0 && !targetFieldTypes.includes(fieldType)) {
            err.push(
              `The Value passed to the function, at position : ${index}, has an incorrect data type. Expecting ${
                targetFieldTypes.length > 1 ? `one of: ${targetFieldTypes.join(", ")}` : targetFieldTypes[0]
              } but got ${field["Data Type"]} instead.`,
            );
          }
        }
      }
    }
    return err;
  };

  const getDataType = node => {
    let fieldType = undefined;
    let format = undefined;
    const fullName = getFullName(node);
    const sourceName = fullName.split(".")[0];
    const fieldName = fullName.substring(sourceName.length + 1);
    const sourceDetails = allSources.filter(s => s.name.replace(/[\s "]/g, "") === sourceName)[0];
    if (availableSources.includes(sourceName)) {
      const fields = sourceDetails.fields;
      if (fields) {
        let field = fields.data.find(e => getDisplayName(e) == fieldName);
        if (field) {
          fieldType = field["Data Type"] === "string" ? "text" : field["Data Type"];
          format = field["Format"];
        }
      }
    }
    return { type: fieldType, foirmat: format };
  };

  const validateExp = expression => {
    try {
      let expressionTree = math.parse(expression);
      errors = [];
      operatorsUsed = [];
      validateNode(expressionTree, null);
    } catch (e) {
      //e.char
      console.log(e.message);
      errors.push(e.message);
    }
  };

  const handleKeyDown = e => {
    let subExp = exp.substring(0, e.target.selectionStart);
    if (e.which === 190) {
      subExp = `${subExp}.`;
    }
    subExp = subExp.substring(0, subExp.lastIndexOf("."));
    mapActiveSourceColumns(subExp);
  };
  const getActiveSourceName = subExp => {
    const seperators = [">", "<", "=", "+", " ", "/", "*", "%", "(", ")", "[", "]", "{", "}"];
    //1) start with subExp
    //2) get last occurence of "."
    //3) get first occurence of seperator from reverse search
    //4) set the sourceStartIndex
    let lastOccurence = {
      symbol: "",
      index: -1,
    };
    seperators.forEach(function (seperator) {
      let seperatorIndex = subExp.lastIndexOf(seperator);
      if (lastOccurence.index <= seperatorIndex) {
        lastOccurence.symbol = seperator;
        lastOccurence.index = seperatorIndex;
      }
    });
    return lastOccurence.index === -1 ? subExp : subExp.substring(subExp.length, lastOccurence.index + 1);
  };

  const mapActiveSourceColumns = subExp => {
    let sourceName = getActiveSourceName(subExp);
    sourceName = sourceName.split(".")[0];
    if (sourceName !== currentActiveSource) {
      setCurrentActiveSource(sourceName);
      let source = allSources.filter(item => {
        return item.name.replace(/[\s "]/g, "") === sourceName;
      })[0];
      let s = source ? source.fields : source;
      let cols = s ? s.data.map(f => getDisplayName(f)) : [];
      setActiveSourceColumns(cols);
    }
  };

  return (
    <div className="flexItem expandable">
      <div className="flexContainer">
        {
          <TextInput
            className="flexItem form-control"
            autoComplete="off"
            Component="input"
            ref={inputRef => {
              if (inputRef) {
                expressionInput = inputRef.refInput.current;
              }
            }}
            trigger={props.type && props.type === "FieldMapping" ? ["@s", "."] : ["@s", "@t", "."]}
            options={{ "@s": availableSources, "@t": availableFunctions, ".": activeSourceColumns }}
            changeOnSelect={displaySelection}
            onSelect={handleSelect}
            onRequestOptions={handleRequestOptions}
            requestOnlyIfNoOptions={true}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={exp === "undefined" ? "" : exp}
            spacer={""}
            offsetY={7}
            regex="^[a-zA-Z0-9_\-\.]+$"
            maxOptions={0}
          />
        }

        {validationErrors.length > 0 && (
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="expressionWarnings">
                <strong>Expression Warnings</strong>
                <ul>
                  {validationErrors.map((err, i) => (
                    <li key={i} className="alert alert-warning">
                      {err}
                    </li>
                  ))}
                </ul>
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faExclamation} className="Clickable" style={{ margin: "5px" }} />
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default RenderExpression;
