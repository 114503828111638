import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { EditableCellRateTable } from "./EditableCells";
import objectpath from "object-path";
import PayCurveChart from "./ChartsPlots";
import TableInput from "./TableBuilder";

class RateTableInput extends TableInput {
  constructor(props) {
    super(props);

    this.rateTableForm = "";
    this.RateTableType = this.getRateTableType();
    this.RateType = this.getRateType();
    this.AssignmentType = "";
    this.RateAttributes = [];
    this.lkpDims = [];
    this.subComponent = {};
    this.defaultRate = this.getDefaultRate();
    this.initialData = [];
    this.calculationType = this.getCalculationType();
    this.defaultColumns =
      this.RateTableType === "PayCurve"
        ? [
            { Header: "Tier", accessor: "Tier", maxWidth: 150 },
            {
              Header: "% Quota Attainment Value",
              accessor: "Max",
              props: { type: "number", min: 0, step: 1 },

              Cell: this.renderEditableCell
            },
            {
              Header: "% Personal Target",
              accessor: "Value",
              props: { type: "number", min: 0, step: 1 },

              Cell: this.renderEditableCell
            }
          ]
        : [
            { Header: "Tier", accessor: "Tier", maxWidth: 150 },
            { Header: "Min", accessor: "Min" },
            {
              Header: "Max",
              accessor: "Max",
              props: { type: "number", min: 0, step: 1 },

              Cell: this.renderEditableCell
            },
            {
              Header: "Value",
              accessor: "Value",
              props: { type: "number", step: 1, calculationType: this.calculationType },

              Cell: this.renderEditableCell
            }
          ];
    this.defaultData = [
      {
        Tier: "1",
        Min: "0",
        Max: "0",
        Value: this.defaultRate,
        id: 0,
        subComponent: this.subComponent
      }
    ];

    this.state = {
      columns: this.defaultColumns,
      data: this.defaultData,
      expanded: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.titles && this.AssignmentType !== "Plan") {
      //old projects will not have this property
      let currentTitles = this.state.data[0].subComponent.data.map(item => item.Title);
      if (currentTitles.length > 0) {
        currentTitles = [...new Set(currentTitles)];
      }
      if (JSON.stringify(currentTitles.sort()) !== JSON.stringify(this.props.titles.sort())) {
        this.updateTitleAssignments(currentTitles);
      }
    }
  }
  updateTitleAssignments = currentTitles => {
    let data = [...this.state.data];
    let missingTitles = this.props.titles.filter(t => !currentTitles.includes(t));
    data.forEach((row, index) => {
      let newSubCompData = [];
      let filteredSubCompData = row.subComponent.data.filter(subRow => this.props.titles.includes(subRow.Title));
      if (missingTitles.length > 0) {
        newSubCompData = this.createRows(this.RateAttributes, index, missingTitles);
      }
      row.subComponent.data = [].concat(filteredSubCompData, newSubCompData);
    });
    this.setState({ data: data });
    this.saveTable(data, this.state.columns);
  };
  renderEditableCell = info => {
    return <EditableCellRateTable cellInfo={info} saveCell={this.saveCellData.bind(this)} hasErrors={this.errorStatus.bind(this)} />;
  };
  getRateTableType = () => {
    let rateTableType;
    if (typeof this.props.GlobalVariables.RateTableType[0] === "object") {
      //for backward compatibility. In old schema this variable is a string.
      this.props.GlobalVariables.RateTableType.forEach(element => {
        if (element.Plan === this.props.currProps.plan && element.Incentive === this.props.currProps.incentive) {
          rateTableType = element.val[0];
        }
      });
    } else {
      rateTableType = this.props.GlobalVariables.RateTableType[0];
    }
    return rateTableType;
  };

  getRateType = () => {
    let rateType;
    if (this.props.GlobalVariables.RateCalcType) {
      this.props.GlobalVariables.RateCalcType.forEach(element => {
        if (element.Plan === this.props.currProps.plan && element.Incentive === this.props.currProps.incentive) {
          if (element.val[0] === "Calculated") {
            rateType = "Calculated";
            //  rate = "CalculatedRate * 1";
          }
        }
      });
    }
    return rateType;
  };

  getCalculationType = () => {
    let calculationType = "Percent";
    if (this.props.GlobalVariables.CalculationType) {
      this.props.GlobalVariables.CalculationType.forEach(element => {
        if (element.Plan === this.props.currProps.plan && element.Incentive === this.props.currProps.incentive) {
          calculationType = element.val[0];
        }
      });
    }
    return calculationType;
  };
  renderSavedTable = () => {
    this.updateRateAttributes();
    this.initialData = this.props.currData.data;
    for (var row = 0; row < this.initialData.length; row++)
      for (var col = 0; col < this.initialData[row].subComponent.columns.length; col++) {
        if (this.initialData[row].subComponent.columns[col]["accessor"] === "SubValue") {
          this.initialData[row].subComponent.columns[col]["Cell"] = this.renderEditableCell; //info => <EditableCellRateTable cellInfo={info} saveCell={this.saveCellData.bind(this)} hasErrors={this.errorStatus.bind(this)} />;
        }
      }
    return { columns: this.state.columns, data: this.initialData };
  };

  getDefaultRate = (subComponentLkp = false) => {
    let rate = "100";
    if (this.RateTableType !== "PayCurve") {
      if (this.RateType === "Calculated") {
        rate = "CalculatedRate * 1";
      }
      if (this.lkpDims.length > 0 && !subComponentLkp) {
        rate = "LookupValue * 1";
      }
    }
    return rate;
  };

  updateRateAttributes = () => {
    this.RateAttributes = [];
    let rateVariesByAttribute = objectpath.get(this.props.answers, `Organization.Plans.${this.props.currProps.plan}.Incentives.${this.props.currProps.incentive}.Questions.Comp_Incentive33`);
    rateVariesByAttribute = this.RateTableType !== "PayCurve" && rateVariesByAttribute ? rateVariesByAttribute.answer.Response : false;

    //determine default rate value

    if (this.props.GlobalVariables.IncentiveRateAttributes && rateVariesByAttribute) {
      let rateAttributes = this.props.GlobalVariables.IncentiveRateAttributes.filter(row => row.Plan === this.props.currProps.plan && row.Incentive === this.props.currProps.incentive)[0];
      if (rateAttributes) {
        this.RateAttributes = [...rateAttributes.val];
      }
    }

    //Get Assignment Type
    let queResponse = objectpath.get(this.props.answers, `Organization.Plans.${this.props.currProps.plan}.Incentives.${this.props.currProps.incentive}.Questions.Comp_Incentive15`);
    if (!queResponse) {
      queResponse = objectpath.get(this.props.answers, `Organization.Plans.${this.props.currProps.plan}.Incentives.${this.props.currProps.incentive}.Questions.Comp_Incentive15_1`);
    }
    this.AssignmentType = queResponse.answer.Response[0];

    //If assignment type is plan, then a column is not required.
    if (this.AssignmentType !== "Plan") {
      this.RateAttributes.splice(0, 0, this.AssignmentType);
    }
    this.lkpDims = this.RateAttributes.filter(val => {
      return !["Title", "Position"].includes(val);
    });
  };

  refreshTable = () => {
    let data = [...this.state.data];
    this.updateRateAttributes();
    data.forEach((row, index) => {
      let currVal = typeof row.Value === "number" ? row.Value : row.Value.indexOf("*") >= 0 ? row.Value.split("*")[1].trim() : row.Value.trim();
      if (this.lkpDims.length > 0) {
        row.Value = `LookupValue * ${currVal}`;
      } else if (this.RateType === "Calculated") {
        row.Value = `CalculatedRate * ${currVal}`;
      } else {
        row.Value = currVal;
      }
      let subData = index > 0 && this.lkpDims.length > 0 ? { data: [], columns: [] } : this.createSubComponent(index);
      row.subComponent = subData;
    });
    this.saveTable(data, this.state.columns);
  };

  generateTable = () => {
    this.updateRateAttributes();
    this.subComponent = this.createSubComponent(0);
    this.defaultData[0].subComponent = this.subComponent;
    this.initialData = this.defaultData;
    this.initialData[0].Value = this.getDefaultRate();

    return { columns: this.state.columns, data: this.initialData, expanded: {} };
  };

  createSubComponent = parentRowId => {
    return {
      columns: this.createColumns(this.RateAttributes),
      data: this.createRows(this.RateAttributes, parentRowId, this.props.titles)
    };
  };

  createColumns = columnArray => {
    let columns = [];
    // create column for each member of the column array.
    columnArray.forEach(col => {
      columns.push({ Header: col, accessor: col });
    });
    //Add an editable column for entering rate value
    if (columnArray.length > 0) {
      columns.push({
        Header: "Value",
        accessor: "SubValue",
        props: { type: "number", step: 1, calculationType: this.calculationType },
        Cell: this.renderEditableCell
      });
    }
    return columns;
  };

  createRows = (attr, parentId, selectedTitles) => {
    var data = [];
    var subComponentData = [];
    // functions to create cartesian product of parameters
    const flatten = arr => [].concat.apply([], arr);
    const product = sets => sets.reduce((acc, set) => flatten(acc.map(x => set.map(y => [...x, y]))), [[]]);

    attr.forEach(a => {
      let attrData = [];
      switch (a) {
        case "Title":
          if (this.props.GlobalVariables.PlanTitles) {
            let titles = this.props.GlobalVariables.PlanTitles.filter(row => row.Plan === this.props.currProps.plan && row.Incentive === this.props.currProps.incentive)[0];
            if (titles) {
              attrData = selectedTitles || titles.val; //for multiple rates, the titles will passed down as property.
            }
          }

          break;
        case "Position":
          attrData.push("Position1");
          break;
        default:
          let ans;
          if (this.props.GlobalVariables.rateDimensionValues) {
            ans = this.props.GlobalVariables.rateDimensionValues.filter(row => row.Plan === this.props.currProps.plan && row.Incentive === this.props.currProps.incentive && row.Attribute === a)[0];
          } else {
            ans = objectpath.get(this.props.answers, `Organization.Plans.${this.props.currProps.plan}.Incentives.${this.props.currProps.incentive}.Attributes.Rate.${a}.Questions.Rate0`);
          }
          if (ans) {
            let dat;
            if (ans.val) {
              if (Array.isArray(ans.val)) {
                dat = ans.val;
              } else {
                dat = ans.val.values;
              }
            } else {
              dat = ans.answer.Response.data;
            }
            dat.forEach(val => {
              if (val["Attribute Value"]) {
                attrData.push(val["Attribute Value"]);
              } else {
                attrData.push(val);
              }
            });
          }
          break;
      }
      if (attrData.length > 0) {
        data.push(attrData);
      }
    });

    let cartesianData = product(data);

    for (let r = 0; r < cartesianData.length; r++) {
      if (cartesianData[r].length > 0) {
        subComponentData.push({});
        subComponentData[r]["SubValue"] = this.getDefaultRate(true);
        subComponentData[r]["ParentRow"] = parentId;
        subComponentData[r]["dimDataTypes"] = [];
        for (let a = 0; a < attr.length; a++) {
          subComponentData[r][attr[a]] = cartesianData[r][a];
          let attrDetails = this.props.lists.AllAttributes.filter(row => row.id === attr[a])[0];
          if (attrDetails) {
            subComponentData[r]["dimDataTypes"][a] = attrDetails.dataType;
          } else {
            subComponentData[r]["dimDataTypes"][a] = "string";
          }
        }
      }
    }

    return subComponentData;
  };

  addTier = () => {
    let nextTier = this.state.data ? this.state.data.length + 1 : 1;
    let minVal = this.state.data[this.state.data.length - 1] ? this.state.data[this.state.data.length - 1]["Max"] : 0;
    const newData = [...this.state.data];
    newData[this.state.data.length] = {
      Tier: `${nextTier}`,
      Min: minVal,
      Max: 0,
      Value: this.getDefaultRate(),
      id: nextTier - 1,
      subComponent: this.lkpDims.length > 0 ? { data: [], columns: [] } : this.addTierSubcomponent(nextTier - 1) //this.createSubComponent(nextTier - 1)
    };
    this.saveTable(newData);
  };
  addTierSubcomponent = parentRowId => {
    let prevRowId = parentRowId - 1 >= 0 ? parentRowId - 1 : 0;
    let prevTierData = JSON.parse(JSON.stringify(this.state.data[prevRowId].subComponent));
    let data = prevTierData.data.map(row => {
      row.ParentRow = parentRowId;
      return row;
    });

    return { data: data, columns: prevTierData.columns };
  };

  removeTier = () => {
    const newdata = [...this.state.data];
    newdata.pop();
    this.saveTable(newdata);
  };

  validateData = data => {
    let type = "Max value less than Min ";

    let d = data.map((row, index) => {
      if (index > 0) {
        row.Min = data[index - 1].Max;
      }
      if (data.length > 1 && parseFloat(row.Min) >= parseFloat(row.Max) && index < data.length - 1) {
        this.errorStatus(true, { row: index + 1, column: "Max" }, type);
      } else {
        this.errorStatus(false, { row: index + 1, column: "Max" }, type);
      }
      return row;
    });

    return d;
  };

  displayData = () => {
    let d = JSON.parse(JSON.stringify(this.state.data));
    let data = d.map(row => {
      if (row.subComponent.columns) {
        row.subComponent.columns.forEach(subRow => {
          if (this.props.lists["AllObjects"][subRow.Header]) {
            subRow.Header = this.props.lists["AllObjects"][subRow.Header].name || subRow.Header;
          }
          if (subRow.accessor === "SubValue") {
            subRow["Cell"] = this.renderEditableCell;
            subRow["props"] = { type: "number", step: 1, calculationType: this.calculationType };
          }
        });
        row.subComponent.data.forEach(subRow => {
          Object.keys(subRow).forEach(key => {
            //subRow[key] = JSON.stringify(subRow[key]);
            //subRow[key] = subRow[key].minValue ? `From ${subRow[key].minValue} through ${subRow[key].maxValue}` : subRow[key];
            subRow[key] = subRow[key].hasOwnProperty("minValue") ? `From ${subRow[key].minValue} through ${subRow[key].maxValue}` : subRow[key];

            if (this.props.lists["AllObjects"][subRow[key]]) {
              subRow[key] = this.props.lists["AllObjects"][subRow[key]].name;
            }
          });
        });
      }
      return row;
    });
    return data;
  };

  render() {
    return (
      <div>
        <div className="Tabular" />
        {Object.values(this.errors).length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {this.displayErrorMessages()}
          </div>
        ) : (
          ""
        )}

        <ReactTable
          columns={this.state.columns}
          data={this.displayData()}
          className="-striped -highlight"
          defaultPageSize={5}
          expanded={this.state.expanded}
          onExpandedChange={expanded => this.setState({ expanded })}
          SubComponent={row => {
            return (
              <div style={{ padding: "20px" }}>
                <ReactTable
                  columns={row.original.subComponent.columns}
                  data={row.original.subComponent.data}
                  defaultPageSize={row.original.subComponent.data.length}
                  showPagination={false}
                  className="-striped -highlight"
                />
              </div>
            );
          }}
        />

        <button className="btn btn-primary btn-padded" onClick={this.addTier}>
          Add Tier
        </button>

        <button className="btn btn-warning btn-padded" onClick={this.removeTier} disabled={this.state.data.length <= 1}>
          Remove Tier
        </button>

        <button type="button" onClick={this.refreshTable} className="glyphicon glyphicon-refresh btn-padded RightAlign" />
        {this.RateTableType === "PayCurve" ? (
          <div className="NewSection">
            <PayCurveChart chartData={this.state.data} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default RateTableInput;
