import listProps_v4 from "./listPropertiesConfig_v4.json";
import UpdateLists_v4 from "./updateLists_v4.js";

class UpdateLists_v5 extends UpdateLists_v4 {
  constructor(props) {
    super(props);
    this.listProps = listProps_v4;
  }
}

export default UpdateLists_v5;
