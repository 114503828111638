import React, { Component, Fragment } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import Routes from "./Routes";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Server from "./containers/Server";
import store from "store";
import update from "store/plugins/update";
import Websocket from "react-websocket";
import { WS_ROOT, NOTIFICATION_TIMEOUT, redirectToIAM } from "./containers/api-config";
import ReactNotification, { store as NotificationStore } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { ThemeProvider } from "@xactlycorp/xactly-core-components";
import IdleTimer from "react-idle-timer";
import Modal from "react-modal";
import QuestionList from "./containers/questionList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const timeout = 1000 * 60 * 25;
const sessionRefreshThreshold = 1000 * 60 * 4;

store.addPlugin(update);
const server = new Server();

class App extends Component {
  constructor(props) {
    super(props);
    let uriParts = this.props.location.pathname.split("/m/");
    this.pathPrefix = "";
    if (uriParts.length >= 2) {
      this.mode = uriParts[uriParts.length - 1];
    } else {
      this.mode = "";
    }
    var currentUser = {
        name: "",
        id: "",
        customerName: "",
        projectName: ""
      },
      projectId = "";
    if (store.get("user") && store.get("currentUser") && store.get("user")[store.get("currentUser")]) {
      currentUser = {
        name: store.get("user")[store.get("currentUser")]["name"],
        id: store.get("user")[store.get("currentUser")]["id"],
        customerName: store.get("user")[store.get("currentUser")]["customerName"],
        projectName: store.get("user")[store.get("currentUser")]["projectName"],
      };
      projectId = store.get("user")[store.get("currentUser")]["projectId"];
    }
    this.state = {
      theme: localStorage.getItem("selectedTheme"),
      isAuthenticated: false,
      user: currentUser,
      projectSelection: {},
      showEnvironmentSpecificConfig: localStorage.getItem("showEnvironmentSpecificConfig"),
      showFRDSections: localStorage.getItem("showFRDSections"),
      projectId: projectId,
      showSearchWidget: false,
      qid: null,
      allowedTabs: [],
      userid: ""
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);

    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {}

  authenticate() {
    server.authenticate().then(response => {
      this.setAuthenticationData(response);
    });
  }

  setAuthenticationData = response => {
    if (response.status) {
      this.setState({ timeout: timeout, userid: response.userId });
      this.userHasAuthenticated(response.status);
      this.setAllowedTabs(response.tabs);
      this.setUser(response.name);
      this.mode = response.mode;
      store.set("mode", this.mode);
      store.set("currentUser", this.state.userid);
      if (!store.get("user") || !store.get("user")[this.state.userid]) {
        store.set("user", {
          [this.state.userid]: {
            name: response.name,
            role: response.roles,
            access_map: response.access_map,
            id: this.state.userid
          }
        });
      }
      if (this.idleTimer) {
        this.idleTimer.reset();
      }
    } else {
      this.updateLocalStorage();
    }
  };

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  setProject = proj => {
    this.setState({ projectSelection: proj });
  };
  setUser = user => {
    const updatedUser = this.state.user;
    updatedUser.name = user;
    this.setState({ user: updatedUser });
  };
  setAllowedTabs = tabList => {
    this.setState({ allowedTabs: tabList });
  };

  handleLogout = event => {
    server.logout().then(() => {
      this.userHasAuthenticated(false);
      this.setState({
        showFRDSections: false
      });
      redirectToIAM(this.mode);
      this.updateLocalStorage();
    });
  };

  updateLocalStorage = () => {
    try {
      store.update("user", user => {
        delete user[store.get("currentUser")];
      });
      store.remove("currentUser");
      store.remove("mode");
    } catch (error) {
      console.log(error);
    }
    store.remove("showFRDSections");
    store.remove("showEnvironmentSpecificConfig");
    this.setState({ user: "", projectId: "", showFRDSections: false, showEnvironmentSpecificConfig: false });
  };

  changeTheme = event => {
    this.setState({ theme: event.target.value });
    localStorage.setItem("selectedTheme", event.target.value);
  };
  showProjectTabs = () => {
    this.setState({ projectId: store.get("user")[store.get("currentUser")]["projectId"] });
  };
  setEnvironmentSpecificConfigVisibility = visible => {
    this.setState({ showEnvironmentSpecificConfig: visible });
  };
  setFRDQuestionVisibility = visible => {
    this.setState({ showFRDSections: visible });
  };

  handleOnAction(event) {
    if (this.idleTimer && this.idleTimer.getElapsedTime() > sessionRefreshThreshold) {
      this.authenticate();
    }
  }

  handleOnIdle(event) {
    this.idleTimer.getElapsedTime();
    const confirmation = window.confirm(`Your session is about to expire. You will be logged out in 5 minutes. Would you like to continue working?`);
    if (confirmation) {
      this.authenticate();
    }
  }
  handleConfirmDialog(resp) {
    if (resp) {
      this.authenticate();
    }
  }

  setQueid = qid => {
    this.setState({ qid: qid });
  };

  updateProjectMeta = () =>{
    const userData = {
      name: store.get("user")[store.get("currentUser")]["name"],
      id: store.get("user")[store.get("currentUser")]["id"],
      customerName: store.get("user")[store.get("currentUser")]["customerName"],
      projectName: store.get("user")[store.get("currentUser")]["projectName"],
    };
    this.setState({user: userData});
  }

  render() {
    const currUser = store.get("currentUser");
    let currRole = "user";
    if (store.get("user") && store.get("user")[currUser]) {
      currRole = store.get("user")[currUser].role[0];
    }
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setAuthenticationData: this.setAuthenticationData,
      setUser: this.setUser,
      user: this.state.user,
      setProject: this.setProject,
      setAllowedTabs: this.setAllowedTabs,
      projectSelection: this.state.projectSelection,
      setEnvironmentSpecificConfigVisibility: this.setEnvironmentSpecificConfigVisibility,
      setFRDQuestionVisibility: this.setFRDQuestionVisibility,
      showProjectTabs: this.showProjectTabs,
      pathPrefix: this.pathPrefix,
      allowedTabs: this.state.allowedTabs,
      mode: this.mode,
      modePath: this.modePath,
      qid: this.state.qid,
      setQueid: this.setQueid,
      updateProjectMeta: this.updateProjectMeta
    };
    const theme = {};
    return (
      <ThemeProvider theme={theme}>
        <div>
          {this.state.isAuthenticated && (
            <IdleTimer
              ref={ref => {
                this.idleTimer = ref;
              }}
              timeout={this.state.timeout}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              debounce={250}
              stopOnIdle={true}
            />
          )}
          <ReactNotification />
          <link rel="stylesheet" href={`/themes/${this.state.theme}/bootstrap.min.css`} />
          <link rel="stylesheet" href="/themes/app.css" />
          <div className="container App ">
            <Navbar expand="xl">
              <Navbar.Brand href="#home"></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {this.state.isAuthenticated ? (
                  <div>
                    <Nav>
                      <Fragment>
                        {this.state.allowedTabs.includes("Projects") && (
                          <React.Fragment>
                            <LinkContainer to={`${this.pathPrefix}/projects`}>
                              <NavItem>Project</NavItem>
                            </LinkContainer>
                          </React.Fragment>
                        )}
                        {this.state.allowedTabs.includes("AdminDashboard") && (
                          <LinkContainer to={`${this.pathPrefix}/adminDashboard`}>
                            <NavItem>Project Admin Dashboard</NavItem>
                          </LinkContainer>
                        )}
                        {this.state.projectId && (
                          <React.Fragment>
                            {this.state.allowedTabs.includes("Dashboard") && (
                              <LinkContainer to={`${this.pathPrefix}/dashboard`}>
                                <NavItem>Dashboard</NavItem>
                              </LinkContainer>
                            )}
                            {this.state.allowedTabs.includes("Rules") && (
                              <LinkContainer to={`${this.pathPrefix}/rulesDashboard`}>
                                <NavItem>Rules</NavItem>
                              </LinkContainer>
                            )}
                            {this.state.allowedTabs.includes("ConnectAutomation") && (
                              <LinkContainer to={`${this.pathPrefix}/connectAutomationDashboard`}>
                                <NavItem>Connect Automation</NavItem>
                              </LinkContainer>
                            )}
                            {this.state.allowedTabs.includes("ImplementationSurvey") && (
                              <LinkContainer to={`${this.pathPrefix}/implementationSurvey`}>
                                <NavItem>Implementation Survey</NavItem>
                              </LinkContainer>
                            )}
                          </React.Fragment>
                        )}
                        {this.state.projectId && (
                          <React.Fragment>
                            {this.state.allowedTabs.includes("requirementSummary") && (
                              <LinkContainer to={`${this.pathPrefix}/requirementSummary`}>
                                <NavItem>Requirement Summary</NavItem>
                              </LinkContainer>
                            )}
                          </React.Fragment>
                        )}
                        {this.state.showEnvironmentSpecificConfig && this.state.allowedTabs.includes("EnvironmentSpecificConfig") && (
                          <LinkContainer to={`${this.pathPrefix}/connectDependentRules`}>
                            <NavItem>EnvironmentSpecificConfig</NavItem>
                          </LinkContainer>
                        )}
                      </Fragment>
                    </Nav>
                    <Nav pullRight>
                      <NavItem onClick={this.handleLogout}>Logout</NavItem>
                    </Nav>
                  </div>
                ) : null}
              </Navbar.Collapse>
            </Navbar>

            <div>
              {this.state.isAuthenticated && this.state.projectId && currRole && currRole !== "customer" ? (
                <button
                  type="button"
                  className="btn btn-warning btn-clear btn-padded"
                  onClick={() => {
                    this.setState({
                      showSearchWidget: true
                    });
                  }}
                >
                  {"Search"}
                </button>
              ) : (
                ""
              )}
              {this.state.user.name ? <p className="RightAlign" style={{"paddingRight": "15px", paddingLeft: "10px"}}><b>User</b>: {this.state.user.name}</p> : ""}
              {this.state.user.projectName ? <p className="RightAlign" style={{"paddingRight": "15px", borderRight: "1px solid #222", paddingLeft: "10px"}}><b>Project</b>: {this.state.user.projectName}</p> : ""}
              {this.state.user.customerName ? <p className="RightAlign" style={{"paddingRight": "15px", borderRight: "1px solid #222", paddingLeft: "10px"}}><b>Customer</b>: {this.state.user.customerName}</p> : ""}

              {this.state.user.name ? (
                <Websocket
                  url={`${WS_ROOT}/jobStatus`}
                  onMessage={function (data) {
                    data = JSON.parse(data);
                    let title = "";

                    let message_suffix = data.isComplete ? "completed" + (data.isSuccess ? "." : " with Error.") : "started.";
                    let message = `FRD request for ${data.customerName} / ${data.project.name} (${data.project.sow}) ${message_suffix}`;
                    let type = data.isSuccess ? "info" : "warning";
                    NotificationStore.addNotification({
                      title: title,
                      message: message,
                      type: type,
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: NOTIFICATION_TIMEOUT,
                        onScreen: true,
                        showIcon: true
                      }
                    });
                  }}
                  debug={false}
                />
              ) : (
                ""
              )}
            </div>
            <Routes childProps={childProps} />
          </div>
          <Modal
            isOpen={this.state.showSearchWidget}
            contentLabel="Component Summary"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => {
              this.setState({ showSearchWidget: false });
            }}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "70%"
              },
              overlay: { zIndex: "2" }
            }}
          >
            <QuestionList
              flag={"summary"}
              redirectFlag={true}
              history={this.props.history}
              onSelect={qid => {
                this.setState({ showSearchWidget: false, qid: qid });
              }}
              allowedSearchMenus={["summary", "notes", "parkingLot"]}
              allowedSections={["GenericInfo", "Organization", "TermsConditions", "Data"]}
            />
          </Modal>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(App);
