import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TableInput from "./TableBuilder";
import DynamicSelect from "./dynamicSelect";
import { generateID } from "./ManageObjectIDs";
import { validateNewValue } from "./UpdateLists";
import LoadingOverlay from "react-loading-overlay";
import { compareArrayOfObjects } from "./util";
import Server from "./Server";

const server = new Server();

class TitlesTableBuilder extends TableInput {
  constructor(props) {
    super(props);
    this.initialColumns = [];
    this.initialData = [];
    this.state = { columns: [], data: [], isDataReady: true };
    this.selectedItems = [];
    if (this.props.currData.data && this.props.currData.data.length > 0) {
      this.props.currData.data.forEach(item => this.selectedItems.push(item.id));
    }
  }

  getColumnProps = col => {
    let columnProps = { type: "text", nullable: true };
    if (this.props.editableColumnProps && this.props.editableColumnProps[this.props.cols[col]]) {
      columnProps = this.props.editableColumnProps[this.props.cols[col]];
    }
    return columnProps;
  };

  fillInitialColumns = () => {
    for (var col = 0; col < this.props.cols.length; col++) {
      if (this.props.editableCols.includes(this.props.cols[col])) {
        let sortable = true;
        if (
          this.props.columnProperties &&
          this.props.columnProperties[this.props.cols[col]] &&
          this.props.columnProperties[this.props.cols[col]].hasOwnProperty("sortable") &&
          this.props.columnProperties[this.props.cols[col]].sortable === false
        ) {
          sortable = false;
        }

        this.initialColumns.push({
          Header: this.renderTableHeader(this.props.cols[col], this.initialColumns.length),
          accessor: this.props.cols[col],
          sortable: sortable,
          props: this.getColumnProps(col),
          Cell: this.renderEditableCell,
        });
      } else {
        this.initialColumns.push({
          Header: this.props.cols[col],
          accessor: this.props.cols[col],
        });
      }
    }
  };

  generateTable = () => {
    this.initialColumns = [];
    this.initialData = [];
    this.fillInitialColumns();

    for (var col = 0; col < this.props.cols.length; col++) {
      // if any column needs to be prepopulated then the following code populates it based on the props passed.
      //1) Subs in Fill Lists:
      if (this.props.fillLists && this.props.fillLists[this.props.cols[col]]) {
        this.props.lists[this.props.fillLists[this.props.cols[col]]].forEach(row => {
          if (this.selectedItems.includes(row.id)) {
            let exitingRow = this.state.data.find(item => item.id === row.id);
            if (exitingRow) {
              this.initialData.push(exitingRow);
            } else {
              this.initialData.push({
                id: row.id,
              });
              this.initialData[this.initialData.length - 1][this.props.cols[col]] = row.name;
            }
          }
        });
      }
    }
    return { columns: this.initialColumns, data: this.initialData };
  };

  addListItem = (item, listType) => {
    let key = generateID(listType);
    let listItem = { name: item, id: key };
    let newList = this.props.lists;
    if (!newList.AllObjects) {
      newList.AllObjects = {};
    }
    if (!newList[listType]) {
      newList[listType] = [];
    }
    newList[listType].push(listItem);
    newList.AllObjects[key] = { name: item, type: listType };
    let listData = {};
    listData[listType] = newList[listType];
    listData.AllObjects = newList.AllObjects;
    this.setState({ isDataReady: false });
    server.updateLists(this.props.currProps.customerId, this.props.currProps.projectId, listData, response => {
      this.props.saveProjectDetails(response.data.object[0]);
      this.setState({ isDataReady: true });
    });
  };

  filterDeselectedOptions = data => {
    let dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy &&
      dataCopy.forEach(row => {
        Object.keys(row).forEach(key => {
          if (row[key] === false) {
            delete row[key];
          }
        });
      });
    return dataCopy;
  };

  render() {
    let showWarning = false;
    if (this.props.currData && this.props.currData.data && this.state.data) {
      let currentData = this.filterDeselectedOptions([...this.props.currData.data]);
      let modifiedData = this.filterDeselectedOptions([...this.state.data]);
      if (!compareArrayOfObjects(currentData, modifiedData)) {
        showWarning = true;
      }
    }

    return (
      <LoadingOverlay
        active={!this.state.isDataReady}
        spinner
        text="Loading ..."
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
          }),
          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      >
        <DynamicSelect
          className="flexItem"
          data={this.props.lists.Titles}
          defaultSelect={this.selectedItems}
          labelKey="name"
          valueKey="id"
          onSelectionChange={e => {
            this.selectedItems = e;
            let table = this.generateTable();
            this.saveTable(table.data);
          }}
          caption={"Select Title(s)"}
          addCaption={"Add new Title"}
          multiSelect={true}
          onAdd={e => {
            this.addListItem(e, "Titles");
          }}
          addItemLabel={"Title"}
          validate={newTitle => validateNewValue("Titles", newTitle)}
          preventRender={true}
          saveTableWarning={showWarning}
          warningMsg="Make sure that all changes on the table is saved before proceeding further. Failed to do so may result in loss of unsaved data."
        />
        <ReactTable
          columns={this.state.columns}
          data={this.displayData()}
          filterable={this.props.filterable}
          className="-striped -highlight"
          defaultPageSize={this.props.pageSize || 5}
          pageSize={this.props.pageSize}
          showPagination={this.props.showPagination}
        />
      </LoadingOverlay>
    );
  }
}
export default TitlesTableBuilder;
