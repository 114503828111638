import React, { useState, useCallback, useEffect, useRef } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faClose, faFloppyDisk, faFlorinSign, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import classes from "./QueryBuilder.module.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";

const selectMarginStyles = {
  marginLeft: "10px",
  marginRight: "10px",
};

const createId = prefix => {
  return `${prefix}_${Date.now()}_${Math.floor(Math.random() * 10000000000)}`;
};
const SubString = props => {
  const [index, setIndex] = useState(props.functionData ? props.functionData.index : 0);
  useEffect(() => {
    props.setFunctionData({ ...props.functionData, index: index });
  }, [index]);
  useEffect(() => {
    props.setError(props.id, error);
  }, [props.firstField]);

  const error = props.firstField && !["string", "text"].includes(props.firstField.dataType);

  return (
    <div className={[classes.DivGrid, classes.SubStringContainer].join(" ")} style={{ backgroundColor: error ? "red" : "white" }}>
      <p className={classes.InlineText}>SubString (</p>
      <FieldOptionList {...props} />
      <p className={[classes.InlineText, classes.Index].join(" ")}>,</p>
      <input type="number" className={classes.Input} min="1" max="100" step="1" value={index} onChange={e => setIndex(e.target.value)} style={{ backgroundColor: error ? "red" : "white" }} />
      <p className={classes.InlineText}>)</p>
    </div>
  );
};

const RightSideOption = props => {
  const formRef = useRef();
  let paramTypeOptions = props.paramOptions && props.paramOptions.length > 0 ? ["Field", "Value", "Variable"].filter(o => props.paramOptions.includes(o)) : ["Field", "Value", "Variable"];
  if (!props.enableSourceFilter) {
    paramTypeOptions = paramTypeOptions.filter(o => o !== "Field");
  }
  const { firstField, onUpdate } = props;
  const secondValue = props.secondValue
    ? props.secondValue.firstOption
      ? {
          ...props.secondValue,
          selectedOption: paramTypeOptions[0],
          displayField: paramTypeOptions[0] === "Field",
          displayValue: paramTypeOptions[0] === "Value",
          displayVariable: paramTypeOptions[0] === "Variable",
        }
      : props.secondValue
    : {
        selectedOption: paramTypeOptions[0],
        displayField: paramTypeOptions[0] === "Field",
        displayValue: paramTypeOptions[0] === "Value",
        displayVariable: paramTypeOptions[0] === "Variable",
      };
  const [selectedOption, setSelectedOption] = useState(secondValue.selectedOption);
  const [isFunctionOpen, setIsFunctionOpen] = useState(false);
  const [transformFunction, setTransformFunction] = useState(secondValue.transformFunction);
  const [functionData, setFunctionData] = useState(secondValue.functionData);
  const [displayField, setDisplayField] = useState(secondValue.displayField);
  const [displayValue, setDisplayValue] = useState(secondValue.displayValue);
  const [displayVariable, setDisplayVariable] = useState(secondValue.displayVariable);
  const [value, setValue] = useState(secondValue.value);
  const [variable, setVariable] = useState(secondValue.variable);
  const [fieldValue, setFieldValue] = useState(secondValue.fieldValue);
  const [functionOptions, updateFunctionOptions] = useState(firstField ? props.functions.filter(f => (firstField ? f.dataType.includes(firstField.dataType) : true)) : []);

  useEffect(() => {
    onUpdate({ displayField, displayValue, displayVariable, fieldValue, value, variable, transformFunction, functionData, selectedOption, extraFilter: props.extraFilter });
  }, [displayField, displayValue, displayVariable, fieldValue, value, variable, transformFunction, functionData]);

  useEffect(() => {
    setDisplayField(secondValue.displayField);
    setDisplayValue(secondValue.displayValue);
    setDisplayVariable(secondValue.displayVariable);
  }, [secondValue.displayField, secondValue.displayValue, secondValue.displayVariable]);

  const setRHSValue = target => {
    if (firstField && firstField.dataType === "boolean") {
      setValue(target.checked);
    } else {
      setValue(target.value);
    }
  };
  const validateValue = target => {
    if (!target.checkValidity()) {
      props.setError(props.id, true);
      formRef.current.reportValidity();
    } else {
      props.setError(props.id, false);
    }
  };
  const toggleFieldValue = option => {
    setDisplayField(option === "Field");
    setDisplayValue(option === "Value");
    setDisplayVariable(option === "Variable");
    setSelectedOption(option);
  };
  const childId = `${props.qid}__2ndfield`;
  const componentProps = {
    ...props,
    fieldValue,
    setFieldValue,
    functionData,
    setFunctionData,
    id: childId,
    type: transformFunction && transformFunction.rhsComponent ? transformFunction.rhsComponent : "FieldOptionList",
    isFunctionComponent: transformFunction && transformFunction.rhsComponent ? true : false,
  };
  return (
    <form ref={formRef}>
      <div className={[classes.DivGrid, classes.RightSideOptionContainer].join(" ")}>
        <Switch options={paramTypeOptions} onClick={toggleFieldValue} selectedOption={selectedOption} />
        {displayField && (
          <div className={[classes.DivGrid, classes.ParamTypeSwitchContainer].join(" ")}>
            <FontAwesomeIcon
              icon={faFlorinSign}
              className={[firstField && functionOptions.length > 0 ? "" : classes.Disabled, classes.Function].join(" ")}
              style={{ margin: "10px" }}
              {...(firstField &&
                functionOptions.length > 0 && {
                  onClick: e => {
                    setIsFunctionOpen(true);
                  },
                })}
            />
            {isFunctionOpen ? (
              <Modal
                style={{
                  content: {
                    height: "80px",
                    width: "300px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "10px",
                  },
                }}
                isOpen={isFunctionOpen}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                onRequestClose={() => setIsFunctionOpen(false)}
              >
                <div className={classes.Close}>
                  <FontAwesomeIcon onClick={() => setIsFunctionOpen(false)} icon={faWindowClose} />
                </div>
                <Select
                  defaultValue={transformFunction}
                  options={functionOptions}
                  isClearable
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      ...selectMarginStyles,
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isSearchable
                  menuShouldScrollIntoView={false}
                  onChange={setTransformFunction}
                />
              </Modal>
            ) : null}
            <DynamicComponent {...componentProps} />
          </div>
        )}
        {displayValue && (
          <input
            type={
              firstField
                ? firstField.dataType === "decimal"
                  ? "number"
                  : firstField.dataType === "boolean"
                  ? "checkbox"
                  : firstField.dataType === "dateTime"
                  ? "datetime-local"
                  : firstField.dataType
                : "text"
            }
            value={value ? value : ""}
            {...(firstField && (firstField.dataType === "decimal" || firstField.dataType === "number") && { onKeyDown: evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault() })}
            onChange={e => setRHSValue(e.target)}
            onBlur={e => validateValue(e.target)}
            className={[classes.Input, classes.Field].join(" ")}
            {...(firstField &&
              firstField.dataType === "boolean" && {
                checked: value === true,
              })}
            {...(firstField && (firstField.dataType === "date" || firstField.dataType === "dateTime") && { max: "2100-12-31", min: "1900-01-01" })}
          />
        )}
        {displayVariable && <input type="text" value={variable} onChange={e => setVariable(e.target.value)} className={[classes.Input, classes.Field].join(" ")} />}
      </div>
    </form>
  );
};

const BetweenRightSideOption = props => {
  const [firstOption, setFirstOption] = useState(props.secondValue ? props.secondValue.firstOption : null);
  const [secondOption, setSecondOption] = useState(props.secondValue ? props.secondValue.secondOption : null);

  useEffect(() => {
    props.onUpdate({ firstOption, secondOption });
  }, [firstOption, secondOption]);

  return (
    <div className={classes.BetweenRightSideOptionContainer}>
      <RightSideOption {...props} secondValue={firstOption} onUpdate={setFirstOption} />
      <div className={classes.ConnectorContainer}>
        <p className={classes.ConnectorText}>AND</p>
      </div>
      <RightSideOption
        {...props}
        secondValue={secondOption}
        onUpdate={setSecondOption}
        extraFilter={firstOption && firstOption.displayField && firstOption.fieldValue ? [firstOption.fieldValue.label] : []}
      />
    </div>
  );
};

const operations = [
  { value: "<", label: "<", dataType: ["decimal", "number", "date", "dateTime"], rhsComponent: "RightSideOption", paramOptions: ["Field", "Value", "Variable"] },
  { value: ">", label: ">", dataType: ["decimal", "number", "date", "dateTime"], rhsComponent: "RightSideOption", paramOptions: ["Field", "Value", "Variable"] },
  { value: "=", label: "=", dataType: ["decimal", "boolean", "number", "date", "dateTime", "string", "text"], rhsComponent: "RightSideOption", paramOptions: ["Field", "Value", "Variable"] },
  { value: "in", label: "in", dataType: ["string", "text"], rhsComponent: "RightSideOption", paramOptions: ["Value"] },
  { value: "not in", label: "not in", dataType: ["string", "text"], rhsComponent: "RightSideOption", paramOptions: ["Value"] },
  { value: "like", label: "like", dataType: ["string", "text"], rhsComponent: "RightSideOption", paramOptions: ["Value"] },
  { value: ">=", label: ">=", dataType: ["decimal", "number", "date", "dateTime"], rhsComponent: "RightSideOption", paramOptions: ["Field", "Value", "Variable"] },
  { value: "<=", label: "<=", dataType: ["decimal", "number", "date", "dateTime"], rhsComponent: "RightSideOption", paramOptions: ["Field", "Value", "Variable"] },
  { value: "between", label: "between", dataType: ["decimal", "number", "date", "dateTime"], rhsComponent: "BetweenRightSideOption", paramOptions: ["Field", "Value", "Variable"] },
];
const functions = [{ value: "subString", label: "subString(field, startIndex)", dataType: ["string", "text"], rhsComponent: "SubString" }];

const Switch = props => {
  const [activeOption, setActiveOption] = useState(props.selectedOption && props.options.indexOf(props.selectedOption) > -1 ? props.selectedOption : props.options[0]);
  useEffect(() => {
    setActiveOption(props.selectedOption && props.options.indexOf(props.selectedOption) > -1 ? props.selectedOption : props.options[0]);
  }, [props.selectedOption, props.options]);
  const handleSwitchClick = option => {
    setActiveOption(option);
    if (props.onClick) {
      props.onClick(option);
    }
  };
  return (
    <div className={classes.SwitchContainer}>
      {props.options.map((o, i) => {
        return (
          <div
            key={`option_${i}`}
            className={classes.ToggleItem}
            style={{
              backgroundColor: activeOption === o ? "grey" : "transparent",
            }}
            onClick={() => handleSwitchClick(o)}
          >
            <div className={classes.Text}>{o}</div>
          </div>
        );
      })}
    </div>
  );
};
const QueryBuilder = props => {
  const [errors, updateErrors] = useState({});
  const [validationErrors, updateValidationErrors] = useState({});
  const [queries, updateQueries] = useState({});
  const enableLogicalOperator = props.enableLogicalOperator === undefined ? true : props.enableLogicalOperator;
  const setError = (id, error) => {
    updateErrors(errors => {
      const newErrors = { ...errors };
      newErrors[id] = error;
      return newErrors;
    });
  };
  const removeQuery = id => {
    updateQueryList(arr => {
      const newArr = arr.filter(function (obj) {
        return obj.id !== id;
      });
      return newArr;
    });
    updateQueries(obj => {
      delete obj[id];
      return obj;
    });
  };
  const validateQuery = (key, query, isDelete) => {
    const { firstField, operator, secondValue } = query;
    let isError = !firstField || !operator || !secondValue;
    if (secondValue) {
      isError =
        isError ||
        (operator.value !== "between" &&
          ((secondValue.displayField && !secondValue.fieldValue) ||
            (secondValue.displayValue && !secondValue.value && secondValue.value !== false) ||
            (secondValue.displayVariable && !secondValue.variable)));

      isError =
        isError ||
        (operator.value === "between" &&
          secondValue.firstOption &&
          secondValue.secondOption &&
          ((secondValue.firstOption.displayField && !secondValue.firstOption.fieldValue) ||
            (secondValue.firstOption.displayValue && !secondValue.firstOption.value) ||
            (secondValue.firstOption.displayVariable && !secondValue.firstOption.variable) ||
            (secondValue.secondOption.displayField && !secondValue.secondOption.fieldValue) ||
            (secondValue.secondOption.displayValue && !secondValue.secondOption.value) ||
            (secondValue.secondOption.displayVariable && !secondValue.secondOption.variable)));
    }
    updateValidationErrors(errors => {
      const newErrors = { ...errors };
      if (isDelete) {
        delete newErrors[key];
      } else {
        newErrors[key] = isError;
      }
      return newErrors;
    });
  };
  const update = (key, query) => {
    let isDelete = false;
    updateQueries(queries => {
      const newQueries = { ...queries };
      if (Object.keys(query).length === 0) {
        delete newQueries[key];
        isDelete = true;
      } else {
        newQueries[key] = query;
      }
      validateQuery(key, query, isDelete);
      return newQueries;
    });
  };

  const moveQuery = useCallback((dragIndex, hoverIndex) => {
    updateQueryList(queries => {
      const query = queries[dragIndex];
      const newQueries = [...queries];
      newQueries.splice(dragIndex, 1);
      newQueries.splice(hoverIndex, 0, query);
      return newQueries;
    });
  }, []);
  const renderQuery = useCallback((query, index, referenceSourceName) => {
    return <Query key={query.key} {...query} referenceSourceName={referenceSourceName} index={index} />;
  }, []);
  const addQuery = (queryProps, updateList = true) => {
    const newId = createId("q");
    const query = {
      key: queryProps.key ? queryProps.key : newId,
      id: queryProps.key ? queryProps.key : newId,
      ...queryProps,
      fields: props.fields,
      displaySwitch: enableLogicalOperator,
      onClose: removeQuery,
      operations: operations,
      functions: functions,
      onUpdate: update,
      setError: setError,
      moveQuery: moveQuery,
    };
    if (updateList) {
      updateQueryList(arr => [...arr, query]);
    } else {
      return query;
    }
  };
  let initQueryList = [];
  if (props.queryData && props.queryData.queries) {
    let queryKeyList = Object.keys(props.queryData.queries);
    if (props.queryData.queryKeyList) {
      queryKeyList = props.queryData.queryKeyList;
    }
    initQueryList = queryKeyList.map(k => {
      return addQuery({ ...props.queryData.queries[k], key: k, enableLogicalOperator: props.enableLogicalOperator, enableSourceFilter: props.enableSourceFilter }, false);
    });
  }
  const [queryList, updateQueryList] = useState(initQueryList);
  const submitQuery = () => {
    if (props.onSubmit) {
      props.onSubmit(
        queries,
        queryList.map(q => q.key),
      );
    }
  };
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <div className={[classes.Sticky, classes.DivGrid, classes.HeaderContainer].join(" ")}>
          <div>
            <FontAwesomeIcon icon={faPlus} className={classes.BigIcon} onClick={() => addQuery(props)} />
            <FontAwesomeIcon
              icon={faFloppyDisk}
              className={[classes.BigIcon, Object.keys(queries).length === 0 || Object.values(errors).includes(true) || Object.values(validationErrors).includes(true) ? classes.Disabled : ""].join(
                " ",
              )}
              {...(Object.keys(queries).length > 0 &&
                !Object.values(errors).includes(true) &&
                !Object.values(validationErrors).includes(true) && {
                  onClick: submitQuery,
                })}
            />
          </div>
          {props.enableClose && (
            <div className={classes.Close}>
              <FontAwesomeIcon icon={faWindowClose} className={classes.BigIcon} onClick={props.onClose} />
            </div>
          )}
        </div>
        {queryList.map((q, i) => renderQuery(q, i, props.referenceSourceName))}
      </DndProvider>
    </div>
  );
};

const FieldOptionList = props => {
  const [error, setError] = useState(false);
  useEffect(() => {
    props.setError(props.id, error);
  }, [error]);
  useEffect(() => {
    const isError =
      props.firstField &&
      props.fieldValue &&
      (props.firstField.dataType !== props.fieldValue.dataType ||
        (props.enableSourceFilter ? props.firstField.source === props.fieldValue.source : props.firstField.fieldName === props.fieldValue.fieldName) ||
        (props.extraFilter ? props.extraFilter.includes(props.fieldValue.label) : false));
    if (isError !== error) {
      setError(isError);
    }
  }, [props]);

  return (
    <Select
      defaultValue={props.fieldValue}
      options={props.fields.filter(f => {
        return props.firstField
          ? f.dataType === props.firstField.dataType &&
              (props.enableSourceFilter
                ? f.source !== props.firstField.source && (props.firstField.source === props.referenceSourceName ? true : f.source === props.referenceSourceName)
                : f.fieldName !== props.firstField.fieldName) &&
              (props.extraFilter ? !props.extraFilter.includes(f.label) : true)
          : false;
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          ...selectMarginStyles,
          backgroundColor: error ? "red" : "white",
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body}
      isSearchable
      menuShouldScrollIntoView={false}
      onChange={props.setFieldValue}
    />
  );
};

const Query = props => {
  const { onUpdate, id, index } = props;
  const [logicalOperator, setLogicalOperator] = useState(props.logicalOperator ? props.logicalOperator : "AND");
  const [firstField, setFirstField] = useState(props.firstField);
  const [operator, setOperator] = useState(props.operator);
  const [secondValue, setSecondValue] = useState(props.secondValue);

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "query",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveQuery(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "query",
    item: () => {
      return { id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  useEffect(() => {
    onUpdate(id, { logicalOperator, firstField, operator, secondValue });
  }, [logicalOperator, firstField, operator, secondValue]);

  useEffect(() => {
    props.setError(props.id, error);
  }, [firstField, operator]);

  const close = id => {
    props.onClose(id);
    onUpdate(id, {});
  };
  const error = firstField && operator && !operator.dataType.includes(firstField.dataType);
  const rhsComponentProps = {
    ...props,
    firstField,
    onUpdate: setSecondValue,
    qid: props.id,
    type: operator ? operator.rhsComponent : "RightSideOption",
    paramOptions: operator ? operator.paramOptions : undefined,
  };
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={[classes.DivGrid, ...(props.displaySwitch && logicalOperator == "OR" ? [classes.QueryMainContainer] : [classes.QueryMainContainerSubGroup])].join(" ")}
    >
      {props.displaySwitch ? <Switch options={["AND", "OR"]} onClick={setLogicalOperator} selectedOption={logicalOperator} /> : <div></div>}
      <div className={[classes.DivGrid, ...(props.displaySwitch && logicalOperator == "OR" ? [classes.QuerySubContainer] : [classes.QuerySubContainerSubGroup])].join(" ")}>
        <Select
          defaultValue={firstField}
          options={props.fields}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...selectMarginStyles,
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          isSearchable
          menuShouldScrollIntoView={false}
          onChange={setFirstField}
        />
        <Select
          options={props.operations.filter(op => (firstField ? op.dataType.includes(firstField.dataType) : false))}
          defaultValue={operator}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...selectMarginStyles,
              backgroundColor: error ? "red" : "white",
            }),
          }}
          onChange={setOperator}
        />
        <DynamicComponent {...rhsComponentProps} />
        <FontAwesomeIcon
          icon={faClose}
          style={{ margin: "10px" }}
          onClick={e => {
            close(props.id);
          }}
        />
      </div>
    </div>
  );
};

const components = {
  BetweenRightSideOption: BetweenRightSideOption,
  RightSideOption: RightSideOption,
  FieldOptionList: FieldOptionList,
};

const functionComponents = {
  SubString: SubString,
};

const DynamicComponent = props => {
  const SpecificComponent = props.isFunctionComponent ? functionComponents[props.type] : components[props.type];
  return <SpecificComponent {...props} />;
};

export default QueryBuilder;
