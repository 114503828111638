import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Tooltip as XTooltip } from "@xactlycorp/xactly-core-components";
import "react-table/react-table.css";
import { EditableCell } from "./EditableCells";
import { getNamesForListID } from "./ManageObjectIDs";
/**
 * Table builder is a component that renders form data in a tabular format.
 * ====================
 *
 *
 * Depending on the properties passed, it either renders the passed data in a tabular format or creates a new table depending on the column information provided.
 *
 * 1. aksjdkasjd
 * 2. alsjd
 * # khjkjh
 * - asdasd
 * - asdasd
 * 
 * <pre><code>This is a code block.
</code></pre>
 *
 */
class TableInput extends Component {
  constructor(props) {
    //console.log(" table input constructor");
    super(props);

    this.initialColumns = [];
    this.initialData = [];
    this.errors = {};
    this.state = { columns: [], data: [] };
  }

  componentDidMount() {
    if (this.props.autoRefresh) {
      this.refreshTable();
    } else {
      let { data, columns } = this.props.currData.data && this.props.currData.data.length > 0 ? this.renderSavedTable() : this.generateTable();
      this.saveTable(data, columns);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currData && nextProps.currData.data !== prevState) return { selectedList: nextProps.currData };
    else return null;
  }

  renderEditableCell = info => {
    return <EditableCell cellInfo={info} saveCell={this.saveCellData.bind(this)} hasErrors={this.errorStatus.bind(this)} />;
  };

  renderTableHeader = (info, index, isChecked) => {
    if (this.props.columnProperties && this.props.columnProperties[info] && this.props.headerCheckbox) {
      if (this.props.columnProperties[info].checkbox) {
        //<TODO>: we also need to save the state and pull the check box from the state
        return (
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={e => {
                const onHeaderCheckboxClick = this.onChangeHandler.bind(this);
                onHeaderCheckboxClick(e, index);
              }}
            ></input>
            {this.props.columnProperties[info].label && <div>{info}</div>}
          </label>
        );
      } else {
        return <div>{info}</div>;
      }
    } else {
      return info;
      //return <div>{info}</div>;
    }
  };

  onChangeHandler = (events, index) => {
    const cols = this.state.columns;
    cols[index]["checked"] = events.currentTarget.checked;
    // re-render table header if checkbox value gets changed.
    cols[index]["Header"] = this.renderTableHeader(cols[index]["accessor"], index, events.currentTarget.checked);
    this.saveTable(this.state.data, cols);
  };

  checkIfAllRowSelected = (d, accessor) => d.every(row => row[accessor]);

  renderSavedTable = () => {
    return { data: this.props.currData.data, columns: this.state.columns };
  };

  generateTable = () => {
    //this function will be overriden by every class that extends tableBuilder
    return { columns: this.state.columns, data: this.state.data };
  };

  saveCellData = (val, cellInfo) => {
    let d = JSON.parse(JSON.stringify(this.state.data));

    if (cellInfo.original.ParentRow !== undefined) {
      d[cellInfo.original.ParentRow]["subComponent"]["data"][cellInfo.index][cellInfo.column.id] = val;
    } else {
      if (cellInfo.index === 0 && this.props.hasOwnProperty("selectAll") && this.props.selectAll === true) {
        // If selectAll checkbox (first row checkbox) is selected, Upadte all rows for this comumn id.
        d.forEach(row => (row[cellInfo.column.id] = val));
      } else {
        // else only update selected row values
        d[cellInfo.index][cellInfo.column.id] = val;
      }
    }
    /**
     * The below handling is for multiple column selection in a Table Row.
     * Its working is similar to how selectAll option work for a Column in Tables.
     **/
    if (this.props.hasOwnProperty("allColumnSelector") && !!this.props.allColumnSelector) {
      if (cellInfo.column.id === this.props.allColumnSelector) {
        if (cellInfo.index === 0 && this.props.hasOwnProperty("selectAll") && this.props.selectAll === true) {
          d.forEach(row => {
            this.props.editableCols.forEach(col => {
              row[col] = val;
            });
          });
        } else {
          this.props.editableCols.forEach(col => {
            d[cellInfo.index][col] = val;
          });
        }
      } else {
        if (cellInfo.index === 0 && this.props.hasOwnProperty("selectAll") && this.props.selectAll === true) {
          // If selectAll checkbox (first row checkbox) is selected, Upadte all rows for all col.
          d.forEach((row, rowIndex) => {
            let isAllColSelected = true;
            this.props.editableCols.forEach(col => {
              if (col !== this.props.allColumnSelector && !d[rowIndex][col]) {
                isAllColSelected = false;
              }
            });
            d[rowIndex][this.props.allColumnSelector] = isAllColSelected;
          });
        } else {
          // else only update selected row values
          let isAllColSelected = true;
          this.props.editableCols.forEach(col => {
            if (col !== this.props.allColumnSelector && !d[cellInfo.index][col]) {
              isAllColSelected = false;
            }
          });
          d[cellInfo.index][this.props.allColumnSelector] = isAllColSelected;
        }
      }
    }

    this.saveTable(d);
  };

  // any table level validations go here. This will be overriden by any class that extends table builder
  validateData = data => data;

  errorStatus = (status, cell, error) => {
    cell = JSON.stringify(cell);
    if (status && !this.errors[cell]) {
      this.errors[cell] = [];
      this.errors[cell].push(error);
    } else if (status && !this.errors[cell].includes(error)) {
      this.errors[cell].push(error);
    } else if (!status && this.errors[cell] && this.errors[cell].includes(error)) {
      this.errors[cell].splice(this.errors[cell].indexOf(error), 1);

      if (this.errors[cell].length === 0) {
        delete this.errors[cell];
      }
    }

    if (Object.values(this.errors).length > 0) {
      this.updateParentSubmitButton(true);
    } else {
      this.updateParentSubmitButton(false);
    }
  };

  updateParentSubmitButton = status => {
    if (this.props.disableParent) {
      this.props.disableParent(status);
    }
  };
  displayErrorMessages = () => {
    let errList = Object.entries(this.errors).map(err => {
      let { row, column } = JSON.parse(err[0]);

      return (
        <div key={err}>
          {`Error on Row: ${row}, Column: ${column}`}

          {err[1].map(e => (
            <p key={e}>{e}</p>
          ))}

          <br />
        </div>
      );
    });

    return errList;
  };
  saveTable = (data, tableColumns = this.state.columns) => {
    //Before saving the table, run any custom table level validations
    let validData = this.validateData(data, tableColumns);

    if (this.props.hasOwnProperty("selectAll") && this.props.selectAll === true) {
      // Add new row for selectAll options in the table.
      let selectAllRow = {};
      if (validData && validData.length && Object.values(validData[0]).includes("Select All")) {
        selectAllRow = validData.splice(0, 1)[0];
      } else {
        selectAllRow.id = Math.random();
      }
      tableColumns.forEach((col, index) => {
        if (index === 0) {
          selectAllRow[col.accessor] = "Select All";
        } else {
          selectAllRow[col.accessor] = this.checkIfAllRowSelected(validData, col.accessor);
        }
      });
      validData = [selectAllRow, ...validData];
    }

    // if remove row icon on the data, then replace with null prior to save
    var tableData = JSON.parse(JSON.stringify(validData));
    for (var row = 0; row < data.length; row++) {
      if (tableData[row].RemoveItem) {
        delete tableData[row].RemoveItem;
      }
    }
    this.setState({ data: validData, columns: tableColumns });
    this.props.tableData({
      columns: tableColumns,
      data: tableData,
    });
  };

  refreshTable = () => {
    let table = this.generateTable();
    if (this.props.mergeKey && this.props.currData.data) {
      let allKeys = table.columns.map(row => row.accessor);
      let mergedData = table.data.map(genRow => {
        let newRow = { ...genRow };
        this.props.currData.data.forEach(currRow => {
          if (newRow[this.props.mergeKey] === currRow[this.props.mergeKey]) {
            allKeys.forEach(genKey => {
              if (this.props.editableCols.includes(genKey) && currRow[genKey]) {
                newRow[genKey] = currRow[genKey];
              }
            });
          }
        });
        return newRow;
      });

      table.data = mergedData;
    }

    this.errors = {};
    this.updateParentSubmitButton(false);
    this.saveTable(table.data, table.columns);
  };

  displayData = () => {
    let d = JSON.parse(JSON.stringify(this.state.data));

    let data = d.map(row => {
      row.RemoveItem = <i onClick={this.removeItem} id={row.id} className="glyphicon glyphicon-trash Clickable RightAlign" />;
      if (this.props.conditionalDeleteRow && (row.isMapped || row.usedInFilter || row.usedInJoin || row.isCustomValidation)) {
        row.RemoveItem = (
          <>
            <i onClick={() => {}} id={row.id} className="glyphicon glyphicon-trash ClickDisabled RightAlign" data-for="deleteRow" data-tip="react-tooltip" />
            <XTooltip id="deleteRow" placement="left">
              This fields is used in Data Mapping / Join Criteria / Filter Condition / Custom Validation.
            </XTooltip>
          </>
        );
      }

      Object.keys(row).forEach(key => {
        if (this.props.lists.AllObjects) {
          row[key] = this.props.lists.AllObjects[row[key]] ? this.props.lists.AllObjects[row[key]].name : getNamesForListID(row[key], this.props.lists.Titles);
        } else {
          row[key] = getNamesForListID(row[key], this.props.lists.Titles);
        }
      });

      return row;
    });

    return data;
  };
  render() {
    return (
      <div>
        <div className="Tabular" />
        {Object.values(this.errors).length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {this.displayErrorMessages()}
          </div>
        ) : (
          ""
        )}

        <ReactTable
          columns={this.state.columns}
          data={this.displayData()}
          filterable={this.props.filterable}
          className="-striped -highlight"
          defaultPageSize={this.props.pageSize || 5}
          pageSize={this.props.pageSize}
          showPagination={this.props.showPagination}
        />
        {this.props.disableAdditionalRows ? (
          ""
        ) : (
          <button type="button" className="btn btn-primary btn-padded" onClick={this.addRow}>
            {this.props.addRowLabel ? this.props.addRowLabel : "Add Row"}
          </button>
        )}
        {this.props.disableRefresh ? "" : <button type="button" onClick={this.refreshTable} className="glyphicon glyphicon-refresh btn-padded RightAlign" />}
      </div>
    );
  }
}

TableInput.propTypes = {
  currData: PropTypes.object,
  cols: PropTypes.array,
  editableCols: PropTypes.array,
};

export default TableInput;
