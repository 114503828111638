import React, { Component } from "react";
import MaterialTable from "material-table";
import { getNamesForListID } from "./ManageObjectIDs";
import { standardFields } from "./CommonResources.json";

const headerFontSize = "1.5rem";
const cellFontSize = "1.3rem";
const customFieldTag = "****CUSTOM FIELDS****";
standardFields.DataMapping.push(customFieldTag);
standardFields.HRDataMapping.push(customFieldTag);

class DataTableBuilder extends Component {
  constructor(props) {
    super(props);
    this.dataTableForm = "";
    this.pageSize = this.props.tableType === "DataMapping" ? 30 : 25;

    this.state = {
      selectedRow: null,
      columns: [
        {
          title: "Xactly",
          field: "xactly",
          initialEditValue: "Custom Field Name",
          cellStyle: {
            fontSize: cellFontSize
          },
          headerStyle: {
            fontSize: headerFontSize
          },
          editComponent: rowProps => {
            if (!standardFields[this.props.tableType].includes(rowProps.rowData.xactly))
              return (
                <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl" style={{ fontSize: cellFontSize }}>
                  <input className="MuiInputBase-input MuiInput-input" type="text" value={rowProps.value} onChange={e => rowProps.onChange(e.target.value)} />
                </div>
              );
            else
              return (
                <div style={{ fontSize: cellFontSize }}>
                  <input disabled className="MuiInputBase-input" type="text" value={rowProps.value} onChange={e => rowProps.onChange(e.target.value)} />
                </div>
              );
          }

          //  render: rowData => <span><img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/><input></span>
        },
        {
          title: "DataType",
          field: "DataType",
          headerStyle: {
            fontSize: headerFontSize
          },
          cellStyle: {
            fontSize: cellFontSize
          },

          lookup: { Numeric: "Numeric", Date: "Date", String: "String", Boolean: "Boolean", "Drop Down List": "Drop Down List" }
        }
      ],
      data:
        this.props.tableType === "DataMapping"
          ? [
              { xactly: "Order Code", DataType: "String" },
              { xactly: "Item Code", DataType: "String" },
              { xactly: "Batch Name", DataType: "String" },
              { xactly: "Batch Type", DataType: "String" },
              { xactly: "Product Name", DataType: "String" },
              { xactly: "Geography Name", DataType: "String" },
              { xactly: "Customer Name", DataType: "String" },
              { xactly: "Quantity", DataType: "Numeric" },
              { xactly: "Amount", DataType: "Numeric" },
              { xactly: "Amount UnitType", DataType: "String" },
              { xactly: "Incentive Date", DataType: "Date" },
              { xactly: "Order Date", DataType: "Date" },
              { xactly: "Order Type", DataType: "String" },
              { xactly: "Discount", DataType: "String" },
              { xactly: "Description", DataType: "String" },
              { xactly: "Related Order Code", DataType: "String" },
              { xactly: "Related Item Code", DataType: "String" },
              { xactly: "Employee ID", DataType: "String" },
              { xactly: "Split Amount (%)", DataType: "Numeric" },
              { xactly: customFieldTag }
            ]
          : [
              { xactly: "First Name", DataType: "String" },
              { xactly: "Middle Name", DataType: "String" },
              { xactly: "Last Name", DataType: "String" },
              { xactly: "Prefix", DataType: "String" },
              { xactly: "Employee ID", DataType: "String" },
              { xactly: "Employee Status", DataType: "String" },
              { xactly: "Region", DataType: "String" },
              { xactly: "Hire Date", DataType: "Date" },
              { xactly: "Termination Date", DataType: "Date" },
              { xactly: "Personal Target", DataType: "Numeric" },
              { xactly: "Prorated Personal Target", DataType: "Numeric" },
              { xactly: "Personal Currency", DataType: "String" },
              { xactly: "Payment Currency", DataType: "String" },
              { xactly: "Salary", DataType: "Numeric" },
              { xactly: "Prorated Salary", DataType: "Numeric" },
              { xactly: "Salary Currency", DataType: "String" },
              { xactly: "Business Group", DataType: "String" },
              { xactly: customFieldTag }
            ]
    };
  }

  componentDidMount() {
    console.log(`${this.props.tableType} -  table builder Mounted`);
    console.log(this.props);
    const sourcelist = (this.props.tableType === "DataMapping" ? this.props.variables.FinalSources : this.props.variables.HRSources) || this.props.variables.SelectedSources;

    let sources = sourcelist.map(item => {
      return getNamesForListID(item.id || item, this.props.lists["Sources"]);
    });
    // if currData sent to the component has valid data then assign that as intial data and set state.data as the same else use default statedata.

    let initialData = this.props.currData.data === undefined ? [...this.state.data] : [...this.props.currData.data];
    let intialFields = [];
    for (let f = 0; f < initialData.length; f++) {
      intialFields.push(initialData[f]["xactly"]);
    }
    // push splitAmount into this array as well so that it does not show up as a custom field
    intialFields.push("SplitAmount");
    let attributes = [];
    ["IncentiveQualifierAttributes", "IncentiveComputationAttributes", "IncentiveRateAttributes"].forEach(v => {
      if (this.props.variables[v]) {
        for (let a in this.props.variables[v]) {
          attributes = attributes.concat(this.props.variables[v][a].val);
        }
      }
    });
    attributes = [...new Set(attributes)];

    for (let attr = 0; attr < attributes.length; attr++) {
      let attribute_name = this.props.lists["AllObjects"][attributes[attr]] ? this.props.lists["AllObjects"][attributes[attr]].name : attributes[attr];
      let attribute_type = "OrderItem";

      if (intialFields.includes(attribute_name) || attribute_name === "All") {
        continue;
      } else {
        //check if attr of the type HR or transactional
        for (let att in this.props.variables.AttributeType) {
          if (this.props.variables.AttributeType[att].Attribute === attribute_name) {
            attribute_type = this.props.variables.AttributeType[att].val[0];
          }
        }
        if ((this.props.tableType === "DataMapping" && attribute_type === "OrderItem") || (this.props.tableType === "HRDataMapping" && attribute_type === "People")) {
          console.log(`pushing attribute${attribute_name} of the type ${attribute_type}`);
          initialData.push({
            xactly: attribute_name,
            IncentFieldType: attribute_type
          });
        }
      }
    }
    //console.log(initialData);
    let initialColumns = [...this.state.columns];

    sources.forEach(source => {
      initialColumns.push({
        title: source,
        field: source,
        headerStyle: {
          fontSize: headerFontSize
        },
        cellStyle: {
          fontSize: cellFontSize
        }
      });
    });

    this.setState({ columns: initialColumns, data: initialData }, () => {
      this.saveTable();
    });
  }

  saveTable = () => {
    //convert column keys to Header and accessor vs title and field

    let cols = [];
    for (let r = 0; r < this.state.columns.length; r++) {
      cols.push({ Header: this.state.columns[r].title, accessor: this.state.columns[r].field });
    }
    this.props.tableData({
      columns: cols,
      data: this.state.data
    });
    this.disableParentSubmit(false);
  };

  deleteRow = (event, rowData) => {
    let confirmation = window.confirm(`You want to delete the field: ${rowData.xactly} ?`);
    if (confirmation) console.log(event, rowData);
  };

  disableParentSubmit = status => {
    this.props.disableParent(status);
  };

  render() {
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <div className="Tabular" style={{ fontSize: "1rem" }}>
          <MaterialTable
            columns={this.state.columns}
            data={this.state.data}
            title={this.props.tableType === "DataMapping" ? "Order Data Mapping Table" : "HR Data Mapping Table"}
            actions={[]}
            onRowClick={(evt, selectedRow) => this.setState({ selectedRow })}
            options={{
              rowStyle: rowData => ({
                backgroundColor: this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id ? "#EEE" : "#FFF"
              }),
              actionsColumnIndex: 0,
              filtering: true,
              addRowPosition: "first",
              pageSize: this.pageSize,
              pageSizeOptions: [5, 10, 25, 30, 40, 50],
              grouping: false
            }}
            onChangeRowsPerPage={pageSize => {
              console.log(pageSize);
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  const data = [...this.state.data];
                  newData.IncentFieldType = this.props.tableType === "DataMapping" ? "OrderItem" : "People";
                  data.push(newData);
                  this.setState({ data }, () => {
                    this.disableParentSubmit(true);
                    resolve();
                  });
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...this.state.data];
                  const index = oldData.tableData.id;
                  data[index] = newData;
                  this.setState({ data }, () => {
                    this.disableParentSubmit(true);
                    resolve();
                  });
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...this.state.data];
                  const index = oldData.tableData.id;
                  data.splice(index, 1);
                  this.setState({ data }, () => {
                    this.disableParentSubmit(true);
                    resolve();
                  });
                })
            }}
          />
        </div>

        <button className="btn btn-success btn-padded" onClick={this.saveTable}>
          Save Table
        </button>
      </div>
    );
  }
}
export default DataTableBuilder;
