import listProps_v2 from "./listPropertiesConfig_v3.json";
import UpdateLists_v2, { validateNewValue, validations } from "./updateLists_v2.js";
import { incentStandardFields } from "./util.js";

class UpdateLists_v4 extends UpdateLists_v2 {
  constructor(props) {
    super(props);
    this.listProps = listProps_v2;
  }

  validateOptions = (formData, errors) => {
    let standardFields = JSON.parse(JSON.stringify(incentStandardFields));
    let standardFieldNames = { OrderItem: [], People: [] };
    ["OrderItem", "People"].forEach(fieldType => {
      standardFieldNames[fieldType] = Object.keys(standardFields)
        .filter(key => standardFields[key].type === fieldType)
        .map(key => standardFields[key].name.replace(/[\s "]/g, "").toUpperCase());
    });

    let names = {};
    formData.forEach(item => {
      let val = item.name;
      let charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].max;

      if (!val) {
        errors.addError(`Empty values not allowed`);
      } else {
        val = val.trim();
        if (this.state.selectedList === "QualifierAttributes") {
          if (val.match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
            errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
          }
          if (item.dataType === "number") {
            charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].maxNum;
          }
        } else {
          if (val.match(/[!@#$%^&*(),.?":{}|<>-]/g) !== null) {
            errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
          }
        }
        if (val.length < validations.fieldLengthBounds[this.state.selectedList].min || val.length > charLength_MAX) {
          errors.addError(
            `Length of each value in the list ${this.state.selectedList} should be between ${validations.fieldLengthBounds[this.state.selectedList].min} and ${charLength_MAX} characters.`,
          );
        }
        if (this.state.selectedList === "QualifierAttributes" && standardFieldNames[item.type].includes(val.replace(/[\s "]/g, "").toUpperCase())) {
          errors.addError(`Name matches incent standard field name:  ${val}`);
        }

        const valueUpperCase = val.toUpperCase();
        names[valueUpperCase] = names[valueUpperCase] || [];
        names[valueUpperCase].push(valueUpperCase);
      }
    });

    for (let name in names) {
      if (names[name].length > 1) {
        errors.addError(`Unique values required. The following value appears more than once: ${name}`);
      }
    }

    return errors;
  };
}

export { validateNewValue };
export default UpdateLists_v4;
