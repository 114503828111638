import React, { useState, useRef, useEffect } from "react";

function Cover(props) {
  const [disabled, setDisabled] = useState(props.disabled === true);
  const parentRef = useRef(null);
  const coverRef = useRef(null);
  const [style, setStyle] = useState({});
  const [parentStyle, setParentStyle] = useState({});
  useEffect(() => {
    setDisabled(props.disabled === true);
  }, [props.disabled]);
  useEffect(() => {
    if (disabled) {
      setParentStyle({ cursor: "not-allowed" });
      setStyle({
        pointerEvents: "none",
        opacity: 0.9
      });
      var all = coverRef.current.getElementsByTagName("*");
      for (let i = 0; i < all.length; i++) {
        all[i].disabled = true;
      }
    } else {
      setParentStyle({});
      setStyle({});
    }
  }, [disabled, coverRef.current]);

  return (
    <div ref={parentRef} style={parentStyle}>
      <div ref={coverRef} style={style}>
        {props.children}
      </div>
    </div>
  );
}

export default Cover;
