const overrideCompMatrix = (compMatrix, MatrixOverride) => {
  compMatrix = JSON.parse(JSON.stringify(compMatrix));
  let compMatrixOverride = JSON.parse(JSON.stringify(MatrixOverride));

  let overridenMatrixColumns = compMatrix.columns;
  let overridenMatrixdata = compMatrix.data.map(matrixRow => {
    compMatrixOverride.data.compMatrix.forEach(overrideRow => {
      if (matrixRow.plan === overrideRow.plan && matrixRow.role === overrideRow.role) {
        Object.keys(matrixRow).forEach(matrixRowField => {
          if (!["plan", "role"].includes(matrixRowField)) {
            Object.keys(overrideRow).forEach(overrideRowField => {
              if (matrixRowField === overrideRowField && overrideRow[overrideRowField] === false) {
                delete matrixRow[matrixRowField];
              }
            });
          }
        });
      }
    });
    return matrixRow;
  });

  return { columns: overridenMatrixColumns, data: overridenMatrixdata };
};
const generateCompMatrixOverride = (compMatrix, override, unfitIncentives) => {
  let unfitIncentiveIds = unfitIncentives.filter(item => item.unfit === true).map(item => item.incentiveId);
  let matrixOverride = { data: { compMatrix: [] }, columns: { compMatrix: [] } };
  matrixOverride.columns.compMatrix = JSON.parse(JSON.stringify(compMatrix.columns));
  matrixOverride.data.compMatrix = JSON.parse(JSON.stringify(compMatrix.data)).map(matrixItem => {
    if (override) {
      override.data.compMatrix.forEach(overrideRow => {
        if (matrixItem.plan === overrideRow.plan && matrixItem.role === overrideRow.role) {
          Object.keys(matrixItem).forEach(matrixItemfield => {
            if (!["plan", "role"].includes(matrixItemfield)) {
              matrixItem[matrixItemfield] = true;
              Object.keys(overrideRow).forEach(overrideRowField => {
                if (overrideRowField === matrixItemfield) {
                  matrixItem[matrixItemfield] = unfitIncentiveIds.includes(matrixItemfield) ? false : overrideRow[overrideRowField];
                }
              });
            }
          });
        }
      });
    } else {
      Object.keys(matrixItem).forEach(matrixItemfield => {
        if (!["plan", "role"].includes(matrixItemfield)) {
          let include = unfitIncentiveIds.includes(matrixItemfield) ? false : true;
          matrixItem[matrixItemfield] = include;
        }
      });
    }
    return matrixItem;
  });

  // Create plan list

  matrixOverride.data.plan = JSON.parse(JSON.stringify(compMatrix.data)).map(matrixItem => {
    if (override) {
      let r = JSON.stringify({ plan: matrixItem.plan, include: true });
      override.data.plan.forEach(overrideRow => {
        if (overrideRow.plan === matrixItem.plan) {
          r = JSON.stringify({ plan: matrixItem.plan, include: overrideRow.include });
        }
      });
      return r;
    } else {
      return JSON.stringify({ plan: matrixItem.plan, include: true });
    }
  });

  matrixOverride.data.plan = [...new Set(matrixOverride.data.plan)].map(item => JSON.parse(item));

  return matrixOverride;
};

export default { generateCompMatrixOverride, overrideCompMatrix };
