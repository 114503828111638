import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

import TableInput from "./TableBuilder";
import PropTypes from "prop-types";

const levelArray = ["Weekly", "Semi-Monthly", "Monthly", "Quarterly", "Semi-Annual", "Yearly"];

const defaultCalendarLevels = {
  Weekly: {
    lvl: "weeks",
    periods: 52,
    increment: 1,
    label: "Week-",
    lvlNumber: 0,
  },
  "Semi-Monthly": {
    lvl: "semi-monthly",
    periods: 24,
    increment: 1,
    label: "Semi-Month-",
    lvlNumber: 1,
  },
  Monthly: {
    lvl: "months",
    periods: 12,
    increment: 1,
    label: "Month-",
    lvlNumber: 2,
  },
  "4-Week-Month": {
    lvl: "4-week",
    periods: 13,
    increment: 1,
    label: "Month-",
    lvlNumber: 2,
  },
  Quarterly: {
    lvl: "quarters",
    periods: 4,
    increment: 1,
    label: "QTR",
    lvlNumber: 3,
  },
  "Semi-Annual": {
    lvl: "half-years",
    periods: 12,
    increment: 6,
    label: "H",
    lvlNumber: 4,
  },
  Yearly: { lvl: "years", periods: 1, label: "YEAR", lvlNumber: 5 },
};

const periodNames = {
  months: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
  quarters: ["Q1", "Q2", "Q3", "Q4"],
  halfs: ["H1", "H2"],
};

/**
 * ### Overview
 * This component builds incent calendar based on the plan year and calendar levels passed as properties.
 *

 */
class CalendarBuilder extends TableInput {
  constructor(props) {
    super(props);
    this.UseMonthNames = true;
    this.CalendarYear = this.props.variables.calendarParams ? this.props.variables.calendarParams.calendarYear : this.props.variables["CalendarYear"];
    this.CalendarType = this.props.variables.calendarParams ? [this.props.variables.calendarParams.calendarType] : this.props.variables["CalendarType"];
    this.CalendarStartDate = `${this.CalendarYear}-01-01`;
    this.CalendarEndDate = `${this.CalendarYear}-12-31`;
    this.CalendarLevels = this.props.variables.calendarParams ? this.props.variables.calendarParams.calendarLevels : this.props.variables["CalendarLevels"];
    this.lowestLevel = "Monthly";
    this.firstLevel = "Quarterly";
    this.secondLevel = "Yearly";
    this.thirdLevel = "Semi-Annual";
    this.state = {
      tags: {},
      CalendarLevels: JSON.parse(JSON.stringify(defaultCalendarLevels)),
      columns: [],
      data: [],
    };

    //3 vs 4 Level Calendar Parameters:
    if (this.props.variables.calendarParams.calendarLevels.length === 4) {
      this.levels = ["Monthly", "Quarterly", "Semi-Annual", "Yearly"];
      this.state.columns = [
        {
          Header: "Year",
          accessor: "Year",
          props: { type: "text", confirmation: false, confirmationMessage: "Are you sure?" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "Semi-Annual",
          accessor: "Semi-Annual",
          props: { type: "text" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "Quarter",
          accessor: "Quarter",
          props: { type: "text" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "Month",
          accessor: "Month",
          props: { type: "text" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "From",
          accessor: "From",
          props: { type: "date" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "To",
          accessor: "To",
          props: { type: "date" },
          Cell: this.renderEditableCell,
        },
      ];
      this.lowestLevel = "Monthly";
      this.firstLevel = "Quarterly";
      this.secondLevel = "Semi-Annual";
      this.thirdLevel = "Yearly";
    } else {
      this.levels = ["Monthly", "Quarterly", "Yearly"];
      this.state.columns = [
        {
          Header: "Yearly",
          accessor: "Year",
          props: { type: "text", confirmation: false, confirmationMessage: "Are you sure?" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "Quarter",
          accessor: "Quarter",
          props: { type: "text" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "Month",
          accessor: "Month",
          props: { type: "text" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "From",
          accessor: "From",
          props: { type: "date" },
          Cell: this.renderEditableCell,
        },
        {
          Header: "To",
          accessor: "To",
          props: { type: "date" },
          Cell: this.renderEditableCell,
        },
      ];
      this.lowestLevel = "Monthly";
      this.firstLevel = "Quarterly";
      this.secondLevel = "Yearly";
    }
  }

  generateTable = () => {
    let SelectedCalLevels = [];
    let calendarLevel = { ...this.state.CalendarLevels };

    //if custom calendar start and end date begin with calendar start and end date params:
    if (this.CalendarType[0] === "Custom") {
      this.CalendarStartDate = this.props.variables.calendarParams ? this.props.variables.calendarParams.calendarStartDate : this.props.variables["CalendarStartDate"];
      this.CalendarEndDate = this.props.variables.calendarParams ? this.props.variables.calendarParams.calendarEndDate : this.props.variables["CalendarEndDate"];
    }

    this.CalendarLevels.forEach(level => {
      if (level === "Bi-Weekly") {
        level = "Semi-Monthly";
      }
      SelectedCalLevels.push(calendarLevel[level]["lvlNumber"]);
    });
    SelectedCalLevels.sort();
    //set the levels
    if (levelArray.length > 3) {
    }

    this.lowestLevel = levelArray[SelectedCalLevels[0]];
    this.firstLevel = levelArray[SelectedCalLevels[1]];
    this.secondLevel = levelArray[SelectedCalLevels[2]];
    if (levelArray[SelectedCalLevels[3]]) {
      //it is a four calendar level
      this.thirdLevel = levelArray[SelectedCalLevels[3]];
    } else {
      this.thirdLevel = undefined;
    }
    var data = [];

    for (var i = 0; i < calendarLevel[this.lowestLevel]["periods"]; i = i + calendarLevel[this.lowestLevel]["increment"]) {
      let periodStartDate = moment(this.CalendarStartDate, "YYYY-MM-DD").add(i, calendarLevel[this.lowestLevel]["lvl"]);
      let periodEndDate = moment(periodStartDate).endOf(calendarLevel[this.lowestLevel]["lvl"]);
      if (calendarLevel[this.lowestLevel]["lvl"] === "weeks") {
        periodStartDate = moment(this.CalendarStartDate, "YYYY-MM-DD").add(i * 7, "days");
        periodEndDate = moment(periodStartDate).add(6, "days");
      }
      if (calendarLevel[this.lowestLevel]["lvl"] === "semi-monthly") {
        const momentObject = {
          year: this.CalendarYear,
          month: Math.floor(i / 2),
        };
        const startDate = moment(momentObject).startOf("month");
        const endDate = moment(momentObject).endOf("month");
        const midDate = startDate.clone().add(Math.floor(startDate.daysInMonth() / 2), "days");

        if (i % 2 === 0) {
          periodStartDate = moment(startDate, "YYYY-MM-DD");
          periodEndDate = moment(midDate, "YYYY-MM-DD");
        } else {
          const secondStartDate = midDate.clone().add(1, "days");
          periodStartDate = moment(secondStartDate, "YYYY-MM-DD");
          periodEndDate = moment(endDate, "YYYY-MM-DD");
        }
      }
      if (calendarLevel[this.lowestLevel]["lvl"] === "4-week") {
        periodStartDate = moment(this.CalendarStartDate, "YYYY-MM-DD").add(i * 28, "days");
        periodEndDate = moment(periodStartDate).add(27, "days");
      }

      let firstlevelLabelSuffix = "";
      let secondlevelLabelSuffix = "";
      let thirdlevelLabelSuffix = "";
      let lowestlevelLabelSuffix = "";
      let SemiAnnualThreshold = 6;
      let QuarterThreshold = 3;
      let MonthThreshold = 1;

      if (calendarLevel[this.lowestLevel]["lvl"] === "weeks") {
        SemiAnnualThreshold = 26;
        QuarterThreshold = 12;
        MonthThreshold = 4;
      } else if (calendarLevel[this.lowestLevel]["lvl"] === "semi-monthly") {
        SemiAnnualThreshold = 12;
        QuarterThreshold = 6;
        MonthThreshold = 2;
      } else if (calendarLevel[this.lowestLevel]["lvl"] === "quarters") {
        SemiAnnualThreshold = 2;
      }

      switch (calendarLevel[this.lowestLevel]["lvl"]) {
        case "months":
          if (this.UseMonthNames) {
            calendarLevel[this.lowestLevel]["label"] = periodNames.months[periodStartDate.format("M") - 1];
          } else {
            lowestlevelLabelSuffix = Math.ceil((i + 1) / MonthThreshold);
          }

          break;
        case "semi-monthly":
          lowestlevelLabelSuffix = i / 2 + 1;
          break;
        default:
          lowestlevelLabelSuffix = i + 1;
          break;
      }
      switch (calendarLevel[this.firstLevel]["lvl"]) {
        case "half-years":
          firstlevelLabelSuffix = i + 1 <= SemiAnnualThreshold ? 1 : 2;
          break;
        case "quarters":
          firstlevelLabelSuffix = Math.ceil((i + 1) / QuarterThreshold);
          break;
        case "months":
          if (this.UseMonthNames) {
            calendarLevel[this.firstLevel]["label"] = periodNames.months[periodStartDate.format("M") - 1];
          } else firstlevelLabelSuffix = Math.ceil((i + 1) / MonthThreshold);

          break;
        case "semi-monthly":
          firstlevelLabelSuffix = Math.ceil((i + 1) / 2);
          break;
        default:
          break;
      }
      switch (calendarLevel[this.secondLevel]["lvl"]) {
        case "half-years":
          secondlevelLabelSuffix = i + 1 <= SemiAnnualThreshold ? 1 : 2;
          break;
        case "quarters":
          secondlevelLabelSuffix = Math.ceil((i + 1) / QuarterThreshold);
          break;
        case "months":
          if (this.UseMonthNames) {
            secondlevelLabelSuffix = Math.ceil((i + 1) / MonthThreshold);
          }
          break;

        default:
          break;
      }
      if (this.thirdLevel) {
        switch (calendarLevel[this.thirdLevel]["lvl"]) {
          case "half-years":
            thirdlevelLabelSuffix = i + 1 <= SemiAnnualThreshold ? 1 : 2;
            break;
          case "quarters":
            thirdlevelLabelSuffix = Math.ceil((i + 1) / QuarterThreshold);
            break;
          case "months":
            if (this.UseMonthNames) {
              thirdlevelLabelSuffix = Math.ceil((i + 1) / MonthThreshold);
            }
            break;
        }
      }
      if (this.thirdLevel) {
        data[data.length] = {
          Year: `${calendarLevel[this.thirdLevel]["label"]}${thirdlevelLabelSuffix}-${this.CalendarYear}`,
          "Semi-Annual": `${calendarLevel[this.secondLevel]["label"]}${secondlevelLabelSuffix}-${this.CalendarYear}`,
          Quarter: `${calendarLevel[this.firstLevel]["label"]}${firstlevelLabelSuffix}-${this.CalendarYear}`,
          Month: `${calendarLevel[this.lowestLevel]["label"]}${lowestlevelLabelSuffix}-${this.CalendarYear}`,
          From: periodStartDate.format("MM/DD/YYYY"),
          To: periodEndDate.format("MM/DD/YYYY"),
        };
      } else {
        data[data.length] = {
          Year: `${calendarLevel[this.secondLevel]["label"]}${secondlevelLabelSuffix}-${this.CalendarYear}`,
          Quarter: `${calendarLevel[this.firstLevel]["label"]}${firstlevelLabelSuffix}-${this.CalendarYear}`,
          Month: `${calendarLevel[this.lowestLevel]["label"]}${lowestlevelLabelSuffix}-${this.CalendarYear}`,
          From: periodStartDate.format("MM/DD/YYYY"),
          To: periodEndDate.format("MM/DD/YYYY"),
        };
      }
    }
    //Remove excess calendars if applicable
    let calendarPeriod = data[0] && data[0].Year ? data[0].Year : "";
    let newData = [];
    data.forEach(datum => {
      if (datum.Year && datum.Year === calendarPeriod) {
        newData.push(datum);
      }
    });
    if (data.length === newData.length) {
      newData[newData.length - 1].To = moment(this.CalendarEndDate, "YYYY-MM-DD").format("MM/DD/YYYY");
    }
    data = newData;

    return { columns: this.state.columns, data: data };
  };

  refreshTable = () => {
    let newTags = {};
    for (let tag in { ...this.state.tags }) {
      newTags[tag] = "";
    }
    this.setState({ CalendarLevels: JSON.parse(JSON.stringify(defaultCalendarLevels)), tags: newTags }, () => {
      let table = this.generateTable();
      this.saveTable(table.data, table.columns);
    });

    this.errors = {};
    this.updateParentSubmitButton(false);
  };
  changeTag = (event, level) => {
    let updatedCalendarLevels = JSON.parse(JSON.stringify(this.state.CalendarLevels));
    let updatedTags = { ...this.state.tags };
    updatedTags[level] = event.target.value;
    updatedCalendarLevels[level]["label"] = event.target.value;
    this.setState({ CalendarLevels: updatedCalendarLevels, tags: updatedTags }, () => {
      let { data } = this.generateTable();
      this.saveTable(data);
    });
  };

  toggleMonthNames = () => {
    this.UseMonthNames = !this.UseMonthNames;
    let { data } = this.generateTable();
    this.saveTable(data);
  };

  render() {
    return (
      <div>
        <div />
        {Object.values(this.errors).length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {this.displayErrorMessages()}
          </div>
        ) : (
          ""
        )}
        <ReactTable columns={this.state.columns} data={this.state.data} className="-striped -highlight" defaultPageSize={12} />
        {this.thirdLevel && <input type="text" onChange={e => this.changeTag(e, this.thirdLevel)} value={this.state.tags[this.thirdLevel]} className="btn-padded" placeholder="Enter a prefix" />}
        <input type="text" onChange={e => this.changeTag(e, this.secondLevel)} value={this.state.tags[this.secondLevel]} className="btn-padded" placeholder="Enter a prefix" />
        <input type="text" onChange={e => this.changeTag(e, this.firstLevel)} value={this.state.tags[this.firstLevel]} className="btn-padded" placeholder="Enter a prefix" />
        <input type="text" onChange={e => this.changeTag(e, this.lowestLevel)} value={this.state.tags[this.lowestLevel]} className="btn-padded" placeholder="Enter a prefix" />
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ToggleMonthNameTip">
              <strong>Toggle Months</strong>
            </Tooltip>
          }
        >
          <button type="button" onClick={this.toggleMonthNames} className="glyphicon glyphicon-calendar btn-primary btn-padded RightAlign" />
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="RefreshTableTip">
              <strong>Reset Calendar</strong>
            </Tooltip>
          }
        >
          <button type="button" onClick={this.refreshTable} className="glyphicon glyphicon-refresh btn-primary btn-padded RightAlign" />
        </OverlayTrigger>
      </div>
    );
  }
}

CalendarBuilder.propTypes = {
  /**
 variables is an oject that contains all Global variables for a given project. Calendar component expects the following variables:

 - CalendarYear
 - CalendarType
 - CalendarLevel
 - CalendarStartDate (if CalendarType is Custom)
 - CalendarEndDate (if CalendarType is Custom)
  */
  variables: PropTypes.object,
};

export default CalendarBuilder;
