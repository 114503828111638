import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import objectpath from "object-path";
import TableInput from "./TableBuilder";

class DataDerivation extends TableInput {
  constructor(props) {
    super(props);

    this.dataMapping = "";

    this.state = {
      columns: [
        { Header: "Field", accessor: "field" },
        { Header: "Source", accessor: "source" },
        {
          Header: "Derivation",
          accessor: "derivation",
          props: { type: "text" },
          Cell: this.renderEditableCell
        }
      ],
      data: []
    };
  }

  generateTable = () => {
    let initialFields = [],
      dataMappingFields = [];
    let initialData = this.props.currData.data === undefined ? [...this.state.data] : [...this.props.currData.data];

    initialData.forEach(row => {
      initialFields.push(`${row["field"]}_${row["source"]}`);
    });

    this.dataMapping =
      this.props.tableType === "DataDerivation"
        ? objectpath.get(this.props.answers, "Data.Questions.DataMapping").answer.Response
        : objectpath.get(this.props.answers, "Data.Questions.HRDataMapping").answer.Response;

    this.dataMapping.data.forEach(row => {
      Object.keys(row).forEach(field => {
        if (!["xactly", "paramOverride", "tableData", "rowLevelProps", "RemoveItem"].includes(field) && row[field].toUpperCase() === "DERIVED") {
          if (!initialFields.includes(`${row["xactly"]}_${field}`)) {
            initialData.push({
              field: row["xactly"],
              source: field
            });
            initialFields.push(`${row["xactly"]}_${field}`);
          }

          dataMappingFields.push(`${row["xactly"]}_${field}`);
        }
      });
    });

    let finalData = initialData.filter(row => dataMappingFields.includes(`${row.field}_${row.source}`));

    return { columns: this.state.columns, data: finalData };
  };
  render() {
    return (
      <div>
        <div className="Tabular" />
        {Object.values(this.errors).length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {this.displayErrorMessages()}
          </div>
        ) : (
          ""
        )}

        <ReactTable columns={this.state.columns} data={this.displayData()} className="-striped -highlight" defaultPageSize={5} />
        <button type="button" onClick={this.refreshTable} className="glyphicon glyphicon-refresh btn-padded RightAlign" />
      </div>
    );
  }
}

export default DataDerivation;
