import objectpath from "object-path";
export const findDependent = (formData, QuestionOrder, sectionPath, fullSchemaPath, summary, currentProps, AllResponses, GlobalVariables, lists) => {
  var response = typeof formData.Response === "object" ? formData.Response[0] : formData.Response;

  //console.log(`checking dependent for ${fullSchemaPath}.options.${response}`);

  var ques =
    objectpath.get(QuestionOrder, `${fullSchemaPath}.options.${response}`) === undefined
      ? objectpath.get(QuestionOrder, `${fullSchemaPath}.options.Default`)
      : objectpath.get(QuestionOrder, `${fullSchemaPath}.options.${response}`);

  //console.log(ques);

  if (!ques)
    return {
      found: false,
      lvl: "",
    };
  else if (ques.customRoute) {
    ques = JSON.parse(JSON.stringify(resolveCustomRoute(ques, response, currentProps, QuestionOrder, AllResponses, GlobalVariables, lists, formData)));
  }
  //alert if redirected to incentive selection question.
  if (ques.nextQuesId === "Organization.Plans.Questions.Plan1" && fullSchemaPath.split(".")[fullSchemaPath.split(".").length - 1] === "Comp_Incentive0_0") {
    let confirmation = window.confirm(
      "If this Incentive is not the same as previously discussed then a new Incentive should be created.You will be redirected to incentive selection question. Are you sure?",
    );
    if (!confirmation) {
      ques = { nextQuesId: fullSchemaPath };
    }
  }
  return {
    next_qid: ques.nextQuesId,
    sectionChange: ques.hasOwnProperty("sectionChange") ? true : false,
    found: true,
    lvl: ques.hasOwnProperty("sectionChange") ? ques.nextQuesLvl : sectionPath,
    loop: ques.loopSection ? true : false,
    onLoopBreak: ques.loopSection ? ques.onLoopBreak : "Org1",
    checkParentLoop: ques.checkParentLoop ? true : false,
    parentLoop: ques.parentLoop,
  };
};

const resolveCustomRoute = (ques, response, currentProps, QuestionOrder, AllResponses, GlobalVariables, lists, formData) => {
  //need to stringify and parse else original object will be changed.
  ques = JSON.parse(JSON.stringify(ques));
  let incentive = currentProps.Incentive;
  for (let plan of lists.Plans) {
    let similarCrediting = objectpath.get(AllResponses, `Organization.Plans.${plan.id}.Incentives.${currentProps.Incentive}.Questions.Comp_Incentive47`);
    if (similarCrediting) {
      similarCrediting = similarCrediting ? similarCrediting.answer.Response[0] : similarCrediting;
      incentive = similarCrediting ? (similarCrediting !== "None" ? similarCrediting : currentProps.Incentive) : currentProps.Incentive;
      break;
    }
  }

  if (ques.customRoute.name === "CheckPreviousOccurances") {
    for (var r = 0; r < GlobalVariables.PlanIncentives.length; r++) {
      if (GlobalVariables.PlanIncentives[r].val.includes(currentProps.Incentive) && GlobalVariables.PlanIncentives[r].Plan !== currentProps.Plan) {
        if (GlobalVariables.incentiveTransactionTypes) {
          GlobalVariables.incentiveTransactionTypes.forEach(row => {
            if (row.Incentive === currentProps.Incentive && row.Plan !== currentProps.Plan) {
              ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
            }
          });
          GlobalVariables.CalculationFrequency.forEach(row => {
            if (row.Incentive === currentProps.Incentive && row.Plan !== currentProps.Plan) {
              ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
            }
          });
        } else {
          ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
        }
        break;
      } else {
        const BorrowingCredit = sessionStorage.getItem("BorrowingCredit");
        // Skip Similar-Incentive Or Borrowing-Credit questions, If already in Borrowing flow.
        if (!BorrowingCredit) {
          let hasDirectCredit = false;
          for (let index = 0; index < GlobalVariables.PlanIncentiveCredits.length; index++) {
            if (
              GlobalVariables.PlanIncentiveCredits[index].Plan === currentProps.Plan &&
              GlobalVariables.PlanIncentiveCredits[index].Incentive === currentProps.Incentive &&
              GlobalVariables.PlanIncentiveCredits[index].val.includes("Direct")
            ) {
              hasDirectCredit = true;
              break;
            }
          }

          // Check if we have another Plan, on which borrowing can be applied.
          const OtherPlans = GlobalVariables.PlanTitles ? GlobalVariables.PlanTitles.filter(row => row.Plan !== currentProps.Plan) : [];

          /**
           * Navigate the question control flow to:
           * To 'Similar Incentive question', if the incentive has direct credit.
           * To 'Borrowing credit question', if the incentive has no titles with direct credit associated && if have another plans to borrow from.
           */
          if (!hasDirectCredit) {
            if (objectpath.get(QuestionOrder, "Organization.Plans.Incentives.Questions.Comp_Incentive47_1")) {
              ques[ques.customRoute.props[0]] = "Organization.Plans.Incentives.Questions.Comp_Incentive47_1";
            }
          } else {
            if (objectpath.get(QuestionOrder, "Organization.Plans.Incentives.Questions.Comp_Incentive47") && lists.selectedIncentives.length > 0) {
              ques[ques.customRoute.props[0]] = "Organization.Plans.Incentives.Questions.Comp_Incentive47";
            }
          }
        }
      }
    }
  } else if (ques.customRoute.name === "CheckVariable" && GlobalVariables[ques.customRoute.variable]) {
    //console.log(GlobalVariables[ques.customRoute.variable], currentProps);
    if (["RateTableType"].includes(ques.customRoute.variable)) {
      let rateCalcType = "Fixed";
      if (GlobalVariables.RateCalcType) {
        GlobalVariables.RateCalcType.forEach(element => {
          if (element.Incentive === currentProps.Incentive && element.Plan === currentProps.Plan) {
            rateCalcType = element.val[0];
          }
        });
      }
      GlobalVariables[ques.customRoute.variable].forEach(element => {
        if (element.Incentive === currentProps.Incentive && element.Plan === currentProps.Plan && element.val[0] === ques.customRoute.compareValue && rateCalcType === "Fixed") {
          //console.log(`custom route ${ques.customRoute.name} condition satisfied`);
          ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
        }
      });
    }
  } else if (ques.customRoute.name === "checkMultipleTransactionTypes") {
    if (GlobalVariables.incentiveTransactionTypes) {
      GlobalVariables.incentiveTransactionTypes.forEach(row => {
        if (row.Incentive === incentive && row.val.length > 1) {
          ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
        }
      });
    }
  } else if (ques.customRoute.name === "confirmMultipleCredit") {
    let tranTypes = [],
      commTypes = [],
      quotaRetTypes = [],
      confirmMultipleCredits = false;

    if (!GlobalVariables.commissionableTransactionTypes) {
      GlobalVariables.commissionableTransactionTypes = [...GlobalVariables.incentiveTransactionTypes];
    }

    if (!GlobalVariables.quotaRetiringTransactionTypes) {
      GlobalVariables.quotaRetiringTransactionTypes = [...GlobalVariables.commissionableTransactionTypes];
    }

    GlobalVariables.incentiveTransactionTypes.forEach(row => {
      if (row.Plan === currentProps.Plan && row.Incentive === currentProps.Incentive && row.val.length > 1) {
        tranTypes = row.val;
      }
    });

    GlobalVariables.quotaRetiringTransactionTypes.forEach(row => {
      if (row.Plan === currentProps.Plan && row.Incentive === currentProps.Incentive) {
        quotaRetTypes = row.val;
      }
    });

    GlobalVariables.commissionableTransactionTypes.forEach(row => {
      if (row.Plan === currentProps.Plan && row.Incentive === currentProps.Incentive) {
        commTypes = row.val;
      }
    });
    if (quotaRetTypes.length === 0) {
      quotaRetTypes = commTypes;
    }
    let uniqueType = [...new Set(quotaRetTypes.concat(commTypes))];

    uniqueType.forEach(item => {
      if (!tranTypes.includes(item) || !quotaRetTypes.includes(item) || !commTypes.includes(item)) {
        confirmMultipleCredits = false;
      } else {
        confirmMultipleCredits = true;
      }
    });
    if (confirmMultipleCredits && tranTypes.length > 1) {
      ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
    }
  } else if (ques.customRoute.name === "attributeDataType") {
    let compareAttr = currentProps.Attribute || response;
    lists.AllAttributes.forEach(attr => {
      if (attr.id === compareAttr && attr.dataType === "string") {
        // console.log(`custom route ${ques.customRoute.name} condition satisfied`);
        ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
      }
    });
  } else if (ques.customRoute.name === "rateAttributeDataType") {
    let compareAttr = currentProps.Attribute || response;
    lists.AllAttributes.forEach(attr => {
      if (attr.id === compareAttr && attr.dataType === "number") {
        ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
      } else if (attr.id === compareAttr && attr.dataType === "date") {
        ques[ques.customRoute.props[0]] = ques.customRoute.val[1];
      }
    });
  } else if (ques.customRoute.name === "RateTableDimensionTypeCheck") {
    // console.log("RateTableDimensionTypeCheck");
    if (ques.customRoute.variable && ["RateTableType"].includes(ques.customRoute.variable)) {
      let rateCalcType = "Fixed";
      if (GlobalVariables.RateCalcType) {
        GlobalVariables.RateCalcType.forEach(element => {
          if (element.Incentive === currentProps.Incentive && element.Plan === currentProps.Plan) {
            rateCalcType = element.val[0];
          }
        });
      }
      GlobalVariables[ques.customRoute.variable].forEach(element => {
        if (element.Incentive === currentProps.Incentive && element.Plan === currentProps.Plan && element.val[0] === ques.customRoute.compareValue && rateCalcType === "Fixed") {
          //console.log(`custom route ${ques.customRoute.name} condition satisfied`);
          ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
        }
      });
    }
    let compareAttr = response || currentProps.Attribute;
    // console.log({ currentProps }, { response });
    lists.AllAttributes.forEach(attr => {
      if (attr.id === compareAttr && attr.dataType === "number") {
        ques[ques.customRoute.props[1]] = ques.customRoute.val[1];
      } else if (attr.id === compareAttr && attr.dataType === "date") {
        ques[ques.customRoute.props[1]] = ques.customRoute.val[2];
      } else if (attr.id === compareAttr) {
        ques[ques.customRoute.props[1]] = ques.customRoute.val[3];
      }
    });
  } else if (ques.customRoute.name === "nonCommissionableCheck") {
    let currIncentive = lists.Incentives.filter(row => row.id === currentProps.Incentive)[0];

    if (currIncentive && currIncentive.nonCommissionable) {
      // console.log(`custom route ${ques.customRoute.name} condition satisfied`);
      ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
      ques[ques.customRoute.props[1]] = ques.customRoute.val[1];
      ques[ques.customRoute.props[2]] = ques.customRoute.val[2];
    }
  } else if (ques.customRoute.name === "SelectedValueCheck") {
    response = Array.isArray(formData.Response) ? formData.Response : response;
    if (ques.customRoute.condition === "includes" && response.includes(ques.customRoute.compareValue)) {
      ques[ques.customRoute.props[0]] = ques.customRoute.val[0];
    }
  }

  return ques;
};
