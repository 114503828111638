import React, { Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./ConfigurationDashboard.css";
import LoadingOverlay from "react-loading-overlay";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem, OverlayTrigger, Tooltip, Checkbox } from "react-bootstrap";
import Server from "./Server";
import store from "store";
import TableInput from "./TableBuilder";
import FilterElement from "./tableFilters";
import { getNameFromAllObjects } from "./ManageObjectIDs";
import ComponentSummary from "./componentSummary";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import CommonResources from "./CommonResources.json";

const server = new Server();

//Render core Dashboard Objects:
const RenderGeneric = props => {
  props.renderProps.data.forEach(item => {
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key])) {
        item[key] = item[key].join(" , ");
      }
    });
  });
  return (
    <div className="NewSection">
      <ReactTable
        columns={props.renderProps.columns}
        data={props.renderProps.data}
        className="-striped -highlight"
        defaultPageSize={props.renderProps.defaultPageSize || 5}
        showPagination={props.renderProps.showPagination}
        filterable
        defaultFilterMethod={(filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())}
      />
    </div>
  );
};
const RenderPlans = props => {
  return (
    <div className="NewSection">
      <ReactTable
        columns={props.renderProps.columns}
        data={props.renderProps.data}
        className="-striped -highlight"
        defaultPageSize={props.renderProps.defaultPageSize || 5}
        showPagination={props.renderProps.showPagination}
        pivotBy={["PlanName", "Roles", "Rules"]}
        filterable
        filterAll={true}
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
      />
    </div>
  );
};
const RenderCreditRules = props => {
  return (
    <div className="NewSection">
      <ReactTable
        columns={props.renderProps.columns}
        data={props.renderProps.data}
        className="-striped -highlight"
        showPagination={props.renderProps.showPagination}
        defaultPageSize={props.renderProps.defaultPageSize || 5}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
        SubComponent={row => {
          return (
            <div className="SummarySection">
              <div style={{ padding: "30px" }}>
                <ReactTable
                  columns={[
                    {
                      Header: "CONDITIONS",
                      columns: [
                        ...row.original.condition.ConditionColumns.map(col => ({
                          ...col,
                          Cell: cellProps => (
                            <RenderWithTooltip
                              id="CreditRulesConditionsTooltip"
                              value={cellProps.value}
                              tooltipText={getFormulaExpression(props.renderProps.formulas, cellProps.value)}
                              placement="top"
                            />
                          ),
                        })),
                      ],
                    },
                  ]}
                  data={row.original.condition.ConditionData}
                  defaultPageSize={row.original.condition.ConditionData.length}
                  showPagination={false}
                  className="-striped -highlight"
                />
              </div>
              <div style={{ padding: "30px" }}>
                <ReactTable
                  columns={[
                    {
                      Header: "RESULTS",
                      columns: [
                        ...row.original.computation.ComputationColumns.map(col => ({
                          ...col,
                          Cell: cellProps => (
                            <RenderWithTooltip id="CreditRulesResultTooltip" value={cellProps.value} tooltipText={getFormulaExpression(props.renderProps.formulas, cellProps.value)} placement="top" />
                          ),
                        })),
                      ],
                    },
                  ]}
                  data={row.original.computation.ComputationData}
                  defaultPageSize={row.original.computation.ComputationData.length}
                  showPagination={false}
                  className="-striped -highlight"
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
const RenderCommissionRules = props => {
  return (
    <div className="NewSection">
      <ReactTable
        columns={props.renderProps.columns}
        data={props.renderProps.data}
        className="-striped -highlight"
        showPagination={props.renderProps.showPagination}
        defaultPageSize={props.renderProps.defaultPageSize || 5}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
        SubComponent={row => {
          return (
            <div className="SummarySection">
              <div style={{ padding: "30px" }}>
                <ReactTable
                  columns={[
                    {
                      Header: "CONDITIONS",
                      columns: [
                        ...row.original.condition.ConditionColumns.map(col => ({
                          ...col,
                          Cell: cellProps => (
                            <RenderWithTooltip id="CommRuleConditionTooltip" value={cellProps.value} tooltipText={getFormulaExpression(props.renderProps.formulas, cellProps.value)} placement="top" />
                          ),
                        })),
                      ],
                    },
                  ]}
                  data={row.original.condition.ConditionData}
                  defaultPageSize={row.original.condition.ConditionData.length}
                  showPagination={false}
                  className="-striped -highlight"
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
const RenderRateTables = props => {
  return (
    <div className="NewSection">
      <ReactTable
        columns={[
          {
            Header: props.renderProps.name,
            columns: [
              ...props.renderProps.columns.map(col => ({
                ...col,
                Cell: cellProps => <RenderWithTooltip id="RateTableTooltip" value={cellProps.value} tooltipText={getFormulaExpression(props.renderProps.formulas, cellProps.value)} placement="top" />,
              })),
            ],
          },
        ]}
        data={props.renderProps.data}
        className="-striped -highlight"
        defaultPageSize={props.renderProps.defaultPageSize || 5}
        showPagination={props.renderProps.showPagination}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
        SubComponent={row => {
          //return row.original.subComponent.columns.length <= 2 ? (
          return !props.renderProps.lkpTableName ? (
            <div style={{ padding: "20px" }}>
              <ReactTable
                columns={[
                  ...row.original.subComponent.columns.map(col => ({
                    ...col,
                    Cell: cellProps => (
                      <RenderWithTooltip id="RateTableSubTooltip" value={cellProps.value} tooltipText={getFormulaExpression(props.renderProps.formulas, cellProps.value)} placement="top" />
                    ),
                  })),
                ]}
                data={row.original.subComponent.data}
                defaultPageSize={row.original.subComponent.data.length}
                showPagination={false}
                className="-striped -highlight"
              />
            </div>
          ) : (
            <ReactTable columns={[]} data={[]} defaultPageSize={row.original.subComponent.data.length} showPagination={false} className="-striped -highlight" />
          );
        }}
      />
    </div>
  );
};
const RenderLookupTables = props => {
  let columns = props.renderProps.columns.map(col => ({ Header: col.field, accessor: col.field, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) }));
  columns.push({ Header: "Return Value", accessor: "ReturnValue", Filter: ({ filter, onChange }) => FilterElement(filter, onChange) });
  let data = props.renderProps.data.map(row => {
    let r = {};
    row.data.forEach(item => {
      if (item.hasOwnProperty("low")) {
        r[item.field] = `From ${item.low} through ${item.high}`;
      } else {
        r[item.field] = item.value;
      }
    });
    r.ReturnValue = row.returnValue;
    return r;
  });
  let column = [{ Header: props.renderProps.name, columns: columns }];
  return (
    <div>
      <RenderGeneric renderProps={{ columns: column, data: data }} />
    </div>
  );
};
const RenderIncentive = props => {
  let errors = props.renderProps.errors ? props.renderProps.errors.filter(item => item.type === "ERROR") : [];
  let warnings = props.renderProps.errors ? props.renderProps.errors.filter(item => item.type === "WARNING") : [];
  return (
    <div>
      <div>
        <h5>
          All configuration for Component:
          <button
            className="btn btn-secondary"
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={() => {
              props.renderProps.showPlanSummary();
            }}
          >
            {props.renderProps.incentive}
          </button>
          {errors.length > 0 && (
            <div>
              <button
                onClick={() => {
                  props.renderProps.showErrors();
                }}
              >
                <FontAwesomeIcon style={{ color: "red" }} size={"2x"} icon={faExclamationTriangle} data-for="closeModal" data-tip="react-tooltip" />
                <div style={{ margin: "10px", display: "inline-block" }}>{"Errors While Generating Rules!"}</div>
              </button>
            </div>
          )}
          {errors.length <= 0 && warnings.length > 0 && (
            <div>
              <button
                onClick={() => {
                  props.renderProps.showErrors();
                }}
              >
                <FontAwesomeIcon style={{ color: "yellow" }} size={"2x"} icon={faExclamationTriangle} data-for="closeModal" data-tip="react-tooltip" />
                <div style={{ margin: "10px", display: "inline-block" }}>{"Warnings While Generating Rules!"}</div>
              </button>
            </div>
          )}
        </h5>
      </div>
      <div>
        {props.renderProps.data && props.renderProps.data.CreditRules && (
          <RenderCreditRules
            renderProps={{
              columns: props.renderProps.col.CreditRuleColumns,
              data: props.renderProps.data.CreditRules,
              formulas: props.renderProps.data.FormulaData,
              showPagination: false,
            }}
          />
        )}
      </div>
      <div>
        {props.renderProps.data && props.renderProps.data.CommissionRules && (
          <RenderCommissionRules
            renderProps={{
              columns: props.renderProps.col.CommissionRuleColumns,
              data: props.renderProps.data.CommissionRules,
              formulas: props.renderProps.data.FormulaData,
              showPagination: false,
            }}
          />
        )}
      </div>
      <div>
        {props.renderProps.data && props.renderProps.data.FormulaData && (
          <RenderGeneric renderProps={{ columns: props.renderProps.col.FormulaColumns, data: props.renderProps.data.FormulaData, showPagination: true }} />
        )}
      </div>
      <div>
        {props.renderProps.data &&
          props.renderProps.data.LookupTables &&
          props.renderProps.data.LookupTables.length > 0 &&
          props.renderProps.data.LookupTables.map(table => (
            <div key={table.name}>
              <RenderLookupTables
                renderProps={{
                  name: table.name,
                  columns: table.versionData[0].dimensions,
                  data: table.versionData[0].tableData,
                  showPagination: false,
                }}
              />
            </div>
          ))}
      </div>
      <div className=" row">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div>
            {props.renderProps.data && props.renderProps.data.ConfigObjects && props.renderProps.data.ConfigObjects.RateTable && props.renderProps.data.ConfigObjects.RateTable.length > 0 && (
              <RenderRateTables
                renderProps={{
                  name: props.renderProps.data.ConfigObjects.RateTable[0].Name,
                  columns: props.renderProps.data.ConfigObjects.RateTable[0].data.columns,
                  data: props.renderProps.data.ConfigObjects.RateTable[0].data.data,
                  formulas: props.renderProps.data.FormulaData,
                  lkpTableName: props.renderProps.data.ConfigObjects.RateTable[0].lkpTableName,
                  showPagination: false,
                }}
              />
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div>
            {props.renderProps.data && props.renderProps.data.ConfigObjects && props.renderProps.data.ConfigObjects.PayCurve && props.renderProps.data.ConfigObjects.PayCurve.length > 0 && (
              <div>
                {props.renderProps.data.ConfigObjects.PayCurve.map(tab => (
                  <div key={tab.name}>
                    <RenderGeneric
                      renderProps={{
                        data: tab.data[0].payCurveVersionWSOList[0].payCurveDetailWSOList,
                        columns: [{ Header: tab.Name, columns: props.renderProps.col.PayCurveColumns }],
                        showPagination: false,
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div>
            {props.renderProps.data &&
              props.renderProps.data.ConfigObjects &&
              props.renderProps.data.ConfigObjects.QuotaRelationships &&
              props.renderProps.data.ConfigObjects.QuotaRelationships.length > 0 && (
                <div>
                  {props.renderProps.data.ConfigObjects.QuotaRelationships.map(tab => (
                    <div key={tab.Name}>
                      <RenderGeneric
                        renderProps={{
                          data: tab.data.data.map(row => ({ Assignment: row.Assignment, Weight: row.Weight })),
                          columns: [{ Header: tab.Name, columns: props.renderProps.col.QuotaRelationshipColumns }],
                          showPagination: false,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderWithTooltip = ({ id = "", value = "", tooltipText = "", placement = "top" }) => {
  if (!tooltipText || tooltipText.length === 0) return <>{value}</>;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={id} style={{ wordBreak: "break-word" }}>
          <strong>{tooltipText}</strong>
        </Tooltip>
      }
    >
      <span>{value}</span>
    </OverlayTrigger>
  );
};
const getFormulaExpression = (formulas, formulaName) => {
  if (formulas && formulas.length > 0) {
    const searchFormula = formulas.find(elem => elem.FormulaName === formulaName);
    return (searchFormula && searchFormula.FormulaExpression) || "";
  }
  return "";
};

class ConfigurationDashboard extends TableInput {
  constructor(props) {
    super(props);
    this.initialColumns = [];
    this.initialData = [];
    this.projectId = store.get("user")[store.get("currentUser")]["projectId"];
    this.customerId = store.get("user")[store.get("currentUser")]["customerId"];
    this.state = {
      RenderComponent: RenderIncentive,
      renderComponentProps: {
        columns: [],
        data: [],
      },
      CreditRuleColumns: [
        {
          Header: "CREDIT RULES",
          columns: [
            { Header: "Name", accessor: "RuleName", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Type", accessor: "RuleType", maxWidth: 150, style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Credit Type", accessor: "CreditType", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
          ],
        },
      ],
      CommissionRuleColumns: [
        {
          Header: "COMMISSION RULES",
          columns: [
            { Header: "Name", accessor: "RuleName", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Earning Group", accessor: "EarningGroup", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Quota", accessor: "Quota", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Quota Period", accessor: "QuotaPeriod", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Rate Type", accessor: "RateTypeName", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Attainment Measure", accessor: "AttainmentMeasure", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Multiplier", accessor: "Multiplier", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
          ],
        },
      ],
      FormulaColumns: [
        {
          Header: "FORMULAS",
          columns: [
            { Header: "Name", accessor: "FormulaName", maxWidth: 350, style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Type", accessor: "FormulaType", maxWidth: 350, style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },

            {
              Header: "Expression",
              accessor: "FormulaExpression",
              style: { whiteSpace: "unset", overflow: "hidden" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],
      data: {},
      dataReady: false,
      dataLoadingText: "Generating Rules...",
      TitleColumns: [
        {
          Header: "Titles",
          columns: [{ Header: "Name", accessor: "Name", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) }],
        },
      ],
      CustomFieldsColumns: [
        {
          Header: "CustomFields",
          columns: [
            { Header: "Name", accessor: "Name", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Data Type", accessor: "DataTypeName", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Type", accessor: "Type", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
          ],
        },
      ],
      CreditTypeColumns: [
        {
          Header: "Credit Types",
          columns: [
            {
              Header: "Name",
              accessor: "Name",
              style: { whiteSpace: "unset", overflow: "hidden" },
              props: { type: "text", obj: "credit_type", confirmation: true, confirmationMessage: "This action will update all dependent objects. Are you sure?" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],
      QuotaColumns: [
        {
          Header: "Quotas",
          columns: [
            {
              Header: "Name",
              accessor: "Name",
              style: { whiteSpace: "unset", overflow: "hidden" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
            {
              Header: "UnitType",
              accessor: "UnitType",
              style: { whiteSpace: "unset", overflow: "hidden" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],
      EarningGroupColumns: [
        {
          Header: "Earning Groups",
          columns: [
            {
              Header: "Name",
              accessor: "Name",
              style: { whiteSpace: "unset", overflow: "hidden" },
              props: { type: "text", obj: "earning_group", confirmation: true, confirmationMessage: "This action will update all dependent objects. Are you sure?" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],

      OrderTypeColumns: [
        {
          Header: "Order Types",
          columns: [
            {
              Header: "Name",
              accessor: "Name",
              style: { whiteSpace: "unset", overflow: "hidden" },
              props: { type: "text", obj: "order_type", confirmation: true, confirmationMessage: "This action will update all dependent objects. Are you sure?" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],
      UnitTypeColumns: [
        {
          Header: "Unit Types",
          columns: [{ Header: "Name", accessor: "Name", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) }],
        },
      ],
      AttainmentMeasureColumns: [
        {
          Header: "Attainment Measures",
          columns: [
            { Header: "Name", accessor: "Name", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Credit Name", accessor: "Credit", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            { Header: "Period", accessor: "PeriodType", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
          ],
        },
      ],
      PlanColumns: [
        {
          Header: "Plans",
          columns: [
            {
              Header: "Name",
              accessor: "Name",
              style: { whiteSpace: "unset", overflow: "hidden" },
              props: { type: "text", obj: "plan", confirmation: true, confirmationMessage: "This action will update all dependent objects. Are you sure?" },
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],
      PlanAssignmentColumns: [
        {
          Header: "Plan Assignment",
          columns: [
            { Header: "Plan", accessor: "PlanName", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
            {
              Header: "Roles",
              accessor: "Roles",
              style: { whiteSpace: "unset", overflow: "hidden" },
              aggregate: vals => vals[0],
              Aggregated: row => <span>{row.value} .....</span>,
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
            {
              Header: "Rules",
              accessor: "Rules",
              style: { whiteSpace: "unset", overflow: "hidden" },
              aggregate: vals => vals[0],
              Aggregated: row => <span>{row.value} .....</span>,
              Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            },
          ],
        },
      ],

      PayCurveColumns: [
        { Header: "Quota Attainment", accessor: "quotaAttainment", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
        {
          Header: "Target Income",
          accessor: "targetIncome",
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      ],
      QuotaRelationshipColumns: [
        { Header: "Assignment", accessor: "Assignment", style: { whiteSpace: "unset", overflow: "hidden" }, Filter: ({ filter, onChange }) => FilterElement(filter, onChange) },
        {
          Header: "Weight",
          accessor: "Weight",
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      ],
      showComponentSummary: false,
      errors: [],
      showErrorsModal: false,
      showExportConfigOptions: false,
      selectedExportOptions: [],
      selectedIncentives: [],
    };
    this.Project = "";
  }
  updateParentSubmitButton = status => {
    return true;
  };
  componentDidMount() {
    //this.getProjectDetails();
    server.getGeneratedRules(this.customerId, this.projectId, response => {
      if (response && response.status === 200) {
        this.Project = response.data.object.answers[0];
        console.log("*** this is the response from getRules *****");
        console.log(response.data.object.rules);
        if (response.data.object.rules.results) {
          //Create csv and excel files for demo users, people, position and orders

          //if Quota relationships then create new tab in the navigation.
          if (
            response.data.object.rules.results.ConfigObjects &&
            response.data.object.rules.results.ConfigObjects.QuotaRelationships &&
            response.data.object.rules.results.ConfigObjects.QuotaRelationships.length > 0
          ) {
            this.props.setEnvironmentSpecificConfigVisibility(true);
            localStorage.setItem("showEnvironmentSpecificConfig", true);
          } else {
            this.props.setEnvironmentSpecificConfigVisibility(false);
            localStorage.removeItem("showEnvironmentSpecificConfig", false);
          }
          let planRules = { CreditRules: response.data.object.rules.results.CreditRules, CommissionRules: response.data.object.rules.results.CommissionRules };
          let inc = Object.keys(response.data.object.rules.results.ConfigPerIncentive)[0];
          let genericErrors = response.data.object.rules.errors["Generic"] ? response.data.object.rules.errors["Generic"] : [];
          let incentiveErrors = response.data.object.rules.errors[inc] ? response.data.object.rules.errors[inc] : [];
          this.setState({
            dataReady: true,
            // renderComponentProps: { columns: this.state.CreditRuleColumns, data: response.data.object.rules.results.CreditRules },
            errors: [...genericErrors, ...incentiveErrors],
            renderComponentProps: {
              errors: response.data.object.rules.errors[inc],
              data: response.data.object.rules.results.ConfigPerIncentive[inc],
              col: this.state,
              incentive: getNameFromAllObjects(inc, response.data.object.answers[0].AllLists.AllObjects),
              showPlanSummary: () => {
                this.setState({ showComponentSummary: true, selectedIncentive: inc });
              },
              showErrors: () => {
                this.setState({ showErrorsModal: true, selectedIncentive: inc });
              },
            },
            data: {
              errors: response.data.object.rules.errors,
              credit: response.data.object.rules.results.CreditRules,
              commission: response.data.object.rules.results.CommissionRules,
              formula: response.data.object.rules.results.FormulaData,
              answers: response.data.object.answers,
              credit_type: response.data.object.rules.results.ConfigObjects.CreditType,
              order_type: response.data.object.rules.results.ConfigObjects.OrderType,
              batch_type: response.data.object.rules.results.ConfigObjects.BatchType,
              unit_type: response.data.object.rules.results.ConfigObjects.UnitType,
              earning_group: response.data.object.rules.results.ConfigObjects.EarningGroup,
              title: response.data.object.rules.results.ConfigObjects.Title,
              attainment_measure: response.data.object.rules.results.ConfigObjects.AttainmentMeasure,
              rate_table: response.data.object.rules.results.ConfigObjects.RateTable,
              pay_curve: response.data.object.rules.results.ConfigObjects.PayCurve,
              quota: response.data.object.rules.results.ConfigObjects.Quota,
              plan: response.data.object.rules.results.Plans,
              planAssignment: response.data.object.rules.results.PlanAssignment,
              lookupTable: response.data.object.rules.results.LookupTables,
              namedRelationship: response.data.object.rules.results.ConfigObjects.NamedRelationship,
              customFields: response.data.object.rules.results.CustomFields,
              externalProcess: response.data.object.rules.results.ExternalProcesses,
              processGroup: response.data.object.rules.results.ProcessGroups,
              calendar: response.data.object.rules.results.Calendar,
              rules: planRules,
              config_per_incentive: response.data.object.rules.results.ConfigPerIncentive,
              quotaRelationship: response.data.object.rules.results.ConfigObjects.QuotaRelationships,
            },
          });
        } else {
          this.setState({
            dataReady: true,
          });
        }
      }
    });
  }
  getProjectDetails = () => {
    server.getProjectDetails(this.customerId, this.projectId, response => {
      this.Project = response.data.object[0];
    });
  };
  handleNavSelect = props => {
    this.setState({
      RenderComponent: props.component,
      renderComponentProps: props.props,
    });
  };
  getConfigByIncentive = (list, incentiveId) => {
    const filteredData = list.filter(item => {
      if (item && item.sourceData && item.sourceData.Incentive && item.sourceData.Incentive === getNameFromAllObjects(incentiveId, this.Project.AllLists.AllObjects)) {
        return true;
      } else return false;
    });
    if (filteredData && filteredData.length) {
      return filteredData;
    } else {
      return [];
    }
  };
  getRuleXML = () => {
    this.setState({
      dataReady: false,
      dataLoadingText: "Generating XML...",
    });
    let rulesData = {
      formula: this.state.data.formula,
      credit_type: this.state.data.credit_type,
      order_type: this.state.data.order_type,
      batch_type: this.state.data.batch_type,
      earning_group: this.state.data.earning_group,
      unit_type: this.state.data.unit_type,
      title: this.state.data.title,
      attainment_measure: this.state.data.attainment_measure,
      rate_table: this.state.data.rate_table,
      pay_curve: this.state.data.pay_curve,
      quota: this.state.data.quota,
      plan: this.state.data.plan,
      lookupTable: this.state.data.lookupTable,
      namedRelationship: this.state.data.namedRelationship,
      customFields: this.state.data.customFields,
      externalProcess: this.state.data.externalProcess,
      processGroup: this.state.data.processGroup,
      calendar: this.state.data.calendar,
      rule: this.state.data.rules,
    };

    const ifSelectedAll =
      Object.keys(this.state.data.config_per_incentive).length === this.state.selectedIncentives.length && CommonResources.XmlExportConfigOptions.length === this.state.selectedExportOptions.length;
    let isExistingImplementation = false;
    if (ifSelectedAll) {
      isExistingImplementation = false;
    } else {
      isExistingImplementation = this.Project && this.Project.GlobalVariables && this.Project.GlobalVariables.IsExistingImplementation;
    }

    if (Object.keys(this.state.data.config_per_incentive).length > this.state.selectedIncentives.length) {
      rulesData = {
        ...rulesData,
        formula: [],
        lookupTable: [],
        rule: { CreditRules: [], CommissionRules: [] },
        attainment_measure: [],
        earning_group: [],
        quota: [],
        pay_curve: [],
        rate_table: [],
        credit_type: [],
        order_type: [],
        batch_type: [],
      };
      this.state.selectedIncentives.forEach(incentiveId => {
        rulesData.formula.push(...this.state.data.config_per_incentive[incentiveId].FormulaData);
        rulesData.lookupTable.push(...this.state.data.config_per_incentive[incentiveId].LookupTables);
        rulesData.rule.CreditRules.push(...this.getConfigByIncentive(this.state.data.credit, incentiveId));
        rulesData.rule.CommissionRules.push(...this.getConfigByIncentive(this.state.data.commission, incentiveId));

        const { AttainmentMeasure = [], EarningGroup = [], Quota = [], RateTable = [], PayCurve = [] } = this.state.data.config_per_incentive[incentiveId].ConfigObjects;

        rulesData.attainment_measure.push(...AttainmentMeasure);
        rulesData.earning_group.push(...EarningGroup);
        rulesData.quota.push(...Quota);
        rulesData.pay_curve.push(...PayCurve);
        rulesData.rate_table.push(...RateTable);

        // filter object types given selected incentive.
        rulesData.credit_type.push(...this.getConfigByIncentive(this.state.data.credit_type, incentiveId));
        rulesData.order_type.push(...this.getConfigByIncentive(this.state.data.order_type, incentiveId));
        rulesData.batch_type.push(...this.getConfigByIncentive(this.state.data.batch_type, incentiveId));
      });
    }

    if (isExistingImplementation) {
      delete rulesData["calendar"];
      delete rulesData["externalProcess"];
      delete rulesData["processGroup"];
      delete rulesData["namedRelationship"];
    } else {
      this.state.selectedExportOptions.forEach(key => {
        if (this.state.data[key]) {
          rulesData[key] = this.state.data[key];
        } else if (key === "creditRule") {
          rulesData.rule.CreditRules = this.state.data.credit;
        } else if (key === "commissionRule") {
          rulesData.rule.CommissionRules = this.state.data.commission;
        }
      });
    }
    const resultData = { customerId: this.customerId, projectId: this.projectId };
    Object.keys(rulesData).forEach(key => {
      if (Array.isArray(rulesData[key]) && rulesData[key].length > 0) {
        resultData[key] = rulesData[key];
      } else if (typeof rulesData[key] === "object" && !Array.isArray(rulesData[key]) && rulesData[key] !== null && Object.keys(rulesData[key]).length) {
        resultData[key] = rulesData[key];
      }
    });
    server.getRulesXMLFromJSON(resultData, { customerId: this.customerId, projectId: this.projectId }, response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "GeneratedRules.zip");
      document.body.appendChild(link);
      link.click();
      this.setState({
        dataReady: true,
      });
    });
  };
  getTestData = () => {
    server.getTestData(this.customerId, this.projectId, response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers["x-suggested-filename"]);
      document.body.appendChild(link);
      link.click();
      this.setState({
        dataReady: true,
      });
    });
  };
  getAppendix = () => {
    this.setState({
      dataReady: false,
      dataLoadingText: "Generating Appendix...",
    });
    server.getAppendix(this.customerId, this.projectId, response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers["x-suggested-filename"]);
      document.body.appendChild(link);
      link.click();
      this.setState({
        dataReady: true,
      });
    });
  };

  handleQuestionSelect = qid => {
    localStorage.setItem("lastRendered", qid);
    this.props.history.replace("/projects");
  };
  handleSelect = qid => {
    localStorage.setItem("lastRendered", qid);
    this.props.history.replace("/projects");
    this.state.showErrorsModal = false;
  };

  changeHandler = evt => {
    let selectedOptArr = [];
    if (evt.target.name === "Components") {
      selectedOptArr = [...this.state.selectedIncentives];
    } else if (evt.target.name === "ConfigItmes") {
      selectedOptArr = [...this.state.selectedExportOptions];
    }

    var index = selectedOptArr.indexOf(evt.target.value);
    if (index >= 0) {
      selectedOptArr.splice(index, 1);
    } else {
      selectedOptArr.push(evt.target.value);
    }

    if (evt.target.name === "Components") {
      this.setState({
        selectedIncentives: selectedOptArr,
      });
    } else if (evt.target.name === "ConfigItmes") {
      this.setState({
        selectedExportOptions: selectedOptArr,
      });
    }
  };

  handleSelectAll = evt => {
    if (evt.target.checked) {
      this.setState({
        selectedIncentives: Object.keys(this.state.data.config_per_incentive),
        selectedExportOptions: CommonResources.XmlExportConfigOptions.map(item => item.value),
      });
    } else {
      this.setState({
        selectedIncentives: [],
        selectedExportOptions: [],
      });
    }
  };

  render() {
    const {
      RenderComponent,
      renderComponentProps,
      data,
      CreditRuleColumns,
      CommissionRuleColumns,
      FormulaColumns,
      TitleColumns,
      CreditTypeColumns,
      UnitTypeColumns,
      AttainmentMeasureColumns,
      OrderTypeColumns,
      PlanAssignmentColumns,
      EarningGroupColumns,
      CustomFieldsColumns,
      QuotaColumns,
      PayCurveColumns,
      QuotaRelationshipColumns,
    } = this.state;
    //const AllObjects = this.Project ? this.Project.AllLists.AllObjects : {};
    const isExistingImplementation = this.Project && this.Project.GlobalVariables && this.Project.GlobalVariables.IsExistingImplementation;

    return (
      <div className="RuleMenu" style={{ margin: "20px  0px 0px 0px" }}>
        <LoadingOverlay
          active={!this.state.dataReady}
          spinner
          text={this.state.dataLoadingText}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
              position: "absolute",
              top: 0,
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <Navbar>
            <Navbar.Toggle aria-controls="basic-navbar-nav2" />
            <Navbar.Collapse id="basic-navbar-nav2">
              <Nav>
                <Fragment>
                  <NavItem onClick={e => this.handleNavSelect({ component: RenderCreditRules, props: { columns: CreditRuleColumns, data: data.credit, formulas: data.formula } })}>Credits</NavItem>
                  <NavItem onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: FormulaColumns, data: data.formula } })}>Formulas</NavItem>
                  <NavItem onClick={e => this.handleNavSelect({ component: RenderCommissionRules, props: { columns: CommissionRuleColumns, data: data.commission, formulas: data.formula } })}>
                    Commissions
                  </NavItem>
                  <NavItem onClick={e => this.handleNavSelect({ component: RenderPlans, props: { columns: PlanAssignmentColumns, data: data.planAssignment } })}>Plan Assignment</NavItem>
                  {data.rate_table && data.rate_table.length > 0 && (
                    <NavDropdown key="RateTables" title="Rate Tables" id="RateTables">
                      {data.rate_table.map(tab => {
                        return (
                          <MenuItem
                            eventKey={tab.Name}
                            key={tab.Name}
                            onClick={e =>
                              this.handleNavSelect({
                                component: RenderRateTables,
                                props: { columns: tab.data.columns, data: tab.data.data, formulas: data.formula, name: tab.Name, lkpTableName: tab.lkpTableName },
                              })
                            }
                          >
                            {tab.Name}
                          </MenuItem>
                        );
                      })}
                    </NavDropdown>
                  )}
                  {data.lookupTable && data.lookupTable.length > 0 && (
                    <NavDropdown key="LookupTables" title="LookupTables" id="LookupTables">
                      {data.lookupTable.map(tab => {
                        return (
                          <MenuItem
                            eventKey={tab.name}
                            key={tab.name}
                            onClick={e =>
                              this.handleNavSelect({
                                component: RenderLookupTables,
                                props: { name: tab.name, columns: tab.versionData[0].dimensions, data: tab.versionData[0].tableData },
                              })
                            }
                          >
                            {tab.name}
                          </MenuItem>
                        );
                      })}
                    </NavDropdown>
                  )}
                  {data.pay_curve && data.pay_curve.length > 0 && (
                    <NavDropdown key="PayCurves" title="PayCurves" id="PayCurves">
                      {data.pay_curve.map(tab => {
                        return (
                          <MenuItem
                            eventKey={tab.Name}
                            key={tab.Name}
                            onClick={e =>
                              this.handleNavSelect({
                                component: RenderGeneric,
                                props: {
                                  name: tab.Name,
                                  data: tab.data[0].payCurveVersionWSOList[0].payCurveDetailWSOList,
                                  columns: [{ Header: tab.Name, columns: PayCurveColumns }],
                                  defaultPageSize: 5,
                                },
                              })
                            }
                          >
                            {tab.Name}
                          </MenuItem>
                        );
                      })}
                    </NavDropdown>
                  )}
                  <NavDropdown key="ConfigItems" title="ConfigItems" id="ConfigItems">
                    <MenuItem eventkey="Titles" key="Titles" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: TitleColumns, data: data.title } })}>
                      Titles
                    </MenuItem>
                    <MenuItem eventkey="Units" key="Units" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: UnitTypeColumns, data: data.unit_type } })}>
                      Unit Types
                    </MenuItem>
                    <MenuItem eventkey="credit_type" key="credit_type" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: CreditTypeColumns, data: data.credit_type } })}>
                      Credit Types
                    </MenuItem>
                    <MenuItem eventkey="order_type " key="order_type" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: OrderTypeColumns, data: data.order_type } })}>
                      Order Types
                    </MenuItem>
                    <MenuItem
                      eventkey="attain"
                      key="attain"
                      onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: AttainmentMeasureColumns, data: data.attainment_measure } })}
                    >
                      Attainment Measure
                    </MenuItem>
                    <MenuItem eventkey="eg" key="eg" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: EarningGroupColumns, data: data.earning_group } })}>
                      Earning Group
                    </MenuItem>
                    <MenuItem eventkey="fields" key="fields" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: CustomFieldsColumns, data: data.customFields } })}>
                      Custom Fields
                    </MenuItem>
                    <MenuItem eventkey="quota" key="quota" onClick={e => this.handleNavSelect({ component: RenderGeneric, props: { columns: QuotaColumns, data: data.quota } })}>
                      Quotas
                    </MenuItem>
                    {data.quotaRelationship && data.quotaRelationship.length > 0 && (
                      <NavDropdown key="quotaRelationships" title="Quota Relationships" id="quotaRelationships">
                        {data.quotaRelationship.map(tab => {
                          return (
                            <MenuItem
                              eventkey={tab.Name}
                              key={tab.Name}
                              onClick={e =>
                                this.handleNavSelect({
                                  component: RenderGeneric,
                                  props: {
                                    columns: [{ Header: tab.Name, columns: QuotaRelationshipColumns }],
                                    data: tab.data.data.map(row => ({ Assignment: row.Assignment, Weight: row.Weight })),
                                  },
                                })
                              }
                            >
                              {tab.Name}
                            </MenuItem>
                          );
                        })}
                      </NavDropdown>
                    )}
                  </NavDropdown>
                  {data.config_per_incentive && (
                    <NavDropdown key="ConfigPerIncentive" title={<b>Incentive Level Config</b>} id="ConfigPerIncentive">
                      {Object.keys(data.config_per_incentive).length > 0
                        ? Object.keys(data.config_per_incentive).map(incentive => {
                            return (
                              <MenuItem
                                eventKey={getNameFromAllObjects(incentive, this.Project.AllLists.AllObjects)}
                                key={getNameFromAllObjects(incentive, this.Project.AllLists.AllObjects)}
                                onClick={e =>
                                  this.handleNavSelect({
                                    component: RenderIncentive,
                                    props: {
                                      data: data.config_per_incentive[incentive],
                                      col: this.state,
                                      incentive: getNameFromAllObjects(incentive, this.Project.AllLists.AllObjects),
                                      errors: data.errors[incentive],
                                      showPlanSummary: () => {
                                        this.setState({ showComponentSummary: true, selectedIncentive: incentive });
                                      },
                                      showErrors: () => {
                                        this.setState({ showErrorsModal: true, selectedIncentive: incentive, errors: data.errors[incentive] });
                                      },
                                    },
                                  })
                                }
                              >
                                {getNameFromAllObjects(incentive, this.Project.AllLists.AllObjects)}
                                {data.errors[incentive].filter(item => item.type === "ERROR").length > 0 ? (
                                  <FontAwesomeIcon style={{ color: "red" }} size={"1x"} icon={faExclamationTriangle} data-for="closeModal" data-tip="react-tooltip" />
                                ) : data.errors[incentive].filter(item => item.type === "WARNING").length > 0 ? (
                                  <FontAwesomeIcon style={{ color: "yellow" }} size={"1x"} icon={faExclamationTriangle} data-for="closeModal" data-tip="react-tooltip" />
                                ) : (
                                  <span />
                                )}
                              </MenuItem>
                            );
                          })
                        : ""}
                    </NavDropdown>
                  )}
                </Fragment>
              </Nav>

              <Nav pullRight>
                <NavDropdown key="Export" title="Export" id="Export">
                  <MenuItem eventkey="config" key="config" onClick={() => this.setState({ showExportConfigOptions: true, selectedExportOptions: [], selectedIncentives: [] })}>
                    Export Configuration
                  </MenuItem>
                  <MenuItem eventkey="config" key="config" onClick={this.getTestData}>
                    Export Test Data
                  </MenuItem>
                  <MenuItem eventkey="config" key="config" onClick={this.getAppendix}>
                    Export Appendix
                  </MenuItem>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <RenderComponent renderProps={renderComponentProps} />
          <div id="componentSummary">
            <Modal
              isOpen={this.state.showComponentSummary}
              contentLabel="Component Summary"
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              onRequestClose={() => {
                this.setState({ showComponentSummary: false });
              }}
              style={{
                content: {
                  top: "40px",
                  left: "40px",
                  height: "80%",
                },
              }}
            >
              <ComponentSummary
                lists={this.Project.AllLists}
                params={{ level: "Incentive", value: this.state.selectedIncentive }}
                SendQuestionPath={this.handleQuestionSelect} //<TODO>: need to test
                closeModal={() => {
                  this.setState({ showComponentSummary: false });
                }}
              ></ComponentSummary>
            </Modal>
          </div>
          <div id="errorsModal">
            <Modal
              isOpen={this.state.showErrorsModal}
              contentLabel="Errors Summary"
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              onRequestClose={() => {
                this.setState({ showErrorsModal: false });
              }}
              style={{
                content: {
                  top: "40px",
                  left: "40px",
                  height: "80%",
                },
              }}
            >
              <div>
                {" "}
                <FontAwesomeIcon
                  size={"1x"}
                  onClick={() => {
                    this.setState({ showErrorsModal: false });
                  }}
                  icon={faWindowClose}
                  data-for="closeModal"
                  data-tip="react-tooltip"
                />
              </div>
              <div style={{ padding: "20px" }}>
                {this.state.errors &&
                  this.state.errors.map((item, index) => {
                    if (item.type === "ERROR") {
                      return (
                        <div>
                          {index > 0 && (
                            <hr
                              style={{
                                color: "#000000",
                                backgroundColor: "#000000",
                                height: 0.5,
                                borderColor: "#000000",
                              }}
                            />
                          )}
                          <p style={{ color: "red" }}>{`${item.type}:  `}</p>
                          {item.path ? <a onClick={e => this.handleSelect(item.path)}>{`${item.msg}`}</a> : <div>{item.msg}</div>}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          {index > 0 && (
                            <hr
                              style={{
                                color: "#000000",
                                backgroundColor: "#000000",
                                height: 0.5,
                                borderColor: "#000000",
                              }}
                            />
                          )}
                          <p style={{ color: "yellow" }}>{`${item.type}:  `}</p>
                          {item.path ? <a onClick={e => this.handleSelect(item.path)}>{`${item.msg}`}</a> : <div>{item.msg}</div>}
                        </div>
                      );
                    }
                  })}
              </div>
            </Modal>
          </div>
          <div id="ExportConfigOptions">
            <Modal
              isOpen={this.state.showExportConfigOptions}
              contentLabel="Incent Export Config"
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              onRequestClose={() => {
                this.setState({ showExportConfigOptions: false });
              }}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  width: "80%",
                  height: "80%",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                },
              }}
            >
              <div className="SubSection">
                <h4>Export Configuration Options</h4>
                <div>
                  <span style={{ fontWeight: "bold" }}>Select All</span>
                  {data.config_per_incentive && (
                    <Checkbox
                      key="AllConfig"
                      name="AllConfig"
                      value="AllConfig"
                      onChange={this.handleSelectAll}
                      checked={
                        Object.keys(data.config_per_incentive).length === this.state.selectedIncentives.length &&
                        CommonResources.XmlExportConfigOptions.length === this.state.selectedExportOptions.length
                      }
                      style={{ paddingLeft: "12px" }}
                    >
                      All Configuration Options
                    </Checkbox>
                  )}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Components</span>
                  {data.config_per_incentive &&
                    Object.keys(data.config_per_incentive).length > 0 &&
                    Object.keys(data.config_per_incentive).map(incentive => {
                      return (
                        <Checkbox
                          key={incentive}
                          name="Components"
                          value={incentive}
                          onChange={this.changeHandler}
                          checked={this.state.selectedIncentives.includes(incentive)}
                          style={{ paddingLeft: "12px" }}
                        >
                          {getNameFromAllObjects(incentive, this.Project.AllLists.AllObjects)}
                        </Checkbox>
                      );
                    })}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Config Itmes</span>
                  {CommonResources.XmlExportConfigOptions.map(item => {
                    return (
                      <Checkbox
                        key={item.value}
                        name="ConfigItmes"
                        value={item.value}
                        onChange={this.changeHandler}
                        checked={this.state.selectedExportOptions.includes(item.value)}
                        style={{ paddingLeft: "12px" }}
                        disabled={isExistingImplementation && this.state.selectedIncentives.length > 0}
                      >
                        {item.name}
                      </Checkbox>
                    );
                  })}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    if (this.state.selectedIncentives.length > 0) {
                      this.getRuleXML();
                      this.setState({ showExportConfigOptions: false });
                    } else {
                      if (this.state.selectedIncentives.length < 1) {
                        alert("Components required!");
                      }
                    }
                  }}
                >
                  Export Configuration
                </button>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default ConfigurationDashboard;
