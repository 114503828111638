import React, { useState, useEffect } from "react";
import store from "store";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Moment from "react-moment";
import Server from "./Server";
import Websocket from "react-websocket";
import { useForm } from "react-hook-form";
import { WS_ROOT, NOTIFICATION_TIMEOUT } from "./api-config";
import { store as NotificationStore } from "react-notifications-component";
import classes from "./importSFDCMetadata.module.css";
import ConfirmDialog from "../components/ConfirmDialog";

const JOB_TYPES = { TEST_CASE_CREATION: "TestCaseCreationJob", TEST_DATA_VALIDATION: "TestDataValidationJob", TEST_DATA_CREATION: "TestDataCreationJob" };
const server = new Server();
const MAX_ROW = 10;
const DateCell = ({ value: initialValue, row: { index }, column: { id } }) => {
  if (initialValue) {
    let dateValue = new Date(initialValue);
    return <Moment format="YYYY/MM/DD HH:mm:ss">{dateValue}</Moment>;
  } else {
    return "";
  }
};

const SITAutomationStatus = () => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [sitJobs, setSITJobs] = useState([]);
  const [allowSITRequest, setAllowSITRequest] = useState(true);
  const [dataReady, setDataReadyFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [overlayText, setOverlayText] = useState("Loading job status");
  const [showConnectInfo, setShowConnectInfo] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [jobType, setJobType] = useState(JOB_TYPES.TEST_CASE_CREATION);
  const [showCustomURL, setShowCustomURL] = useState(false);
  const [modalHeight, setModalHeight] = useState(500);

  const connectEnvOptions = [
    "implement1.xactlycorp.com",
    "euimplement1.xactlycorp.com",
    "sandbox.xactlycorp.com",
    "sandbox3.xactlycorp.com",
    "sandbox4.xactlycorp.com",
    "eusb1.xactlycorp.com",
    "demo.xactlycorp.com",
    "training.xactlycorp.com",
    "other",
  ];
  const customURLPatterns = [/implement[a-zA-Z0-9]+\.xactlycorp\.com/, /sandbox[a-zA-Z0-9]+\.xactlycorp\.com/, /euimplement[a-zA-Z0-9]+\.xactlycorp\.com/, /eusb[a-zA-Z0-9]+\.xactlycorp\.com/];

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({});

  const downloadResult = (customer, project, requestId, jobType) => {
    setDataReadyFlag(false);
    setOverlayText("Downloading Result...");
    server.downloadJobResult(customer, project, requestId, jobType, response => {
      if (response && response.status === 200) {
        const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers["x-suggested-filename"]);
        document.body.appendChild(link);
        link.click();
      }
      setDataReadyFlag(true);
    });
  };

  const dataColumns = [
    { Header: "User", accessor: "userName" },
    { Header: "Request Type", accessor: "jobType" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ original }) => {
        let statusTxt = original.isComplete ? (original.isSuccess ? "Completed Successfully" : "Completed with Failure") : "In Progress";
        if (original.isComplete) {
          return (
            <a className="Clickable" onClick={e => downloadResult(original.customerId, original.projectId, original.requestId, original.jobType)}>
              {statusTxt}
            </a>
          );
        } else {
          return statusTxt;
        }
      },
    },
    { Header: "Start Time", accessor: "executionStartTime", Cell: DateCell },
    { Header: "End Time", accessor: "executionEndTime", Cell: DateCell },
  ];

  const submitJob = (jobType, data = {}) => {
    setDataReadyFlag(false);
    server.submitJob(customerId, projectId, jobType, data, response => {
      setDataReadyFlag(true);
      if (response.status != 200 && response.status != 201) {
        if (response.status === 400) {
          setError("connectionInfo.user", { type: "serverError", message: response.data.error.messages });
          setError("connectionInfo.password", { type: "serverError", message: response.data.error.messages });
          setError("connectionInfo.env", { type: "serverError", message: response.data.error.messages });
          if (data.connectionInfo.env === "other") {
            setError("connectionInfo.customURL", { type: "serverError", message: response.data.error.messages });
            setModalHeight(700);
          } else {
            setModalHeight(600);
          }
        } else {
          setError("connectionInfo.error", { type: "serverError", message: response.data.error.messages });
        }
      } else {
        setShowConnectInfo(false);
        setShow(false);
      }
    });
  };

  const handleNotification = data => {
    data = JSON.parse(data);
    if (data.isComplete) {
      let objIndex = sitJobs.findIndex(obj => obj.requestId == data.requestId);
      let newList = [...sitJobs];
      newList[objIndex] = data;
      let enableButton = true;
      newList.forEach(job => {
        enableButton = enableButton && job.isComplete;
      });
      setAllowSITRequest(enableButton);
      setSITJobs(newList);
    } else {
      let newJob = [data, ...sitJobs];
      setAllowSITRequest(false);
      setSITJobs(newJob);
    }
    let title = "";
    let message_suffix = data.isComplete ? "completed" + (data.isSuccess ? "." : " with Error.") : "started.";
    let message = `${data.jobType} for ${data.customerName} / ${data.project.name} (${data.project.sow}) ${message_suffix}`;
    let type = data.isSuccess ? "info" : "warning";
    NotificationStore.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: NOTIFICATION_TIMEOUT,
        onScreen: true,
        showIcon: true,
      },
    });
  };

  //get the Job List
  useEffect(() => {
    server.getJobs(customerId, projectId, Object.values(JOB_TYPES), response => {
      if (response && response.status === 200) {
        var jobList = response.data.object;
        jobList.forEach(job => {
          if (!job.isComplete) {
            setAllowSITRequest(false);
          }
        });
        setSITJobs(jobList);
        setDataReadyFlag(true);
      }
    });
  }, []);

  const hideConnectInfoPopup = () => {
    setShowConnectInfo(false);
  };
  const renderConnectInfoScreen = () => {
    const submitSITJob = data => {
      if (data.connectionInfo.env === "other") {
        if (data.connectionInfo.customURL.trim() === "") {
          setError("connectionInfo.customURL", { type: "serverError", message: "Connect Custom URL is required when 'other' is selected." });
          setModalHeight(620);
          return;
        } else {
          let customURL = data.connectionInfo.customURL.trim().toLowerCase();
          let isValid = false;
          customURLPatterns.forEach(p => (isValid = p.test(customURL) ? true : isValid));
          if (!isValid) {
            setError("connectionInfo.customURL", { type: "serverError", message: "Connect Custom URL should be one for implement or sandbox environment." });
            setModalHeight(620);
            return;
          }
        }
      }
      setOverlayText("Validating Credentials...");
      submitJob(jobType, data);
    };
    const renderCustomURL = () => {
      return (
        <>
          <label className={classes.label} htmlFor="connectionInfo.customURL">
            Connect Custom URL
          </label>
          <input className={classes.input} {...register("connectionInfo.customURL", { required: false })} />
          {errors.connectionInfo && errors.connectionInfo.customURL && errors.connectionInfo.customURL === "required" && <p role="alert">Connect Custom URL is required.</p>}
          {errors.connectionInfo && errors.connectionInfo.customURL && errors.connectionInfo.customURL !== "required" && <p role="alert">{errors.connectionInfo.customURL.message}</p>}
        </>
      );
    };
    const handleEnvSelection = e => {
      if (e.target.value === "other") {
        setShowCustomURL(true);
        setModalHeight(590);
      } else {
        setShowCustomURL(false);
        setModalHeight(500);
      }
    };
    return (
      <Modal
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            width: "500px",
            height: modalHeight,
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={showConnectInfo}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => hideConnectInfoPopup()}
        ariaHideApp={false}
      >
        <h1 className={classes.h1}>Connect Information</h1>
        <form className={classes.form} onSubmit={handleSubmit(submitSITJob)}>
          <>
            <label className={classes.label} htmlFor="connectionInfo.user">
              User Name
            </label>
            <input className={classes.input} {...register("connectionInfo.user", { required: true })} aria-invalid={errors.connectionInfo && errors.connectionInfo.user ? "true" : "false"} />
            {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type === "required" && <p role="alert">User Name is required.</p>}
            {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type !== "required" && <p role="alert">{errors.connectionInfo.user.message}</p>}
            <label className={classes.label} htmlFor="password">
              Password
            </label>
            <input
              type="password"
              className={classes.input}
              {...register("connectionInfo.password", { required: true })}
              aria-invalid={errors.connectionInfo && errors.connectionInfo.password ? "true" : "false"}
            />
            {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type === "required" && <p role="alert">Password is required.</p>}
            {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type !== "required" && <p role="alert">{errors.connectionInfo.password.message}</p>}
            <label className={classes.label} htmlFor="connectionInfo.env">
              Connect Environment
            </label>
            <select className={classes.input} {...register("connectionInfo.env")} onChange={handleEnvSelection}>
              {connectEnvOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {errors.connectionInfo && errors.connectionInfo.env && <p role="alert">{errors.connectionInfo.env.message}</p>}
            {showCustomURL && renderCustomURL()}
          </>
          <input type="hidden" {...register("connectionInfo.error")} aria-invalid={errors.connectionInfo && errors.connectionInfo.error ? "true" : "false"} />
          {errors.connectionInfo && errors.connectionInfo.error && <p role="alert">{errors.connectionInfo.error.message}</p>}
          <div className={classes.textCenterContainer}>Note: Please ensure that all connect variables (including v_period_name) are setup correctly in connect before proceeding.</div>
          <div className={classes.textCenterContainer}>
            <input className={classes.inputSubmit} type="button" onClick={e => hideConnectInfoPopup()} value="Cancel" />
            <input className={classes.inputSubmit} type="submit" value="Submit" />
          </div>
        </form>
      </Modal>
    );
  };

  return (
    <LoadingOverlay
      active={!dataReady}
      spinner
      text={overlayText}
      styles={{
        spinner: base => ({
          ...base,
          width: "80px",
          position: "absolute",
          top: 0,
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)",
        }),
      }}
    >
      <ConfirmDialog open={showError} setOpen={setShowError} alert onConfirm={() => {}}>
        {errorMessage}
      </ConfirmDialog>
      <div style={{ textAlign: "center" }}>
        <ReactTable data={sitJobs} columns={dataColumns} showPagination={true} defaultPageSize={MAX_ROW} />
        <p />
        <button type="button" className="btn btn-warning btn-clear btn-padded" disabled={!allowSITRequest} onClick={() => submitJob(JOB_TYPES.TEST_CASE_CREATION)}>
          Create Test Cases
        </button>
        <button
          type="button"
          className="btn btn-warning btn-clear btn-padded"
          disabled={!allowSITRequest}
          onClick={() => {
            setJobType(JOB_TYPES.TEST_DATA_VALIDATION);
            setShowConnectInfo(true);
          }}
        >
          Validate Test Data
        </button>
        <button
          type="button"
          className="btn btn-warning btn-clear btn-padded"
          disabled={!allowSITRequest}
          onClick={() => {
            setJobType(JOB_TYPES.TEST_DATA_CREATION);
            setShowConnectInfo(true);
          }}
        >
          Generate Test Data
        </button>
        <Websocket url={`${WS_ROOT}/customers/${customerId}/projects/${projectId}/jobStatus`} onMessage={handleNotification} debug={false} />
      </div>
      {renderConnectInfoScreen()}
    </LoadingOverlay>
  );
};

export default SITAutomationStatus;
