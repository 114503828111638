import CommonResources from "./CommonResources.json";

const expDataTypes = {
  text: {
    defaultOperator: "equals",
    operators: ["equals", "notEquals", "like", "not_like"],
    systemFunctions: ["trim", "startsWith", "endsWith", "is_empty", "is_not_empty", "replace", "substring"],
  },
  number: {
    operators: ["equals", "notEquals", "lessThan", "lessThanEquals", "greaterThan", "greaterThanEquals", "between"],
    defaultOperator: "equals",
    systemFunctions: ["max", "min", "average", "count", "absolute"],
  },
  date: {
    defaultOperator: "equals",
    operators: ["equals", "notEquals", "lessThan", "lessThanEquals", "greaterThan", "greaterThanEquals", "between"],
    systemFunctions: [],
  },
};
const operators = {
  equals: { id: "equals", symbol: "==", type: ["query"], validForDataTypes: ["text", "date", "number"] },
  add: { id: "add", symbol: "+", type: ["evaluation"], validForDataTypes: ["text", "date", "number"] },
  subtract: { id: "add", symbol: "-", type: ["evaluation"], validForDataTypes: ["text", "date", "number"] },
  lessThan: { id: "lessThan", symbol: "<", type: ["query"], validForDataTypes: ["date", "number"] },
  lessThanEquals: { id: "lessThan", symbol: "<=", type: ["query"], validForDataTypes: ["date", "number"] },
  greaterThan: { id: "lessThan", symbol: ">", type: ["query"], validForDataTypes: ["date", "number"] },
  greaterThanEquals: { id: "lessThan", symbol: ">=", type: ["query"], validForDataTypes: ["date", "number"] },
};
const systemFunctions = {
  concat: {
    id: "concat",
    label: "Concat",
    type: "evaluation",
    exp: "Concat(source1.FieldA, source2.FieldB, Source3.FieldC)",
    validations: {
      argLength: { comparison: "between", value: [1, Infinity] },
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode"], fieldDataTypes: ["text"] } },
      resultType: "text",
    },
  },
  startsWith: {
    id: "startsWith",
    label: "StartsWith",
    type: "query",
    exp: "StartsWith(source1.FieldA,matchString)",
    validations: {
      argLength: { comparison: "equals", value: 2 },
      argType: { 0: { nodeType: ["AccessorNode"], fieldDataTypes: ["text"] }, default: { nodeType: ["ConstantNode"], fieldDataTypes: ["text"] } },
      parentFunctionNodes: ["if", "when"],
      resultType: "boolean",
    },
  },
  endsWith: {
    id: "EndsWith",
    label: "EndsWith",
    type: "query",
    exp: 'EndsWith(source1.FieldA,"matchString")',
    validations: {
      argLength: { comparison: "equals", value: 2 },
      argType: { 0: { nodeType: ["AccessorNode"], fieldDataTypes: ["text"] }, default: { nodeType: ["ConstantNode"], fieldDataTypes: ["text"] } },
      parentFunctionNodes: ["if", "when"],
      resultType: "boolean",
    },
  },
  isEmpty: {
    id: "isEmpty",
    label: "IsEmpty",
    type: "query",
    exp: "IsEmpty(source1.FieldA)",
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["AccessorNode"], fieldDataTypes: ["text", "number", "date"] } },
      parentFunctionNodes: ["if", "when"],
      resultType: "boolean",
    },
  },
  isNotEmpty: {
    id: "isNotEmpty",
    label: "IsNotEmpty",
    type: "query",
    exp: "IsNotEmpty(source.field)",
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["AccessorNode"], fieldDataTypes: ["text", "number", "date"] } },
      parentFunctionNodes: ["if", "when"],
      resultType: "boolean",
    },
  },
  substring: {
    //<TODO>: Review these fieldDataTypes
    id: "subString",
    label: "SubString",
    type: "query",
    exp: "SubString(source.field,startIndex,NumberOfChars)",
    validations: {
      argLength: { comparison: "equals", value: 3 },
      argType: { 0: { nodeType: ["AccessorNode"], fieldDataTypes: ["text"] }, default: { nodeType: ["ConstantNode"], fieldDataTypes: ["number"] } },
      resultType: "text",
    },
  },
  replace: {
    id: "replace",
    label: "Replace",
    type: "evaluation",
    exp: 'Replace(source.field,"matchString", "replaceString")',
    validations: {
      argLength: { comparison: "equals", value: 3 },
      argType: {
        0: { nodeType: ["AccessorNode"], fieldDataTypes: ["text"] },
        1: { nodeType: ["ConstantNode"], fieldDataTypes: ["text"] },
        2: { nodeType: ["ConstantNode"], fieldDataTypes: ["text"] },
      },
      resultType: "text",
    },
  },
  max: {
    id: "max",
    label: "MaxValue",
    type: "evaluation",
    exp: "MaxValue(value1, value2)",
    validations: {
      argLength: { comparison: "between", value: [2, Infinity] },
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode"], fieldDataTypes: ["number", "date"] } },
      resultType: "number",
    },
  },
  min: {
    id: "min",
    label: "MinValue",
    type: "evaluation",
    exp: "MinValue(value1, value2)",
    validations: {
      argLength: { comparison: "between", value: [2, Infinity] },
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode"], fieldDataTypes: ["number", "date"] } },
      resultType: "number",
    },
  },
  ifElse: {
    id: "ifElse",
    label: "If",
    type: "evaluation",
    exp: 'If(source.field1=="ABC") Then(source.field2) Else(Source.field3)',
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["OperatorNode", "FunctionNode"], fieldDataTypes: ["text", "number", "date"] } },
    },
  },
  then: {
    id: "then",
    label: "Then",
    hidden: true,
    validations: {
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode", "OperatorNode", "FunctionNode"], fieldDataTypes: ["number", "date", "text"] } },
    },
  },
  else: {
    id: "Else",
    label: "Else",
    hidden: true,
    validations: {
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode", "OperatorNode", "FunctionNode"], fieldDataTypes: ["number", "date", "text"] } },
    },
  },
  nestedIf: {
    id: "NestedIf",
    label: "NestedIf",
    type: "evaluation",
    exp: 'If(source.field1=="ABC") Then(source.field2) Else(If(source.field1=="BCA") Then(source.field3) Else(Source.field4) )',
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["OperatorNode", "FunctionNode"], fieldDataTypes: ["text", "number", "date"] } },
    },
  },
  caseWhen: {
    id: "caseWhen",
    label: "WHEN",
    type: "evaluation",
    exp: 'CASE WHEN(source.field1=="ABC") Then(source.field2) Else(source.field3)',
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["OperatorNode", "FunctionNode"], fieldDataTypes: ["text", "number", "date"] } },
    },
  },
  case: {
    id: "case",
    label: "CASE",
    hidden: true,
    validations: {},
  },
  end: {
    id: "end",
    label: "END",
    hidden: true,
    validations: {},
  },
  round: {
    id: "Round",
    label: "Round",
    exp: "Round(source.field1, 2)",
    validations: {
      argLength: { comparison: "equals", value: 2 },
      argType: { 0: { nodeType: ["AccessorNode"], fieldDataTypes: ["number"] }, default: { nodeType: ["ConstantNode"], fieldDataTypes: ["number"] } },
      resultType: "number",
    },
  },
  absolute: {
    id: "absolute",
    label: "Abs",
    exp: "Abs(source.field1)",
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["AccessorNode"], fieldDataTypes: ["number"] } },
      resultType: "number",
    },
  },
  diff: {
    id: "diff",
    label: "DiffDays",
    exp: "DiffDays(source.dateField1, source.dateField2)",
    validations: {
      argLength: { comparison: "equals", value: 2 },
      argType: { default: { nodeType: ["AccessorNode"], fieldDataTypes: ["date"] } },
      resultType: "number",
    },
  },
  isNotNull: {
    id: "isNotEmpty",
    label: "isNotNull",
    type: "evaluation",
    exp: "isNotNull(source.field)",
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: { default: { nodeType: ["AccessorNode"], fieldDataTypes: ["text", "number", "date"] } },
      parentFunctionNodes: ["if", "when"],
      resultType: "boolean",
    },
  },
  nvl: {
    id: "nvl",
    label: "Nvl",
    exp: "Nvl(source.field1, 'value')",
    validations: {
      argLength: { comparison: "equals", value: 2 },
      argType: { default: { nodeType: ["AccessorNode", "ConstantNode"] } },
      resultType: "text",
    },
  },
  toDecimal: {
    id: "toDecimal",
    label: "ToDecimal",
    exp: "ToDecimal(source.field1)",
    validations: {
      argLength: { comparison: "equals", value: 1 },
      argType: {
        default: { nodeType: ["AccessorNode"], fieldDataTypes: ["number"] },
      },
      resultType: "number",
    },
  },
  toDate: {
    id: "toDate",
    label: "ToDate",
    exp: "ToDate(source.field1,'dd/MM/yyyy')",
    validations: {
      argLength: { comparison: "between", value: [1, 2] },
      argType: {
        default: { nodeType: ["AccessorNode", "FunctionNode"], fieldDataTypes: ["date"] },
        1: { nodeType: ["ConstantNode"], allowableValues: CommonResources.dateSelectOptions },
      },
      resultType: "date",
    },
  },
  curDate: {
    id: "curDate",
    label: "CurDate",
    exp: "CurDate()",
    validations: {
      argLength: { comparison: "equals", value: 0 },
      resultType: "date",
    },
    parentFunctionNodes: ["if", "when", "todate"],
  },
  now: {
    id: "now",
    label: "Now",
    exp: "Now()",
    validations: {
      argLength: { comparison: "equals", value: 0 },
      resultType: "date",
    },
    parentFunctionNodes: ["if", "when", "todate"],
  },
};

const errorCodes = {
  fieldMappingOperatorDetected: "Only direct column mapping from a source is allowed. If an expression is needed, change mapping type to 'Derived',",
  emptyFunctionArguments: "Transformation should be passed a value",
  twoArgumentsRequired: "Two arguments required. Separated by a comma",
};

export { expDataTypes, systemFunctions, operators, errorCodes };
