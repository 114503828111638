import objectpath from "object-path";

const { Parser } = require("json2csv");

const defaultValue = (fieldType, context, variables, lists, qid) => {
  let answer = {};
  const idToDefaultTableNameMapping = {
    Comp_Incentive0: "Crediting table",
    Rate0: "Attribute value list",
    Comp_Incentive26_2: `${lists.AllObjects ? (lists.AllObjects[context.Incentive] ? `${lists.AllObjects[context.Incentive].name}` : "") : ""}Weight Table`,
    Reporting3: "Analytics reports table",
    HRDataMapping: "Incent Person Record",
    DataMapping: "Data Mapping",
    Comp_Incentive19_1: "Credit Results",
  };
  switch (fieldType) {
    case "array":
      answer["Response"] = [];
      break;
    case "boolean":
      answer["Response"] = null;
      break;
    case "Rate-Table-Builder":
      answer["Response"] = `${lists.AllObjects[context.Incentive].name} Rate Table`;
      break;
    case "Data-Table-Builder":
      answer["Response"] = idToDefaultTableNameMapping[qid];
      break;
    case "Calendar-Builder":
      answer["Response"] = `FY ${variables.calendarParams ? variables.calendarParams.calendarYear : variables.CalendarYear} Calendar`;
      break;
    case "Table-Builder":
    case "Titles-Table-Builder":
      answer["Response"] = idToDefaultTableNameMapping[qid];
      break;
    case "CreditResults-Table-Builder":
      answer["Response"] = idToDefaultTableNameMapping[qid];
      break;
    case "object":
      answer["Response"] = {};
      break;
    default:
      answer["Response"] = "";
      break;
  }
  return answer;
};
const formValidation = (data, validationParams) => {
  let validationErrors = [];
  data.forEach((row, idx) => {
    validationParams.forEach(validation => {
      switch (validation.validationType) {
        case "compare":
          switch (validation.comparison) {
            case "greaterThan":
              if (objectpath.get(row, validation.maxField) <= objectpath.get(row, validation.minField)) {
                validationErrors.push({ failedValidation: validation, msg: "Maximum Value should be greater than Minimum Value", index: idx });
              }
              break;
            case "greaterThanEquals":
              if (objectpath.get(row, validation.maxField) < objectpath.get(row, validation.minField)) {
                validationErrors.push({ failedValidation: validation, msg: "Maximum Value should be greater than Minimum Value", index: idx });
              }
              break;
            case "lessThan":
              if (objectpath.get(row, validation.maxField) >= objectpath.get(row, validation.minField)) {
                validationErrors.push({ failedValidation: validation, msg: "Maximum Value should be greater than Minimum Value", index: idx });
              }
              break;
            case "lessThanEquals":
              if (objectpath.get(row, validation.maxField) > objectpath.get(row, validation.minField)) {
                validationErrors.push({ failedValidation: validation, msg: "Maximum Value should be greater than Minimum Value", index: idx });
              }
              break;
            case "equals":
              if (objectpath.get(row, validation.maxField) !== objectpath.get(row, validation.minField)) {
                validationErrors.push({ failedValidation: validation, msg: "Maximum Value should be greater than Minimum Value", index: idx });
              }
              break;
          }
        case "tableValidation":
          switch (validation.comparison) {
            case "required":
              if (validation.requiredCols) {
                let isEmpty = true;
                data.forEach(row => {
                  validation.requiredCols.forEach(col => {
                    if (row[col]) {
                      isEmpty = false;
                    }
                  });
                });
                if (isEmpty) {
                  validationErrors.push({ failedValidation: validation, msg: "Table data is required", index: idx });
                }
              }
              break;
          }
      }
    });
  });

  let validationResults = { valid: validationErrors.length === 0, errors: validationErrors };

  return validationResults;
};
//class for Manipulating Table Objects
class TableActions {
  static generateCSV(table, blackList = []) {
    //2) Format columns:
    let csvExportFields = [];
    table.columns.forEach(function (column, index) {
      //remove the path column as it is used to create hyperlinks only
      if (!blackList.includes(column.accessor)) {
        csvExportFields.push({ label: column.Header, value: column.accessor });
      }
    });
    let json2csvParser = new Parser({ fields: csvExportFields });
    //3) Format Data:
    const safeValues = table.data.map(item => {
      if (item.note) {
        item.note = `'${item.note}`;
      }
      return item;
    });
    let elemList = safeValues;
    let data = json2csvParser.parse(elemList);
    //5) Return the csv data as an array
    return [data];
  }
}

const incentStandardFields = [
  { name: "Order Code", id: "OrderCode", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Item Code", id: "ItemCode", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Batch Name", id: "BatchName", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Batch Type", id: "BatchType", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Product Name", id: "Product", type: "OrderItem", dataType: "string", visibleInFormulas: true, configKey: "id" },
  { name: "Geography Name", id: "Geography", type: "OrderItem", dataType: "string", visibleInFormulas: true, configKey: "id" },
  { name: "Customer Name", id: "Customer", type: "OrderItem", dataType: "string", visibleInFormulas: true, configKey: "id" },
  { name: "Split Amount", id: "SplitAmount", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Amount", id: "Amount", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Quantity", id: "Quantity", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Total Amount", id: "TotalAmount", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Amount UnitType", id: "AmountUnitType", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Incentive Date", id: "IncentiveDate", type: "OrderItem", dataType: "date", visibleInFormulas: true, configKey: "id" },
  { name: "Order Date", id: "OrderDate", type: "OrderItem", dataType: "date", visibleInFormulas: true, configKey: "id" },
  { name: "Order Type", id: "OrderType", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Discount", id: "Discount", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Description", id: "Description", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Related Order Code", id: "RelatedOrderCode", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Related Item Code", id: "RelatedItemCode", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Employee ID", id: "EmployeeID", type: "OrderItem", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Split Amount (%)", id: "SplitAmountPercentage", type: "OrderItem", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "First Name", id: "FirstName", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Middle Name", id: "MiddleName", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Last Name", id: "LastName", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Prefix", id: "Prefix", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Employee Status", id: "EmployeeStatus", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Region", id: "Region", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Hire Date", id: "PersonHireDate", type: "People", dataType: "date", visibleInFormulas: true, configKey: "id" },
  { name: "Termination Date", id: "PersonTerminationDate", type: "People", dataType: "date", visibleInFormulas: true, configKey: "id" },
  { name: "Personal Target", id: "PersonalTarget", type: "People", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Prorated Personal Target", id: "ProratedPersonalTarget", type: "People", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Personal Currency", id: "PersonalCurrency", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Payment Currency", id: "PaymentCurrency", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Salary", id: "PersonSalary", type: "People", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Prorated Salary", id: "ProratedPersonSalary", type: "People", dataType: "number", visibleInFormulas: true, configKey: "id" },
  { name: "Salary Currency", id: "SalaryCurrency", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
  { name: "Business Group", id: "BusinessGroup", type: "People", dataType: "string", visibleInFormulas: false, configKey: "id" },
];

const listHeaders = {
  Titles: "Titles",
  Plans: "Plans",
  Incentives: "Incentives",
  Sources: "Sources (TransactionTypes)",
  HRSources: "HR Sources",
  Quotas: "Quotas",
  QualifierAttributes: "Attributes",
  ComputationAttributes: "Attributes",
  SuccessFactors: "Success Factors",
  AllAttributes: "Attributes",
  UnitTypes: "Unit Types",
};

const Section_Hierarchy = {
  GenericInfo: 0,
  Organization: 0,
  TermsConditions: 0,
  Data: 0,
  Plans: 1,
  SuccessFactors: 1,
  Incentives: 2,
  Roles: 3,
  Attributes: 4,
  TransactionType: 5,
  Qualifier: 6,
  NewSourceQualifier: 7,
  Computation: 5,
  Rate: 5,
  Questions: 8,
  ImplementationSurvey: 0,
  "Attributes.TransactionType": 4,
};

const DeepCopyObject = data => {
  return JSON.parse(JSON.stringify(data));
};

const isValidPath = (path, AllObjects) => {
  let valid = true;
  if (AllObjects && path) {
    for (const [key, value] of Object.entries(AllObjects)) {
      if (value.invalid && path.includes(key)) {
        valid = false;
        break;
      }
    }
  }
  return valid;
};

const generateIDMapping = sources => {
  const sourceIdToNames = {};
  const fieldIdToNames = {};
  sources.forEach(item => {
    sourceIdToNames[item.id] = item.name.replace(/[\s "]/g, "");
    if (item.fields && item.fields.data) {
      item.fields.data.forEach(f => {
        if (f.id && f.Name) {
          fieldIdToNames[f.id] = f.Name.replace(/[\s "]/g, "");
        }
      });
    }
    if (item.relationships) {
      item.relationships.forEach(r => {
        sourceIdToNames[r.id] = r.name.replace(/[\s "]/g, "");
        if (r.fields) {
          r.fields.forEach(f => {
            if (f.id && f.Name) {
              fieldIdToNames[f.id] = f.Name.replace(/[\s "]/g, "");
            }
          });
        }
      });
    }
  });
  return { sourceIdToNames, fieldIdToNames };
};

const expIdToName = (exp, idMap) => {
  let newExp = exp ? exp : "";
  Object.entries(idMap).forEach(pair => {
    let sRegExInput = new RegExp(`\\b${pair[0]}\\b`, "g");
    newExp = newExp.replace(sRegExInput, pair[1]);
  });
  return newExp;
};

const isObject = value => {
  return typeof value === "object" && value !== null && !Array.isArray(value);
};

const compareObjects = (obj1, obj2) => {
  for (let p in obj1) {
    if (obj1.hasOwnProperty(p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  for (let p in obj2) {
    if (obj2.hasOwnProperty(p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  return true;
};

let compareArrayOfObjects = (array_of_objects1, array_of_objects2) => {
  return array_of_objects1.length === array_of_objects2.length && array_of_objects1.every(element_1 => array_of_objects2.some(element_2 => compareObjects(element_1, element_2)));
};

export {
  defaultValue,
  TableActions,
  incentStandardFields,
  formValidation,
  listHeaders,
  Section_Hierarchy,
  DeepCopyObject,
  isValidPath,
  generateIDMapping,
  expIdToName,
  isObject,
  compareObjects,
  compareArrayOfObjects,
};
