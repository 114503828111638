import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Server from "./Server";
import store from "store";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormGroup, ControlLabel } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
const summaryConfig = require("./componentSummaryLabelsConfig.json");

const server = new Server();
/**
 * ### Overview
 * Component summary provides a high level overview for each component
 */
const ComponentSummary = props => {
  let level = props.params.level;
  let val = props.params.value ? props.params.value : props.lists.Incentives[0].id ? props.lists.Incentives[0].id : props.lists.Incentives[0];
  let incentiveOptions = props.lists.Incentives.map(item => {
    // for backward compatibility with projects that did not have id and names
    let option = {};
    if (!item.id) {
      option.id = item;
      option.name = item;
    } else {
      option = item;
    }
    return option;
  });
  const [selectedValue, setSelected] = useState({ id: val, name: props.lists.AllObjects[val].name });
  const [dataReady, setDataReadyFlag] = useState(false);
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const summaryColumns = [
    {
      Header: "Description",
      accessor: "label",
      maxWidth: 400,
      style: { whiteSpace: "unset" },
      sortMethod: (a, b, desc) => {
        a = typeof a.props.children === "string" ? a.props.children.toLowerCase() : a;
        b = typeof b.props.children === "string" ? b.props.children.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      }
    },
    { Header: "Value", accessor: "value", style: { whiteSpace: "unset" } }
  ];
  const [summaryData, setSummaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const handleSelect = x => {
    props.SendQuestionPath(x.target.id);
    props.closeModal();
  };

  useEffect(() => {
    setDataReadyFlag(false);
    server.generateComponentSummary(customerId, projectId, { level: level, value: selectedValue.id }, response => {
      let newData = [];
      if (response && response.status === 200) {
        if (response.data.object[0]) {
          //1) FLATTEN ALL OBJECTS, CONCATENATE VALUES IF MULTIPLE EXIST:
          for (const [sectionName, sectionArray] of Object.entries(response.data.object[0])) {
            if (Array.isArray(sectionArray)) {
              sectionArray.forEach(function (subArray, subArrayIndex) {
                subArray.forEach(function (obj, objIndex) {
                  if (summaryConfig.incentiveSummaryLabels.hasOwnProperty(obj.key)) {
                    if (obj.path) {
                      obj.label = (
                        <a className="Clickable" id={obj.path} onClick={e => handleSelect(e)}>
                          {summaryConfig.incentiveSummaryLabels[obj.key]}
                        </a>
                      );
                    }
                    newData.push(obj);
                  }
                });
              });
            }
          }
        }
        setSummaryData(newData);
        setPageSize(newData.length);
      }
      setDataReadyFlag(true);
    });
  }, [selectedValue]);

  return (
    <div>
      <div>
        <SecondaryButton
          icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
          clickHandler={() => {
            props.closeModal();
          }}
          iconSize={20}
        />
        <Tooltip id="closeModal">Close</Tooltip>
      </div>
      <LoadingOverlay
        active={!dataReady}
        spinner
        text={`Loading Summary for ${selectedValue.name}...`}
        styles={{
          spinner: base => ({
            ...base,
            width: "80px"
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)"
          })
        }}
      >
        <h4>{`Summary for ${level}: ${selectedValue.name}`}</h4>
        <div className="SubSection">
          <ReactTable columns={summaryColumns} data={summaryData} className="-striped -highlight" showPagination={false} pageSize={pageSize} />
        </div>
      </LoadingOverlay>
      <div className="SubSection">
        {
          <form className="form-inline">
            <ControlLabel>{level}: </ControlLabel>
            <FormGroup controlId="customer" bsSize="small" className="proj-select-form-group">
              <Typeahead
                labelKey="name"
                id="customer"
                options={incentiveOptions}
                placeholder="Search a Incentive"
                onChange={selected => {
                  if (selected.length > 0) setSelected(selected[0]);
                }}
                selected={[selectedValue]}
              />
            </FormGroup>
          </form>
        }
      </div>
    </div>
  );
};
export default ComponentSummary;
