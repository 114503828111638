import React, { Component } from "react";
import Form from "react-jsonschema-form";
import Server from "./Server";
import LoadingOverlay from "react-loading-overlay";

const server = new Server();

class ConnectAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataReady: true,
      formData: {
        clientId: "Placeholder",
        consumerId: "Placeholder",
        environment: "",
        connectUserId: "", // "Aralhan@xactlycorp.com",
        connectPassword: "" //"Welcome1"
      }
    };
  }

  getToken = ({ formData }) => {
    //console.log("getting new token");
    this.setState({
      dataReady: false
    });

    server.getConnectToken(
      formData.clientId || this.state.clientId,
      formData.consumerId || this.state.consumerId,
      formData.environment,
      formData.connectUserId,
      formData.connectPassword,
      this.props.useClientId,
      response => {
        if (response && response.status === 200) {
          //console.log("new token received. Calling get objects");
          this.props.getObjects(formData.connectUserId, formData.environment, this.props.useClientId);
        } else {
          this.setState({
            dataReady: true
          });
          alert("invalid credentials");
        }
      }
    );
  };
  render() {
    return (
      <LoadingOverlay
        active={!this.state.dataReady}
        spinner
        text="Authenticating..."
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
            position: "absolute",
            top: 0
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.3)"
          })
        }}
      >
        <div className="SubSection ">
          <Form
            schema={
              this.props.useClientId
                ? {
                    title: `Incent Credentials for project : ${this.props.projectName}`,
                    type: "object",
                    properties: {
                      environment: {
                        type: "string",
                        enum: ["PRODUCTION", "SANDBOX", "IMPLEMENT"],
                        enumNames: ["PRODUCTION", "SANDBOX", "IMPLEMENT"],
                        title: "Select target environment:"
                      },
                      clientId: { type: "string", title: "Client ID" },
                      consumerId: { type: "string", title: "Consumer ID" },
                      connectUserId: { type: "string", title: "User ID" },
                      connectPassword: { type: "string", title: "Password" }
                    }
                  }
                : {
                    title: `Incent Credentials for project : ${this.props.projectName}`,
                    type: "object",
                    properties: {
                      environment: {
                        type: "string",
                        enum: ["eu1", "eusb1", "implement1", "sandbox", "sandbox3", "sandbox4", "secure1", "secure2", "secure3", "secure4", "training1"],
                        enumNames: ["eu1", "eusb1", "implement1", "sandbox", "sandbox3", "sandbox4", "secure1", "secure2", "secure3", "secure4", "training1"],
                        title: "Select target environment:"
                      },
                      connectUserId: { type: "string", title: "User ID" },
                      connectPassword: { type: "string", title: "Password" }
                    }
                  }
            }
            uiSchema={{
              "ui:order": ["clientId", "consumerId", "environment", "connectUserId", "connectPassword"],
              connectPassword: {
                "ui:widget": "password"
              },
              environment: {
                "ui:widget": "select"
              },
              connectUserId: { "ui:placeholder": "enter email" },
              clientId: { "ui:placeholder": "unique client id for the customer" },
              consumerId: { "ui:placeholder": "unique consumer id for the customer" }
            }}
            onSubmit={this.getToken}
            formData={this.state.formData}
            showErrorList={false}
          >
            <button className="btn btn-success btn-padded" type="submit">
              Login
            </button>
          </Form>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ConnectAuthentication;
