import React, { useState, useEffect } from "react";
import Server from "./Server";
import Form from "react-jsonschema-form";
import store from "store";
// import DualListBox from "react-dual-listbox";
// import "react-dual-listbox/lib/react-dual-listbox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
const server = new Server();

const UserAssignmentModal = props => {
  const currentUser = store.get("user")[store.get("currentUser")]["id"];
  const [assignedUsers, setAssignedUsers] = useState(props.assignedUsers.map(item => item.user));
  const [userList, setUserList] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    //on initial mount:
    //convert selectd:
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    server.getAssignedUsers(response => {
      if (response.status == 200) {
        let newOptions = [];
        response.data.object.forEach(user => {
          let option = { value: user.user, label: user.user };
          newOptions.push(option);
        });
        setUserList(newOptions);
      } else {
        let error = { message: Object.values(response.originalMessages)[0], alertHeader: "Failed to Get Users" };
        setError(error);
      }
    });
  };

  const handleSubmit = () => {
    //1) Checking which Users are new:
    let newUsers = [];
    assignedUsers.forEach(user => {
      //1) Checking if it is already assigned:
      if (!props.assignedUsers.map(item => item.user).includes(user)) {
        newUsers.push(user);
      }
    });
    //2) Checking which users are old:
    let oldUsers = [];
    props.assignedUsers.forEach(user => {
      if (!assignedUsers.includes(user.user)) {
        oldUsers.push(user.user);
      }
    });

    return new Promise((resolve, reject) => {
      try {
        newUsers.forEach(newUser => {
          //assign customer/projeect:
          server.assignCustomer(props.customerId, newUser, response => {
            if (response.status === 200) {
              server.assignProject(props.customerId, props.projectId, newUser, resp => {
                console.log(resp);
                if (resp.status === 200) {
                } else {
                  let error = { message: Object.values(resp.originalMessages)[0], alertHeader: `Failed to Assign ${newUser} to Project ${props.projectId}` };
                  setError(error);
                }
                console.log("ASSIGNING NEW USER: ", newUser);
              });
            } else {
              let error = { message: Object.values(response.originalMessages)[0], alertHeader: `Failed to Assign ${newUser} to Customer ${props.customerId}` };
              setError(error);
            }
          });
        });
        oldUsers.forEach(oldUser => {
          server.removeProjectAccess(props.customerId, props.projectId, oldUser, response => {
            if (response.status === 200) {
              server.removeCustomerAccess(props.customerId, oldUser, resp => {
                if (resp.status === 200) {
                } else {
                  let error = { message: Object.values(resp.originalMessages)[0], alertHeader: `Failed to Unassign ${oldUser} to Project: ${props.projectId}` };
                  setError(error);
                }
              });
            } else {
              let error = { message: Object.values(response.originalMessages)[0], alertHeader: `Failed to Unassign ${oldUser} to Customer: ${props.customerId}` };
              setError(error);
            }
          });
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    }).then(props.handleSubmit());
  };
  return (
    <div>
      <h3>{"User Project Assignment:"}</h3>
      {/* <DualListBox
        canFilter
        showHeaderLabels={true}
        //alignActions="top"
        icons={{
          moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
          moveAllLeft: [<FontAwesomeIcon icon={faChevronLeft} />, <FontAwesomeIcon icon={faChevronLeft} />],
          moveRight: <FontAwesomeIcon icon={faChevronRight} />,
          moveAllRight: [<FontAwesomeIcon icon={faChevronRight} />, <FontAwesomeIcon icon={faChevronRight} />],
          moveDown: <span className="fa fa-chevron-down" />,
          moveUp: <span className="fa fa-chevron-up" />,
          moveTop: <span className="fa fa-double-angle-up" />,
          moveBottom: <span className="fa fa-double-angle-down" />
        }}
        options={userList}
        selected={assignedUsers}
        onChange={selected => {
          setAssignedUsers(selected);
        }}
      /> */}
      <button onClick={() => handleSubmit()}>{"Submit"}</button>
    </div>
  );
};

export default UserAssignmentModal;
