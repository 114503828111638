import React, { Component, createRef } from "react";
import Form from "react-jsonschema-form";
import ES from "es6-template-render";
import moment from "moment";
import { findDependent } from "./findDependent";
import { extractSchemaPath } from "./ExtractSchemaPath";
import { Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip, MenuItem, Alert } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import objectpath from "object-path";
import Server from "./Server";
import GenericTable from "./GenericTable";
import CreditResultsTable from "./CreditResultsTable";
import RateTableBuilder from "./RateTableBuilder";
import CalendarBuilder from "./CalendarBuilder";
import DataDerivation from "./DataDerivation";
import { generateID, getNamesForListID, getNameFromAllObjects } from "./ManageObjectIDs";
import UpdateLists from "./UpdateLists";
import ComponentProvider from "./ComponentProvider";
import DataTableBuilder from "./DataTableBuilder";
import TitlesTableBuilder from "./TitlesTableBuilder";
import ComponentSummary from "./componentSummary";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLock, faClipboardList, faCompass, faExclamationTriangle, faWindowClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import ExitSection from "./LockIncentive";
import Cover from "./cover";
import LoadingOverlay from "react-loading-overlay";
import IdleTimer from "react-idle-timer";
import Websocket from "react-websocket";
import { DropDown, PrimaryButton, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { WS_ROOT, NOTIFICATION_TIMEOUT } from "./api-config";
import ReactNotifications, { store as NotificationStore } from "react-notifications-component";
import SectionNavigation from "./sectionNavigation";
import QuestionList from "./questionList";
import { defaultValue, formValidation, Section_Hierarchy, isValidPath } from "./util";
import { getCustomWidgets, getCustomFields } from "./jsonSchemaFormCustomWidgets";
import dataSourceDetailsSchema from "./dataSourceDetailsSchema.json";
import CopyDataMapping from "./copyDatamapping";
import DynamicSelect from "./dynamicSelect";

const server = new Server();
const MAX_INACTIVITY_TIME = 10 * 60 * 1000;
const MAX_COUNTDOWN_SEC = 120; // In seconds.

var Ques_Schema = {
  title: "",
  type: "object",
  properties: {
    Response: { type: "string", title: "" },
    Notes: { type: "string", title: "" },
  },
};

var UISchema = {};
var BreadCrumb = "";

//<TODO>: Update after secetion change
const QuesNavSectionMap = {
  GenericInfo: "Implementation Survey",
};
const QuesNavNameListMap = {
  Plans: "Plans",
  Incentives: "Incentives",
  Computation: "AllAttributes",
  Qualifier: "AllAttributes",
  NewSourceQualifier: "AllAttributes",
  Roles: "Titles",
  SuccessFactors: "SuccessFactors",
  Rate: "AllAttributes",
  TransactionType: "Sources",
};

//const FirstQes = "GenericInfo.Questions.Org1_1";
const firstConfigQuestion = "GenericInfo.Questions.Org1_1";
const firstSurveyQuestion = "Organization.Questions.Imp0_0";
//setup first question as array?
//<TODO>: Update after secetion change
//what does this do?
const QuesSecNavMap = {
  GenericInfo: {
    id: "Org1_1",
    text: "Business Information Section",
    response: [],
  },
  Organization: {
    id: "Org1",
    text: "Organization Section",
    response: "",
  },
  TermsConditions: {
    id: "TC1",
    text: "Terms and Conditions Section",
    response: "",
  },
  Data: {
    id: "DataMapping",
    text: "Data Section",
    response: [],
  },
  ImplementationSurvey: {
    id: "ImpSurvey1_0",
    text: "Implementation Survey",
    response: "",
  },
};

const fixedWidgetBtnStyle = {
  top: "0px",
  left: "0px",
  zIndex: 2,
  width: "100%",
  padding: "8px 15px",
  position: "fixed",
  background: "#ffffff",
  boxShadow: "0 8px 8px -4px #cccccc",
};

const alertBoxStyle = { border: "1px solid #D47800", borderRadius: "2px" };

const inactivityModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

const releaseLockToastStyle = {
  background: "#333333",
  color: "#ffffff",
  padding: "8px",
  boxShadow: "0px 2px 4px rgb(0 0 0 / 50%)",
  borderRadius: "4px",
  borderLeftColor: "#66B6DE",
  borderLeftWidth: "10px",
  borderLeftStyle: "solid",
};

class Questions extends Component {
  constructor(props) {
    super(props);
    this.dataTableRef = createRef();

    this.AllLists = {};
    this.GlobalVariables = {};
    this.Path = [];
    this.AllResponses = {};
    this.Loop = {};
    this.Questions = {};
    this.schemaPath = {};
    this.QuestionOrder = {};
    this.Path_Index = "";
    this.Current_Section = "";
    this.Current_Plan = "";
    this.Current_Incentive = "";
    this.Current_Role = "";
    this.Current_Incentive_Attribute = "";
    this.Current_Incentive_Attribute_Type = "";
    this.Current_NewSourceQualifier = "";
    this.TableBuilder = "";
    this.RateTableBuilder = "";
    this.CalendarBuilder = "";
    this.DataTableBuilder = "";
    this.DataDerivation = "";
    this.Current_TransactionType = "";
    this.SectionSummary = "";
    this.tabularAnswer = {};
    this.NotesDisplay = "hidden";
    this.RenderedQuestionId = "";
    this.parkingLotItemsButton = "";
    this.CurrentProps = {};
    this.listWithIDs = true;
    this.dynamicComponentMapping = this.props.dynamicComponentMapping;
    this.allowedSections = this.props.allowedSections;
    this.QuesSecNavMap = QuesSecNavMap;
    this.allowSearch = this.props.allowSearch || false;
    this.allowedSearchMenus = [];
    this.SectionPaths = [];
    this.idleTimeRef = null;
    this.countDownTimer = null;
    this.state = {
      showComponentSummary: false,
      showSectionSummary: false,
      showAddOptionForm: false,
      Current_QID: "",
      formData: {},
      projectId: this.props.projectId,
      disableSubmit: false,
      dataReady: true,
      socketConnected: false,
      showNavigation: false,
      showParkingLot: false,
      showNotes: false,
      showCompletionForm: false,
      Path: [],
      buttonContainersStyle: {},
      sectionLockStatus: {
        isLocked: false,
        lockedBy: "",
        section: "",
        message: "",
      },
      isInactivityModalOpen: false,
      connectionLostModalOpen: false,
      countDownCounter: MAX_COUNTDOWN_SEC,
      preventNext: false,
      displayCopySourcePopup: false,
      tableWidgetValidationErr: "",
      selectedTitles: [],
    };
    this.updateQuestions = [];
    this.handleScroll = this.handleScroll.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.inactivityModalCloseHandler = this.inactivityModalCloseHandler.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.handleGettingLock = this.handleGettingLock.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.projectId !== prevState.projectId) return { projectId: nextProps.projectId };
    else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.qid && prevProps.qid !== this.props.qid) {
      this.handleSelect(this.props.qid);
    }
  }

  async componentDidMount() {
    //This function makes the service calls necessary to retreive the last saved state.
    //1. It reads ProjectDetails from database which contains the configuration details, question schema and question order control
    Modal.setAppElement("body");
    console.log("COMPONENT MOUNTED");
    //<TODO>: Update QuesSecNavMap with only the allowed sections
    let newMap = {};
    Object.keys(QuesSecNavMap)
      .filter(section => this.props.allowedSections.includes(section))
      .forEach(section => {
        newMap[section] = QuesSecNavMap[section];
      });
    this.QuesSecNavMap = newMap;

    //update search widget menus/parking lots and notes
    this.allowedSearchMenus = ["summary"];
    if (this.props.allowNotes) {
      this.allowedSearchMenus.push("notes");
    }
    if (this.props.allowParkingLot) {
      this.allowedSearchMenus.push("parkingLot");
    }

    //get last rendered if available here:
    this.UpdateQuestion(this.props.qid);
    document.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
    clearTimeout(this.countDownTimer);
    document.removeEventListener("click", this.handleGettingLock);
  }

  handleScroll(event) {
    const parentContainerElement = document.getElementById("buttonContainer");
    if (parentContainerElement) {
      const parentTop = parentContainerElement.getBoundingClientRect().top;
      if (parentTop < 10) {
        this.setState({ buttonContainersStyle: fixedWidgetBtnStyle });
      } else {
        this.setState({ buttonContainersStyle: {} });
      }
    }
  }

  resetTimer() {
    clearTimeout(this.countDownTimer);
    this.countDownTimer = null;
    this.setState({ countDownCounter: MAX_COUNTDOWN_SEC });
  }

  handleOnIdle() {
    this.setState({ isInactivityModalOpen: true }, () => {
      this.startCountDown();
    });
  }

  inactivityModalCloseHandler() {
    if (this.state.countDownCounter <= 0) {
      window.location.reload();
    } else {
      this.resetTimer();
      this.setState({ isInactivityModalOpen: false }, () => {
        this.idleTimeRef.reset();
      });
    }
  }

  startCountDown() {
    this.setState({ countDownCounter: this.state.countDownCounter - 1 }, () => {
      if (this.state.countDownCounter <= 0) {
        clearTimeout(this.countDownTimer);
        this.countDownTimer = null;
        // Notify server If connection is being terminated.
        if (this.refWebSocket) {
          this.sendMessage(
            JSON.stringify({
              action: "TERMINATE_CONNECTION",
            }),
          );
        }
      } else {
        this.countDownTimer = setTimeout(() => this.startCountDown(), 1000);
      }
    });
  }

  //here refactor:
  projectDetailBreakdown = projDetails => {
    this.Questions = projDetails.QuestionSchemaObj;
    this.QuestionOrder = projDetails.QuestionOrderControlObj;
    this.AllLists = projDetails.AllLists;
    this.GlobalVariables = projDetails.GlobalVariables === undefined ? this.GlobalVariables : projDetails.GlobalVariables;
    //filter this based on allowable sections?
    this.SectionPaths = projDetails.SectionPaths ? projDetails.SectionPaths : this.initilizeSectionPaths();
    let newPaths = [];
    projDetails.Path.forEach(path => {
      this.allowedSections.forEach(section => {
        if (path.startsWith(section)) {
          newPaths.push(path);
        }
      });
    });
    //this.Path = JSON.parse(JSON.stringify(projDetails.Path));
    const isSectionLocked = !!(this.state.sectionLockStatus.isLocked && this.Current_Section === this.state.sectionLockStatus.section);
    if (!isSectionLocked) {
      this.Path = projDetails.Path;
      this.state.Path = JSON.parse(JSON.stringify(newPaths));
    }

    //this.Path = newPaths;

    //f1) Adjusting the answer space with new sections
    let newResponses = {};
    Object.keys(projDetails.ConfigDetails).forEach(key => {
      if (this.allowedSections.includes(key)) {
        newResponses[key] = projDetails.ConfigDetails[key];
      }
    });
    this.AllResponses = newResponses;
    //this.AllResponses = projDetails.ConfigDetails;

    //<TODO>: How to handle the loop?
    this.Loop = projDetails.Loop;
    if (this.state.Path.length <= 0) {
      if (this.allowedSections.includes("Organization")) {
        this.state.Path[0] = firstConfigQuestion;
      } else {
        this.state.Path[0] = firstSurveyQuestion;
      }
      //this.Path[0] = FirstQes;
    }
    //how to handle unfits?
    if (this.allowedSections.includes("Organization")) {
      this.UnfitSections = projDetails.UnfitSections;
      this.UnfitSectionsWithStatusTrue = this.UnfitSections.filter(item => item.unfit === true);
    } else {
      this.UnfitSections = [];
    }
    this.showReinstateButton = false;
    this.showSummaryIcon = this.AllLists.Incentives.length > 0;

    this.createSectionStartQuestions();
  };
  initilizeSectionPaths = () => {
    let SectionPath = {};
    Object.keys(this.Questions).forEach(key => {
      if (key !== "Metadata" && !key.startsWith("_")) {
        SectionPath[key] = [];
      }
    });
    return SectionPath;
  };
  //looked at
  UpdateQuestion = redirectQid => {
    //used for search widget routing
    if (redirectQid) {
      localStorage.setItem("lastRendered", redirectQid);
    }
    this.projectDetailBreakdown(this.props.projectDetails);
    //<TODO>:
    //const sections = ["GenericInfo", "Organization", "TermsConditions", "Data"];
    const sections = this.allowedSections;
    //console.log(this.props.allowedSections);
    sections.forEach(section => {
      if (this.QuestionOrder[section]) {
        this.QuesSecNavMap[section].id = this.QuestionOrder[section].Questions.Default.options.Default.nextQuesId.split(".").pop();
      } else {
        //Do what for backwards compatability?
      }
    });
    //On component mount, set to last rendered item. If not found or if it is a new project, then set to the last item in the path variable for the allowed sections:
    if (this.allowedSections.includes("Organization")) {
      if (objectpath.get(this.AllResponses, "Organization.Questions.Org8") !== undefined) {
        objectpath.del(this.AllResponses, "Organization.Questions.Org8");
      }
      objectpath.set(this.AllResponses, "Organization.Questions.Org8", {
        id: "Org8",
        text: "Now that you have completed the current piece, let’s start a new topic. Select the topic you would like to review next.",
        answer: { Response: [] },
      });
    }

    //On component mount, set to last rendered item. If not found or if it is a new project, then set to the last item in the path variable for the allowed sections:
    //Attempts to get the lastRendered Question:
    if (localStorage.getItem("lastRendered") && objectpath.get(this.AllResponses, localStorage.getItem("lastRendered"))) {
      this.handleSelect(localStorage.getItem("lastRendered"));
    } else {
      //if not found set to first question in first section
      let currentSection = this.Current_Section !== "" ? this.Current_Section : this.allowedSections[0];
      if (this.GlobalVariables["lastRendered"] && this.GlobalVariables["lastRendered"].startsWith(currentSection)) {
        this.handleSelect(this.GlobalVariables["lastRendered"]);
      } else {
        //set to last question in flow
        if (this.SectionPaths[currentSection].length - 1 > 0 && !this.SectionPaths[currentSection][this.SectionPaths[currentSection].length - 1]) {
          this.handleSelect(this.SectionPaths[currentSection][this.SectionPaths[currentSection].length - 1]);
        } else {
          //Last Resort: Get 1st question from the Questions Schema Object itself:
          let secPath = currentSection + ".Questions." + this.QuesSecNavMap[currentSection].id;
          this.handleSelect(secPath);
        }

        //let lastRendered = this.SectionPaths[this.Current_Section][this.SectionPaths[currentSection].length - 1];
        //console.log(lastRendered);
        //this.handleSelect(lastRendered);
      }

      //if not found set to last in path variable that contains the section
      //<TODO>: Revisit now that we moved Path to the state
      // let lastSection = this.allowedSections[this.allowedSections.length - 1];
      // let self = this;
      // for (let i = this.Path.length - 1; i > 0; i--) {
      //   console.log(this.Path);
      //   if (this.Path[i].startsWith("lastSection")) {
      //     console.log(this.Path[i]);
      //     this.handleSelect(self.GlobalVariables["lastRendered"] || this.Path[i]);
      //     break;
      //   }
      // }
      //<TODO>: What to do for current section?

      //this.handleSelect(this.GlobalVariables["lastRendered"] || this.SectionPaths[this.Current_Section][this.SectionPaths[this.Current_Section].length - 1]);
      //this.handleSelect(this.GlobalVariables["lastRendered"] || this.Path[this.Path.length - 1]);
    }
  };

  //<TODO>: Could this be refactored?
  createSectionStartQuestions = () => {
    Object.keys(this.QuesSecNavMap).forEach((item, index) => {
      const secPath = item + ".Questions." + this.QuesSecNavMap[item].id;
      if (objectpath.get(this.AllResponses, secPath) === undefined) {
        const value = {
          id: this.QuesSecNavMap[item].id,
          text: this.QuesSecNavMap[item].text,
          answer: { Response: this.QuesSecNavMap[item].response },
        };
        objectpath.set(this.AllResponses, secPath, value);
      }
    });
  };
  handleError = error => {
    console.log("Error in API call");
    console.log(error);
  };

  sendMessage = message => {
    this.refWebSocket.sendMessage(message);
  };

  handleGettingLock() {
    if (this.refWebSocket && this.Current_Section && this.notificationId) {
      this.sendMessage(
        JSON.stringify({
          action: "SECTION_CHANGE",
          section: this.state.Current_QID === "Org8" ? "CommonQuestion" : this.Current_Section,
        }),
      );
    }
  }
  getSimilarCrediting(currentIncentive, baseIncentive) {
    let similarCrediting = objectpath.get(this.AllResponses, `Organization.Plans.${this.Current_Plan}.Incentives.${currentIncentive}.Questions.Comp_Incentive47`);
    similarCrediting = similarCrediting ? similarCrediting.answer.Response[0] : similarCrediting;
    if (similarCrediting && similarCrediting !== "None" && similarCrediting !== baseIncentive) {
      return similarCrediting;
    } else {
      return null;
    }
  }

  getBorrowedCredits() {
    const hasDirectCredit = this.GlobalVariables.PlanIncentiveCredits.find(item => item.Plan === this.Current_Plan && item.Incentive === this.Current_Incentive && item.val.includes("Direct"));
    let borrowedCredits = objectpath.get(this.AllResponses, `Organization.Plans.${this.Current_Plan}.Incentives.${this.Current_Incentive}.Questions.Comp_Incentive47_1`);
    borrowedCredits = borrowedCredits ? borrowedCredits.answer.Response : borrowedCredits;
    if (borrowedCredits && borrowedCredits.length && !hasDirectCredit) {
      return borrowedCredits.filter(item => item !== "RollOverCredit");
    } else {
      return [];
    }
  }

  setCurrentProps = id => {
    //This function updates different properties like Current_plan, Current_Incentive after each submit. Also updates all variables that keep track of selected plans,incentives, attributes
    var pathArray = id.split(".");

    // Notify server If socket connection is available and either 'section is being changed' or 'section is unlocked'.
    const nextQueId = pathArray[pathArray.length - 1];
    if (this.refWebSocket && (this.Current_Section !== pathArray[0] || this.notificationId || nextQueId === "Org8" || this.state.Current_QID === "Org8")) {
      this.sendMessage(
        JSON.stringify({
          action: "SECTION_CHANGE",
          section: nextQueId === "Org8" ? "CommonQuestion" : pathArray[0],
        }),
      );
    }

    this.Current_Incentive = pathArray.indexOf("Incentives") >= 0 ? pathArray[pathArray.indexOf("Incentives") + 1] : "";
    this.Current_Incentive_Attribute =
      pathArray.indexOf("Qualifier") >= 0
        ? pathArray[pathArray.indexOf("Qualifier") + 1]
        : pathArray.indexOf("Computation") >= 0
        ? pathArray[pathArray.indexOf("Computation") + 1]
        : pathArray.indexOf("Rate") >= 0
        ? pathArray[pathArray.indexOf("Rate") + 1]
        : "";
    this.Current_NewSourceQualifier = pathArray.indexOf("NewSourceQualifier") >= 0 ? pathArray[pathArray.indexOf("NewSourceQualifier") + 1] : "";
    this.Current_Incentive_Attribute_Type = pathArray.indexOf("Qualifier") >= 0 ? "Qualifier" : pathArray.indexOf("Computation") >= 0 ? "Computation" : pathArray.indexOf("Rate") >= 0 ? "Rate" : "";
    this.Current_Plan = pathArray.indexOf("Plans") >= 0 ? pathArray[pathArray.indexOf("Plans") + 1] : "";
    this.Current_Role = pathArray.indexOf("Roles") >= 0 ? pathArray[pathArray.indexOf("Roles") + 1] : "";
    this.Current_Section = pathArray[0];
    this.Current_SuccessFactor = pathArray.indexOf("SuccessFactors") >= 0 ? pathArray[pathArray.indexOf("SuccessFactors") + 1] : "";
    this.Current_TransactionType = pathArray.indexOf("TransactionType") >= 0 ? pathArray[pathArray.indexOf("TransactionType") + 1] : "";

    if (this.Current_NewSourceQualifier) this.Current_lvl = "Qualifier";
    else if (this.Current_Incentive_Attribute) this.Current_lvl = "Attributes";
    else if (this.Current_TransactionType) this.Current_lvl = "Attributes.TransactionType";
    else if (this.Current_Role) this.Current_lvl = "Roles";
    else if (this.Current_Incentive) this.Current_lvl = "Incentives";
    else if (this.Current_Plan) this.Current_lvl = "Plans";
    else this.Current_lvl = this.Current_Section;
    this.CurrentProps = {
      Incentive: this.Current_Incentive,
      Plan: this.Current_Plan,
      Section: this.Current_Section,
      Attribute: this.Current_Incentive_Attribute,
      Role: this.Current_Role,
      SuccessFactor: this.Current_SuccessFactor,
      TransactionType: this.Current_TransactionType,
      NewSourceQualifier: this.Current_NewSourceQualifier,
    };

    this.AllLists["selectedIncentives"] = [];
    this.AllLists["selectedQualifierAttributes"] = [];
    if (this.GlobalVariables && this.GlobalVariables.PlanTitles) {
      let uniqueIncentives = [];
      this.GlobalVariables.PlanTitles.forEach(row => {
        if (!uniqueIncentives.includes(row.Incentive) && row.Incentive !== this.Current_Incentive && !this.AllLists.AllObjects[row.Incentive].invalid) {
          this.AllLists["selectedIncentives"].push({
            id: row.Incentive,
            name: getNamesForListID(row.Incentive, this.AllLists["Incentives"]),
          });
          uniqueIncentives.push(row.Incentive);
        }
      });
    }

    if (this.GlobalVariables.SelectedSources) {
      this.AllLists["selectedSources"] = this.GlobalVariables.SelectedSources.map(item => {
        return { id: item, name: getNamesForListID(item, this.AllLists["Sources"]) };
      });
    }
    let arr = [];
    let incentive = this.Current_Incentive;

    if (this.GlobalVariables && this.GlobalVariables.IncentiveComputationAttributes) {
      let compAttributes = null;
      compAttributes = this.GlobalVariables.IncentiveComputationAttributes.filter(row => row.Incentive === incentive)[0];
      while (this.getSimilarCrediting(incentive, this.Current_Incentive)) {
        incentive = this.getSimilarCrediting(incentive, this.Current_Incentive);
        compAttributes = this.GlobalVariables.IncentiveComputationAttributes.filter(row => row.Incentive === incentive)[0];
      }
      if (compAttributes) {
        compAttributes = compAttributes.val;
        ["Monthly", "Quarterly", "QTD", "Semi-Annual", "HTD", "Yearly", "YTD"].forEach(period => {
          for (let attr of compAttributes) {
            let periodId = `${period} ${attr}`;
            let name = `${period} ${getNamesForListID(attr, this.AllLists["AllAttributes"])}`;
            arr.push({ id: periodId, name: name });
            this.AllLists.AllObjects[periodId] = { type: "ComputationAttributesPeriods", name: name };
          }
        });
      }
    }
    this.AllLists["ComputationAttributesPeriods"] = arr;

    //create list for multiple credit types.
    if (this.GlobalVariables.incentiveTransactionTypes) {
      this.AllLists.currentIncentiveTransactionTypes = [...this.AllLists.Sources].filter(source => {
        let match = false;
        this.GlobalVariables.incentiveTransactionTypes.forEach(tran => {
          if (tran.Incentive === incentive && tran.val.includes(source.id)) {
            match = true;
          }
        });
        return match;
      });
    }
    this.resetItems();
  };

  resetItems = () => {
    //tabular data sent by child components needs to be reset after each question.
    this.tabularAnswer = {};
    this.NotesDisplay = "hidden";
    this.CalendarBuilder = "";
    this.RateTableBuilder = "";
    this.DataTableBuilder = "";
    this.DataDerivation = "";
    this.TableBuilder = "";
    this.TitlesTableBuilder = "";
  };

  //This function updates the schema before calling the set state function.
  //used for pulling in default answers and creating custom form widgets.
  UpdateSchema = async (id, formData) => {
    let DynamicComponent;
    this.RenderedQuestionId = id;
    this.setState({ tableWidgetValidationErr: "" }); // remove table error if any.
    localStorage.setItem("lastRendered", this.RenderedQuestionId);
    this.GlobalVariables["lastRendered"] = this.RenderedQuestionId;
    var resp = this.getQuestionDetails(id);
    var qid = id.split(".");

    if (resp.prop.componentMapping) {
      let ver = resp.prop.componentMapping.version;
      if (ver) {
        this.dynamicComponentMapping[resp.prop.componentMapping.componentKey] = await ComponentProvider.getComponent(resp.prop.componentMapping.componentKey, ver);
      }
    }

    //Check for complex input types like table builders. This involves invoking a child component based on the complex type.
    let fieldType = resp.prop.type;
    switch (resp.prop.type) {
      case "Table-Builder":
        resp.prop.type = "string";
        this.TableBuilder = (
          <div className="SubSection">
            <GenericTable
              currData={this.tabularAnswer}
              cols={resp.prop.columns}
              lists={this.AllLists}
              editableCols={resp.prop.editableColumns}
              editableColumnProps={resp.prop.editableColumnProps}
              mergeKey={resp.prop.mergeKey}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
              variables={this.GlobalVariables}
              fillVariable={resp.prop.fillVariable}
              disableAdditionalRows={resp.prop.disableAdditionalRows}
              autoRefresh={resp.prop.autoRefresh}
              disableRefresh={resp.prop.disableRefresh}
              currProps={{
                plan: this.Current_Plan,
                incentive: this.Current_Incentive,
              }}
            />
          </div>
        );
        break;
      case "PlanSource-Table-Builder":
        resp.prop.type = "string";
        //1) Adjust columns:
        let newColumns = [];
        let newColumnProps = {};
        let newEditableColumns = []; //<TODO>: Need to fix this one here:
        let newColumnProperties = {};
        if (resp.prop.columns) {
          resp.prop.columns.forEach(column => {
            if (column.startsWith("?{")) {
              //geteitablecolumn props
              let columnProp = { type: "textarea", nullable: true };
              if (resp.prop.hasOwnProperty("editableColumnProps") && resp.prop.editableColumnProps.hasOwnProperty(column)) {
                columnProp = resp.prop.editableColumnProps[column];
              }
              //check for editable columns:
              let isEditable = false;
              if (resp.prop.hasOwnProperty("editableColumns")) {
                resp.prop.editableColumns.forEach(editableColumn => {
                  if (editableColumn === column) {
                    isEditable = true;
                  }
                });
              }
              let columnProperty = { label: true };
              if (resp.prop.hasOwnProperty("columnProperties") && resp.prop.columnProperties.hasOwnProperty(column)) {
                columnProperty = resp.prop.columnProperties[column];
              }

              let path = column.slice(2, column.length - 1).replace(new RegExp("/", "g"), ".");
              let searchObject = path.split(".")[0];
              path = path.replace(searchObject + ".", "");
              objectpath.get(this[searchObject], path).forEach(item => {
                newColumns.push(item);
                newColumnProps[item] = columnProp;
                newColumnProperties[item] = columnProperty;
                if (isEditable) {
                  newEditableColumns.push(item);
                }
              });
            } else {
              newColumns.push(column);
              if (resp.prop.hasOwnProperty("columnProperties") && resp.prop.columnProperties.hasOwnProperty(column)) {
                newColumnProperties[column] = resp.prop.columnProperties[column];
              }
            }
          });
        }
        //<TODO>: Find a way to factor in column properties
        //2) <TODO>: Need to think about refactoring this
        //harcoded solution, adds in automation row and removes headers from display:
        this.TableBuilder = (
          <div className="SubSection">
            {" "}
            <GenericTable
              currData={this.tabularAnswer}
              cols={newColumns}
              lists={this.AllLists}
              editableCols={newEditableColumns}
              editableColumnProps={newColumnProps}
              columnProperties={newColumnProperties}
              headerCheckbox={true}
              mergeKey={resp.prop.mergeKey}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
              variables={this.GlobalVariables}
              fillLists={resp.prop.fillLists}
              defaultRows={resp.prop.defaultRows}
              selectAll={resp.prop.selectAll}
              disableAdditionalRows={resp.prop.disableAdditionalRows}
              autoRefresh={resp.prop.autoRefresh}
              disableRefresh={resp.prop.disableRefresh}
            />
          </div>
        );
        break;
      case "Rate-Table-Builder":
        DynamicComponent = this.dynamicComponentMapping.RateTableBuilder ? this.dynamicComponentMapping.RateTableBuilder : RateTableBuilder; // for backward compatibility
        resp.prop.type = "string";
        this.RateTableBuilder = (
          <div className="SubSection">
            {" "}
            <DynamicComponent
              saveTablularanswers={ins => (this.updateTabAnswers = ins)}
              currData={this.tabularAnswer}
              answers={this.AllResponses}
              lists={this.AllLists}
              GlobalVariables={this.GlobalVariables}
              currProps={{
                plan: this.Current_Plan,
                incentive: this.Current_Incentive,
              }}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
              defaultName={defaultValue(fieldType, this.CurrentProps, this.GlobalVariables, this.AllLists, qid[qid.length - 1])}
            />
          </div>
        );
        break;
      case "Calendar-Builder":
        resp.prop.type = "string";
        this.CalendarBuilder = (
          <div className="SubSection">
            {" "}
            <CalendarBuilder currData={this.tabularAnswer} variables={this.GlobalVariables} tableData={this.TableSubmission.bind(this)} disableParent={this.subComponentChanged.bind(this)} />
          </div>
        );
        break;
      case "Data-Table-Builder":
        resp.prop.type = "string";
        DynamicComponent = this.dynamicComponentMapping.DataMappingTable ? this.dynamicComponentMapping.DataMappingTable : DataTableBuilder; // for backward compatibility

        this.DataTableBuilder = (
          <div className="SubSection">
            <DynamicComponent
              ref={this.dataTableRef}
              saveTablularanswers={click => (this.updateTabAnswers = click)}
              ListComponent={this.dynamicComponentMapping.UpdateLists || UpdateLists}
              tableType={resp.prop.setVariable.name}
              hrIntegration={this.GlobalVariables.hrIntegration | false}
              currData={this.tabularAnswer}
              lists={this.AllLists}
              variables={this.GlobalVariables}
              tableData={this.TableSubmission.bind(this)}
              updatedLists={newlists => {
                this.AllLists = newlists;
                this.props.projectDetails.AllLists = newlists;
              }}
              disableParent={this.subComponentChanged.bind(this)}
              saveProjectDetails={this.saveProjectDetails.bind(this)}
              customerId={this.props.customerId}
              projectId={this.props.projectId}
              SendQuestionPath={this.handleSelect}
            />
          </div>
        );
        break;
      case "Data-Derivation":
        resp.prop.type = "string";
        this.DataDerivation = (
          <div className="SubSection">
            <DataDerivation
              tableType={resp.prop.setVariable ? resp.prop.setVariable.name : "DataDerivation"}
              lists={this.AllLists}
              currData={this.tabularAnswer}
              answers={this.AllResponses}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
            />
          </div>
        );
        break;
      case "CreditResults-Table-Builder":
        resp.prop.type = "string";
        let tColumns = [],
          tColumnsProps = [],
          tEditableColumns = [],
          tEditableColumnsProps = [];
        if (resp.prop.columns) {
          resp.prop.columns.forEach(column => {
            tColumns.push(column);
            if (resp.prop.editableColumns && resp.prop.editableColumns.includes(column)) {
              tColumnsProps.push(column);
            }
            if (resp.prop.hasOwnProperty("columnProperties") && resp.prop.columnProperties.hasOwnProperty(column)) {
              tEditableColumns[column] = resp.prop.columnProperties[column];
            }
            if (resp.prop.hasOwnProperty("editableColumnProps") && resp.prop.editableColumnProps.hasOwnProperty(column)) {
              tEditableColumnsProps[column] = resp.prop.editableColumnProps[column];
            }
          });
        }
        this.TableBuilder = (
          <div className="SubSection">
            <CreditResultsTable
              currData={this.tabularAnswer}
              lists={this.AllLists}
              cols={tColumns}
              columnProperties={tEditableColumns}
              editableCols={tColumnsProps}
              editableColumnProps={tEditableColumnsProps}
              mergeKey={resp.prop.mergeKey}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
              variables={this.GlobalVariables}
              fillVariable={resp.prop.fillVariable}
              defaultRows={resp.prop.defaultRows}
              selectAll={resp.prop.selectAll}
              allColumnSelector={resp.prop.allColumnSelector} // Provide columnName which will trigger selecting all editable columns for a row.
              disableAdditionalRows={resp.prop.disableAdditionalRows}
              autoRefresh={resp.prop.autoRefresh}
              disableRefresh={resp.prop.disableRefresh}
              getBorrowedCredits={this.getBorrowedCredits.bind(this)}
              currProps={{
                plan: this.Current_Plan,
                incentive: this.Current_Incentive,
              }}
            />
          </div>
        );
        break;
      case "Titles-Table-Builder":
        resp.prop.type = "string";
        this.TitlesTableBuilder = (
          <div className="SubSection">
            <TitlesTableBuilder
              currData={this.tabularAnswer}
              lists={this.AllLists}
              cols={resp.prop.columns}
              editableCols={resp.prop.editableColumns}
              editableColumnProps={resp.prop.editableColumnProps}
              mergeKey={resp.prop.mergeKey}
              tableData={this.TableSubmission.bind(this)}
              disableParent={this.subComponentChanged.bind(this)}
              variables={this.GlobalVariables}
              fillLists={resp.prop.fillLists}
              autoRefresh={resp.prop.autoRefresh}
              currProps={{
                plan: this.Current_Plan,
                incentive: this.Current_Incentive,
                customerId: this.props.customerId,
                projectId: this.props.projectId,
              }}
              saveProjectDetails={this.saveProjectDetails}
            />
          </div>
        );

        break;
      default:
        this.CalendarBuilder = null;
        this.RateTableBuilder = null;
        this.DataTableBuilder = null;
        this.DataDerivation = null;
        this.TableBuilder = null;
        this.TitlesTableBuilder = null;
        break;
    }
    //update Bread crumb
    BreadCrumb = this.updateBreadCrumb();
    Ques_Schema = {
      title: BreadCrumb,
      type: "object",
      required: ["Response"],
      properties: {
        Response: resp.prop,
        Notes: { type: "string", title: "Additional Notes: " },
        TableValidation: { type: "string" },
      },
      widgetType: fieldType,
    };

    if (formData.Notes) {
      this.NotesDisplay = "textarea";
    }

    UISchema = {
      Response: resp.prop.ui || {},
      Notes: { "ui:widget": this.NotesDisplay },
      TableValidation: { "ui:widget": "hidden", "ui:disabled": true },
    };

    //update parking lot item display. If current rendered question exists in the parking lot items, then show remove icon, else show add icon
    if (this.props.allowParkingLot) {
      if (!this.AllLists["ParkingLot"]) this.AllLists["ParkingLot"] = [];
      // Adding flag to disable all action on this current question.
      const disableQueActions = !!(this.state.sectionLockStatus.isLocked && this.Current_Section === this.state.sectionLockStatus.section);
      if (!this.AllLists["ParkingLot"].includes(this.RenderedQuestionId)) {
        this.parkingLotItemsButton = (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="ParkingLotTip">
                <strong>Add To Parking Lot Items</strong>
              </Tooltip>
            }
          >
            <button type="button" onClick={this.AddParkingItem} className="btn btn-primary RightAlign btn-padded" disabled={disableQueActions}>
              <i className="glyphicon glyphicon-flag " />
            </button>
          </OverlayTrigger>
        );
      } else {
        this.parkingLotItemsButton = (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="ParkingLotTip">
                <strong>Remove from Parking Lot Items</strong>
              </Tooltip>
            }
          >
            <button type="button" onClick={this.RemoveParkingItem} className="btn btn-warning RightAlign btn-padded" disabled={disableQueActions}>
              <i className="glyphicon glyphicon-minus-sign " />
            </button>
          </OverlayTrigger>
        );
      }
    }
    let SavedAnswer = {};
    this.savedResponseFound = false;
    if (formData.hasOwnProperty("Response")) {
      SavedAnswer.Notes = formData.Notes;
      var temporaryElementToDecode = document.createElement("textarea");
      if (formData.Notes) {
        temporaryElementToDecode.innerHTML = formData.Notes;
        SavedAnswer.Notes = typeof formData.Notes == "object" || typeof formData.Notes == "number" ? formData.Notes : temporaryElementToDecode.value;
        formData.Notes = typeof formData.Notes == "object" || typeof formData.Notes == "number" ? formData.Notes : temporaryElementToDecode.value;
      }
      if (formData.Response && Array.isArray(formData.Response) && formData.Response.length > 0 && formData.Response !== "EmptyField") {
        this.savedResponseFound = true;
      }
      if (formData.Response && formData.Response.hasOwnProperty("tableName")) {
        temporaryElementToDecode.innerHTML = formData.Response.tableName;
        SavedAnswer["Response"] = typeof formData.Response.tableName == "object" ? formData.Response.tableName : temporaryElementToDecode.value;
        this.tabularAnswer.tableName = typeof formData.Response.tableName == "object" ? formData.Response.tableName : temporaryElementToDecode.value;
        if (formData.Response.hasOwnProperty("tableList")) {
          temporaryElementToDecode.innerHTML = formData.Response.tableList;
          this.tabularAnswer.tableList = typeof formData.Response.tableList == "object" ? formData.Response.tableList : temporaryElementToDecode.value;
        } else {
          this.tabularAnswer["columns"] = formData.Response.columns;
          this.tabularAnswer["data"] = formData.Response.data;
        }
      } else if (formData.Response === "EmptyField" || (Array.isArray(formData.Response) && formData.Response.length === 0)) {
        SavedAnswer = defaultValue(fieldType, this.CurrentProps, this.GlobalVariables, this.AllLists, qid[qid.length - 1]);
      } else if (resp.prop.bindToList && !["ComputationAttributes", "QualifierAttributes"].includes(resp.prop.bindToList)) {
        SavedAnswer.Response = this.matchResponseListOrder(formData.Response, resp.prop.bindToList, resp.prop.items);
      } else if (resp.prop && resp.prop.properties && resp.prop.properties.aggregateQuotaIntegration) {
        SavedAnswer = formData;
      } else {
        // Iterate over then Response object and remove duplicate and dot from child array values.
        for (const key in formData.Response) {
          if (Array.isArray(formData.Response[key])) {
            if (Array.isArray(formData.Response[key])) {
              let modifiedformData = formData.Response[key].map(item => {
                if (typeof item === "string") {
                  return item.split(".")[0];
                }
                return item;
              });
              formData.Response[key] = [...new Set(modifiedformData)];
            }
            // The above method is written to replace the below one
            // formData.Response[key] = [...new Set(formData.Response[key].map(item => item.split(".")[0]))];
          }
        }
        SavedAnswer = formData;
      }
      this.setState({
        showAddOptionForm: false,
        Current_QID: qid[qid.length - 1],
        formData: SavedAnswer,
      });
    } else {
      this.setState({
        showAddOptionForm: false,
        Current_QID: qid[qid.length - 1],
      });
    }
  };

  addListItem = (title, type) => {
    let listType = type;
    let key = generateID(listType);
    let NewLists = this.AllLists;
    let listItem = { name: title, id: key };
    if (!NewLists.AllObjects) {
      NewLists.AllObjects = {};
    }
    NewLists[listType].push(listItem);
    NewLists.AllObjects[key] = { name: title, type: listType };
    this.setState({ dataReady: false });
    let listData = {};
    listData[listType] = NewLists[listType];
    listData.AllObjects = NewLists.AllObjects;
    server.updateLists(this.props.customerId, this.props.projectId, listData, response => {
      if (response.status === 200) {
        this.saveProjectDetails(response.data.object[0]);
        this.AllLists = NewLists;
      } else {
      }
      this.setState({ dataReady: true });
    });
    return key;
  };

  updateBreadCrumb = () => {
    let currentSection = this.Current_Section === "GenericInfo" ? "Implementation Survey" : this.Current_Section;

    return `${[
      currentSection,
      getNamesForListID(this.Current_SuccessFactor, this.AllLists.SuccessFactors),
      getNamesForListID(this.Current_Plan, this.AllLists.Plans),
      getNamesForListID(this.Current_Incentive, this.AllLists.Incentives),
      getNamesForListID(this.Current_Role, this.AllLists.Titles),
      getNamesForListID(this.Current_TransactionType, this.AllLists.Sources),
      getNamesForListID(this.Current_Incentive_Attribute, this.AllLists.AllAttributes),
      getNamesForListID(this.Current_NewSourceQualifier, this.AllLists["AllAttributes"]),
    ]
      .filter(Boolean)
      .join("/")}`;
  };
  //Need to look at this next:
  //pulls in list data, used for questions that have modifieedList items:
  updateEnumValues = (ques, id = "") => {
    if (ques.bindToGlobalVar && objectpath.has(this.GlobalVariables, ques.bindToGlobalVar)) {
      let listArray = objectpath.get(this.GlobalVariables, ques.bindToGlobalVar);

      // Following method is to set crediting results as QueShcema options, from current & similar incentives.
      const setQueSchemaProps = (AttrList, CurrentIncentive, CurrentPlan, checkInAllPlans = false) => {
        let enumArr = [],
          enumNamesArr = [];

        let currIncentiveAttr = [];
        if (ques.showExistingDirectCredits) {
          currIncentiveAttr = AttrList.filter(listItem => {
            if (listItem.Plan === CurrentPlan) {
              // Ignore all crediting attributes from current plan, if any.
              return false;
            } else {
              // Check if any title inside a Plan>Incentive is receiving direct credit.
              let hasDirectCredit = false;
              for (let index = 0; index < this.GlobalVariables.PlanIncentiveCredits.length; index++) {
                if (
                  this.GlobalVariables.PlanIncentiveCredits[index].Plan === listItem.Plan &&
                  this.GlobalVariables.PlanIncentiveCredits[index].Incentive === listItem.Incentive &&
                  this.GlobalVariables.PlanIncentiveCredits[index].val.includes("Direct")
                ) {
                  hasDirectCredit = true;
                  break;
                }
              }
              return hasDirectCredit;
            }
          });
        } else if (ques.listOtherPlans) {
          currIncentiveAttr = AttrList.filter(listItem => listItem.Plan !== this.Current_Plan);
        } else {
          currIncentiveAttr = AttrList.filter(listItem => (checkInAllPlans || listItem.Plan === CurrentPlan) && listItem.Incentive === CurrentIncentive);
        }

        currIncentiveAttr.forEach(item => {
          if (Array.isArray(item.val)) {
            item.val.forEach(val => {
              const groupName = ques.listOtherPlans ? item.Plan : item.TransactionType;
              enumArr.push(`${val} From ${groupName}`);
              let nameString = `${this.AllLists["AllObjects"][val] && this.AllLists["AllObjects"][val].name ? this.AllLists["AllObjects"][val].name : val} From ${
                this.AllLists["AllObjects"][groupName] && this.AllLists["AllObjects"][groupName].name ? this.AllLists["AllObjects"][groupName].name : groupName
              }`;
              enumNamesArr.push(nameString);
            });
          }
        });

        if (id.split(".").includes("Comp_Incentive37_1")) {
          const borrowCredits = this.getBorrowedCredits();
          borrowCredits.forEach(creditString => {
            const itemArr = creditString.split(" From ");
            enumArr.push(creditString);
            let nameString = `${this.AllLists["AllObjects"][itemArr[0]] && this.AllLists["AllObjects"][itemArr[0]].name ? this.AllLists["AllObjects"][itemArr[0]].name : itemArr[0]} From ${
              this.AllLists["AllObjects"][itemArr[1]] && this.AllLists["AllObjects"][itemArr[1]].name ? this.AllLists["AllObjects"][itemArr[1]].name : itemArr[1]
            }`;
            enumNamesArr.push(nameString);
          });
        }

        if (enumArr.length) {
          if (ques.groupWithSimilarComp) {
            ques = {
              title: `Please select which of ${this.AllLists["AllObjects"][CurrentIncentive].name} crediting attributes will contribute to this incentive.`,
              type: "array",
              items: {
                type: "string",
                enum: enumArr,
                enumNames: enumNamesArr,
              },
              minItems: 1,
              uniqueItems: true,
              addable: false,
              ui: { "ui:widget": "checkboxes" },
              parseTag: "SimiliarCreditingAttributes",
            };
          } else {
            ques.items.enum = ques.items.enum.concat(enumArr);
            ques.items.enumNames = ques.items.enumNames.concat(enumNamesArr);
          }
        }
      };

      setQueSchemaProps(listArray, this.Current_Incentive, this.Current_Plan);
      const similarIncentive = this.getSimilarCrediting(this.Current_Incentive, this.Current_Incentive);
      const hasDirectCredit = this.GlobalVariables.PlanIncentiveCredits.find(item => item.Plan === this.Current_Plan && item.Incentive === this.Current_Incentive && item.val.includes("Direct"));
      const hasOtherPlans = this.GlobalVariables.PlanIncentives ? this.GlobalVariables.PlanIncentives.filter(row => row.Plan !== this.Current_Plan) : [];
      if (similarIncentive && (hasDirectCredit || hasOtherPlans.length < 1)) {
        setQueSchemaProps(listArray, similarIncentive, this.Current_Plan, true);
      }
      return ques;
    }
    if (ques.bindToList && objectpath.has(this.AllLists, ques.bindToList)) {
      let listArray = objectpath.get(this.AllLists, ques.bindToList),
        enumArr = [],
        enumNamesArr = [];
      if (id && id.endsWith("Comp_Incentive6")) {
        listArray = listArray.filter(item => item.dataType === "number");
      }

      listArray.forEach(item => {
        //the null coalescing is done for backward compatibility. Old projects will not have id and names, instead it would have a simple array.
        enumArr.push(item.id || item);
        enumNamesArr.push(item.name || item);
      });

      if (ques.type === "array") {
        ques.items.enum = ques.items.enum.concat(enumArr);
        ques.items.enumNames = ques.items.enumNames.concat(enumNamesArr);
      } else {
        ques.enum = enumArr.length > 0 ? enumArr : ques.enum;
        ques.enumNames = enumNamesArr.length > 0 ? enumNamesArr : ques.enumNames;
      }

      return ques;
    } else {
      if (ques.properties) {
        Object.values(ques.properties).forEach(prop => {
          return this.updateEnumValues(prop);
        });
      }
      if (ques.dependencies) {
        Object.values(ques.dependencies).forEach(dep => {
          if (dep.oneOf) {
            dep.oneOf.forEach(option => {
              return this.updateEnumValues(option);
            });
          }
        });
      }
    }

    return ques;
  };

  getQuestionDetails = id => {
    //This function accepts a question id and searches the master list to retreive the details.
    this.schemaPath = extractSchemaPath(id, Section_Hierarchy);
    //JSON parse and stringify are used as in JS objects are assigned via reference. If this is not used then the original Questions schema will be modified. JSON parse/stringify result in assigning of value and not reference.

    var newQues = JSON.parse(JSON.stringify(objectpath.get(this.Questions, this.schemaPath.fullSchemaPath)));
    newQues.title = this.evaluateString(
      {
        Plan: getNamesForListID(this.Current_Plan, this.AllLists["Plans"]),
        Incentive: getNamesForListID(this.Current_Incentive, this.AllLists["Incentives"]),
        Attribute: getNamesForListID(this.Current_Incentive_Attribute, this.AllLists["AllAttributes"]),
        Role: getNamesForListID(this.Current_Role, this.AllLists["Titles"]),
        SuccessFactor: getNamesForListID(this.Current_SuccessFactor, this.AllLists["SuccessFactors"]), //this.AllLists["AllObjects"][this.Current_SuccessFactor] ? this.AllLists["AllObjects"][this.Current_SuccessFactor].name : this.Current_SuccessFactor,
        transactionType: getNamesForListID(this.Current_TransactionType, this.AllLists["Sources"]),
        NewSourceQualifier: getNamesForListID(this.Current_NewSourceQualifier, this.AllLists["AllAttributes"]),
      },
      newQues.title,
    );
    if (newQues.properties && newQues.properties.attributeOperation && newQues.properties.attributeOperation.title) {
      newQues.properties.attributeOperation.title = this.evaluateString(
        {
          Plan: getNamesForListID(this.Current_Plan, this.AllLists["Plans"]),
          Incentive: getNamesForListID(this.Current_Incentive, this.AllLists["Incentives"]),
          Attribute: getNamesForListID(this.Current_Incentive_Attribute, this.AllLists["AllAttributes"]),
          Role: getNamesForListID(this.Current_Role, this.AllLists["Titles"]),
          SuccessFactor: getNamesForListID(this.Current_SuccessFactor, this.AllLists["SuccessFactors"]), //this.AllLists["AllObjects"][this.Current_SuccessFactor] ? this.AllLists["AllObjects"][this.Current_SuccessFactor].name : this.Current_SuccessFactor,
          transactionType: getNamesForListID(this.Current_TransactionType, this.AllLists["Sources"]),
          NewSourceQualifier: getNamesForListID(this.Current_NewSourceQualifier, this.AllLists["AllAttributes"]),
        },
        newQues.properties.attributeOperation.title,
      );
    }
    newQues = this.updateEnumValues(newQues, id);
    return { prop: newQues };
  };
  //<TODO>:Refactor
  createResponsePath = () => {
    return [
      this.Current_Section,
      this.Current_SuccessFactor ? "SuccessFactors" : "",
      this.Current_SuccessFactor,
      this.Current_Plan ? "Plans" : "",
      this.Current_Plan,
      this.Current_Incentive ? "Incentives" : "",
      this.Current_Incentive,
      this.Current_Role ? "Roles" : "",
      this.Current_Role,
      this.Current_Incentive_Attribute || this.Current_TransactionType ? "Attributes" : "",
      this.Current_TransactionType ? "TransactionType" : "",
      this.Current_TransactionType,
      this.Current_Incentive_Attribute ? this.Current_Incentive_Attribute_Type : "",
      this.Current_Incentive_Attribute,
      this.Current_NewSourceQualifier ? "NewSourceQualifier" : "",
      this.Current_NewSourceQualifier ? this.Current_NewSourceQualifier : "",
      "Questions",
      this.state.Current_QID,
    ]
      .filter(Boolean)
      .join(".");
  };

  createFullPathFromSchema = (schemaPath, loopPath = false) => {
    let schemaPathArray = schemaPath.split(".");
    if (schemaPathArray.includes("Plans")) schemaPathArray.splice(schemaPathArray.indexOf("Plans") + 1, 0, this.Current_Plan);
    if (schemaPathArray.includes("Incentives")) schemaPathArray.splice(schemaPathArray.indexOf("Incentives") + 1, 0, this.Current_Incentive);
    if (schemaPathArray.includes("TransactionType")) schemaPathArray.splice(schemaPathArray.indexOf("TransactionType") + 1, 0, this.Current_TransactionType);
    // if (schemaPathArray.includes("Attributes")) schemaPathArray.splice(schemaPathArray.indexOf("Attributes") + 2, 0, this.Current_Incentive_Attribute);
    if (schemaPathArray.includes("Qualifier")) schemaPathArray.splice(schemaPathArray.indexOf("Qualifier") + 1, 0, this.Current_Incentive_Attribute);
    if (schemaPathArray.includes("NewSourceQualifier")) schemaPathArray.splice(schemaPathArray.indexOf("NewSourceQualifier") + 1, 0, this.Current_NewSourceQualifier);
    if (schemaPathArray.includes("Computation")) schemaPathArray.splice(schemaPathArray.indexOf("Computation") + 1, 0, this.Current_Incentive_Attribute);
    if (schemaPathArray.includes("Rate")) schemaPathArray.splice(schemaPathArray.indexOf("Rate") + 1, 0, this.Current_Incentive_Attribute);
    if (schemaPathArray.includes("Roles")) schemaPathArray.splice(schemaPathArray.indexOf("Roles") + 1, 0, this.Current_Role);
    if (schemaPathArray.includes("SuccessFactors")) schemaPathArray.splice(schemaPathArray.indexOf("SuccessFactors") + 1, 0, this.Current_SuccessFactor);

    if (loopPath) {
      schemaPathArray.pop();
    }
    let fullSchemaPath = schemaPathArray.join(".");

    return fullSchemaPath;
  };

  //Adds dependent questions to parking lot if a title is changed for a given question.
  //<TODO>: Refactor after looking at the
  updateRateWeightTables = (formData, qid) => {
    let rateTableTarget = "",
      weightTableTarget = "",
      updateRate = false,
      updateWeight = false;
    if ((qid === "Comp_Incentive100" && formData.Response) || (qid === "Comp_Incentive48" && formData.Response[0] !== "None") || (qid === "Comp_Incentive0_0" && formData.Response)) {
      let targetIncentive = qid === "Comp_Incentive48" ? formData.Response[0] : this.Current_Incentive;
      //find the rate table to be updated
      if (this.GlobalVariables.RateTablePaths) {
        this.GlobalVariables.RateTablePaths.forEach(row => {
          if (row.split(".")[row.split(".").indexOf("Incentives") + 1] === targetIncentive) {
            rateTableTarget = row;
          }
        });
      }
      if (rateTableTarget && objectpath.get(this.AllResponses, rateTableTarget) && objectpath.get(this.AllResponses, rateTableTarget).answer) {
        updateRate = true;
      }
      if (updateRate) {
        let reqData = {
          questionResponse: objectpath.get(this.AllResponses, `${rateTableTarget}`),
          path: this.Path,
          sectionPaths: this.SectionPaths,
          lastRendered: localStorage.getItem("lastRendered"),
          updateFlag: true,
          quesSchema: Ques_Schema,
        };
        this.updateQuestions.push({ customerId: this.props.customerId, projectId: this.props.projectId, path: rateTableTarget, reqData: reqData });

        objectpath.set(this.AllResponses, `${rateTableTarget}.answer.Notes`, "Review this table as more assignments were added.");
        if (!this.AllLists["ParkingLot"].includes(rateTableTarget)) {
          this.AllLists["ParkingLot"].push(rateTableTarget);
        }
      }
      //find the Weight Tables to be updated
      if (this.GlobalVariables.WeightTablePaths) {
        this.GlobalVariables.WeightTablePaths.forEach(row => {
          if (row.split(".")[row.split(".").indexOf("Incentives") + 1] === targetIncentive) {
            weightTableTarget = row;
          }
        });
      }
      if (weightTableTarget && objectpath.get(this.AllResponses, weightTableTarget) && objectpath.get(this.AllResponses, weightTableTarget).answer) {
        updateWeight = true;
      }
      if (updateWeight) {
        let reqData = {
          questionResponse: objectpath.get(this.AllResponses, `${weightTableTarget}`),
          path: this.Path,
          sectionPaths: this.SectionPaths,
          lastRendered: localStorage.getItem("lastRendered"),
          updateFlag: true,
          quesSchema: Ques_Schema,
        };
        this.updateQuestions.push({ customerId: this.props.customerId, projectId: this.props.projectId, path: weightTableTarget, reqData: reqData });
        objectpath.set(this.AllResponses, `${weightTableTarget}.answer.Notes`, "Review this table as more assignments were added.");
        if (!this.AllLists["ParkingLot"].includes(weightTableTarget)) {
          this.AllLists["ParkingLot"].push(weightTableTarget);
        }
      }
    }
    server.updateLists(this.props.customerId, this.props.projectId, { ParkingLot: this.AllLists["ParkingLot"] }, response => {
      this.saveProjectDetails(response.data.object[0]);
    });
    this.updateDependentQuestions();
  };

  updateDependentQuestions() {
    this.updateQuestions.forEach(function (ques) {
      server.submitQuestionResponse(ques.customerId, ques.projectId, ques.path, ques.reqData, resp => {});
    });
  }

  SubmitResponse = ({ formData }) => {
    let preventNext = this.state.preventNext;
    if (preventNext) {
      formData = this.state.formData;
    }
    this.setState({ dataReady: false });
    const isSectionLocked = !!(this.state.sectionLockStatus.isLocked && this.Current_Section === this.state.sectionLockStatus.section);
    // create the object that will be inserted/updated into the database.
    var Responsepath = this.createResponsePath();
    this.SectionPaths[this.Current_Section][this.Path_Index] = Responsepath;
    this.Path[this.Path_Index] = Responsepath;
    var ans = { Response: "", Notes: "" };
    let currentAnswer = objectpath.get(this.AllResponses, Responsepath);
    if (currentAnswer && currentAnswer.answer && currentAnswer.answer.Response && currentAnswer.answer.Response.columns) {
      if (!this.tabularAnswer.columns) {
        server.log({ log: { log_message: "Empty table received from tabular subcomponent", user: this.props.user.name, module: "Questions", action: "Submit", qid: Responsepath } }, () => {});
        this.tabularAnswer = { columns: currentAnswer.answer.Response.columns, data: currentAnswer.answer.Response.data };
      }
    }

    if (this.tabularAnswer.columns || this.tabularAnswer.tableList) {
      this.tabularAnswer["tableName"] = formData.Response || "";
      if (this.tabularAnswer.tableList) {
        this.tabularAnswer.tableList[0].tableName = formData.Response;
      }
      ans.Response = this.tabularAnswer;
      ans.Notes = formData.Notes;
    } else ans = formData;
    if (["Comp_Incentive100", "Comp_Incentive48"].includes(this.state.Current_QID)) {
      this.updateRateWeightTables(formData, this.state.Current_QID);
    }

    var obj = {
      answer: ans,
      text: Ques_Schema.properties.Response.title,
    };

    objectpath.set(this.AllResponses, Responsepath, obj);

    if (!preventNext) {
      //this.setCurrentProps(Responsepath);
      ++this.Path_Index;
    }
    if (isSectionLocked) {
      // If current section is locked and disabled, only generate the next question.
      this.setCurrentProps(Responsepath);
      this.getNextQuestion(formData);
      this.setState({ dataReady: true });
    } else {
      let reqData = {
        questionResponse: obj,
        path: this.Path,
        sectionPaths: this.SectionPaths,
        lastRendered: localStorage.getItem("lastRendered"),
        updateFlag: this.savedResponseFound,
        quesSchema: Ques_Schema,
      };
      if (currentAnswer && currentAnswer._id) {
        reqData._id = currentAnswer._id;
      }
      if (!this.props.projectDetails.dataSourceDetailsSchema || dataSourceDetailsSchema !== this.props.projectDetails.dataSourceDetailsSchema) {
        reqData.dataSourceDetailsSchema = dataSourceDetailsSchema;
      }
      if (["Data.Questions.DataMapping", "Data.Questions.HRDataMapping"].includes(Responsepath)) {
        const ListObject = {};
        if (Responsepath === "Data.Questions.DataMapping") {
          ListObject["Sources"] = this.AllLists.Sources;
        }
        if (Responsepath === "Data.Questions.HRDataMapping") {
          ListObject["HRSources"] = this.AllLists.HRSources;
        }
        this.updateUsedFields(ListObject, reqData.questionResponse.answer.Response.data).then(res => {
          server.submitQuestionResponse(this.props.customerId, this.props.projectId, Responsepath, reqData, resp => {
            this.projectDetailBreakdown(resp.data.object[0]);
            // this.props.saveProjectDetails(resp.data.object[0]);
            this.saveProjectDetails(resp.data.object[0]); // To update local states along with parants.
            this.GlobalVariables = resp.GlobalVariables === undefined ? this.GlobalVariables : resp.GlobalVariables;
            if (!preventNext) {
              this.getNextQuestion(formData);
              this.setCurrentProps(Responsepath);
            } else {
              this.dataTableRef.current.resetTableWarning();
            }
            this.setState({ dataReady: true });
          });
        });
      } else {
        server.submitQuestionResponse(this.props.customerId, this.props.projectId, Responsepath, reqData, resp => {
          this.projectDetailBreakdown(resp.data.object[0]);
          if (Ques_Schema.widgetType === "Titles-Table-Builder") {
            this.saveProjectDetails(resp.data.object[0]); // To update local states along with parants.
          } else {
            this.props.saveProjectDetails(resp.data.object[0]);
          }
          this.GlobalVariables = resp.GlobalVariables === undefined ? this.GlobalVariables : resp.GlobalVariables;
          if (!preventNext) {
            this.setCurrentProps(Responsepath);
            this.getNextQuestion(formData);
          }
          this.setState({ dataReady: true });
        });
      }
    }
  };

  //<TODO>: Need to update these 2 now?
  resolveSectionEnd = (nextQues, schemaPathString, response) => {
    // schemaPathString = JSON.stringify(this.schemaPath.loopPath);
    if (this.schemaPath.enclosingLoopPath && this.Loop[JSON.stringify(this.schemaPath.enclosingLoopPath)]) {
      schemaPathString = JSON.stringify(this.schemaPath.enclosingLoopPath); //for new multiuser support
    } else {
      //for backward compatibillity where user is already inside a loop
      let arr = this.schemaPath.loopPath.replace(/["]/g, "").split(".");
      let p = arr.filter(p => Section_Hierarchy[p] !== undefined).join(".");
      schemaPathString = JSON.stringify(p);
    }

    let continueLoop = false;
    if (this.Loop[schemaPathString].Idx < this.Loop[schemaPathString].Values.length - 1) {
      continueLoop = true;
    } else {
      if (this.Loop[schemaPathString].checkParentLoop) {
        if (this.Loop[schemaPathString].parentLoop) {
          //if a parent loop is provided then use that instead.
          schemaPathString = JSON.stringify(this.createFullPathFromSchema(this.Loop[schemaPathString].parentLoop, true));
        } else {
          schemaPathString = JSON.stringify(this.schemaPath.parentLoopPath);
        }
        this.schemaPath = extractSchemaPath(`${schemaPathString.replace(/["]/g, "")}.Questions`, Section_Hierarchy);
        if (this.Loop[schemaPathString].Idx < this.Loop[schemaPathString].Values.length - 1) {
          continueLoop = true;
        }
      }

      nextQues.next_qid = this.Loop[schemaPathString].OnLoopBreak;
      nextQues.lvl = this.Loop[schemaPathString].OriginSection;
    }

    if (continueLoop) {
      this.Loop[schemaPathString].Idx = this.Loop[schemaPathString].Idx + 1;
      response = this.Loop[schemaPathString].Values[this.Loop[schemaPathString].Idx];

      let defaultLoopQuestion = findDependent(
        { Response: response },
        this.QuestionOrder,
        this.schemaPath.sectionPath,
        `${this.schemaPath.sectionPath}.Default`,
        this.Summary,
        this.CurrentProps,
        this.AllResponses,
        this.GlobalVariables,
        this.AllLists,
      );

      nextQues.next_qid = defaultLoopQuestion.next_qid;
      nextQues.sectionChange = true;
      nextQues.lvl = this.Loop[schemaPathString].LoopSection;
      nextQues.continueLoop = continueLoop;
    }
    server.updateLoop(this.props.customerId, this.props.projectId, this.Loop, resp => {
      this.saveProjectDetails(resp.data.object[0]);
    });
    return { schemaPathString, nextQues, response };
  };
  getNextQuestion = async (formData, quid, skipSection = false) => {
    const current_incentive = this.Current_Incentive;
    this.schemaPath = extractSchemaPath(this.createResponsePath(), Section_Hierarchy);

    if (skipSection) {
      this.schemaPath = extractSchemaPath(quid, Section_Hierarchy);
    }
    var nextQues = findDependent(
      formData,
      this.QuestionOrder,
      this.schemaPath.sectionPath,
      this.schemaPath.fullSchemaPath,
      this.Summary,
      this.CurrentProps,
      this.AllResponses,
      this.GlobalVariables,
      this.AllLists,
    );

    let schemaPathString = JSON.stringify(this.schemaPath.loopPath + "." + nextQues.lvl);

    //var response = typeof formData.Response === "object" ? formData.Response[0] : formData.Response;
    var response = Array.isArray(formData.Response) ? formData.Response[0] : formData.Response;

    if (nextQues.loop) {
      //This logic initiates a loop object that will keep track of the section that needs to looped over.
      //check if an entry for this loop already exists. This is needed to ensure loop works correctly when the answer to origin question is updated.

      this.Loop[schemaPathString] = {
        Values: formData.Response,
        Idx: 0,
        LoopSection: nextQues.lvl,
        OnLoopBreak: nextQues.onLoopBreak,
        OriginSection: nextQues.onLoopBreak.split(".")[nextQues.onLoopBreak.split(".").length - 3],
        checkParentLoop: nextQues.checkParentLoop,
        parentLoop: nextQues.parentLoop,
      };
      server.updateLoop(this.props.customerId, this.props.projectId, this.Loop, resp => {
        this.saveProjectDetails(resp.data.object[0]);
      });
    }

    // if no dependency found, it implies we need to check for a loop or go back to origin question and get the
    if (!nextQues.found) {
      console.log("Dependency not found in control file. check file");
    }
    //<TODO>: For Backward compatability (Schemas older than Jan 25, 2021)
    //if (nextQues.next_qid === "SectionEnd") {
    if (nextQues.next_qid === "SectionEnd") {
      let x = await this.resolveSectionEnd(nextQues, schemaPathString, response);
      schemaPathString = x.schemaPathString;
      nextQues = x.nextQues;
      response = x.response;
    }

    // If the next question in line belongs to a different section, the current section property needs to be updated.
    if (nextQues.sectionChange || this.Current_lvl !== nextQues.lvl) {
      switch (nextQues.lvl) {
        case "Plans":
          this.Current_Plan = Section_Hierarchy[nextQues.lvl] > Section_Hierarchy[this.Current_lvl] ? response : this.Current_Plan;
          this.Current_Incentive = "";
          this.Current_Incentive_Attribute = "";
          this.Current_Role = "";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "Organization":
          this.Current_Plan = "";
          this.Current_Incentive = "";
          this.Current_Incentive_Attribute = "";
          this.Current_Role = "";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";

          break;
        case "Incentives":
          if (Section_Hierarchy[nextQues.lvl] === Section_Hierarchy[this.Current_lvl] || nextQues.continueLoop) {
            this.Current_Incentive = this.Loop[schemaPathString].Values[this.Loop[schemaPathString].Idx];
          } else if (Section_Hierarchy[nextQues.lvl] > Section_Hierarchy[this.Current_lvl]) {
            this.Current_Incentive = response;
          }
          this.Current_Incentive_Attribute = "";
          this.Current_Incentive_Attribute_Type = "";
          this.Current_Role = "";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "Roles":
          if (Section_Hierarchy[nextQues.lvl] === Section_Hierarchy[this.Current_lvl]) {
            this.Current_Role = this.Loop[schemaPathString].Values[this.Loop[schemaPathString].Idx];
          } else if (Section_Hierarchy[nextQues.lvl] > Section_Hierarchy[this.Current_lvl]) this.Current_Role = response;

          this.Current_Incentive_Attribute = "";
          this.Current_Incentive_Attribute_Type = "";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "Attributes.Qualifier":
          this.Current_Incentive_Attribute = response;
          this.Current_Incentive_Attribute_Type = "Qualifier";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "NewSourceQualifier":
          this.Current_NewSourceQualifier = response;
          this.Current_SuccessFactor = "";
          break;
        case "Attributes.Computation":
          this.Current_Incentive_Attribute = response;
          this.Current_Incentive_Attribute_Type = "Computation";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "Attributes.Rate":
          this.Current_Incentive_Attribute = response;
          this.Current_Incentive_Attribute_Type = "Rate";
          this.Current_SuccessFactor = "";
          this.Current_TransactionType = "";
          break;
        case "SuccessFactors":
          this.Current_SuccessFactor = Section_Hierarchy[nextQues.lvl] > Section_Hierarchy[this.Current_lvl] ? response : this.Current_SuccessFactor;
          this.Current_Incentive = "";
          this.Current_Incentive_Attribute = "";
          this.Current_Role = "";
          this.Current_Plan = "";
          this.Current_TransactionType = "";
          break;
        case "Attributes.TransactionType":
          //(nextQues.lvl, Section_Hierarchy[nextQues.lvl], this.Current_lvl, Section_Hierarchy[this.Current_lvl]);
          if (Section_Hierarchy[nextQues.lvl] === Section_Hierarchy[this.Current_lvl]) {
            this.Current_TransactionType = this.Loop[schemaPathString].Values[this.Loop[schemaPathString].Idx];
          } else if (Section_Hierarchy[nextQues.lvl] > Section_Hierarchy[this.Current_lvl]) {
            this.Current_TransactionType = response;
          }

          this.Current_Incentive_Attribute = "";
          this.Current_Incentive_Attribute_Type = "";
          this.Current_SuccessFactor = "";
          break;
        case "Qualifier":
          this.Current_Incentive_Attribute = response;
          this.Current_Incentive_Attribute_Type = "Qualifier";
          this.Current_SuccessFactor = "";
          break;
        default:
      }
      let pathArray = nextQues.next_qid.split(".");
      const Next_Section = pathArray[0];
      const nextQueId = pathArray[pathArray.length - 1];
      if (this.refWebSocket && (this.Current_Section !== Next_Section || this.notificationId || nextQueId === "Org8" || this.state.Current_QID === "Org8")) {
        // Notify server If socket connection is available and 'section is being changed'.
        this.sendMessage(
          JSON.stringify({
            action: "SECTION_CHANGE",
            section: nextQueId === "Org8" ? "CommonQuestion" : Next_Section,
          }),
        );
      }
      this.Current_Section = Next_Section;
    }

    if (["Comp_Incentive47_2"].includes(this.state.Current_QID)) {
      // Create a session variable if in borrowing credit flow.
      // const BorrowingCredit = {
      //   plan: this.Current_Plan,
      //   incentive: this.Current_Incentive
      // };
      // sessionStorage.setItem("BorrowingCredit", JSON.stringify(BorrowingCredit));
      // Set Plan and incentive on which this direct credit resides on.
      // let resArray = response.split("From").map(item => item.trim());
      // this.Current_Plan = resArray[1];
      // this.Current_Incentive = resArray[0];
      // this.Current_Incentive_Attribute = "";
      // this.Current_Role = "";
      // this.Current_SuccessFactor = "";
      // this.Current_TransactionType = "";
    } else {
      const BorrowingCredit = sessionStorage.getItem("BorrowingCredit");
      if (BorrowingCredit && nextQues.lvl === "Incentives" && nextQues.next_qid.includes("Comp_Incentive10")) {
        // Navigate back to the Borrowing plan and incentive.
        nextQues.next_qid = "Organization.Plans.Incentives.Questions.Comp_Incentive47_1";
        this.Current_Plan = JSON.parse(BorrowingCredit).plan;
        this.Current_Incentive = JSON.parse(BorrowingCredit).incentive;
        this.Current_Incentive_Attribute = "";
        this.Current_Role = "";
        this.Current_SuccessFactor = "";
        this.Current_TransactionType = "";
        sessionStorage.removeItem("BorrowingCredit");
      }
    }

    //To change the list of inputs depending on attribute data type:
    if (nextQues.next_qid.split(".").includes("NewSourceQualifier1") && objectpath.get(this.Questions, `${nextQues.next_qid}_number`)) {
      let attributeDataType = "number";
      this.AllLists.AllAttributes.forEach(attr => {
        if (attr.id && attr.id === this.Current_NewSourceQualifier) {
          attributeDataType = attr.dataType;
        }
      });
      let newQuestionId = `${nextQues.next_qid}_${attributeDataType}`;
      nextQues.next_qid = newQuestionId;
    }

    //<TODO>: How to render the Submission Form now?
    //backwards compatable
    if (nextQues.next_qid === "SubmissionForm" || nextQues.next_qid === "Organization.Questions.Org1") {
      this.state.showCompletionForm = true;
    }

    //Set the state by updating the Current_QID. This will invoke the render function. Reset the add option object
    var ans = { Response: "EmptyField", Notes: "" };
    let id = this.createFullPathFromSchema(nextQues.next_qid);

    let a = objectpath.get(this.AllResponses, id);
    if (a && a.answer) {
      ans.Response = a.answer.hasOwnProperty("Response") ? a.answer.Response : "";
      ans.Notes = a.answer.hasOwnProperty("Notes") ? a.answer.Notes : "";
    }
    let result = this.UnfitSections.filter(section => section.incentiveId === this.Current_Incentive && section.unfit === true);
    if ((skipSection || (result.length > 0 && !a)) && current_incentive === this.Current_Incentive) {
      this.setCurrentProps(id);
      return this.getNextQuestion({ Response: "" }, this.createFullPathFromSchema(nextQues.next_qid), true);
    } else {
      this.UpdateSchema(id, {
        Response: ans.Response,
        Notes: ans.Notes,
      });
      return;
    }
  };

  //Evaluates the template string format of the question title and replaces variables with actual values.
  evaluateString = (param, title_string) => {
    var x = ES(title_string, param);
    return x;
  };

  PreviousQuestion = () => {
    this.setState({ dataReady: false });
    server.getProjectDetails(this.props.customerId, this.props.projectId, response => {
      if (response.status === 200) {
        if (["Comp_Incentive6_1"].includes(this.state.Current_QID)) {
          // Remove BorrowingCredit flow if user render previous ques then 'Comp_Incentive6_1'.
          sessionStorage.removeItem("BorrowingCredit");
        }

        this.Path_Index = this.Path_Index === 0 ? this.Path_Index : --this.Path_Index;
        // Check if path is valid and allowed to visit. else check previous que.
        while (!isValidPath(this.Path[this.Path_Index], this.AllLists.AllObjects) || !this.allowedSections.includes(this.Path[this.Path_Index].split(".")[0])) {
          this.Path_Index = this.Path_Index === 0 ? this.Path_Index : --this.Path_Index;
        }
        this.setCurrentProps(this.Path[this.Path_Index]);
        this.schemaPath = extractSchemaPath(this.Path[this.Path_Index], Section_Hierarchy);
        this.setState({ disableSubmit: false, dataReady: true }, () => {
          let ans = { Response: "EmptyField", Notes: "" };
          const queRes = objectpath.get(this.AllResponses, this.Path[this.Path_Index]);
          if (queRes && queRes.answer) {
            ans = queRes.answer;
          }
          //     following function updates loop index if applicable.
          this.saveProjectDetails(response.data.object[0], true);
          this.updateLoopStatus(this.Path[this.Path_Index]);
          this.UpdateSchema(this.Path[this.Path_Index], {
            Response: ans.Response,
            Notes: ans.Notes,
          });
        });
      }
    });
  };

  updateLoopStatus = id => {
    let schemaPath = extractSchemaPath(id, Section_Hierarchy);

    //for backward compatibillity where user is already inside a loop
    let arr = this.schemaPath.loopPath.replace(/["]/g, "").split(".");
    let p = arr.filter(p => Section_Hierarchy[p] !== undefined).join(".");
    let schemaPathString = JSON.stringify(p);
    if (schemaPath.enclosingLoopPath && this.Loop[JSON.stringify(schemaPath.enclosingLoopPath)]) {
      schemaPathString = JSON.stringify(this.schemaPath.enclosingLoopPath); //for new multiuser support
    }
    if (this.Loop[schemaPathString]) {
      let newIdx = this.Loop[schemaPathString]["Values"].indexOf(schemaPath.loopItem);
      if (newIdx < 0) {
        //for backward compatibillity where user is already inside a loop
        let loopItem = id.split(".")[id.split(".").indexOf(schemaPath.loopItem) + 1];
        newIdx = this.Loop[schemaPathString]["Values"].indexOf(loopItem);
      }
      this.Loop[schemaPathString]["Idx"] = newIdx;
    }
  };

  toggleOptionFormDisplay = () => {
    this.setState({ showAddOptionForm: !this.state.showAddOptionForm });
  };
  toggleComponentSummaryDisplay = () => {
    this.setState({ showComponentSummary: !this.state.showComponentSummary });
  };
  //here:
  saveProjectDetails = (projectData, prevData) => {
    this.projectDetailBreakdown(projectData);
    this.props.saveProjectDetails(projectData);
    let id = this.createFullPathFromSchema(this.schemaPath.fullSchemaPath);
    this.setCurrentProps(id);
    let resp = objectpath.get(this.AllResponses, id);
    let data = { Response: "EmptyField", Notes: "" };

    //   merge with current state
    if (resp && resp.answer) {
      data = { ...resp.answer };
      data.Notes = this.state.formData.Notes ? this.state.formData.Notes : data.Notes;
      if (data.Response.tableName && this.state.formData.Response && !prevData) {
        data.Response.tableName = this.state.formData.Response;
      }
      if (!Array.isArray(this.state.formData.Response) && typeof this.state.formData.Response === "object" && this.state.formData.Response != null && !prevData) {
        Object.entries(this.state.formData.Response).forEach(pair => {
          data.Response[pair[0]] = pair[1];
        });
      }
    } else if (this.state.formData) {
      data.Response = this.state.formData.Response;
      data.Notes = this.state.formData.Notes;
    }

    this.UpdateSchema(id, data);
  };
  matchResponseListOrder = (arr, listName, defaultItems) => {
    let list = [];
    if (this.AllLists[listName] && this.AllLists[listName].length) {
      list = this.AllLists[listName].map(item => item.id);
    }
    if (defaultItems && defaultItems.enum) {
      defaultItems.enum.forEach(item => {
        if (!list.includes(item)) {
          list.push(item);
        }
      });
    }
    const newArr = list.filter(item => arr.includes(item)).map(option => option);
    return newArr;
  };

  handleSelect = id => {
    // Removing BorrowingCredit session, If user navigates from the flow.
    sessionStorage.removeItem("BorrowingCredit");

    this.state.showCompletionForm = false;
    if (!this.Path_Index) {
      this.Path_Index = 0;
    }
    this.Path_Index = this.Path.lastIndexOf(id) === -1 ? this.Path_Index : this.Path.lastIndexOf(id);
    this.setCurrentProps(id);
    let ans = (objectpath.get(this.AllResponses, id) ? objectpath.get(this.AllResponses, id).answer : { Response: "EmptyField", Notes: "" }) || { Response: "EmptyField", Notes: "" };

    this.setState({ disableSubmit: false }, () => {
      this.UpdateSchema(id, {
        Response: ans.Response,
        Notes: ans.Notes,
      });
      this.updateLoopStatus(id);
    });
  };

  CreateNavigation = (MenuObject, link = "", lkplist = "Plans", counter = 0) => {
    //This is a function that parse responses by recursively calling this function for each level and creates Navigation
    return Object.keys(MenuObject).map(dropDownItem => {
      let path = link ? link + "." + dropDownItem : dropDownItem;
      if (!this.state.socketConnected && link) {
        return <CircularProgress color="inherit" size={20} disableShrink={true} />;
      } else if (typeof MenuObject[dropDownItem] === "object" && dropDownItem !== "id" && isValidPath(path, this.AllLists.AllObjects)) {
        var title = getNamesForListID(dropDownItem, this.AllLists[lkplist]);
        if (QuesNavNameListMap.hasOwnProperty(dropDownItem)) {
          lkplist = QuesNavNameListMap[dropDownItem];
        }
        if (QuesNavSectionMap.hasOwnProperty(dropDownItem)) {
          title = QuesNavSectionMap[dropDownItem];
        }

        return MenuObject[dropDownItem].hasOwnProperty("text") ? (
          <MenuItem eventkey={1} key={dropDownItem} onSelect={this.handleSelect.bind(this, link + "." + dropDownItem)}>
            {`${++counter}. ${MenuObject[dropDownItem]["text"]}`}
          </MenuItem>
        ) : (
          <NavDropdown key={dropDownItem} title={title} id={dropDownItem}>
            {typeof MenuObject[dropDownItem] === "object" && dropDownItem !== "id" ? this.CreateNavigation(MenuObject[dropDownItem], link ? link + "." + dropDownItem : dropDownItem, lkplist) : ""}
          </NavDropdown>
        );
      } else return null;
    });
  };

  // ParkingLotNav = () => {
  //   if (this.AllLists["ParkingLot"])
  //     return this.AllLists["ParkingLot"].map(item => {
  //       return (
  //         <MenuItem eventkey={item} key={item} onSelect={this.handleSelect.bind(this, item)}>
  //           {objectpath.get(this.AllResponses, item) ? objectpath.get(this.AllResponses, item).text : Ques_Schema.properties.Response.title}
  //         </MenuItem>
  //       );
  //     });
  // };

  unfitSectionNav = () => {
    return this.UnfitSectionsWithStatusTrue.map(item => {
      return (
        <MenuItem
          eventkey={item.incentiveId}
          key={item.incentiveId}
          onSelect={() => {
            this.showReinstateButton = true;
            this.ExitIncentive = item.incentiveId;

            this.toggleExitNotesDisplay();
          }}
        >
          {this.AllLists.AllObjects[item.incentiveId] ? this.AllLists.AllObjects[item.incentiveId].name : item.incentiveId}
        </MenuItem>
      );
    });
  };

  TableSubmission = TableData => {
    if (Array.isArray(TableData)) {
      this.tabularAnswer.tableList = TableData;
    } else {
      this.tabularAnswer = TableData;
    }
  };

  subComponentChanged = status => {
    this.setState({ disableSubmit: status });
  };

  toggleNotesDisplay = () => {
    this.NotesDisplay = this.NotesDisplay === "hidden" ? "textarea" : "hidden";
    this.UpdateSchema(this.RenderedQuestionId, this.state.formData);
  };

  toggleSectionSummaryDisplay = () => {
    // this.showSectionSummary = !this.showSectionSummary;
    this.setState({
      showSectionSummary: !this.state.showSectionSummary,
    });

    // this.UpdateSchema(this.RenderedQuestionId, this.state.formData);
  };

  validate = (formData, errors, validationParams) => {
    //any custom validation goes here. This function is called after the JSON schema validation is done.
    this.setState({ tableWidgetValidationErr: "" });

    let validationResults;
    if (validationParams) {
      if (Array.isArray(validationParams)) {
        validationResults = formValidation(formData.Response, validationParams);
      } else {
        validationResults = {};
        Object.keys(validationParams).forEach(key => {
          if (key === "TableValidation") {
            if (this.tabularAnswer.data.length) {
              validationResults = formValidation(this.tabularAnswer.data, validationParams[key]);
              if (!validationResults.valid) {
                validationResults.errors.forEach(err => {
                  errors[key].addError(err.msg);
                });
              }
            } else {
              errors[key].addError("No data in table");
            }
          } else {
            validationResults = formValidation(formData.Response[key], validationParams[key]);
            if (!validationResults.valid) {
              validationResults.errors.forEach(err => {
                errors.Response[key][err.index].addError(err.msg);
              });
            }
          }
        });
      }
    }
    return errors;
  };

  transformErrors = errors => {
    let responseErrorAvailable = false;
    errors.forEach(error => {
      if (error.property === ".Response") {
        responseErrorAvailable = true;
      }
    });
    if (!responseErrorAvailable && errors.length > 0) {
      let errorSample = JSON.parse(JSON.stringify(errors[0]));
      errorSample.property = ".Response";
      errors.push(errorSample);
    }
    return errors;
  };

  AddParkingItem = () => {
    this.AllLists["ParkingLot"].push(this.RenderedQuestionId);
    let reqData = { ParkingLot: this.AllLists["ParkingLot"] };
    server.updateLists(this.props.customerId, this.props.projectId, reqData, response => {
      this.saveProjectDetails(response.data.object[0]);
    });
  };
  RemoveParkingItem = () => {
    this.AllLists["ParkingLot"].splice(this.AllLists["ParkingLot"].indexOf(this.RenderedQuestionId), 1);
    let reqData = { ParkingLot: this.AllLists["ParkingLot"] };
    server.updateLists(this.props.customerId, this.props.projectId, reqData, response => {
      this.saveProjectDetails(response.data.object[0]);
    });
  };
  handleChange = event => {
    const formData = event.formData;
    const schemaItems = Ques_Schema.properties.Response && Ques_Schema.properties.Response.items;
    if (formData.Response && Array.isArray(formData.Response) && formData.Response !== "EmptyField") {
      if (schemaItems && schemaItems.enum && Array.isArray(schemaItems.enum)) {
        formData.Response = formData.Response.filter(item => schemaItems.enum.includes(item));
      }
    }
    this.setState({ formData: formData });
  };

  updateUnfitSections = (unfitSections, redirectFlag) => {
    this.setState({ dataReady: false });
    this.UnfitSections = unfitSections;
    this.UnfitSectionsWithStatusTrue = this.UnfitSections.filter(item => item.unfit === true);
    this.toggleExitNotesDisplay();
    server.updateUnfitSections(this.props.customerId, this.props.projectId, this.UnfitSections, response => {
      if (response.status === 200) {
        this.saveProjectDetails(response.data.object[0]);
        this.setState({ dataReady: true });
        if (redirectFlag) {
          this.getNextQuestion({ Response: "" }, this.schemaPath.fullSchemaPath, true);
        }
      }
    });
  };
  toggleExitNotesDisplay = () => {
    this.setState({
      showExitNotes: !this.state.showExitNotes,
    });
  };

  shouldRenderDisabled = incentiveId => {
    let flag = false;
    if (this.UnfitSectionsWithStatusTrue) {
      flag = this.UnfitSectionsWithStatusTrue.filter(item => item.incentiveId === incentiveId).length > 0;
    }
    return flag;
  };

  onSectionSwitch = data => {
    let qid = data.qid;
    this.setState({
      showNavigation: false,
    });
    if (data.isNewPlan) {
      qid = `Organization.Plans.${data.planId}.Questions.Plan6`;
      objectpath.set(this.AllResponses, qid, { answer: { Response: "" }, text: "New Plan" });
    } else if (data.isNewIncentive) {
      if (!this.AllResponses.Organization.Plans[data.planId] || !this.AllResponses.Organization.Plans[data.planId].Questions.Plan1) {
        objectpath.set(this.AllResponses, `Organization.Plans.${data.planId}.Questions.Plan1`, { answer: { Response: [] }, text: "New Incentive" });
      }
      this.AllResponses.Organization.Plans[data.planId].Questions.Plan1.answer.Response.push(data.incentiveId);
      qid = `Organization.Plans.${data.planId}.Incentives.${data.incentiveId}.Questions.Comp_Incentive0`;
      objectpath.set(this.AllResponses, qid, { answer: { Response: [] }, text: "New Incentive" });
    } else if (qid.trim().length === 0 && !data.isNewIncentive) {
      qid = `Organization.Plans.${data.planId}.Incentives.${data.incentiveId}.Questions.Comp_Incentive0`;
      objectpath.set(this.AllResponses, qid, { answer: { Response: [] }, text: "New Incentive" });
    }
    this.handleSelect(qid);
  };

  renderToast = msg => {
    return (
      <div style={releaseLockToastStyle}>
        <p>{msg}</p>
        <span
          className="spanLinkStyle"
          onClick={() => {
            window.location.reload();
          }}
        >
          Go to Section
        </span>
      </div>
    );
  };

  render() {
    const BorrowingCredit = sessionStorage.getItem("BorrowingCredit");
    const lockedIncentive = this.shouldRenderDisabled(this.Current_Incentive);
    if (lockedIncentive && UISchema.Response) {
      UISchema.Response["ui:disabled"] = true;
      UISchema.Notes["ui:disabled"] = true;
    }
    const isFirstQuestion = this.Path_Index <= 0;
    //<TODO>: How to update this?
    const isLastQuestion = false;
    //let ListComponent = this.dynamicComponentMapping.UpdateLists || UpdateLists;
    let ListComponent = this.dynamicComponentMapping.UpdateLists ? this.dynamicComponentMapping.UpdateLists : UpdateLists;
    // Adding flag to disable all action on this current question.
    const disableQueActions = !!(this.state.sectionLockStatus.isLocked && this.Current_Section === this.state.sectionLockStatus.section);
    const formattedTime = moment.utc(this.state.countDownCounter * 1000).format("mm:ss");
    return (
      <div className="Questions">
        {/* Following will remove notification when comp gets unmounted  */}
        <ReactNotifications className="custom-unlock-notification" />
        <div className="Questions2">
          <Navbar bg="light" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>{this.CreateNavigation(this.AllResponses)}</Nav>
              {
                <Nav pullRight>
                  {this.props.allowNotes && (
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          showNotes: true,
                        });
                      }}
                      className="btn btn-secondary RightAlign btn-padded"
                    >
                      {"Notes"}
                    </button>
                  )}
                  {this.props.allowParkingLot && (
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          showParkingLot: true,
                        });
                      }}
                      className="btn btn-secondary RightAlign btn-padded"
                    >
                      {"Parking Lot"}
                    </button>
                  )}
                </Nav>
              }
              {this.UnfitSectionsWithStatusTrue
                ? this.UnfitSectionsWithStatusTrue.length > 0 && (
                    <Nav>
                      <NavDropdown key="exited" title="Locked Incentives" id="exited">
                        {this.unfitSectionNav()}
                      </NavDropdown>
                    </Nav>
                  )
                : ""}
            </Navbar.Collapse>
          </Navbar>
        </div>
        <h5>{/*BreadCrumb*/}</h5>
        {/*<div id="coverDiv" style={coverDivStyle}></div>*/}
        <LoadingOverlay
          active={!this.state.dataReady}
          spinner
          text="Loading Data..."
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          {disableQueActions && (
            <Alert bsStyle="warning" style={alertBoxStyle}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> {this.state.sectionLockStatus.message}
            </Alert>
          )}
          <div id="QuestionForm">
            <p id="qid" className="hidden">
              {this.RenderedQuestionId}
            </p>
            {!this.state.showCompletionForm ? (
              <div>
                <Form
                  key={this.state.Current_QID}
                  schema={Ques_Schema}
                  widgets={getCustomWidgets(Ques_Schema, listName => {
                    Ques_Schema.properties.Response.bindToList = listName;
                    this.toggleOptionFormDisplay();
                  })}
                  fields={getCustomFields(Ques_Schema)}
                  onSubmit={this.SubmitResponse}
                  onChange={e => {
                    this.handleChange(e);
                  }}
                  validate={(formdata, errors) => this.validate(formdata, errors, Ques_Schema.properties.Response.validationParams)}
                  onError={errors => {
                    if (errors && errors[0] && errors[0].stack) {
                      this.setState({ tableWidgetValidationErr: errors[0].stack });
                    }
                  }}
                  formData={this.state.formData}
                  uiSchema={UISchema}
                  showErrorList={false}
                  autocomplete="off"
                  disabled={disableQueActions}
                  transformErrors={this.transformErrors}
                >
                  {BorrowingCredit && (
                    <div>
                      <p style={{ color: "#eea236" }}>{`* Borrowing Credit.`}</p>
                    </div>
                  )}
                  <div id="buttonContainer" style={{ minHeight: "44px" }}>
                    <div style={this.state.buttonContainersStyle}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="PrevQuesToolTip">
                            <strong>Previous Question</strong>
                          </Tooltip>
                        }
                      >
                        <button type="button" className="btn btn-primary btn-padded" disabled={isFirstQuestion} onClick={this.PreviousQuestion}>
                          <i className="glyphicon glyphicon-arrow-left" />
                        </button>
                      </OverlayTrigger>

                      <button type="submit" className="btn btn-primary btn-padded" disabled={this.state.disableSubmit || disableQueActions} onClick={() => this.setState({ preventNext: true })}>
                        Save
                      </button>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="NextQuesToolTip">
                            <strong>{disableQueActions ? "Next Question" : "Save and Next"}</strong>
                          </Tooltip>
                        }
                      >
                        <button className="btn btn-primary btn-padded" type="submit" disabled={this.state.disableSubmit} onClick={() => this.setState({ preventNext: false })}>
                          <i className="glyphicon glyphicon-arrow-right" />
                        </button>
                      </OverlayTrigger>

                      {this.parkingLotItemsButton}
                      {this.allowSearch && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="SummarSectionTip">
                              <strong>Display Search Widget</strong>
                            </Tooltip>
                          }
                        >
                          <button type="button" onClick={this.toggleSectionSummaryDisplay} className="btn btn-primary RightAlign btn-padded">
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="AdditionalNotesTip">
                            <strong>Include Additional Comments</strong>
                          </Tooltip>
                        }
                      >
                        <button type="button" onClick={this.toggleNotesDisplay} className="btn btn-primary RightAlign btn-padded" disabled={disableQueActions}>
                          <i className="glyphicon glyphicon-pencil " />
                        </button>
                      </OverlayTrigger>
                      {this.showSummaryIcon && this.props.allowedSections.includes("Organization") && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="AdditionalNotesTip">
                              <strong>Incentive Summary</strong>
                            </Tooltip>
                          }
                        >
                          <button type="button" onClick={this.toggleComponentSummaryDisplay} className="btn btn-primary RightAlign btn-padded">
                            <FontAwesomeIcon icon={faClipboardList} />
                          </button>
                        </OverlayTrigger>
                      )}
                      {Ques_Schema.properties.Response.addable ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="AddOptionsTip">
                              <strong>Modify list of Options</strong>
                            </Tooltip>
                          }
                        >
                          <button type="button" onClick={this.toggleOptionFormDisplay} className="btn btn-warning RightAlign btn-padded" disabled={disableQueActions}>
                            Modify Options
                          </button>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                      {!this.shouldRenderDisabled(this.Current_Incentive) && this.Current_Incentive && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="AdditionalNotesTip">
                              <strong>Lock Incentive</strong>
                            </Tooltip>
                          }
                        >
                          <button
                            type="button"
                            onClick={() => {
                              this.showReinstateButton = false;
                              this.ExitIncentive = this.Current_Incentive;
                              this.toggleExitNotesDisplay();
                            }}
                            className="btn btn-primary RightAlign btn-padded"
                            disabled={disableQueActions}
                          >
                            <FontAwesomeIcon icon={faLock} />
                          </button>
                        </OverlayTrigger>
                      )}
                      {this.Current_Section === "Organization" && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="PrevQuesToolTip">
                              <strong>Plan Navigation</strong>
                            </Tooltip>
                          }
                        >
                          <button
                            type="button"
                            onClick={() => {
                              this.setState({
                                showNavigation: true,
                              });
                            }}
                            className="btn btn-primary RightAlign btn-padded"
                          >
                            <FontAwesomeIcon icon={faCompass} />
                          </button>
                        </OverlayTrigger>
                      )}
                      {this.state.Current_QID === "DataMapping" && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="copyDataSources">
                              <strong>Copy Data Sources</strong>
                            </Tooltip>
                          }
                        >
                          <button
                            type="button"
                            onClick={() => {
                              this.setState({ displayCopySourcePopup: true });
                            }}
                            className="btn btn-primary RightAlign btn-padded"
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </Form>
                {this.Current_Section && this.Current_Section.length && this.props.projectId && this.RenderedQuestionId !== "" && (
                  <>
                    <Websocket
                      url={`${WS_ROOT}/customers/${this.props.customerId}/projects/${this.props.projectId}/section/${
                        this.state.Current_QID === "Org8" ? "CommonQuestion" : this.Current_Section
                      }/sectionLock`}
                      onMessage={data => {
                        const result = JSON.parse(data);
                        if (result.action === "LOCKED") {
                          NotificationStore.removeNotification(this.notificationId);
                          this.setState(
                            {
                              sectionLockStatus: {
                                isLocked: true,
                                section: result.section,
                                lockedBy: result.user,
                                message: `You cannot make changes in ${result.section === "GenericInfo" ? "Implementation Survey" : result.section} as there are changes being done by ${result.user}.`,
                              },
                            },
                            () => {
                              this.notificationId = null;
                              this.UpdateSchema(this.RenderedQuestionId, this.state.formData);
                            },
                          );
                          document.removeEventListener("click", this.handleGettingLock);
                        } else if (result.action === "REMOVE_LOCK") {
                          setTimeout(() => {
                            NotificationStore.removeNotification(this.notificationId);
                            this.notificationId = NotificationStore.addNotification({
                              content: this.renderToast(`${result.section === "GenericInfo" ? "Implementation Survey" : result.section} Section is Unlocked, you can now edit the answers`),
                              insert: "top",
                              container: "top-center",
                              dismiss: {
                                duration: 0,
                                click: false,
                                touch: false,
                              },
                            });
                            document.addEventListener("click", this.handleGettingLock, { passive: true, once: true });
                          }, "1000");
                        } else if (result.action === "IS_ALIVE") {
                          // Notify server If connection is alive.
                          if (this.refWebSocket) {
                            this.sendMessage(
                              JSON.stringify({
                                action: "IS_ALIVE",
                              }),
                            );
                          }
                        } else {
                          NotificationStore.removeNotification(this.notificationId);
                          this.setState(
                            {
                              sectionLockStatus: {
                                isLocked: false,
                                section: "",
                                lockedBy: "",
                                message: "",
                              },
                            },
                            () => {
                              this.notificationId = null;
                              this.UpdateSchema(this.RenderedQuestionId, this.state.formData);
                              console.log(`${result.user} locked ${result.section === "GenericInfo" ? "Implementation Survey" : result.section}`);
                            },
                          );
                          document.removeEventListener("click", this.handleGettingLock);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ socketConnected: true });
                        console.log("Connection OPENED");
                      }}
                      onClose={() => {
                        console.log("Connection CLOSED");
                        // stop timer and show popup for backend disconnection.
                        if (this.idleTimeRef && !(this.state.isInactivityModalOpen && this.state.countDownCounter <= 0)) {
                          this.idleTimeRef.pause();
                          this.setState({ connectionLostModalOpen: true });
                        }
                      }}
                      debug={true}
                      reconnect={false}
                      ref={Websocket => {
                        this.refWebSocket = Websocket;
                      }}
                    />
                    {disableQueActions === false && (
                      <>
                        <IdleTimer
                          ref={ref => {
                            this.idleTimeRef = ref;
                          }}
                          timeout={MAX_INACTIVITY_TIME}
                          onIdle={this.handleOnIdle}
                          debounce={500}
                          stopOnIdle={true}
                        />
                        <Modal
                          isOpen={this.state.isInactivityModalOpen}
                          shouldCloseOnOverlayClick={false}
                          shouldCloseOnEsc={false}
                          onRequestClose={this.inactivityModalCloseHandler}
                          style={inactivityModalStyle}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "12px",
                            }}
                          >
                            <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} />} clickHandler={this.inactivityModalCloseHandler} iconSize={20} />
                          </div>
                          <div style={{ padding: "0px 20px" }}>
                            <h4>Inactivity Warning!</h4>
                          </div>
                          <hr />
                          {this.state.countDownCounter <= 0 ? (
                            <>
                              <div style={{ padding: "0px 20px" }}>
                                <span>
                                  You have been inactive for more than {MAX_INACTIVITY_TIME / (1000 * 60)} minutes, Your hold on{" "}
                                  {this.Current_Section === "GenericInfo" ? "Implementation Survey" : this.Current_Section} Section has been removed.
                                </span>
                              </div>
                              <hr />
                              <PrimaryButton className="RightAlign extraMargins" text="Ok" clickHandler={this.inactivityModalCloseHandler} />
                            </>
                          ) : (
                            <>
                              <div style={{ padding: "0px 20px" }}>
                                <span>
                                  Your hold on "{this.Current_Section === "GenericInfo" ? "Implementation Survey" : this.Current_Section}" Section will expire in <strong>{formattedTime} </strong>.
                                  Please click continue to proceed
                                </span>
                              </div>
                              <hr />
                              <PrimaryButton className="RightAlign extraMargins" text="Continue" clickHandler={this.inactivityModalCloseHandler} />
                            </>
                          )}
                        </Modal>
                        <Modal
                          isOpen={this.state.connectionLostModalOpen}
                          shouldCloseOnOverlayClick={false}
                          shouldCloseOnEsc={false}
                          onRequestClose={() => window.location.reload()}
                          style={inactivityModalStyle}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "12px",
                            }}
                          >
                            <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} />} clickHandler={() => window.location.reload()} iconSize={20} />
                          </div>
                          <div style={{ padding: "0px 20px" }}>
                            <h4>Section Unlocked</h4>
                          </div>
                          <hr />
                          <>
                            <div style={{ padding: "0px 20px" }}>
                              <span>
                                Your hold on “{this.Current_Section === "GenericInfo" ? "Implementation Survey" : this.Current_Section}” Section has been removed. You can now edit the answers in this
                                section.
                              </span>
                            </div>
                            <hr />
                            <PrimaryButton className="RightAlign extraMargins" text="Go to Section" clickHandler={() => window.location.reload()} />
                          </>
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div
                style={{
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {"Thank you for completing the Implementation Survey!"}
              </div>
            )}
          </div>
          {!disableQueActions && this.state.tableWidgetValidationErr.length ? (
            <span className="text-danger" style={{ margin: "0px 35px" }}>
              {this.state.tableWidgetValidationErr}
            </span>
          ) : null}
          <Cover disabled={disableQueActions || lockedIncentive}>{this.RateTableBuilder}</Cover>
          <Cover disabled={disableQueActions}>{this.CalendarBuilder}</Cover>
          <Cover disabled={disableQueActions}>{this.DataTableBuilder}</Cover>
          <Cover disabled={disableQueActions}>{this.DataDerivation}</Cover>
          <Cover disabled={disableQueActions || lockedIncentive}>{this.TitlesTableBuilder}</Cover>
          <Cover disabled={disableQueActions || lockedIncentive}>{this.TableBuilder}</Cover>
        </LoadingOverlay>
        {
          <Cover disabled={lockedIncentive}>
            <Modal
              isOpen={this.state.showAddOptionForm}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              onRequestClose={() => {
                this.toggleOptionFormDisplay();
              }}
              style={{
                content: {
                  top: "40px",
                  left: "40px",
                  height: "70%",
                },
              }}
            >
              <ListComponent
                AllLists={this.AllLists}
                selectedList={Ques_Schema.properties.Response.bindToList}
                defaultOptions={Ques_Schema.properties.Response.items}
                listWithIDs={this.listWithIDs}
                customerId={this.props.customerId}
                projectId={this.props.projectId}
                SendQuestionPath={this.handleSelect}
                saveProjectDetails={this.saveProjectDetails.bind(this)}
                closeModal={() => {
                  this.toggleOptionFormDisplay([]);
                }}
              />
            </Modal>
          </Cover>
        }

        {this.allowSearch && (
          <Modal
            isOpen={this.state.showSectionSummary}
            contentLabel="Component Summary"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={this.toggleSectionSummaryDisplay}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "70%",
              },
            }}
          >
            <QuestionList
              flag={"summary"}
              onSelect={id => {
                this.setState({ showSectionSummary: false });
                this.handleSelect(id);
              }}
              allowedSections={this.props.allowedSections}
              allowedSearchMenus={this.allowedSearchMenus}
            />
          </Modal>
        )}
        {
          <Modal
            isOpen={this.state.showExitNotes}
            contentLabel="Exit Notes"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "50%",
              },
            }}
          >
            <ExitSection
              unfitSections={this.UnfitSections}
              incentive={this.ExitIncentive}
              updateUnfitSections={this.updateUnfitSections}
              toggleExitNotesDisplay={this.toggleExitNotesDisplay}
              lists={this.AllLists}
              showReinstateButton={this.showReinstateButton}
              className="Modal"
            ></ExitSection>
          </Modal>
        }

        <div id="componentSummary">
          <Modal
            isOpen={this.state.showComponentSummary}
            contentLabel="Component Summary"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={this.toggleComponentSummaryDisplay}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "80%",
              },
            }}
          >
            <ComponentSummary
              lists={this.AllLists}
              params={{ level: "Incentive", value: this.Current_Incentive }}
              SendQuestionPath={this.handleSelect}
              closeModal={this.toggleComponentSummaryDisplay}
            ></ComponentSummary>
          </Modal>
        </div>
        {this.state.showNavigation && (
          <SectionNavigation
            paths={this.Path}
            plans={this.AllLists.Plans}
            incentives={this.AllLists.Incentives}
            planIncentives={this.GlobalVariables ? this.GlobalVariables.PlanIncentives : []}
            onClose={() => {
              this.setState({ showNavigation: false });
            }}
            onSelect={data => {
              this.onSectionSwitch(data);
            }}
          />
        )}
        {this.state.showParkingLot && (
          <Modal
            isOpen={this.state.showParkingLot}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              this.setState({ showParkingLot: false });
            }}
            shouldCloseOnEsc={true}
            style={{
              content: {
                position: "relative",
                top: "10%",
                height: "80%%",
                bottom: "10%",
                left: "10%",
                right: "10%",
                width: "80%",
              },
            }}
          >
            <QuestionList
              flag={"parkingLot"}
              onSelect={id => {
                this.setState({ showParkingLot: false });
                this.handleSelect(id);
              }}
              allowedSections={this.props.allowedSections}
              allowedSearchMenus={this.allowedSearchMenus}
            />
          </Modal>
        )}
        {this.state.showNotes && (
          <Modal
            isOpen={this.state.showNotes}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              this.setState({ showNotes: false });
            }}
            shouldCloseOnEsc={true}
            style={{
              content: {
                position: "relative",
                top: "10%",
                height: "80%%",
                bottom: "10%",
                left: "10%",
                right: "10%",
                width: "80%",
              },
            }}
          >
            <QuestionList
              flag={"notes"}
              onSelect={id => {
                this.setState({ showNotes: false });
                this.handleSelect(id);
              }}
              allowedSections={this.props.allowedSections}
              allowedSearchMenus={this.allowedSearchMenus}
            />
          </Modal>
        )}
        {this.state.displayCopySourcePopup && (
          <Modal
            isOpen={this.state.displayCopySourcePopup}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              this.setState({ displayCopySourcePopup: false });
            }}
            shouldCloseOnEsc={true}
            style={{
              content: {
                position: "absolute",
                border: "1px solid rgb(204, 204, 204)",
                background: "rgb(255, 255, 255)",
                borderRadius: "4px",
                outline: "none",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -60%)",
                width: "40%",
                height: "fit-content",
              },
            }}
          >
            <CopyDataMapping
              allLists={this.AllLists}
              tabularAnswer={this.tabularAnswer}
              updateTabularAnswers={updatedTabularAnswer => {
                this.tabularAnswer = updatedTabularAnswer;
                this.setState({ displayCopySourcePopup: false });
                this.dataTableRef.current.resetTableWarning(true);
                this.updateTabAnswers(updatedTabularAnswer);
              }}
              cancelCopying={() => {
                this.setState({ displayCopySourcePopup: false });
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
  updateUsedFields(listObject, dataMapping) {
    return new Promise((resolve, reject) => {
      const updatedListObject = {};
      Object.keys(listObject).forEach(key => {
        const newSources = [];
        listObject[key].forEach(s => {
          const newSource = { ...s };
          newSource.newMappingUpdated = true;
          if (s.fields) {
            const mappings = dataMapping ? dataMapping.flatMap(m => (m[s.id] && m[s.id].exp ? m[s.id].exp : [])) : [];
            //const joinMappings = s.relationships ? s.relationships.map(r => r.relationship) : [];
            let filterString = "";
            try {
              filterString = JSON.parse(s.filterExpression).resolvedQuery;
            } catch (err) {}
            //const mappingStr = s.sourceFilter ? mappings.concat(joinMappings).join(", ") : mappings.join(", ");
            const mappingStr = s.sourceFilter ? mappings.concat([filterString]).join(", ") : mappings.join(", ");
            if (newSource.fields.data) {
              newSource.fields.data = newSource.fields.data.map(d => {
                const newField = { ...d };
                if (mappingStr.includes(`${s.id}.${d.id}`)) {
                  newField.isMapped = true;
                } else {
                  newField.isMapped = false;
                }
                return newField;
              });
            }
            if (s.referenceSourceUsed && s.relationships) {
              s.relationships.forEach(r => {
                let relationUsed = false;
                if (r.fields) {
                  r.fields = r.fields.map(f => {
                    const newField = { ...f };
                    if (mappingStr.includes(`${r.id}.${f.id}`)) {
                      newField.isMapped = true;
                      relationUsed = true;
                    } else {
                      newField.isMapped = false;
                    }
                    return newField;
                  });
                }
                r.isMapped = relationUsed;
              });
            }
          }
          newSources.push(newSource);
        });
        updatedListObject[key] = newSources;
      });
      server.updateLists(this.props.customerId, this.props.projectId, updatedListObject, response => {
        resolve(response);
      });
    });
  }
}

export default Questions;
