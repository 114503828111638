import { generateID } from "./ManageObjectIDs";

const SFDC_DataTypes = {
  ANYTYPE: "string",
  BASE64: "string",
  BOOLEAN: "boolean",
  COMBOBOX: "string",
  CURRENCY: "number",
  DATACATEGORYGROUPREFERENCE: "string",
  DATE: "date",
  DATETIME: "date",
  DOUBLE: "number",
  EMAIL: "string",
  ENCRYPTEDSTRING: "string",
  ID: "string",
  INT: "number",
  FLOAT: "number",
  MULTIPICKLIST: "string",
  PERCENT: "number",
  PHONE: "string",
  PICKLIST: "string",
  REFERENCE: "string",
  STRING: "string",
  TEXTAREA: "string",
  TIME: "time",
  URL: "string",
  ADDRESS: "string",
  LOCATION: "string",
  LOOKUP: "string",
  CHECKBOX: "boolean",
  CURRENCY: "number",
  TEXT: "string",
  NUMBER: "number",
};
const FORMULA_PREFIX = "FORMULA (";
const getSFDCFields = data => {
  const { primary, fieldDetails, selectedRelations, existingFields } = data;

  let columns = getObjectFieldDetails("", fieldDetails[primary], existingFields);
  selectedRelations.forEach(r => {
    if (r.source_object === primary) {
      columns = columns.concat(getObjectFieldDetails(`${r.relationship}.`, fieldDetails[r.target_object], existingFields));
    } else if (r.target_object === primary) {
      columns = columns.concat(getObjectFieldDetails(`${r.source_object}.`, fieldDetails[r.source_object], existingFields));
    } else {
      const fieldPrefix = getFieldPrefix(primary, selectedRelations, r);
      fieldPrefix.forEach(f => {
        columns = columns.concat(getObjectFieldDetails(f, fieldDetails[r.target_object], existingFields));
      });
    }
  });
  return columns;
};

const getObjectFieldDetails = (fieldNamePrefix, fieldList, currentFields) => {
  const existingFields = currentFields ? currentFields : [];
  const columns = [];
  fieldList.forEach(field => {
    const fieldName = `${fieldNamePrefix}${field.field_Name}`;
    const index = existingFields ? existingFields.findIndex(e => e.Name === fieldName) : -1;
    const id = index > -1 ? existingFields[index].id : fieldName;
    const isMapped = index > -1 ? existingFields[index].isMapped : false;
    const mandatory = index > -1 ? existingFields[index].Mandatory : false;
    const usedInFilter = index > -1 ? existingFields[index].usedInFilter : false;
    const usedInJoin = index > -1 ? existingFields[index].usedInJoin : false;
    const usedInCustomValidation = index > -1 ? existingFields[index].isCustomValidation : false;
    let dataType = field.data_type.toUpperCase();
    if (dataType.startsWith(FORMULA_PREFIX)) {
      dataType = dataType.substring(FORMULA_PREFIX.length, dataType.length - 1).trim();
    }
    dataType = getSFDCFieldType(dataType);
    const format = dataType === "date" ? "yyyy-MM-dd" : "";
    columns.push({
      Name: fieldName,
      "Data Type": dataType,
      Format: format,
      id: id,
      isMapped: isMapped,
      Mandatory: mandatory,
      usedInFilter: usedInFilter,
      usedInJoin: usedInJoin,
      usedInCustomValidation: usedInCustomValidation,
    });
  });
  return columns;
};

const getFieldPrefix = (primary, selectedRelations, relObj) => {
  let prefixes = [];
  selectedRelations.forEach(sr => {
    if (sr.target_object === relObj.source_object) {
      if (sr.source_object === primary) {
        prefixes.push(`${sr.relationship}.${relObj.relationship}.`);
      } else {
        if (sr.source_object !== relObj.target_object) {
          const subFieldPrefixes = getFieldPrefix(primary, selectedRelations, sr).map(p => `${p}${relObj.relationship}.`);
          prefixes = [...prefixes, ...subFieldPrefixes];
        }
      }
    } else if (sr.source_object === relObj.source_object && sr.target_object === primary) {
      prefixes.push(`${sr.source_object}.${relObj.relationship}.`);
    }
  });
  return prefixes;
};

const getSFDCFieldType = sfdcType => {
  let dataType = Object.keys(SFDC_DataTypes).filter(type => sfdcType.startsWith(type));
  if (dataType.length === 1) {
    return SFDC_DataTypes[dataType[0]];
  } else {
    return SFDC_DataTypes["ANYTYPE"];
  }
};

export { getSFDCFields };
