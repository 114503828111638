const stringFormattingMap = {
  false: "No",
  true: "Yes"
};

const getNamesForListID = (id, list) => {
  const targetListArray = [...list];
  let name = id;
  for (let i = 0; i < targetListArray.length; i++) {
    if (id === targetListArray[i].id) {
      name = targetListArray[i].name;
      break;
    }
  }
  return name;
};
const getNameFromAllObjects = (id, allObjects) => {
  let name = id;
  if (allObjects && allObjects.hasOwnProperty(id) && allObjects[id].name) {
    name = allObjects[id].name;
  }
  return name;
};
const getIdForListName = (name, list) => {
  const targetListArray = [...list];
  let id = name;
  for (let i = 0; i < targetListArray.length; i++) {
    if (name === targetListArray[i].name) {
      id = targetListArray[i].id;
      break;
    }
  }

  return id;
};

const getIdFromAllObjects = (id, AllObjects) => {
  let name = stringFormattingMap[id] || id;
  Object.keys(AllObjects).forEach(function (item) {
    if (item === id) {
      name = AllObjects[item].name;
    }
  });
  return name;
};

const addIdToXactlySources = (list, listType = "ReferenceSources") => {
  list.forEach(source => {
    source.id = generateID(listType);
    if (source.fields && source.fields.data && source.fields.data.length) {
      source.fields.data = addIdToXactlySources(source.fields.data, "SourceColumn")
    }
  })
  return list;
};

const addQualifierAttributes = (list, qualifierAttrs) =>
  list.map(src => {
    if (src.name === "incent_order_item" && src.fields.data) {
      const DefaultDateFormat = src.fields.data.find(item => (item["Data Type"] === "date")).defaultValue;
      let orderItem = qualifierAttrs.filter(attr => attr.type === "OrderItem") || [];
      orderItem = orderItem.map(attr => ({
        ...attr,
        Name: attr.name,
        ["Data Type"]: attr.dataType,
        defaultValue: attr.dataType ? DefaultDateFormat : undefined
      }));
      return {
        ...src,
        fields: {
          data: [...src.fields.data, ...orderItem]
        }
      };
    } else if (src.name === "incent_participant" && src.fields.data) {
      const DefaultDateFormat = src.fields.data.find(item => (item["Data Type"] === "date")).defaultValue;
      let people = qualifierAttrs.filter(attr => attr.type === "People") || [];
      people = people.map(attr => ({
        ...attr,
        Name: attr.name,
        ["Data Type"]: attr.dataType,
        defaultValue: attr.dataType ? DefaultDateFormat : undefined
      }));
      return {
        ...src,
        fields: {
          data: [...src.fields.data, ...people]
        }
      };
    }
    return src;
  });

const generateID = listName => {
  const listPrefix = {
    AllAttributes: "A_",
    ComputationAttributes: "A_",
    QualifierAttributes: "A_",

    Incentives: "I_",
    Plans: "P_",
    Titles: "T_",
    Quotas: "Q_",
    Sources: "S_",
    HRSources: "HR_",
    Attribute_Period: "AP_",
    SourceSystems: "SS_",
    SuccessFactors: "SF_",
    SourceColumn: "SC_",
    JoinCondition: "JC_",
    ReferenceSources: "RS_",
    CustomerPlans: "CP_",
    UnitTypes: "UT_",
    Validations: "V_"
  };

  return `${listPrefix[listName]}${Date.now()}_${Math.floor(Math.random() * 10000000000)}`;
};

export { getNamesForListID, generateID, getIdForListName, getIdFromAllObjects, getNameFromAllObjects, addIdToXactlySources, addQualifierAttributes };
