import React from "react";

const IconButton = props => {
  const { type = "default", icon, className, ...otherProps } = props;
  return (
    <div style={{ width: "20px" }}>
      <button type="button" className={`btn btn-${type} ${className}`} {...otherProps}>
        <i className={`glyphicon glyphicon-${icon}`} />
      </button>
    </div>
  );
};
const DefaultArrayItem = props => {
  const btnStyle = {
    flex: 1,
    marginTop: 20,
    paddingLeft: 4,
    paddingRight: 4,
    fontWeight: "bold",
  };
  return (
    <div key={props.key} className={props.className}>
      <div className={props.hasToolbar ? "col-xs-9" : "col-xs-12"}>{props.children}</div>
      {props.hasToolbar && (
        <div className="col-xs-3 array-item-toolbox">
          <div
            className="btn-group"
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {(props.hasMoveUp || props.hasMoveDown) && (
              <button
                icon="arrow-up"
                className="array-item-move-up"
                tabIndex="-1"
                style={btnStyle}
                disabled={props.disabled || props.readonly || !props.hasMoveUp}
                onClick={props.onReorderClick(props.index, props.index - 1)}
              />
            )}

            {(props.hasMoveUp || props.hasMoveDown) && (
              <button
                icon="arrow-down"
                className="array-item-move-down"
                tabIndex="-1"
                style={btnStyle}
                disabled={props.disabled || props.readonly || !props.hasMoveDown}
                onClick={props.onReorderClick(props.index, props.index + 1)}
              />
            )}

            {props.hasRemove && (
              <IconButton
                type="danger"
                icon="remove"
                className="array-item-remove"
                tabIndex="-1"
                style={btnStyle}
                disabled={props.disabled || props.readonly || (props.children.props.formData.usedInOtherJoin === true ? true : props.children.props.formData.isMapped === true ? true : false)}
                onClick={props.onDropIndexClick(props.index)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ArrayFieldTemplate = props => {
  return (
    <div className={props.className}>
      {props.TitleField({ title: props.title })}
      {props.items && props.items.map(DefaultArrayItem)}
      {props.canAdd && (
        <div className="row">
          <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
            <button onClick={props.onAddClick} type="button" disabled={props.disabled || props.readonly}>
              Add
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default ArrayFieldTemplate;
