import React from "react";
import "react-table/react-table.css";
import TableInput from "./TableBuilder";
import { generateID, getNamesForListID } from "./ManageObjectIDs";

/**
 * Generic Table builder is a component that renders form data in a tabular format.
 */
class GenericTable extends TableInput {
  constructor(props) {
    super(props);

    this.initialColumns = [];
    this.initialData = [];
    this.errors = {};
    this.state = { columns: [], data: [] };
  }

  renderSavedTable = () => {
    this.fillInitialColumns();
    this.initialData = this.props.currData.data;
    for (var col = 0; col < this.initialColumns.length; col++) {
      if (this.props.editableCols.includes(this.initialColumns[col]["accessor"])) {
        this.initialColumns[col]["props"] = this.getColumnProps(col);
        this.initialColumns[col]["Cell"] = this.renderEditableCell;
      }
    }

    if (!this.props.disableAdditionalRows) {
      let removeItem = this.initialColumns.filter(row => row.Header === "RemoveItem");
      if (!removeItem[0]) {
        this.initialColumns.push({
          Header: "RemoveItem",
          accessor: "RemoveItem",
          maxWidth: 150
        });
      }
    }
    return { columns: this.initialColumns, data: this.initialData };
  };
  getColumnProps = col => {
    let columnProps = { type: "text", nullable: true };
    if (this.props.editableColumnProps && this.props.editableColumnProps[this.props.cols[col]]) {
      columnProps = this.props.editableColumnProps[this.props.cols[col]];
    }
    return columnProps;
  };

  fillInitialColumns = () => {
    for (var col = 0; col < this.props.cols.length; col++) {
      if (this.props.editableCols.includes(this.props.cols[col])) {
        let sortable = true;
        if (
          this.props.columnProperties &&
          this.props.columnProperties[this.props.cols[col]] &&
          this.props.columnProperties[this.props.cols[col]].hasOwnProperty("sortable") &&
          this.props.columnProperties[this.props.cols[col]].sortable === false
        ) {
          sortable = false;
        }

        let checked;
        if (this.props.currData && this.props.currData.columns) {
          const colData = this.props.currData.columns.find(colItem => colItem && colItem.accessor && colItem.accessor === this.props.cols[col]);
          if (colData && colData.checked) {
            checked = true;
          }
        }

        this.initialColumns.push({
          Header: this.renderTableHeader(this.props.cols[col], this.initialColumns.length, checked),
          accessor: this.props.cols[col],
          sortable: sortable,
          props: this.getColumnProps(col),
          Cell: this.renderEditableCell,
          checked
        });
      } else {
        this.initialColumns.push({
          Header: this.props.cols[col],
          accessor: this.props.cols[col]
        });
      }
    }
  };

  generateTable = () => {
    this.initialColumns = [];
    this.initialData = [];
    this.fillInitialColumns();

    //0) Check for default rows:
    // if (this.props.hasOwnProperty("defaultRows") && this.props.defaultRows.length > 0) {
    //   this.props.defaultRows.forEach(row => {
    //     row["id"] = Math.random();
    //     this.initialData.push(row);
    //   });
    // }

    for (var col = 0; col < this.props.cols.length; col++) {
      // if any column needs to be prepopulated then the following code populates it based on the props passed.

      //1) Subs in Fill Lists:
      if (this.props.fillLists && this.props.fillLists[this.props.cols[col]]) {
        this.props.lists[this.props.fillLists[this.props.cols[col]]].forEach(row => {
          let key = Math.random();
          this.initialData.push({
            id: key
          });
          this.initialData[this.initialData.length - 1][this.props.cols[col]] = row.name;

          //row.val.forEach(r => {});
        });
      }
      //2 Subs in Fill Global Variables:
      if (this.props.fillVariable && this.props.fillVariable[this.props.cols[col]]) {
        this.props.variables[this.props.fillVariable[this.props.cols[col]]].forEach(row => {
          if (row.Incentive && row.Plan) {
            if (row.Plan === this.props.currProps.plan && row.Incentive === this.props.currProps.incentive) {
              row.val.forEach(r => {
                let key = Math.random();
                this.initialData.push({
                  id: key
                });

                this.initialData[this.initialData.length - 1][this.props.cols[col]] = r;
              });
            }
          } else {
            if (row.Incentive) {
              if (row.Incentive === this.props.currProps.incentive) {
                row.val.forEach(r => {
                  let key = Math.random();
                  this.initialData.push({
                    id: key
                  });

                  this.initialData[this.initialData.length - 1][this.props.cols[col]] = r;
                });
              }
            }
          }
        });
      }
    }
    if (!this.props.disableAdditionalRows) {
      this.initialColumns.push({
        Header: "RemoveItem",
        accessor: "RemoveItem",
        maxWidth: 150
      });
    }
    if (this.props.defaultData) {
      this.initialData = this.props.defaultData.map(row => {
        row.id = Math.random();
        return row;
      });
    }

    return { columns: this.initialColumns, data: this.initialData };
  };
  addRow = () => {
    //since in React, we cannot manipulate DOM elements, this id is used to tie each row with corresponding delete button. Upon clicking trash icon, this id will be searched in data array
    //then that row is deleted and table is re-rendered.
    //let key = Math.random();
    let key = this.props.idKey ? generateID(this.props.idKey) : Math.random();
    var newdata = [
      ...this.state.data,
      {
        id: `${key}`,
        rowLevelProps: this.props.rowLevelProps,
        RemoveItem: <i onClick={this.removeItem} id={key} className="glyphicon glyphicon-trash Clickable RightAlign" />
      }
    ];
    this.saveTable(newdata, this.state.columns);
  };

  removeItem = event => {
    var newdata = [...this.state.data];
    for (var i = 0; i < newdata.length; i++) {
      if (newdata[i]["id"] == event.target.id) {
        break;
      }
    }
    newdata.splice(i, 1);

    this.saveTable(newdata, this.state.columns);
  };
}

export default GenericTable;
