import React, { Component } from "react";
import "./NotFound.css";

/*
export default () =>
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
  </div>;
*/
class NotFound extends Component {
  constructor(props) {
    console.log(props);
    super(props);
  }
  render() {
    console.log(this.props);
    return (
      <div className="NotFound">
        <h3>Sorry, page not found!</h3>
      </div>
    );
  }
}
export default NotFound;
