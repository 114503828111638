import React, { useEffect, useState, useLayoutEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Row, Col, Navbar, OverlayTrigger, Tooltip, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import CommentBtn from "@material-ui/icons/ChatOutlined";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@mui/material/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faInfoCircle, faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Scroll from "react-scroll";
import ReactTable from "react-table";
import Form from "react-jsonschema-form";
import Server from "./Server";
import store from "store";
import "./requirementSummary.css";
import Modal from "react-modal";
import "./_smartblock.css";
import { SmartBlock, Extensions } from "smartblock";
import { DeepCopyObject } from "./util";
import QuotaValueModule from "./quotaValueModule";
import Websocket from "react-websocket";
import { WS_ROOT, NOTIFICATION_TIMEOUT } from "./api-config";
import { Tooltip as XTooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { store as NotificationStore } from "react-notifications-component";
import ConfirmDialog from "../components/ConfirmDialog";

const server = new Server();
const Link = Scroll.Link;
const MAX_ROW = 10;
const POPUP_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    height: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const EXPAND_ICON_STYLE = { float: "left", fontSize: "15px", marginLeft: "-16px", color: "#777", cursor: "pointer" };

const ROMOVE_COLUMN_BUTTON_STYLE = {
  float: "right",
};

const objectToHtml = obj => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const { type, attributes, content } = obj;
  let html = `<${type}`;
  if (attributes) {
    Object.keys(attributes).forEach(key => {
      html += ` ${key}="${attributes[key]}"`;
    });
  }
  html += ">";

  if (Array.isArray(content)) {
    content.forEach(item => {
      if (typeof item === "string") {
        html += item; // For text content
      } else {
        html += objectToHtml(item); // For nested elements
      }
    });
  }

  html += `</${type}>`;

  return html;
};
const trimFrdSectionData = data => {
  // Specify keys to remove from section data.
  const keysToRemove = ["hidden", "isEditable", "addSmartBlock", "commentsData", "sectionNewComments"];
  let resultData;
  if (data !== Object(data)) {
    resultData = data;
  } else if (Array.isArray(data)) {
    resultData = data.map(item => trimFrdSectionData(item, keysToRemove));
  } else {
    resultData = Object.keys(data)
      .filter(k => !keysToRemove.includes(k))
      .reduce((acc, x) => Object.assign(acc, { [x]: trimFrdSectionData(data[x], keysToRemove) }), {});
  }
  return resultData;
};

function transformSectionNote(obj) {
  let resultData;
  for (let key in obj) {
    if (key === "sectionNote" && typeof obj[key].value === "object") {
      let subComponenetResult = objectToHtml(obj[key].value);
      subComponenetResult = subComponenetResult.substring(5, subComponenetResult.length - 6);
      obj[key].value = subComponenetResult;
    }
    if (typeof obj[key] === "object") {
      if (Array.isArray(obj[key])) {
        // loop through array
        for (let i = 0; i < obj[key].length; i++) {
          transformSectionNote(obj[key][i]);
        }
      } else {
        // call function recursively for object
        transformSectionNote(obj[key]);
      }
    } else {
      resultData = obj;
    }
  }
  return resultData;
}

const addApprovedData = (sectionData, changedDataAfterApproval) => {
  const currentSectionData = DeepCopyObject(sectionData);
  changedDataAfterApproval.forEach(newEntry => {
    let path = `${newEntry.path}`;
    path = path.split(".");
    const valueToChange = path.reduce((a, b) => {
      if (a && Array.isArray(a)) {
        return a.find(elm => elm.key === b);
      }
      return b && a && a[b];
    }, currentSectionData);
    if (valueToChange) {
      valueToChange.hasApprovedValue = newEntry.orangeGenerated;
    }
  });
  return currentSectionData;
};

const FormatDate = date => {
  const newDateOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
    hour: "2-digit",
    minute: "2-digit",
  };
  const newDate = new Date(date).toLocaleString("en-US", newDateOptions);
  return newDate;
};

const ApprovalSectionsSummary = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvalSummary, setApprovalSummary] = useState([]);
  const tableColumns = [
    { Header: "Section", accessor: "section", maxWidth: 250 },
    { Header: "Status", accessor: "status" },
  ];
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  useEffect(() => {
    setIsModalOpen(props.displayApprovalSummary);
    if (props.displayApprovalSummary) {
      getApprovalSummary();
    }
  }, [props.displayApprovalSummary]);
  const getApprovalSummary = () => {
    const customerId = props.customerId;
    const projectId = props.projectId;
    props.setIsLoading(true);
    server.getApprovalSummary(customerId, projectId, response => {
      props.setIsLoading(false);
      if (response && response.status === 200) {
        if (response && response.data && response.data.object) {
          const navItems = props.sectionsData;
          const approvalSummaryData = [];
          Object.keys(navItems).forEach(navItemKey => {
            const approvalSectionData = response.data.object.find(section => navItems[navItemKey].key === section.sectionPath);
            const sectionStatus = approvalSectionData
              ? (approvalSectionData.isApproved ? "Approved by " : "Unapproved by ") + approvalSectionData.modifiedBy + " at " + FormatDate(approvalSectionData.updatedAt)
              : "Not approved";
            const approvalData = {
              section: navItems[navItemKey].key,
              status: sectionStatus,
            };
            approvalSummaryData.push(approvalData);
          });
          setApprovalSummary(approvalSummaryData);
        }
      }
    });
  };
  return (
    <Modal
      isOpen={isModalOpen ? true : false}
      onAfterOpen={() => {}}
      onRequestClose={() => {
        props.setDisplayApprovalSummary(false);
      }}
      style={customStyles}
      contentLabel="Requirement sections Summary"
      shouldCloseOnOverlayClick={true}
    >
      <div className="title-wrapper navbar navbar-default">
        <ul className="nav navbar-nav">
          <li>
            <a>Approval Summary</a>
          </li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
          <li>
            <button className="btn btn-default btn-padded" onClick={() => props.setDisplayApprovalSummary(false)}>
              Cancel
            </button>
          </li>
        </ul>
      </div>
      <ReactTable columns={tableColumns} data={approvalSummary} minRows={5} showPagination={false} />
    </Modal>
  );
};

const CommentBox = props => {
  const { projectId, customerId, isCustomerRole, commentPath, getComments } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReplyComment, setIsReplyComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [commentType, setCommentType] = React.useState("external");

  useEffect(() => {
    if (props.comment) {
      setIsExpanded(true);
      setIsReplyComment(true);
    }
  }, [props.comment]);

  const onValueChange = e => {
    setCommentValue(e.target.value);
  };
  const onTypeChange = event => {
    setCommentType(event.target.value);
  };
  const addComment = data => {
    server.createComment(customerId, projectId, data, response => {
      if (response && response.status === 201) {
        getComments();
      }
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (commentValue.length) {
      const commentRequest = {
        comment: commentValue,
        commentType: commentType,
        path: commentPath,
      };
      if (isReplyComment) {
        commentRequest["parentComment"] = props.comment._id;
      }
      addComment(commentRequest);
      onClose();
    }
  };

  const onExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };
  const onClose = () => {
    if (isReplyComment) {
      props.hideReply(props.comment);
    } else {
      setCommentValue("");
      setCommentType("external");
      setIsExpanded(false);
    }
  };

  return (
    <div>
      <form
        onSubmit={onSubmit}
        className={`commentBox ${isExpanded ? "expanded" : "collapsed"}`}
        style={{
          minHeight: isExpanded ? 130 : 40,
        }}
      >
        <textarea
          onClick={onExpand}
          onFocus={onExpand}
          onChange={onValueChange}
          className="commentField"
          placeholder={isExpanded ? "Comments will be here" : "Add Comment here"}
          value={commentValue}
          name="comment"
          id="comment"
        />
        <div className="actions">
          {isReplyComment || isCustomerRole ? null : (
            <select onChange={onTypeChange} value={commentType} className="commentTypeSelect">
              <option key="external" value="external">
                Public
              </option>
              <option key="internal" value="internal">
                Private
              </option>
            </select>
          )}
          <button type="button" className="cancel" onClick={onClose}>
            Cancel
          </button>
          <button type="submit" className="submitBtn" disabled={commentValue.length < 1}>
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

const CommentsSection = props => {
  const { isCommentpopupOpened, commentPath, toggleDrawer, setHighlightedRow, projectId, customerId, isCustomerRole } = props;
  const [commentFilters, setCommentFilters] = useState({ status: "", commentType: "" });
  const [commentsData, setCommentsData] = useState([]);
  const currentUser = store.get("currentUser");

  useEffect(() => {
    if (isCommentpopupOpened) {
      getComments();
    }
  }, [isCommentpopupOpened]);

  const getComments = () => {
    server.getCommentsForPath(customerId, projectId, commentPath, response => {
      if (response && response.status === 200 && response.data.object) {
        const allComments = response.data.object;
        if (allComments.length > 0) {
          const replyComments = [];
          for (let index = allComments.length - 1; index >= 0; index--) {
            if (typeof allComments[index].parentComment !== "undefined") {
              replyComments.push(allComments[index]);
              allComments.splice(index, 1);
            }
          }
          replyComments.forEach(replyComment => {
            for (let index = 0; index < allComments.length; index++) {
              const comment = allComments[index];
              if (comment._id === replyComment.parentComment) {
                if (!comment["replies"]) {
                  comment["replies"] = [];
                }
                comment["replies"].push(replyComment);
              }
            }
          });
        }
        setCommentsData(allComments);
        const commentsContainerElement = document.getElementById("comment-section-container");
        commentsContainerElement.scrollTo({
          top: commentsContainerElement.scrollHeight,
          behavior: "smooth",
        });
      }
    });
  };

  const onHoverComment = path => {
    setHighlightedRow(path);
  };

  const onMouseLeaveFromComment = () => {
    setHighlightedRow("");
  };

  const renderComments = (comment, parentComment) => {
    const isCommentedByCurrentUser = currentUser === comment.modifiedBy;
    let commentedRow = parentComment ? null : document.getElementById(comment.path);
    if (commentedRow) {
      commentedRow = commentedRow.tagName === "TR";
    }
    return (
      <div className="comment-wrapper">
        <div className="comment-section-left">
          <div className="user-badge-wrapper">
            <span className="user-badge-text">{comment.modifiedBy.charAt(0)}</span>
          </div>
        </div>
        <div className="comment-section-right">
          <div className="comment-widget">
            <div className="comment-detail">
              <p className="user-email">
                <span className="mr-r-5">{comment.modifiedBy.split("@")[0]}</span>
                {comment.status === "addressed" ? <span className="badge bg-success">addressed</span> : null}
              </p>
              <p className="comment-time">
                <span className="mr-r-5">{FormatDate(comment.modifiedOn)}</span>
                {!parentComment && !isCustomerRole ? <span className="badge mr-r-5">{comment.commentType}</span> : null}
              </p>
            </div>
            <div className="comment-actions">
              <div>
                <Nav pullRight>
                  <NavDropdown title="Actions" className="comment-actions">
                    <MenuItem
                      onClick={() => {
                        deleteComment(comment);
                      }}
                    >
                      Delete
                    </MenuItem>
                    {!parentComment && (
                      <MenuItem
                        onClick={() => {
                          showReply(comment);
                        }}
                      >
                        Reply
                      </MenuItem>
                    )}
                    {isCommentedByCurrentUser ? (
                      <MenuItem
                        onClick={() => {
                          editComment(comment, parentComment);
                        }}
                      >
                        Edit
                      </MenuItem>
                    ) : null}
                    {isCustomerRole || parentComment ? null : comment.status !== "addressed" ? (
                      <MenuItem
                        onClick={() => {
                          changeCommentStatus(comment, true);
                        }}
                      >
                        {" "}
                        Mark Comment as addressed{" "}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          changeCommentStatus(comment, false);
                        }}
                      >
                        {" "}
                        Change to unaddressed
                      </MenuItem>
                    )}
                    {commentedRow ? (
                      <MenuItem
                        onClick={() => {
                          onHoverComment(comment.path);
                        }}
                      >
                        Highlight commented row
                      </MenuItem>
                    ) : null}
                  </NavDropdown>
                </Nav>
              </div>
            </div>
          </div>
          <div className="comment-text-section">
            {comment.onEdit ? (
              <form
                className="commentBox expanded"
                style={{ minHeight: "130px" }}
                onSubmit={e => {
                  saveComment(e, comment);
                }}
              >
                <textarea
                  onChange={e => {
                    commentEditOnChange(comment._id, e.target.value, parentComment);
                  }}
                  className="commentField"
                  value={comment.editValue}
                  name="comment"
                  id="edit-comment"
                />
                <div className="actions">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => {
                      cancelEditComment(comment, parentComment);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="submitBtn"
                    onClick={e => {
                      saveComment(e, comment);
                    }}
                  >
                    Save
                  </button>
                </div>
              </form>
            ) : (
              <p>{comment.comment}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const updateCommentsData = (id, whichvalue, newvalue) => {
    var index = commentsData.findIndex(x => x._id === id);
    if (index > -1) {
      let temporaryarray = commentsData.slice();
      temporaryarray[index][whichvalue] = newvalue;
      setCommentsData(temporaryarray);
    }
  };

  const updateReplies = (id, parentId, whichvalue, newvalue) => {
    const parentIndex = commentsData.findIndex(x => x._id === parentId);
    if (parentIndex > -1) {
      const replyIndex = commentsData[parentIndex].replies.findIndex(x => x._id === id);
      if (replyIndex > -1) {
        let temporaryarray = commentsData.slice();
        temporaryarray[parentIndex].replies[replyIndex][whichvalue] = newvalue;
        setCommentsData(temporaryarray);
      }
    }
  };

  const commentEditOnChange = (commentId, newValue, parentComment) => {
    if (parentComment) {
      updateReplies(commentId, parentComment, "editValue", newValue);
    } else {
      updateCommentsData(commentId, "editValue", newValue);
    }
  };
  const editComment = (comment, parentComment) => {
    if (parentComment) {
      updateReplies(comment._id, parentComment, "onEdit", true);
      updateReplies(comment._id, parentComment, "editValue", comment.comment);
    } else {
      updateCommentsData(comment._id, "onEdit", true);
      updateCommentsData(comment._id, "editValue", comment.comment);
    }
  };
  const cancelEditComment = (comment, parentComment) => {
    if (parentComment) {
      updateReplies(comment._id, parentComment, "onEdit", false);
    } else {
      updateCommentsData(comment._id, "onEdit", false);
    }
  };
  const saveComment = (e, comment) => {
    e.preventDefault();
    server.updateComment(customerId, projectId, comment._id, { comment: comment.editValue }, response => {
      if (response && response.status === 200) {
        getComments();
      }
    });
  };
  const showReply = comment => {
    updateCommentsData(comment._id, "showReply", true);
  };
  const hideReply = comment => {
    updateCommentsData(comment._id, "showReply", false);
  };
  const deleteComment = comment => {
    server.updateComment(customerId, projectId, comment._id, { status: "deleted" }, response => {
      if (response && response.status === 200) {
        getComments();
      }
    });
  };
  const changeCommentStatus = (comment, isAddressed) => {
    server.updateComment(customerId, projectId, comment._id, { status: isAddressed ? "addressed" : "new" }, response => {
      if (response && response.status === 200) {
        getComments();
      }
    });
  };
  const handleCommentFiltering = filter => {
    setCommentFilters({
      ...commentFilters,
      ...filter,
    });
  };
  const sortComments = comments => {
    return comments.sort((a, b) => new Date(a.modifiedOn) - new Date(b.modifiedOn));
  };
  return (
    <Drawer anchor="right" open={isCommentpopupOpened} BackdropProps={{ invisible: true }} onClose={toggleDrawer}>
      <div className="drawerContainer">
        <div className="commentHeaderContainer">
          <span className="commentHead">Comments</span>
          <div className="commentHeadActions">
            {isCustomerRole ? null : (
              <>
                <select onChange={e => handleCommentFiltering({ status: e.target.value })} value={commentFilters.status}>
                  <option key="all" value="">
                    All Status
                  </option>
                  <option key="new" value="new">
                    New
                  </option>
                  <option key="addressed" value="addressed">
                    Addressed
                  </option>
                </select>

                <select onChange={e => handleCommentFiltering({ commentType: e.target.value })} value={commentFilters.commentType}>
                  <option key="all" value="">
                    All Type
                  </option>
                  <option key="internal" value="internal">
                    Private
                  </option>
                  <option key="external" value="external">
                    Public
                  </option>
                </select>
              </>
            )}
            <IconButton onClick={toggleDrawer} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="comments-section-container" id="comment-section-container">
          <div className="flex-box-wrapper">
            {sortComments(commentsData).map((comment, index) => {
              if ((commentFilters.status !== "" && commentFilters.status !== comment.status) || (commentFilters.commentType !== "" && commentFilters.commentType !== comment.commentType)) {
                return null;
              }
              return (
                <div className="comment-container">
                  {renderComments(comment)}
                  {comment.replies
                    ? sortComments(comment.replies).map(reply => {
                        return <div className="reply-comment">{renderComments(reply, comment._id)}</div>;
                      })
                    : null}
                  <div>
                    {comment.showReply ? (
                      <CommentBox
                        commentPath={commentPath}
                        getComments={getComments}
                        comment={comment}
                        hideReply={hideReply}
                        customerId={props.customerId}
                        projectId={props.projectId}
                        isCustomerRole={props.isCustomerRole}
                      />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="comment-box-wrapper">
          <CommentBox commentPath={commentPath} getComments={getComments} customerId={props.customerId} projectId={props.projectId} isCustomerRole={props.isCustomerRole} />
        </div>
      </div>
    </Drawer>
  );
};

const Node = props => {
  const [expand, setExpand] = useState(false);
  const MAX = 99;
  const { node } = props;
  const childNodeData = node.components || node.subComponents;
  let childNodeElemnts = null;

  useEffect(() => {
    setExpand(props.expandAll);
  }, [props.expandAll]);

  if (node.hidden) {
    return null;
  }

  if (childNodeData) {
    const renderLabelText = labelText => labelText.charAt(0).toUpperCase() + labelText.slice(1);
    if (Array.isArray(childNodeData) && childNodeData.length) {
      childNodeElemnts = childNodeData.map((item, index) => {
        if (!("key" in item) || item.navbarHidden) {
          return;
        }
        return (
          <Node
            node={item}
            queryStr={`${props.queryStr}/${item.key}`}
            handleNavbarClick={props.handleNavbarClick}
            renderLabelText={renderLabelText}
            expandAll={props.expandAll}
            key={`sidebar-level-2-item-${index}`} // Adding Unique 'key' Prop to remove JSX warning.
          />
        );
      });
    } else {
      const childKeys = Object.keys(childNodeData);
      if (childKeys.length) {
        childNodeElemnts = childKeys.map((key, index) => {
          if (!("key" in childNodeData[key]) || childNodeData[key].navbarHidden) {
            return;
          }
          return (
            <Node
              node={childNodeData[key]}
              queryStr={`${props.queryStr}`}
              handleNavbarClick={props.handleNavbarClick}
              renderLabelText={renderLabelText}
              expandAll={props.expandAll}
              key={`sidebar-level-3-item-${index}`} // Adding Unique 'key' Prop to remove JSX warning.
            />
          );
        });
      }
    }
  }
  return (
    <li className="notification-container">
      <FontAwesomeIcon title={expand ? "Collapse" : "Expand"} icon={expand ? faAngleDown : faAngleRight} onClick={() => setExpand(!expand)} style={EXPAND_ICON_STYLE} />
      <Link
        activeClass="active"
        to={node.key}
        spy={true}
        isDynamic={true}
        smooth={true}
        offset={-75}
        duration={250}
        containerId="mainContentElement"
        onClick={() => {
          if (props.handleNavbarClick) {
            setExpand(true);
            props.handleNavbarClick(props.queryStr);
          }
        }}
      >
        {props.renderLabelText(props.node.label.value)}
        {node.sectionNewComments && <span className="notification-badge btn-warning">{node.sectionNewComments <= MAX ? node.sectionNewComments : MAX + "+"}</span>}
      </Link>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {childNodeElemnts ? <ul className="navbar-nav">{childNodeElemnts}</ul> : null}
      </Collapse>
    </li>
  );
};

const SideBar = props => {
  const [expandAll, setExpandAll] = useState(false);
  const { data, handleNavbarClick, subSectionValue } = props;
  const renderLabelText = labelText => labelText.toUpperCase();

  const keys = Object.keys(data);
  let nodes =
    keys.length &&
    keys.map((key, index) => {
      return (
        <Node
          node={data[key]}
          queryStr={data[key].key}
          handleNavbarClick={handleNavbarClick}
          subSectionValue={subSectionValue}
          expandAll={expandAll}
          renderLabelText={renderLabelText}
          key={`sidebar-level-1-item-${index}`} // Adding Unique 'key' Prop to remove JSX warning.
        />
      );
    });
  return (
    <div className="sidebarContent">
      <Navbar expand="xl">
        <span className="activeLinkStyle RightAlign" onClick={() => setExpandAll(!expandAll)}>
          {expandAll ? "Collapse All" : "Expand All"}
        </span>
        <ul className="navbar-nav">{nodes}</ul>
      </Navbar>
    </div>
  );
};

const RenderAfterApproveChanges = currentObj => {
  const [isOpen, setIsOpen] = useState(false);
  if (currentObj && currentObj.hasApprovedValue) {
    return (
      <>
        <SecondaryButton inline clickHandler={() => setIsOpen(true)} icon={<FontAwesomeIcon icon={faInfoCircle} data-for="CompareApprovedChanges" data-tip="react-tooltip" />} iconSize={15} />
        <XTooltip id="CompareApprovedChanges" placement="top">
          Compare Approved Changes
        </XTooltip>
        <Modal isOpen={isOpen} contentLabel="Compare Approved Data" onRequestClose={() => setIsOpen(false)} style={POPUP_MODAL_STYLE} shouldCloseOnOverlayClick={true}>
          <div style={{ position: "absolute", top: "8px", right: "8px" }}>
            <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />} clickHandler={() => setIsOpen(false)} iconSize={20} />
            <XTooltip id="closeModal" placement="left">
              Close
            </XTooltip>
          </div>
          <div style={{ margin: "12px" }}>
            <h5>Compare Approved Changes</h5>
            <div className="frd-table">
              <table>
                <thead>
                  <tr>
                    <th>Approved Value</th>
                    <th>Changed Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{currentObj.hasApprovedValue}</td>
                    <td>{currentObj.value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </>
    );
  }
  return null;
};

const EditableText = props => {
  const [inputValue, setInputValue] = useState(props.currentValue);
  const [currentObj, setCurrentObj] = useState(props.currentObj);
  const isEditable = props.isEditable;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentUserId = store.get("user")[store.get("currentUser")].id;
  const handleChange = event => {
    setInputValue(event.target.value);
    props.onChangeInput(event.target.value);
  };

  useEffect(() => {
    setInputValue(props.currentValue);
    setCurrentObj(props.currentObj);
  }, [props.currentValue]);

  useEffect(() => {
    setCurrentObj(props.currentObj);
  }, [JSON.stringify(props.currentObj)]);

  const addCommaForCurrencies = input => {
    try {
      if (isNaN(Number(input)) || input === "") {
        return JSON.parse(JSON.stringify(input)).replace(RegExp("\n", "g"), "<br>"); // to replace linebreak with br tag
      }
      let dollarUSLocale = Intl.NumberFormat("en-US");
      return dollarUSLocale.format(input);
    } catch (e) {
      console.log(input);
    }
  };
  const formatCurrencyAndElement = value => {
    if (!value) {
      return value;
    }
    if (typeof value === "string") {
      // for <
      value = value.replace(/</g, "&lt;");
      // for >
      value = value.replace(/>/g, "&gt;");
    }
    return addCommaForCurrencies(value);
  };

  return (
    <>
      {currentObj && currentObj.conflicts && currentObj.conflicts.orangeGenerated && currentObj.conflicts.manualEdit ? (
        <>
          <div className="conflict-block">
            <span>{currentObj.conflicts.manualEdit}</span>
            <button
              className="btn-warning"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Fix Conflicts
            </button>
          </div>
          <Modal
            isOpen={isModalOpen ? true : false}
            onAfterOpen={() => {}}
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
            style={{
              content: {
                width: "50%",
                margin: "auto",
              },
            }}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={true}
          >
            <div className="frd-table">
              <table>
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Orange generated</td>
                    <td>{currentObj.conflicts.orangeGenerated}</td>
                    <td>
                      <button
                        className="btn-warning"
                        onClick={() => {
                          const manualEntriesRequest = DeepCopyObject(currentObj);
                          manualEntriesRequest.value = currentObj.conflicts.orangeGenerated;
                          manualEntriesRequest.orangeGenerated = currentObj.conflicts.orangeGenerated;
                          manualEntriesRequest.editType = "overwrite";
                          manualEntriesRequest.source = "manual";
                          manualEntriesRequest.user = currentUserId;
                          props.fixConflicts([manualEntriesRequest]);
                          setIsModalOpen(false);
                        }}
                      >
                        Apply
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Manually Edited</td>
                    <td>{currentObj.conflicts.manualEdit}</td>
                    <td>
                      <button
                        className="btn-warning"
                        onClick={() => {
                          const manualEntriesRequest = DeepCopyObject(currentObj);
                          manualEntriesRequest.value = currentObj.conflicts.manualEdit;
                          manualEntriesRequest.orangeGenerated = currentObj.conflicts.orangeGenerated;
                          manualEntriesRequest.editType = "overwrite";
                          manualEntriesRequest.source = "manual";
                          manualEntriesRequest.user = currentUserId;
                          props.fixConflicts([manualEntriesRequest]);
                          setIsModalOpen(false);
                        }}
                      >
                        Apply
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        </>
      ) : isEditable ? (
        <span id={props.id || ""} className="editable-input">
          {props.inputType === "textArea" ? (
            <textarea value={inputValue} onChange={handleChange} style={props.inputStyle} rows={4} />
          ) : (
            <input type="text" value={inputValue} onChange={handleChange} style={props.inputStyle} />
          )}
          {currentObj && currentObj.error && <span className="input-error">{currentObj.error}</span>}
        </span>
      ) : (
        <span id={props.id || ""} dangerouslySetInnerHTML={{ __html: formatCurrencyAndElement(inputValue) }}></span>
      )}
      {RenderAfterApproveChanges(props.currentObj)}
    </>
  );
};

const EditableReactTable = props => {
  const { tableProps, isSectionEditable, onChangeInput, toggleDrawer, tablePath, staleManualEntries = () => null } = props;
  const [data, setData] = useState();
  const [columns, setColumns] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState("");
  const isTableEditing = isSectionEditable && tableProps.isEditable;

  useEffect(() => {
    setColumns(tableProps.columns);
    setData(tableProps.data);
    setIsLoaded(true);
  }, [JSON.stringify(props.tableProps)]);

  useEffect(() => {
    setHighlightedRow(props.highlightedRow);
  }, [props.highlightedRow]);

  const fixConflicts = data => {
    props.fixConflicts(data);
  };

  return (
    <>
      {isLoaded && columns.length > 0 ? (
        <>
          <div className="react-table-wrapper">
            <ConfirmDialog open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => staleManualEntries({ path: tablePath })}>
              Are you sure you want to refresh this table? Please note that this will permanently override any manual edits previously made.
            </ConfirmDialog>
            <table className="react-custom-editable-table">
              <thead>
                <tr>
                  {columns.map(columnData => {
                    return !columnData.isDeleted ? (
                      <th key={`header-${columnData.accessor}`}>
                        {columnData.Header}
                        {isSectionEditable && (props.tableProps.colRemoval || columnData.editType === "newCol") && (
                          <button
                            onClick={() => {
                              props.onDeleteCol(columnData.accessor);
                            }}
                            style={ROMOVE_COLUMN_BUTTON_STYLE}
                          >
                            <i className="glyphicon glyphicon-remove"></i>
                          </button>
                        )}
                      </th>
                    ) : null;
                  })}
                  {isSectionEditable ? <th></th> : null}
                </tr>
              </thead>
              <tbody>
                {data.map((dataRow, rowIndex) => {
                  let availableColspan = 1;
                  const isUnresolvedComment = dataRow["commentsData"] && dataRow["commentsData"].length > 0;

                  return !dataRow.isDeleted ? (
                    <tr key={rowIndex} id={dataRow.path} className={`tableRow ${highlightedRow === dataRow.path || isUnresolvedComment ? "bg-info" : ""}`}>
                      {columns.map((columnData, i, arr) => {
                        if (!dataRow[columnData.accessor] || columnData.isDeleted === true) {
                          return null;
                        }
                        let isLastCol = false;
                        if (arr.length - 1 === i) {
                          isLastCol = true;
                        }
                        let metadata = {};
                        if (dataRow.metadata && dataRow.metadata[columnData.accessor]) {
                          metadata = dataRow.metadata[columnData.accessor];
                        }
                        if (metadata.colSpan) {
                          availableColspan = metadata.colSpan;
                        }
                        if (availableColspan > 1 && !dataRow[columnData.accessor].value) {
                          return null;
                        }
                        if (typeof dataRow[columnData.accessor] !== "object") {
                          dataRow[columnData.accessor] = { value: dataRow[columnData.accessor], questionPath: "", isEditable: false };
                        }
                        return (
                          <td
                            key={columnData.accessor}
                            style={{
                              backgroundColor: metadata.color ? metadata.color : "inherit",
                              position: "relative",
                            }}
                            colSpan={metadata.colSpan ? metadata.colSpan : 1}
                          >
                            {dataRow[columnData.accessor].questionPath ? (
                              <a href onClick={() => props.handleSelect(dataRow[columnData.accessor].questionPath)} style={{ cursor: "pointer" }}>
                                <EditableText
                                  fixConflicts={fixConflicts}
                                  isEditable={dataRow[columnData.accessor].isEditable && isTableEditing}
                                  currentValue={dataRow[columnData.accessor].value}
                                  onChangeInput={newValue => {
                                    onChangeInput(rowIndex, columnData.accessor, newValue);
                                  }}
                                  inputStyle={{ width: "100%" }}
                                />
                              </a>
                            ) : (
                              <EditableText
                                isEditable={(dataRow[columnData.accessor].isEditable ? dataRow[columnData.accessor].isEditable : false) && isTableEditing}
                                fixConflicts={fixConflicts}
                                currentValue={dataRow[columnData.accessor].value}
                                currentObj={dataRow[columnData.accessor]}
                                onChangeInput={newValue => {
                                  onChangeInput(rowIndex, columnData.accessor, newValue);
                                }}
                                inputStyle={{ width: "100%" }}
                              />
                            )}
                            {isLastCol && !isSectionEditable && (
                              <IconButton onClick={e => toggleDrawer(e, dataRow.path)} className={`commentIcon ${isUnresolvedComment ? "active-comments" : ""}`}>
                                <CommentBtn />
                                {isUnresolvedComment ? <span className="comments-count">{dataRow["commentsData"].length}</span> : null}
                              </IconButton>
                            )}
                          </td>
                        );
                      })}
                      {isSectionEditable ? (
                        <td>
                          {props.tableProps.rowRemoval || dataRow.rowRemoval ? (
                            <button
                              onClick={() => {
                                props.onDeleteRow(rowIndex);
                              }}
                            >
                              <i className="glyphicon glyphicon-remove"></i>
                            </button>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
            {isTableEditing && tablePath && !props.isCustomGenerated ? (
              <>
                <button type="button" onClick={() => setConfirmOpen(true)} className="glyphicon glyphicon-refresh btn-padded RightAlign" data-for="RePullOrangeData" data-tip="react-tooltip" />
                <XTooltip id="RePullOrangeData" placement="top">
                  Refresh table data
                </XTooltip>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

const FRDSection = props => {
  const { approvalSections = [], handleSectionApproval = () => null } = props;
  const [isCommentpopupOpened, setIsCommentpopupOpened] = React.useState(false);
  const [commentPath, setCommentPath] = React.useState("");
  const [isSectionEditable, setIsSectionEditable] = useState(false);
  const [sectionDetails, setSectionDetails] = useState(props.FRDObject);
  const [actualSectionData, setActualSectionData] = useState({});
  const [actualSectionDataCopy, setActualSectionDataCopy] = useState({});
  const [highlightedRow, setHighlightedRow] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [approvalDetails, setApprovalDetails] = useState();
  const [changedDataAfterApproval, setChangedDataAfterApproval] = useState([]);
  const [removedComponents, setRemovedComponents] = useState([]);
  const [isSnapshotLoading, setIsSnapshotLoading] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(null);
  const currentUserId = store.get("user")[store.get("currentUser")].id;
  useEffect(() => {
    setIsSectionEditable(props.isEdit);
  }, [props.isEdit]);
  useEffect(() => {
    const deletedCmps = [];
    changedDataAfterApproval.forEach(cmp => {
      if (cmp.deletedComponent) {
        deletedCmps.push(cmp.deletedComponent);
      }
    });
    setRemovedComponents(deletedCmps);
  }, [changedDataAfterApproval]);
  useEffect(() => {
    if (!isCommentpopupOpened) {
      const parentContainerElement = document.getElementById("mainContentElement");
      parentContainerElement.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [isCommentpopupOpened]);
  useEffect(() => {
    if (highlightedRow) {
      const parentContainerElement = document.getElementById("mainContentElement");
      const elementToHighlight = document.getElementById(highlightedRow);
      if (parentContainerElement && elementToHighlight) {
        const parentTop = parentContainerElement.getBoundingClientRect().top + window.scrollY;
        const parentHeight = window.innerHeight - parentTop;
        const containerScroll = parentContainerElement.scrollTop;
        const elementTop = elementToHighlight.getBoundingClientRect().top - parentTop;
        let newScrollValue = containerScroll;
        if (elementTop < 0 || elementTop > parentHeight) {
          newScrollValue = containerScroll + elementTop - 100;
          parentContainerElement.scrollTo({
            top: newScrollValue,
            behavior: "smooth",
          });
        }
        setScrollPosition(newScrollValue);
      }
    }
  }, [highlightedRow]);

  useEffect(() => {
    if (approvalDetails && approvalDetails.isApproved && changedDataAfterApproval.length > 0) {
      const tempSectionData = DeepCopyObject(sectionDetails);
      const mergeApprovedSnapshots = addApprovedData({ [props.currentKey]: tempSectionData }, changedDataAfterApproval);
      setSectionDetails(mergeApprovedSnapshots[props.currentKey]);
    }
  }, [changedDataAfterApproval]);

  useEffect(() => {
    setActualSectionData(DeepCopyObject(props.FRDObject));
    setSectionDetails(DeepCopyObject(props.FRDObject));
    getFRDApprovedSnapShot();
  }, [props.FRDObject]);

  const getFRDApprovedSnapShot = () => {
    setIsSnapshotLoading(true);
    server.getFRDSnapshot(props.customerId, props.projectId, props.currentKey, response => {
      if (response && response.status === 200) {
        if (response && response.data && response.data.object && response.data.object[0]) {
          setApprovalDetails(response.data.object[0]);
          if (response.data.object[0].isApproved) {
            const sectionDetailsTrimmed = trimFrdSectionData(sectionDetails);
            const snapshotTrimmed = trimFrdSectionData(response.data.object[0].sectionData);
            const manualEntries = identifyDifferenceAfterApproval(snapshotTrimmed, sectionDetailsTrimmed);
            setChangedDataAfterApproval(manualEntries || []);
          }
        }
      }
      setIsSnapshotLoading(false);
    });
  };

  const toggleDrawer = (event, path = "") => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    if (!isCommentpopupOpened) {
      // set comment path when open comments section.
      if (!path) {
        if (sectionDetails.components) {
          if (props.subSectionValue) {
            path = sectionDetails.components.filter(component => component.key === props.subSectionValue)[0].path;
          } else {
            if (sectionDetails.components.length > 0) {
              path = sectionDetails.components[0].path;
            } else {
              path = sectionDetails.path;
            }
          }
        } else {
          path = sectionDetails.path;
        }
      }
      setCommentPath(path);
    } else {
      setHighlightedRow("");
    }
    if (isCommentpopupOpened) {
      props.refreshFRDData();
    }
    setIsCommentpopupOpened(!isCommentpopupOpened);
  };

  const fixConflicts = data => {
    props.fixConflicts(data);
  };

  const getManualEntries = (obj1, obj2) => {
    let manualEntries = [];
    if (typeof obj1 === "undefined" && typeof obj2 !== "object" && !obj2.hasOwnProperty("rowKey")) {
      manualEntries.push(obj2);
    }
    if (obj2.hasOwnProperty("accessor") && (typeof obj1 === "undefined" || obj2.isDeleted !== obj1.isDeleted)) {
      // this condition handle changes in add/remove table header.
      if (obj2.path.includes("new-col-")) {
        obj2.user = currentUserId;
        obj2["editType"] = "newCol";
      }
      manualEntries.push(obj2);
    } else {
      Object.keys(obj2).forEach(key => {
        if (key !== "commentsData") {
          if (typeof obj2[key] === "object") {
            if (Array.isArray(obj2[key])) {
              for (let index = 0; index < obj2[key].length; index++) {
                const diff = getManualEntries(obj1[key][index], obj2[key][index]);
                manualEntries = [...manualEntries, ...diff];
              }
            } else {
              obj2[key].user = currentUserId;
              if (typeof obj2[key].value === "string") {
                if (typeof obj1 === "undefined" || typeof obj1[key] === "undefined") {
                  if (key === "sectionNote") {
                    obj2[key].user = currentUserId;
                  } else {
                    if (obj2[key] === "label") {
                      obj2[key]["editType"] = "overwrite";
                    } else {
                      obj2[key]["editType"] = "newRow";
                    }
                  }
                  if (obj2[key].path) {
                    manualEntries.push(obj2[key]);
                  }
                } else if (obj1[key].value !== obj2[key].value) {
                  if (obj2[key].source !== "manual") {
                    obj2[key]["source"] = "manual";
                    obj2[key]["orangeGenerated"] = obj1[key].value;
                  }
                  obj2[key]["editType"] = key === "sectionNote" ? "sectionNote" : "overwrite";
                  if (obj2[key].path.includes("new-row-")) {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                }
                if (obj1 && obj2.isDeleted !== obj1.isDeleted) {
                  obj2[key]["source"] = "manual";
                  obj2[key]["orangeGenerated"] = obj1[key].value;
                  obj2[key]["editType"] = "overwrite";
                  obj2[key]["isDeleted"] = obj2.isDeleted;
                  if (obj2[key].path.includes("new-row-")) {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                } else if (obj1 && obj1[key] && obj2[key].isDeleted !== obj1[key].isDeleted) {
                  // Get manual edit changes in a table row because of add/remove column.
                  obj2[key]["source"] = "manual";
                  obj2[key]["orangeGenerated"] = obj1[key].value;
                  obj2[key]["editType"] = "overwrite";
                  obj2[key]["isDeleted"] = obj2[key].isDeleted;
                  if (obj2[key].path.includes("new-col-")) {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                }
              } else {
                if (obj1 && obj2) {
                  const diff = getManualEntries(obj1[key], obj2[key]);
                  manualEntries = [...manualEntries, ...diff];
                }
              }
            }
          }
        }
      });
    }
    return manualEntries;
  };

  const identifyDifferenceAfterApproval = (obj1, obj2) => {
    // Params (snapshot, currentData)
    let manualEntries = [];
    if (typeof obj1 === "undefined" && typeof obj2 !== "object" && !obj2.hasOwnProperty("rowKey")) {
      manualEntries.push(obj2);
    } else {
      Object.keys(obj2).forEach(key => {
        if (key !== "commentsData") {
          if (typeof obj2[key] === "object") {
            if (Array.isArray(obj2[key])) {
              if (obj1[key].length > obj2[key].length && key === "components") {
                const indexRemoved = [];
                obj1[key].forEach((snapshotCmp, snpIndex) => {
                  let keyAvailable = false;
                  obj2[key].forEach(currentCmp => {
                    if (currentCmp.key === snapshotCmp.key) {
                      keyAvailable = true;
                    }
                  });
                  if (!keyAvailable) {
                    indexRemoved.push(snpIndex);
                  }
                });
                indexRemoved.forEach(indRm => {
                  obj1[key].splice(indRm, 1);
                  if (obj1[key][indRm].label && obj1[key][indRm].label.value) {
                    const deletedObj = {
                      deletedComponent: obj1[key][indRm].label.value,
                    };
                    manualEntries.push(deletedObj);
                  }
                });
              }
              for (let index = 0; index < obj2[key].length; index++) {
                const diff = identifyDifferenceAfterApproval(obj1[key][index], obj2[key][index]);
                manualEntries = [...manualEntries, ...diff];
              }
            } else {
              obj2[key].user = currentUserId;
              if (typeof obj2[key].value === "string") {
                if (typeof obj1 === "undefined" || typeof obj1[key] === "undefined") {
                  if (key === "sectionNote") {
                    obj2[key].user = currentUserId;
                  } else {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                } else if (obj1[key].value !== obj2[key].value) {
                  if (obj2[key].source !== "manual") {
                    obj2[key]["source"] = "manual";
                    obj2[key]["orangeGenerated"] = obj1[key].value;
                  }
                  obj2[key]["editType"] = key === "sectionNote" ? "sectionNote" : "overwrite";
                  if (obj2[key].path.includes("new-row-")) {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                }
                if (obj1 && obj2.isDeleted !== obj1.isDeleted) {
                  obj2[key]["source"] = "manual";
                  obj2[key]["orangeGenerated"] = obj1[key].value;
                  obj2[key]["editType"] = "overwrite";
                  obj2[key]["isDeleted"] = obj2.isDeleted;
                  if (obj2[key].path.includes("new-row-")) {
                    obj2[key]["editType"] = "newRow";
                  }
                  manualEntries.push(obj2[key]);
                }
              } else {
                const diff = identifyDifferenceAfterApproval(obj1[key], obj2[key]);
                manualEntries = [...manualEntries, ...diff];
              }
            }
          }
        }
      });
    }
    return manualEntries;
  };

  const validateSectionHeaders = () => {
    let currentCompKey = props.subSectionValue;
    const sectionDetailsTemp = DeepCopyObject(sectionDetails);
    let errorCount = 0;
    if (sectionDetailsTemp.components && Array.isArray(sectionDetailsTemp.components)) {
      if (!props.subSectionValue) {
        currentCompKey = sectionDetailsTemp.components[0].key;
      }
      let componentIndex = sectionDetailsTemp.components.findIndex(obj => obj.key === currentCompKey);
      const labelObj = {};
      if (sectionDetailsTemp.components[componentIndex] && Object.keys(sectionDetailsTemp.components[componentIndex].subComponents).length > 0) {
        Object.keys(sectionDetailsTemp.components[componentIndex].subComponents).forEach(objKey => {
          const subcomponentObj = sectionDetailsTemp.components[componentIndex].subComponents[objKey];
          if (subcomponentObj.label && subcomponentObj.label.value) {
            if (labelObj[subcomponentObj.label.value.toLowerCase()]) {
              subcomponentObj.label.error = "This name already Exists";
              errorCount = errorCount + 1;
            } else {
              subcomponentObj.label.error = "";
              labelObj[subcomponentObj.label.value.toLowerCase()] = true;
            }
          }
        });
      }
      setSectionDetails(sectionDetailsTemp);
    }
    return errorCount;
  };

  const saveSectionDetails = approvalDetails => {
    const errorCount = validateSectionHeaders();
    if (errorCount > 0) {
      props.setEditErrorMessage("Please fix the errors to proceed");
      return;
    }
    props.setEditErrorMessage("");
    let actualData = actualSectionData;
    if (Object.keys(actualSectionDataCopy).length !== 0) {
      actualData = actualSectionDataCopy;
    }
    const manualEntries = getManualEntries(actualData, sectionDetails);
    const isApproved = approvalDetails && approvalDetails.isApproved ? true : false;
    if (isApproved) {
      handleSectionApproval(sectionDetails, false, getFRDApprovedSnapShot);
    }
    props.onSave(manualEntries, isApproved);
    props.closeModal();
  };

  const editSectionDetails = () => {
    props.setEditable(props.currentKey);
  };

  const updateTableOnChange = (currentObj, subcomponent, componentIndex) => {
    const sectionDetailsTemp = DeepCopyObject(sectionDetails);
    if (subcomponent) {
      if (componentIndex !== -1) {
        sectionDetailsTemp.components[componentIndex].subComponents[subcomponent].tables[Object.keys(currentObj)[0]] = Object.values(currentObj)[0];
      } else {
        sectionDetailsTemp.subComponents[subcomponent].tables[Object.keys(currentObj)[0]] = Object.values(currentObj)[0];
      }
      setSectionDetails(sectionDetailsTemp);
    } else if (componentIndex !== -1) {
      sectionDetailsTemp.components[componentIndex].tables[Object.keys(currentObj)[0]] = Object.values(currentObj)[0];
      setSectionDetails(sectionDetailsTemp);
    } else {
      setSectionDetails(currentValue => ({
        ...currentValue,
        tables: {
          ...currentValue.tables,
          ...currentObj,
        },
      }));
    }
  };

  const renderSmartBlock = currentObj => {
    if (isSectionEditable && currentObj.addSmartBlock) {
      const handleSectionNote = (html = null) => {
        const sectionDetailsTemp = DeepCopyObject(sectionDetails);
        let currentObjPathArr = currentObj.path.split(".");
        if (currentObjPathArr.length > 1) {
          currentObjPathArr.splice(0, 1);
          const valueToChange = currentObjPathArr.reduce((a, b) => {
            if (a && Array.isArray(a)) {
              return a.find(elm => elm.key === b);
            }
            return a && a[b];
          }, sectionDetailsTemp);
          if (valueToChange) {
            if (!valueToChange.sectionNote) {
              valueToChange.sectionNote = {};
            }
            valueToChange.sectionNote.value = html || "";
            valueToChange.sectionNote.path = `${currentObj.path}.sectionNote`;
            valueToChange.sectionNote.editType = `sectionNote`;
          }
        } else {
          if (!sectionDetailsTemp.sectionNote) {
            sectionDetailsTemp.sectionNote = {};
          }
          sectionDetailsTemp.sectionNote.value = html || "";
          sectionDetailsTemp.sectionNote.path = `${currentObj.path}.sectionNote`;
          sectionDetailsTemp.sectionNote.editType = `sectionNote`;
        }
        setSectionDetails(sectionDetailsTemp);
      };
      currentObj = DeepCopyObject(currentObj);
      if (currentObj.sectionNote) {
        return (
          <SmartBlock
            extensions={Extensions}
            html={currentObj.sectionNote.value}
            full
            onChange={({ json, html }) => {
              handleSectionNote(html);
            }}
          />
        );
      } else {
        return (
          <button
            onClick={() => {
              handleSectionNote();
            }}
          >
            Add Content
          </button>
        );
      }
    } else if (currentObj.sectionNote && currentObj.sectionNote.value && !currentObj.sectionNote.isDeleted) {
      return (
        <div className="frd-table">
          <div className="smartBlockWrapper" dangerouslySetInnerHTML={{ __html: currentObj.sectionNote.value }} />
        </div>
      );
    }
    return null;
  };

  const RenderAddColumnDialog = (table, tableKey, subcomponent, componentIndex) => {
    const FormSchema = {
      title: "Add new column",
      type: "object",
      required: ["columnName"],
      properties: {
        columnName: {
          type: "string",
          title: "Column Name",
        },
      },
    };
    const UISchema = {
      columnName: {
        "ui:autofocus": true,
      },
    };

    const customValidtion = (formdata, errors) => {
      const currentObj = {
        [tableKey]: { ...table },
      };
      if (formdata && formdata.columnName) {
        const findCol = currentObj[tableKey].tableProps.columns.find(col => col.Header.toLowerCase() === formdata.columnName.toLowerCase());
        if (findCol) {
          errors.columnName.addError("is a duplicate column name.");
        }
      }
      return errors;
    };

    const handleSubmit = ({ formData }) => {
      if (formData && formData.columnName) {
        const currentObj = {
          [tableKey]: { ...table },
        };
        const newColumn = DeepCopyObject(currentObj[tableKey].tableProps.columns[0]);
        newColumn.Header = formData.columnName;
        newColumn.accessor = `new-col-${new Date().getTime()}`;
        newColumn.colRemoval = true;
        newColumn.value = formData.columnName;
        newColumn.source = "manual";
        newColumn.path = `${currentObj[tableKey].path}.tableProps.columns.${newColumn.accessor}`;

        currentObj[tableKey].tableProps.columns.push(newColumn);
        currentObj[tableKey].tableProps.data.forEach(row => {
          const rowPath = row[currentObj[tableKey].tableProps.columns[0].accessor].path.split(".");
          rowPath.splice(rowPath.length - 1, 1, newColumn.accessor);
          row[newColumn.accessor] = {
            value: "",
            source: "manual",
            path: rowPath.join("."),
            questionPath: "",
            isEditable: true,
          };
        });
        updateTableOnChange(currentObj, subcomponent, componentIndex);
        setAddColumnDialog(null);
      }
    };
    return (
      <Modal isOpen={!!addColumnDialog} onRequestClose={() => setAddColumnDialog(null)} style={POPUP_MODAL_STYLE} shouldCloseOnOverlayClick={true}>
        <div>
          <SecondaryButton icon={<FontAwesomeIcon icon={faWindowClose} />} clickHandler={() => setAddColumnDialog(null)} iconSize={20} className="RightAlign" />
        </div>
        <div style={{ margin: "24px" }}>
          <Form key="AddNewColumnForm" schema={FormSchema} uiSchema={UISchema} onSubmit={handleSubmit} validate={customValidtion} liveValidate showErrorList={false}>
            <button className="btn btn-padded" onClick={() => setAddColumnDialog(null)}>
              Cancel
            </button>
            <button className="btn btn-primary btn-padded" type="submit">
              Add
            </button>
          </Form>
        </div>
      </Modal>
    );
  };

  const renderTables = (tables, subcomponent, componentIndex, isCustomGenerated = false) => {
    if (typeof tables !== "object") {
      return null;
    }
    return Object.keys(tables).map(tableKey => {
      const table = tables[tableKey];
      return (
        <div key={tableKey}>
          {table.label && table.label.value ? (
            <p>
              {table.label.questionPath ? (
                <a href onClick={() => props.handleSelect(table.label.questionPath)} style={{ cursor: "pointer" }}>
                  <EditableText
                    isEditable={isSectionEditable && table.label.isEditable}
                    currentValue={table.label.value}
                    fixConflicts={fixConflicts}
                    onChangeInput={newValue => {
                      const currentObj = {
                        [tableKey]: { ...table },
                      };
                      currentObj[tableKey].label.value = newValue;
                      updateTableOnChange(currentObj, subcomponent, componentIndex);
                    }}
                  />
                </a>
              ) : (
                <EditableText
                  isEditable={isSectionEditable && table.label.isEditable}
                  currentValue={table.label.value}
                  fixConflicts={fixConflicts}
                  onChangeInput={newValue => {
                    const currentObj = {
                      [tableKey]: { ...table },
                    };
                    currentObj[tableKey].label.value = newValue;
                    updateTableOnChange(currentObj, subcomponent, componentIndex);
                  }}
                />
              )}
            </p>
          ) : null}
          {table.description && table.description.value ? (
            <p>
              <EditableText
                isEditable={isSectionEditable && table.description.isEditable}
                currentValue={table.description.value}
                fixConflicts={fixConflicts}
                onChangeInput={newValue => {
                  const currentObj = {
                    [tableKey]: { ...table },
                  };
                  currentObj[tableKey].description.value = newValue;
                  updateTableOnChange(currentObj, subcomponent, componentIndex);
                }}
                inputType={"textArea"}
              />
            </p>
          ) : null}
          {/* Display SmartBlock for table discriptions if configured */}
          {table.description && table.description.sectionNote ? <p>{renderSmartBlock(table.description)}</p> : null}

          {/* Display tables only if table data exists */}
          {table.tableProps && table.tableProps.data && table.tableProps.data.length > 0 ? (
            <>
              <EditableReactTable
                tableProps={table.tableProps}
                tablePath={table.path}
                staleManualEntries={props.staleManualEntries}
                isSectionEditable={isSectionEditable}
                onChangeInput={(rowIndex, accessor, newValue) => {
                  const currentObj = {
                    [tableKey]: { ...table },
                  };
                  currentObj[tableKey].tableProps.data[rowIndex][accessor].value = newValue;
                  updateTableOnChange(currentObj, subcomponent, componentIndex);
                }}
                fixConflicts={fixConflicts}
                handleSelect={props.handleSelect}
                highlightedRow={highlightedRow}
                onDeleteRow={deleteIndex => {
                  const currentObj = {
                    [tableKey]: { ...table },
                  };
                  currentObj[tableKey].tableProps.data[deleteIndex]["isDeleted"] = true;
                  updateTableOnChange(currentObj, subcomponent, componentIndex);
                }}
                onDeleteCol={colAccessor => {
                  const currentObj = {
                    [tableKey]: { ...table },
                  };
                  const deleteCol = currentObj[tableKey].tableProps.columns.find(col => col.accessor === colAccessor);
                  if (deleteCol) {
                    deleteCol["isDeleted"] = true;
                  }
                  currentObj[tableKey].tableProps.data.forEach(row => {
                    if (row[colAccessor]) {
                      row[colAccessor]["isDeleted"] = true;
                    }
                  });
                  updateTableOnChange(currentObj, subcomponent, componentIndex);
                }}
                toggleDrawer={toggleDrawer}
                isCustomGenerated={isCustomGenerated}
              />
              <div>
                {isSectionEditable && table.tableProps.rowAdd ? (
                  <button
                    onClick={() => {
                      const currentObj = {
                        [tableKey]: { ...table },
                      };
                      const newRow = DeepCopyObject(currentObj[tableKey].tableProps.data[0]);
                      // if new object is empty or a deleted row ref.
                      // then create the corresponding table column fields. so that newRow have all column fields.
                      if (newRow.isDeleted === true) {
                        if (Array.isArray(currentObj[tableKey].tableProps.columns) && currentObj[tableKey].tableProps.columns.length) {
                          currentObj[tableKey].tableProps.columns.forEach(col => {
                            newRow[col.accessor] = {
                              path: `${currentObj[tableKey].path}.tableProps.data.Row0.${col.accessor}`,
                            };
                          });
                        }
                      }
                      newRow.rowRemoval = true;
                      newRow.rowKey = `new-row-${new Date().getTime()}`;
                      delete newRow.isDeleted;
                      Object.keys(newRow).forEach(newRowKey => {
                        if (typeof newRow[newRowKey] === "object" && newRow[newRowKey].hasOwnProperty("path")) {
                          const pathArr = newRow[newRowKey].path.split(".");
                          pathArr.splice(pathArr.length - 2, 2, newRow.rowKey, newRowKey);
                          newRow[newRowKey] = {
                            value: "",
                            source: "manual",
                            path: pathArr.join("."),
                            questionPath: "",
                            isEditable: true,
                          };
                        }
                      });
                      currentObj[tableKey].tableProps.data.push(newRow);
                      updateTableOnChange(currentObj, subcomponent, componentIndex);
                    }}
                    style={{ marginBottom: "40px", marginRight: "12px" }}
                  >
                    Add Row
                  </button>
                ) : null}
                {isSectionEditable && table.tableProps.colAdd ? (
                  <>
                    <button onClick={() => setAddColumnDialog(tableKey)} style={{ marginBottom: "40px", marginRight: "12px" }}>
                      Add Column
                    </button>
                    {/* Add Column dialog if applicable */}
                    {addColumnDialog === tableKey ? RenderAddColumnDialog(table, tableKey, subcomponent, componentIndex) : null}
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          {/* Add SmartBlock against each Table */}
          {renderSmartBlock(table)}
        </div>
      );
    });
  };

  const convertSectionIntoTemplate = (sectionData, oldKey, newKey) => {
    sectionData.key = newKey;
    sectionData.isEditable = true;
    sectionData.can_duplicate = false;
    const oldPath = sectionData.path;
    const newPath = sectionData.path.replace(oldKey, newKey);
    sectionData.path = newPath;
    sectionData.oldPath = oldPath;
    sectionData.isCustomGenerated = true;
    if (sectionData.label && typeof sectionData.label === "object") {
      sectionData.label.isEditable = true;
      sectionData.label.path = `${sectionData.path}.label`;
    }
    if (sectionData.description && typeof sectionData.description === "object") {
      sectionData.description.isEditable = true;
      sectionData.description.path = `${sectionData.path}.description`;
    }
    if (sectionData.tables && typeof sectionData.tables === "object") {
      Object.keys(sectionData.tables).forEach(tableKey => {
        const table = sectionData.tables[tableKey];
        table.path = table.path.replace(oldPath, newPath);
        if (table.tableProps) {
          table.tableProps.isEditable = true;
          table.tableProps.rowRemoval = true;
          table.tableProps.rowAdd = true;
          if (table.tableProps.data && Array.isArray(table.tableProps.data) && table.tableProps.columns && Array.isArray(table.tableProps.columns)) {
            table.tableProps.data.forEach(row => {
              row.path = row.path.replace(oldPath, newPath);
              table.tableProps.columns.forEach(column => {
                column.isEditable = true;
                row[column.accessor].isEditable = true;
                delete row[column.accessor].questionPath;
                row[column.accessor].path = row[column.accessor].path.replace(oldPath, newPath);
                row[column.accessor].rowRemoval = true;
                if (!column.persistOnDuplicate) {
                  row[column.accessor].value = "";
                }
              });
            });
          }
        }
      });
    }
    return sectionData;
  };

  const onDuplicateSection = (currentObj, componentIndex, sectionKey) => {
    const sectionDetailsTemp = DeepCopyObject(sectionDetails);
    const newKey = "generated_section_" + Math.floor(Math.random() * 10000000000);
    const newData = convertSectionIntoTemplate(DeepCopyObject(currentObj), sectionKey, newKey);
    sectionDetailsTemp.components[componentIndex].subComponents[newKey] = newData;
    let actualSectionDataLocal = DeepCopyObject(actualSectionDataCopy);
    if (Object.keys(actualSectionDataLocal).length === 0) {
      actualSectionDataLocal = DeepCopyObject(actualSectionData);
    }
    actualSectionDataLocal.components[componentIndex].subComponents[newKey] = newData;
    setActualSectionDataCopy(actualSectionDataLocal);
    const duplicatedSections = DeepCopyObject(props.duplicatedSections);
    duplicatedSections.push(newData);
    props.setDuplicatedSections(duplicatedSections);
    setSectionDetails(sectionDetailsTemp);
  };

  const removeSection = (currentObj, componentIndex, sectionKey) => {
    const sectionDetailsTemp = DeepCopyObject(sectionDetails);
    let actualSectionDataCopyTemp = DeepCopyObject(actualSectionDataCopy);
    if (Object.keys(actualSectionDataCopyTemp).length === 0) {
      actualSectionDataCopyTemp = DeepCopyObject(actualSectionData);
    }
    delete sectionDetailsTemp.components[componentIndex].subComponents[sectionKey];
    delete actualSectionDataCopyTemp.components[componentIndex].subComponents[sectionKey];
    setActualSectionDataCopy(actualSectionDataCopyTemp);
    const removedSections = DeepCopyObject(props.removedSections);
    removedSections.push(currentObj.path);
    props.setRemovedSections(removedSections);
    setSectionDetails(sectionDetailsTemp);
  };

  const renderSubcomponents = (subComponents, index) => {
    return Object.keys(subComponents).map(subcomponent => {
      const currentObj = subComponents[subcomponent];
      if (currentObj.hidden) {
        return null;
      }
      return (
        <div id={currentObj.key} key={currentObj.key}>
          <h4>
            <EditableText
              isEditable={isSectionEditable && currentObj.label.isEditable}
              currentValue={currentObj.label.value}
              fixConflicts={fixConflicts}
              onChangeInput={newValue => {
                const sectionDetailsTemp = DeepCopyObject(sectionDetails);
                if (index > -1) {
                  sectionDetailsTemp.components[index].subComponents[subcomponent].label.value = newValue;
                } else {
                  sectionDetailsTemp.subComponents[subcomponent].label.value = newValue;
                }
                currentObj.label.value = newValue;
                setSectionDetails(sectionDetailsTemp);
              }}
              currentObj={currentObj.label}
            />
            {currentObj.label.note ? <span class="label-note"> generated at {FormatDate(currentObj.label.note)}</span> : null}
          </h4>
          {currentObj.can_duplicate && isSectionEditable && (
            <button
              onClick={() => {
                onDuplicateSection(currentObj, index, subcomponent);
              }}
            >
              Duplicate Section
            </button>
          )}
          {currentObj.isCustomGenerated && isSectionEditable && (
            <button
              onClick={() => {
                removeSection(currentObj, index, subcomponent);
              }}
            >
              Remove Section
            </button>
          )}
          {currentObj.description && currentObj.description.value ? (
            <p>
              <EditableText
                isEditable={isSectionEditable && currentObj.description.isEditable}
                fixConflicts={fixConflicts}
                currentValue={currentObj.description.value}
                onChangeInput={newValue => {
                  const sectionDetailsTemp = DeepCopyObject(sectionDetails);
                  if (index > -1) {
                    sectionDetailsTemp.components[index].subComponents[subcomponent].description.value = newValue;
                  } else {
                    sectionDetailsTemp.subComponents[subcomponent].label.value = newValue;
                  }
                  currentObj.label.value = newValue;
                  setSectionDetails(sectionDetailsTemp);
                }}
                inputType={"textArea"}
              />
            </p>
          ) : null}
          {/* Display SmartBlock for section subComponents discriptions if configured */}
          {currentObj.description && currentObj.description.sectionNote ? <p>{renderSmartBlock(currentObj.description)}</p> : null}

          {renderTables(currentObj.tables, subcomponent, index, currentObj.isCustomGenerated)}

          {currentObj.subComponents ? renderSubcomponents(currentObj.subComponents, -1) : null}

          {/* Add SmartBlock against each SubComponent */}
          {renderSmartBlock(currentObj)}
        </div>
      );
    });
  };

  return (
    <div className="requirement-summary-wrapper" id={sectionDetails.key}>
      <CommentsSection
        isCommentpopupOpened={isCommentpopupOpened}
        commentPath={commentPath}
        toggleDrawer={toggleDrawer}
        setHighlightedRow={setHighlightedRow}
        customerId={props.customerId}
        projectId={props.projectId}
        isCustomerRole={props.isCustomerRole}
      />
      <div className="title-wrapper navbar navbar-default">
        <ul className="nav navbar-nav">
          <li>
            <a>
              <EditableText isEditable={isSectionEditable && sectionDetails.label.isEditable} currentValue={sectionDetails.label.value} />
            </a>
          </li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
          {approvalDetails && !isSnapshotLoading && (
            <li>
              <div
                style={{
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {approvalDetails.isApproved ? "Approved" : "Unapproved"} by {approvalDetails.modifiedBy}
              </div>
            </li>
          )}
          {approvalSections.includes(sectionDetails.key) && (
            <li>
              {isSnapshotLoading ? (
                <button
                  className="btn btn-warning btn-padded"
                  style={{
                    opacity: 0.5,
                    cursor: "not-allowed",
                  }}
                >
                  Loading...
                </button>
              ) : (
                <>
                  {approvalDetails && approvalDetails.isApproved ? (
                    <button className="btn btn-warning btn-padded" onClick={() => handleSectionApproval(sectionDetails, false, getFRDApprovedSnapShot)}>
                      <span>Unapprove {sectionDetails.label.value.toLowerCase()}</span>
                    </button>
                  ) : (
                    <button className="btn btn-warning btn-padded" onClick={() => handleSectionApproval(sectionDetails, true, getFRDApprovedSnapShot)}>
                      <span>Approve {sectionDetails.label.value.toLowerCase()}</span>
                    </button>
                  )}
                </>
              )}
            </li>
          )}
          {!isSectionEditable ? (
            <li>
              <button className="btn btn-warning btn-padded" onClick={toggleDrawer}>
                <span>Comments</span>
              </button>
            </li>
          ) : null}
          {props.isCustomerRole ? null : (
            <li>
              {isSectionEditable ? (
                <>
                  {props.editErrorMessage && <span className="input-error">{props.editErrorMessage}</span>}
                  <button
                    className="btn btn-default btn-padded"
                    onClick={() => {
                      props.setDuplicatedSections([]);
                      props.setRemovedSections([]);
                      props.setEditErrorMessage("");
                      props.closeModal();
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-warning btn-padded" onClick={() => saveSectionDetails(approvalDetails)}>
                    <i className="glyphicon glyphicon-save"></i>
                    <span>Save</span>
                  </button>
                </>
              ) : sectionDetails.isEditable ? (
                <button className="btn btn-warning btn-padded" onClick={() => editSectionDetails()}>
                  <i className="glyphicon glyphicon-pencil"></i>
                  <span>Edit</span>
                </button>
              ) : null}
            </li>
          )}
        </ul>
      </div>
      {approvalDetails && approvalDetails.isApproved && changedDataAfterApproval.length > 0 && (
        <p className="bg-info" style={{ padding: "10px", borderRadius: "4px" }}>
          Note: There are few changes in the Section after approval. Find
          <SecondaryButton inline icon={<FontAwesomeIcon icon={faInfoCircle} />} iconSize={15} />
          icon to see the changes
        </p>
      )}
      {removedComponents && removedComponents.length > 0 && (
        <p className="bg-info" style={{ padding: "10px", borderRadius: "4px" }}>
          Components deleted after approval: <b>{removedComponents.join(",")}</b>
        </p>
      )}
      {props.subSectionValue === "" && sectionDetails.description && sectionDetails.description.value ? (
        <p>
          <EditableText
            isEditable={isSectionEditable && sectionDetails.description.isEditable}
            fixConflicts={fixConflicts}
            currentValue={sectionDetails.description.value}
            onChangeInput={newValue => {
              setSectionDetails(currentValue => ({
                ...currentValue,
                description: {
                  ...currentValue.description,
                  value: newValue,
                },
              }));
            }}
            inputType={"textArea"}
          />
        </p>
      ) : null}

      {props.subSectionValue === "" && renderTables(sectionDetails.tables, null, -1)}

      {props.subSectionValue === "" && sectionDetails.subComponents ? renderSubcomponents(sectionDetails.subComponents, -1) : null}

      {sectionDetails.components && sectionDetails.components.length > 0
        ? sectionDetails.components.map((component, index) => {
            if (component.key === props.subSectionValue || (props.subSectionValue === "" && index === 0)) {
              return (
                <div key={"inner-component" + index}>
                  {component.label && component.label.value ? (
                    <h3>
                      <EditableText
                        isEditable={isSectionEditable && component.label.isEditable}
                        fixConflicts={fixConflicts}
                        currentValue={component.label.value}
                        id={component.key}
                        onChangeInput={newValue => {
                          const sectionDetailsTemp = DeepCopyObject(sectionDetails);
                          sectionDetailsTemp.components[index].label.value = newValue;
                          setSectionDetails(sectionDetailsTemp);
                        }}
                      />
                    </h3>
                  ) : null}
                  {component.description && component.description.value ? (
                    <p>
                      <EditableText
                        isEditable={isSectionEditable && component.description.isEditable}
                        fixConflicts={fixConflicts}
                        currentValue={component.description.value}
                        onChangeInput={newValue => {
                          const sectionDetailsTemp = DeepCopyObject(sectionDetails);
                          sectionDetailsTemp.components[index].description.value = newValue;
                          setSectionDetails(sectionDetailsTemp);
                        }}
                        inputType={"textArea"}
                      />
                    </p>
                  ) : null}
                  {/* Display SmartBlock for section Components discriptions if configured */}
                  {component.description && component.description.sectionNote ? <p>{renderSmartBlock(component.description)}</p> : null}
                  {/* Render all sections of subcomponents */}
                  {component.tables && renderTables(component.tables, null, index)}
                  {component.subComponents ? renderSubcomponents(component.subComponents, index) : null}

                  {/* Add SmartBlock against each Component */}
                  {renderSmartBlock(component)}
                </div>
              );
            } else {
              return null;
            }
          })
        : null}
      {/* Add SmartBlock against each Section */}
      {renderSmartBlock(sectionDetails)}
    </div>
  );
};

const MainContent = props => {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const [FRDObject, setFRDObject] = useState(props.FRDObject);
  const [subSectionValue, setSubSectionValue] = useState(props.subSectionValue);
  const [sectionToEdit, setSectionToEdit] = useState("");
  useEffect(() => {
    setFRDObject(props.FRDObject);
    setSubSectionValue(props.subSectionValue);
  }, [props.FRDObject, props.subSectionValue]);
  const redirectToActualQue = qid => {
    props.setQueid(qid);
    localStorage.setItem("lastRendered", qid);
    const requirementSummaryRenderParam = Object.keys(FRDObject)[0] + (subSectionValue ? "/" + subSectionValue : "");
    store.set("requirementSummaryRenderParam", requirementSummaryRenderParam);
    if (qid && qid.startsWith("GenericInfo")) {
      props.history.replace("/implementationSurvey");
    } else {
      props.history.replace("/projects");
    }
  };
  const editSection = key => {
    setSectionToEdit(key);
  };
  const saveSection = (manualEntries, isApproved) => {
    setSectionToEdit("");
    props.saveManualEntries(manualEntries, isApproved);
  };
  const handleCloseModal = () => {
    setSectionToEdit("");
  };
  const fixConflicts = data => {
    props.fixConflicts(data);
  };

  return (
    <div className="mainContent" id="mainContentElement">
      {Object.keys(FRDObject).map(key => {
        return (
          <FRDSection
            FRDObject={FRDObject[key]}
            key={`${FRDObject[key].key}-wrapper`}
            isEdit={false}
            handleSelect={redirectToActualQue}
            setEditable={editSection}
            currentKey={key}
            onSave={saveSection}
            subSectionValue={subSectionValue}
            fixConflicts={fixConflicts}
            projectId={props.projectId}
            customerId={props.customerId}
            isCustomerRole={props.isCustomerRole}
            refreshFRDData={props.refreshFRDData}
            approvalSections={props.approvalSections}
            handleSectionApproval={props.handleSectionApproval}
            duplicatedSections={props.duplicatedSections}
            setDuplicatedSections={props.setDuplicatedSections}
            removedSections={props.removedSections}
            setRemovedSections={props.setRemovedSections}
          />
        );
      })}
      {sectionToEdit ? (
        <Modal
          isOpen={sectionToEdit ? true : false}
          onAfterOpen={() => {}}
          onRequestClose={handleCloseModal}
          style={{
            content: {
              width: "50%",
              margin: "auto",
            },
            overlay: {
              zIndex: "1",
            },
          }}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <FRDSection
            FRDObject={FRDObject[sectionToEdit]}
            isEdit={true}
            onSave={saveSection}
            currentKey={sectionToEdit}
            handleSelect={redirectToActualQue}
            closeModal={handleCloseModal}
            subSectionValue={subSectionValue}
            fixConflicts={fixConflicts}
            refreshFRDData={props.refreshFRDData}
            staleManualEntries={props.staleManualEntries}
            approvalSections={props.approvalSections}
            projectId={props.projectId}
            customerId={props.customerId}
            handleSectionApproval={props.handleSectionApproval}
            duplicatedSections={props.duplicatedSections}
            setDuplicatedSections={props.setDuplicatedSections}
            removedSections={props.removedSections}
            setRemovedSections={props.setRemovedSections}
            editErrorMessage={props.editErrorMessage}
            setEditErrorMessage={props.setEditErrorMessage}
          />
        </Modal>
      ) : null}
    </div>
  );
};

const RequirementSummary = props => {
  const { selectedLevel = "", selectedValue = "" } = props.match.params;
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");
  const [allowFRDCreation, setAllowFRDCreation] = useState(false);
  const [lastFRDStatus, setLastFRDStatus] = useState("");
  const [frdJobs, setFRDJobs] = useState([]);
  const [showQuotaValModule, setQuotaValModule] = useState(false);
  const [summaryObject, setSummaryObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isCustomerRole, setIsCustomerRole] = useState(true);
  const [canDownloadDocx, setCanDownloadDocx] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const [approvalSections, setApprovalSections] = useState([]);
  const [duplicatedSections, setDuplicatedSections] = useState([]);
  const [removedSections, setRemovedSections] = useState([]);
  const [displayApprovalSummary, setDisplayApprovalSummary] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState("");
  useEffect(() => {
    if (projectId && customerId) {
      getRequirementSummary();
      getLatestFRDJObs();
    }
  }, [selectedLevel, projectId, customerId]);

  useLayoutEffect(() => {
    setProjectId(store.get("user")[store.get("currentUser")]["projectId"]);
    setCustomerId(store.get("user")[store.get("currentUser")]["customerId"]);
    setIsCustomerRole(store.get("user")[store.get("currentUser")]["role"].includes("customer"));
    setCanDownloadDocx(store.get("user")[store.get("currentUser")]["role"].includes("lead") || store.get("user")[store.get("currentUser")]["role"].includes("admin"));
    setIsAdminRole(store.get("user")[store.get("currentUser")]["role"].includes("admin"));
    setApprovalSections(store.get("user")[store.get("currentUser")]["approvalSections"]);
    //check local token or something
    const requirementSummaryRenderParam = store.get("requirementSummaryRenderParam");
    const currentParams = props.match.params.selectedLevel + (props.match.params.selectedValue ? "/" + props.match.params.selectedValue : "");
    if (requirementSummaryRenderParam) {
      if (requirementSummaryRenderParam !== currentParams) {
        handleNavbarClick(requirementSummaryRenderParam);
      }
      store.remove("requirementSummaryRenderParam");
    }
  }, []);

  const getRequirementSummary = () => {
    setIsLoading(true);
    server.getRequirementOutput(customerId, projectId, { selectedLevel }, async response => {
      setIsLoading(false);
      if (response && response.status === 200) {
        if (response && response.data && response.data.object) {
          let selectionDataSectionObjectMapping = selectedLevel ? response.data.object.selectedSectionData[selectedLevel] : response.data.object.selectedSectionData["Introduction"];
          transformSectionNote(selectionDataSectionObjectMapping);
          setSummaryObject(response.data.object);
        }
      }
    });
  };

  const handleSectionApproval = (sectionDetails, isApproved, callback) => {
    if (Object.keys(sectionDetails).length) {
      const approvedData = trimFrdSectionData(DeepCopyObject(sectionDetails));
      const reqBody = {
        sectionPath: sectionDetails.path || sectionDetails.key,
        sectionData: approvedData,
        isApproved: isApproved,
      };
      setIsLoading(true);
      server.approveFrdSection(customerId, projectId, reqBody, response => {
        setIsLoading(false);
        callback();
      });
    }
  };

  const getLatestFRDJObs = () => {
    setLastFRDStatus("");
    server.getFRDJobs(customerId, projectId, response => {
      if (response && response.status === 200) {
        const frdJobList = response.data.object;
        setAllowFRDCreation(true);
        frdJobList.sort((a, b) => new Date(b.executionStartTime).getTime() - new Date(a.executionStartTime).getTime());
        frdJobList.forEach(job => {
          if (!job.isComplete) {
            setAllowFRDCreation(false);
          }
        });
        if (frdJobList[0]) {
          setLastFRDStatus(frdJobList[0].status);
        }
        setFRDJobs(frdJobList);
      }
    });
  };

  const saveFRDManualEntries = (data, isApproved) => {
    setIsLoading(true);
    handleSectionApproval({}, true, () => {});
    if (duplicatedSections.length > 0 || removedSections.length > 0) {
      const customGeneratedData = [];
      duplicatedSections.forEach(section => {
        const toRemoveIndex = removedSections.indexOf(section.path);
        if (toRemoveIndex === -1) {
          const updatedData = {
            customerId: customerId,
            projectId: projectId,
            newPath: section.path,
            sourcePath: section.oldPath,
            template: section,
          };
          customGeneratedData.push(updatedData);
        } else {
          removedSections.splice(toRemoveIndex, 1);
        }
      });
      const customSectionsdata = {
        customerId: customerId,
        projectId: projectId,
        addSections: customGeneratedData,
        removeSections: removedSections,
      };
      server.saveCustomGeneratedSections(customSectionsdata, response => {
        if (response && response.status === 200) {
          setDuplicatedSections([]);
          setRemovedSections([]);
          server.saveManualEntries(customerId, projectId, data, response => {
            setIsLoading(false);
            if (response && response.status === 200) {
              getRequirementSummary();
            }
          });
        }
      });
    } else {
      server.saveManualEntries(customerId, projectId, data, response => {
        setIsLoading(false);
        if (response && response.status === 200) {
          getRequirementSummary();
        }
      });
    }
  };

  const staleManualEntries = pathStr => {
    setIsLoading(true);
    server.staleManualEntries(customerId, projectId, pathStr, response => {
      setIsLoading(false);
      if (response && response.status === 200) {
        getRequirementSummary();
      }
    });
  };

  const fixConflicts = data => {
    saveFRDManualEntries(data);
  };

  const handleNavbarClick = queryStr => {
    if (queryStr && queryStr !== "Introduction") {
      props.history.replace(`/requirementSummary/${queryStr}`);
    } else {
      props.history.replace(`/requirementSummary`);
    }
  };

  const renderFullscreenLoader = () => {
    return isLoading ? (
      <LoadingOverlay
        active
        spinner
        text={"Loading..."}
        styles={{
          wrapper: base => ({
            ...base,
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: "0",
            left: "0",
            zIndex: "2",
          }),

          spinner: base => ({
            ...base,
            height: "50px",
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      ></LoadingOverlay>
    ) : null;
  };

  const downloadPdf = () => {
    setIsLoading(true);
    server.getRequirementoutputPdf(customerId, projectId, response => {
      if (response && response.status === 200) {
        // /Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setIsLoading(false);
        //Open the URL on new Window
        window.open(fileURL);
      } else {
        setIsLoading(false);
      }
    });
  };

  const blobToString = b => {
    var u, x;
    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open("GET", u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
  };

  const downloadDocx = () => {
    setIsLoading(true);
    server.getRequirementDocx(customerId, projectId, response => {
      if (response && response.status === 200) {
        const convertedHTMLString = blobToString(response.data);
        var blob = new Blob(["\ufeff", convertedHTMLString], {
          type: "application/msword",
        });

        // Specify link url
        var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(convertedHTMLString);

        // Specify file name
        const filename = "frd.doc";

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;

          // Setting the file name
          downloadLink.download = filename;

          //triggering the function
          downloadLink.click();
        }

        document.body.removeChild(downloadLink);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const closeQuotaModule = (jobStatus = {}) => {
    setQuotaValModule(false);
  };
  const updateJobCreationStatus = props => {
    if (!props.status) {
      setSubmitErrorMsg(props.message);
    }
  };
  const handleNotification = data => {
    data = JSON.parse(data);
    getLatestFRDJObs();
    if (data.isComplete) {
      let objIndex = frdJobs.findIndex(obj => obj.requestId == data.requestId);
      let newList = [...frdJobs];
      newList[objIndex] = data;
      let enableButton = true;
      newList.forEach(job => {
        enableButton = enableButton && job.isComplete;
      });
      setAllowFRDCreation(enableButton);
      setFRDJobs(newList);
    } else {
      let newFrdJobs = [data, ...frdJobs].slice(0, MAX_ROW);
      setAllowFRDCreation(false);
      setFRDJobs(newFrdJobs);
    }
    let title = "";
    let message_suffix = data.isComplete ? "completed" + (data.isSuccess ? ". Please refresh the screen" : " with Error.") : "started.";
    let message = `FRD request for ${data.customerName} / ${data.project.name} (${data.project.sow}) ${message_suffix}`;
    let type = data.isSuccess ? "info" : "warning";
    NotificationStore.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: NOTIFICATION_TIMEOUT,
        onScreen: true,
        showIcon: true,
      },
    });
  };

  return (
    <div>
      <div className="generic-widgets-wrapper">
        {isCustomerRole ? null : (
          <>
            {lastFRDStatus && <div>Example Generation status: {lastFRDStatus}</div>}
            <div>
              {allowFRDCreation ? (
                <button className="btn btn-warning btn-padded" onClick={() => setQuotaValModule(true)} disabled={!allowFRDCreation}>
                  {frdJobs.length > 0 ? "Regenerate Examples" : "Generate Examples"}
                </button>
              ) : (
                <OverlayTrigger placement="top" overlay={<Tooltip id="jobInprogress">Job is in progress</Tooltip>}>
                  <button
                    className="btn btn-warning btn-padded"
                    style={{
                      opacity: 0.5,
                      cursor: "not-allowed",
                    }}
                  >
                    {frdJobs.length > 0 ? "Regenerate Examples" : "Generate Examples"}
                  </button>
                </OverlayTrigger>
              )}
            </div>
            <button className="btn btn-warning btn-padded" onClick={downloadPdf}>
              Download pdf
            </button>
            {canDownloadDocx && (
              <button className="btn btn-warning btn-padded" onClick={downloadDocx}>
                Download Docx
              </button>
            )}
            <Modal isOpen={showQuotaValModule} onHide={closeQuotaModule}>
              <div>
                <QuotaValueModule closeQuotaModule={closeQuotaModule} updateJobCreationStatus={updateJobCreationStatus} />
              </div>
            </Modal>
          </>
        )}
        {projectId && customerId && (
          <button
            className="btn btn-warning btn-padded"
            onClick={() => {
              setDisplayApprovalSummary(true);
            }}
          >
            Approval Summary
          </button>
        )}
      </div>
      {projectId && customerId && (
        <ApprovalSectionsSummary
          displayApprovalSummary={displayApprovalSummary}
          projectId={projectId}
          customerId={customerId}
          setDisplayApprovalSummary={setDisplayApprovalSummary}
          sectionsData={summaryObject.navItems}
          setIsLoading={setIsLoading}
        />
      )}
      <Websocket url={`${WS_ROOT}/customers/${customerId}/projects/${projectId}/jobStatus`} onMessage={handleNotification} debug={false} />
      {renderFullscreenLoader()}
      {Object.keys(summaryObject).length !== 0 ? (
        <Row>
          <Col sm={3} className="stickyGrid">
            <SideBar data={summaryObject.navItems || {}} handleNavbarClick={handleNavbarClick} subSectionValue={selectedValue} />
          </Col>
          <Col sm={9} className="stickyGrid">
            <MainContent
              {...props}
              FRDObject={summaryObject.selectedSectionData || {}}
              saveManualEntries={saveFRDManualEntries}
              refreshFRDData={getRequirementSummary}
              subSectionValue={selectedValue}
              fixConflicts={fixConflicts}
              isCustomerRole={isCustomerRole}
              customerId={customerId}
              projectId={projectId}
              approvalSections={approvalSections || []}
              handleSectionApproval={handleSectionApproval}
              staleManualEntries={staleManualEntries}
              duplicatedSections={duplicatedSections}
              setDuplicatedSections={setDuplicatedSections}
              removedSections={removedSections}
              setRemovedSections={setRemovedSections}
              editErrorMessage={editErrorMessage}
              setEditErrorMessage={setEditErrorMessage}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
// First RequirementSummary
// RequirementSummary refers MainContent
// SaveManualEntries referred as saveSection in MainContent
// MainContent Refers FRDSection
// saveSection of SaveManualEntries referred as onSave in FRDSection
// Within FRDSection saveSectionDetails is to trigger onSave Method to save Manual Entries
export default RequirementSummary;
