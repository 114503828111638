import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TableInput from "./TableBuilder";
import { generateID, getNamesForListID } from "./ManageObjectIDs";
import DynamicSelect from "./dynamicSelect";
import { validateNewValue, validations } from "./UpdateLists";
import FilterElement from "./tableFilters";
import { updateAttributes } from "./updateLists_v2";
import LoadingOverlay from "react-loading-overlay";
import Server from "./Server";
import { standardFields } from "./CommonResources.json";
const server = new Server();
const mandatoryFields = ["Order Code", "Item Code", "Amount", "Amount UnitType", "Incentive Date"];
const tableTypeToIncentFieldType = {
  DataMapping: "OrderItem",
  HRDataMapping: "People",
};
const defaultHRDataSource = "Customer HR Data";

class DataTableInput extends TableInput {
  constructor(props) {
    super(props);
    this.dataTableForm = "";
    this.sourceNames = [];
    this.pageSize = this.props.tableType === "DataMapping" ? 30 : 25;
    //  this.RenderEditable = tableEditor.RenderEditable.bind(this);
    this.errorMsg = "";

    this.attributes = [];
    this.NewLists = {};

    this.state = {
      dataReady: true,
      selectedRow: null,
      useDefaultHRSource: false,
      visibleColumns: [],
      columns: [
        {
          Header: "",
          accessor: "RemoveItem",
          style: { whiteSpace: "unset", overflow: "hidden" },
          maxWidth: 50,
          filterable: false,
        },
        {
          Header: "Xactly",
          accessor: "xactly",
          style: { whiteSpace: "unset", overflow: "hidden" },
          props: { type: "text" },
          Cell: this.renderEditableCell,
          maxWidth: 200,
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
        {
          Header: "DataType",
          accessor: "DataType",
          style: { whiteSpace: "unset", overflow: "hidden" },
          props: { type: "select", selectOptions: ["date", "string", "number"] },
          Cell: this.renderEditableCell,
          maxWidth: 150,
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      ],
      data:
        this.props.tableType === "DataMapping"
          ? [
              { id: "Order Code", xactly: "Order Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Item Code", xactly: "Item Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Batch Name", xactly: "Batch Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Batch Type", xactly: "Batch Type", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Product Name", xactly: "Product Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Geography Name", xactly: "Geography Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Customer Name", xactly: "Customer Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Quantity", xactly: "Quantity", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Amount", xactly: "Amount", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Amount UnitType", xactly: "Amount UnitType", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Incentive Date", xactly: "Incentive Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Order Date", xactly: "Order Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Order Type", xactly: "Order Type", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Discount", xactly: "Discount", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Description", xactly: "Description", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Related Order Code", xactly: "Related Order Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Related Item Code", xactly: "Related Item Code", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Employee ID", xactly: "Employee ID", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Split Amount (%)", xactly: "Split Amount (%)", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
            ]
          : [
              { id: "First Name", xactly: "First Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Middle Name", xactly: "Middle Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Last Name", xactly: "Last Name", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prefix", xactly: "Prefix", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prefix", xactly: "Employee ID", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Employee Status", xactly: "Employee Status", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Region", xactly: "Region", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Hire Date", xactly: "Hire Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Termination Date", xactly: "Termination Date", DataType: "date", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Personal Target", xactly: "Personal Target", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prorated Personal Target", xactly: "Prorated Personal Target", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Personal Currency", xactly: "Personal Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Payment Currency", xactly: "Payment Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Salary", xactly: "Salary", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Prorated Salary", xactly: "Prorated Salary", DataType: "number", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Salary Currency", xactly: "Salary Currency", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
              { id: "Business Group", xactly: "Business Group", DataType: "string", rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } } },
            ],
    };
  }

  componentDidMount() {
    let sourcelist = this.props.lists.Sources;
    let useDefaultHRSource = false;
    this.NewLists = JSON.parse(JSON.stringify(this.props.lists));
    // if currData sent to the component has valid data then assign that as intial data and set state.data as the same else use default statedata.

    let initialData = this.props.currData.data === undefined ? [...this.state.data] : [...this.props.currData.data];
    const sources = [];

    if (this.props.tableType === "HRDataMapping" && sources.length === 0) {
      if (this.props.hrIntegration) {
        useDefaultHRSource = false;
        sourcelist = this.props.lists.HRSources;
      } else {
        useDefaultHRSource = true;
        sourcelist = [{ id: defaultHRDataSource, name: defaultHRDataSource }];
      }
    }
    sourcelist.forEach(item => {
      const sourceID = item.id || item;
      if (this.props.tableType === "HRDataMapping") {
        this.sourceNames.push(getNamesForListID(sourceID, this.props.lists["HRSources"]));
        sources.push(sourceID);
      } else {
        this.sourceNames.push(getNamesForListID(sourceID, this.props.lists["Sources"]));
        sources.push(sourceID);
      }
    });

    let dataWithIncentiveAttributes = this.manageIncentiveAttributes(initialData);
    let initialColumns = [...this.state.columns];
    sources.forEach(source => {
      initialColumns.push(
        {
          Header: "",
          accessor: `Mapping_${source}`,
          show: true,
          props: { type: "select", selectOptions: ["Derived", "SetTo", "FieldMapping"], nullable: true },
          Cell: this.renderEditableCell,
          maxWidth: 150,
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
        {
          Header: this.props.tableType === "HRDataMapping" ? getNamesForListID(source, this.props.lists["HRSources"]) : getNamesForListID(source, this.props.lists["Sources"]),
          accessor: source,
          show: true,
          props: { type: "text", nullable: true },
          Cell: this.renderEditableCell,
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        },
      );
    });
    this.defaultSelected = sources ? (sources.length > 4 ? sources.splice(0, 4) : sources) : [];
    //<TODO>: default HR Data Sources dont work on defaultSelected
    if (useDefaultHRSource) {
      this.setState({ useDefaultHRSource: true, visibleColumns: [defaultHRDataSource] }, () => this.saveTable(dataWithIncentiveAttributes, initialColumns));
    } else {
      this.saveTable(dataWithIncentiveAttributes, initialColumns);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //overriding the  function from TableBuilder
  }

  //changeColumnVisibility = ({ formData }) => {
  changeColumnVisibility = formData => {
    let columns = [...this.state.columns];

    columns.forEach(col => {
      if (col.hasOwnProperty("show")) {
        if (formData.includes(col.accessor) || formData.includes(col.accessor.split("Mapping_")[1])) {
          col["show"] = true;
        } else {
          col["show"] = false;
        }
      }
    });
    this.setState({ columns: columns, visibleColumns: formData });
  };

  manageIncentiveAttributes = initialData => {
    let initialFields = [];
    for (let f = 0; f < initialData.length; f++) {
      initialFields.push(initialData[f]["id"] || initialData[f]["xactly"]);
    }
    // push splitAmount into this array as well so that it does not show up as a custom field

    const standardFieldIds = this.NewLists.AllAttributes.filter(item => item.hasOwnProperty("visibleInFormulas") && item.type === tableTypeToIncentFieldType[this.props.tableType]).map(
      item => item.id,
    );
    initialFields.push(...standardFieldIds);
    this.props.lists.QualifierAttributes.forEach(item => {
      if ((this.props.tableType === "DataMapping" && item.type === "OrderItem") || (this.props.tableType === "HRDataMapping" && item.type === "People")) {
        if (!(initialFields.includes(item.id) || initialFields.includes(item.name) || item.name === "All")) {
          initialData.push({
            id: item.id,
            xactly: item.name,
            rowLevelProps: { xactly: { Disabled: false }, DataType: { Disabled: false } },
            DataType: item.dataType || "string",
            IncentFieldType: item.type || "OrderItem",
          });
        }
      }
    });
    for (var row = 0; row < initialData.length; row++) {
      if (!standardFields[this.props.tableType].includes(initialData[row]["xactly"])) {
        const attrDet = this.props.lists.AllAttributes.filter(r => r.id === initialData[row].id)[0];
        if (attrDet) {
          initialData[row]["xactly"] = attrDet.name;
          initialData[row]["DataType"] = attrDet.dataType;
          initialData[row].IncentFieldType = attrDet.type;
        }
        if (initialData[row].IncentFieldType !== tableTypeToIncentFieldType[this.props.tableType]) {
          initialData.splice(row, 1);
        }
      }
    }
    return initialData;
  };

  validateData = data => {
    let names = {},
      customFields = [];

    let d = JSON.parse(JSON.stringify(data));
    let charLength_MIN = validations.fieldLengthBounds.QualifierAttributes.min;
    let charLength_MAX = validations.fieldLengthBounds.QualifierAttributes.max;
    let charLength_MAX_NUM = validations.fieldLengthBounds.QualifierAttributes.maxNum;

    for (let row = 0; row < d.length; row++) {
      //validate only the custom fields
      if (!standardFields[this.props.tableType].includes(d[row]["xactly"])) {
        customFields.push({ name: d[row]["xactly"], id: d[row]["id"], dataType: d[row].DataType });

        if (d[row]["xactly"].match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
          this.errorStatus(true, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        } else {
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        }
        // Check Character length for all custom field.
        if (d[row].DataType === "number") {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX_NUM) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
        } else {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
        }
      }
      names[d[row]["xactly"]] = names[d[row]["xactly"]] || [];
      names[d[row]["xactly"]].push(d[row]["xactly"]);
    }
    //check for duplicate names
    for (let name in names) {
      if (names[name].length > 1) {
        this.errorStatus(true, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      } else {
        this.errorStatus(false, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      }
    }

    if (Object.keys(this.errors).length === 0) {
      ["QualifierAttributes", "ComputationAttributes"].forEach(list => {
        this.NewLists[list].forEach(row => {
          customFields.forEach(field => {
            if (row.id === field.id) {
              row.name = field.name;
              row.dataType = field.dataType;
              row.type = this.props.tableType === "DataMapping" ? "OrderItem" : "People";
              //  this.NewLists["AllObjects"][field.id].name = field.name;
            }
          });
        });
      });
    }
  };

  updateRowLevelProps = data => {
    data.forEach(row => {
      this.state.visibleColumns.forEach(source => {
        if (row[`Mapping_${source}`] && row[`Mapping_${source}`] === "Derived") {
          if (!row.rowLevelProps) {
            row.rowLevelProps = {};
          }
          if (row.rowLevelProps && !row.rowLevelProps[source]) {
            row.rowLevelProps[source] = { type: "textarea" };
          } else {
            row.rowLevelProps[source].type = "textarea";
          }
        }
      });
      if (mandatoryFields.includes(row.id)) {
        row.isRequired = true;
      }
    });

    return data;
  };

  saveCellData = (val, cellInfo) => {
    let d = JSON.parse(JSON.stringify(this.state.data));
    let oldVal = d[cellInfo.index][cellInfo.column.id];
    if (cellInfo.original.ParentRow !== undefined) {
      d[cellInfo.original.ParentRow]["subComponent"]["data"][cellInfo.index][cellInfo.column.id] = val;
    } else {
      d[cellInfo.index][cellInfo.column.id] = val;
    }
    this.saveTable(d);
    if (["DataType", "xactly"].includes(cellInfo.column.id)) {
      if (oldVal !== val) {
        this.saveAttributeLists(d);
      }
    }
  };

  saveTable = (data, col = this.state.columns, visibleColumns = this.state.visibleColumns) => {
    this.validateData(data);
    this.setState(
      {
        data: data,
        columns: col,
        dataReady: true,
      },
      () => {
        this.changeColumnVisibility(visibleColumns);
        this.props.tableData({
          columns: col,
          data: data,
        });
      },
    );
  };
  removeItem = event => {
    let confirmation = window.confirm(`You want to delete this field ?`);
    if (confirmation) {
      var newdata = [...this.state.data];
      for (var i = 0; i < newdata.length; i++) {
        if (newdata[i]["id"] == event.target.id) {
          break;
        }
      }
      newdata.splice(i, 1);
      this.saveTable(newdata, this.state.columns);
    }
  };

  addRow = () => {
    const newData = [...this.state.data];
    let key = generateID("QualifierAttributes");
    let defaultName = "New_Custom_Field";
    const IncentFieldType = this.props.tableType === "DataMapping" ? "OrderItem" : "People",
      dataType = "string";
    newData[this.state.data.length] = {
      id: key,
      xactly: defaultName,
      DataType: dataType,
      removable: true,
      IncentFieldType: IncentFieldType,
    };

    const listItem = { name: defaultName, id: key, type: IncentFieldType, dataType: dataType };
    this.NewLists.QualifierAttributes.push(listItem);
    this.saveTable(newData);
    this.saveAttributeLists(newData);
  };
  saveAttributeLists = tableData => {
    // console.log(Object.values(this.errors).length);
    if (Object.values(this.errors).length === 0) {
      this.setState({ dataReady: false });
      const reqData = updateAttributes(this.NewLists, this.props.variables);
      server.updateLists(this.props.customerId, this.props.projectId, reqData, response => {
        this.props.saveProjectDetails(response.data.object[0]);
        this.saveTable(tableData);
      });
    }
  };

  addSource = source => {
    let listType = "";
    if (this.props.tableType === "HRDataMapping") {
      listType = "HRSources";
    } else {
      listType = "Sources";
    }
    let key = generateID(listType);
    let listItem = {};
    if (listType === "HRSources") {
      listItem = { name: source, id: key };
    } else {
      listItem = { name: source, id: key, uploadMethod: "native" };
    }
    if (!this.NewLists.AllObjects) {
      this.NewLists.AllObjects = {};
    }
    this.NewLists[listType].push(listItem);
    this.NewLists.AllObjects[key] = { name: source, type: listType };
    this.setState({ dataReady: false });
    let listData = {};
    listData[listType] = this.NewLists[listType];
    listData.AllObjects = this.NewLists.AllObjects;
    server.updateLists(this.props.customerId, this.props.projectId, listData, response => {
      this.props.updatedLists(this.NewLists);
      let columns = [
        ...this.state.columns,
        {
          Header: "",
          accessor: `Mapping_${key}`,
          show: true,
          props: { type: "select", selectOptions: ["Derived", "SetTo", "FieldMapping"], nullable: true },
          Cell: this.renderEditableCell,
          maxWidth: 130,
        },
        {
          Header: source,
          accessor: key,
          show: true,
          props: { type: "text", nullable: true },
          Cell: this.renderEditableCell,
        },
      ];
      this.setState({
        columns: columns,
        dataReady: true,
      });
      this.props.tableData({
        columns: columns,
        data: this.state.data,
      });
      this.props.saveProjectDetails(response.data.object[0]);
    });
    return key;
  };

  validateSource = source => {
    return validateNewValue("Sources", source);
  };
  displayData = () => {
    let d = this.updateRowLevelProps(JSON.parse(JSON.stringify(this.state.data)));
    return d;
  };

  render() {
    return (
      <div>
        <div className="">
          {!this.state.useDefaultHRSource ? (
            <DynamicSelect
              data={this.props.tableType === "HRDataMapping" ? this.props.lists.HRSources : this.props.lists.Sources}
              //data={this.props.sourcelist}
              defaultSelect={this.defaultSelected}
              labelKey="name"
              valueKey="id"
              onSelectionChange={this.changeColumnVisibility}
              caption={this.props.tableType === "HRDataMapping" ? "Select HR Sources(s)" : "Select Data Sources(s)"}
              addCaption={this.props.tableType === "HRDataMapping" ? "Add new HR Source" : "Add new Data Source"}
              multiSelect={true}
              onAdd={this.addSource}
              addItemLabel={this.props.tableType === "HRDataMapping" ? "HR Data Source" : "Data Source"}
              validate={this.validateSource}
            />
          ) : (
            ""
          )}
          <br />
        </div>
        <div className="Tabular">
          {Object.values(this.errors).length > 0 ? (
            <div className="alert alert-danger" role="alert">
              {this.displayErrorMessages()}
            </div>
          ) : (
            ""
          )}
          <LoadingOverlay
            active={!this.state.dataReady}
            spinner
            text="loading ..."
            styles={{
              spinner: base => ({
                ...base,
                width: "80px",
              }),

              overlay: base => ({
                ...base,
                background: "rgba(0, 0, 0, 0.2)",
              }),
            }}
          >
            <ReactTable
              columns={this.state.columns}
              data={this.displayData()}
              className="-striped -highlight"
              defaultPageSize={30}
              filterable
              defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
              pageSizeOptions={[20, 30, 40, 50]}
              showPageSizeOptions={true}
            />
          </LoadingOverlay>
        </div>
        <button className="btn btn-success btn-padded" onClick={this.addRow}>
          Add New Field
        </button>
      </div>
    );
  }
}

export default DataTableInput;
