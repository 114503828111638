import React from "react";
import "react-table/react-table.css";
import TableInput from "./TableBuilder";
import { getNamesForListID } from "./ManageObjectIDs";

class CreditResultsTable extends TableInput {
  constructor(props) {
    super(props);

    this.initialColumns = [];
    this.initialData = [];
    this.errors = {};
    this.state = { columns: [], data: [] };
  }

  getColumnProps = col => {
    let columnProps = { type: "text", nullable: true };
    if (this.props.editableColumnProps && this.props.editableColumnProps[this.props.cols[col]]) {
      columnProps = this.props.editableColumnProps[this.props.cols[col]];
    }
    return columnProps;
  };

  fillInitialColumns = () => {
    for (var col = 0; col < this.props.cols.length; col++) {
      let showColumn = true,
        sortable = true; // set columns default props.
      if (this.props.columnProperties && this.props.columnProperties[this.props.cols[col]]) {
        if (this.props.columnProperties[this.props.cols[col]].hasOwnProperty("show")) {
          showColumn = this.props.columnProperties[this.props.cols[col]].show;
        }
        if (this.props.columnProperties[this.props.cols[col]].hasOwnProperty("sortable")) {
          sortable = this.props.columnProperties[this.props.cols[col]].sortable;
        }
      }
      if (this.props.editableCols.includes(this.props.cols[col])) {
        this.initialColumns.push({
          Header: this.props.cols[col],
          accessor: this.props.cols[col],
          props: this.getColumnProps(col),
          Cell: this.renderEditableCell,
          show: showColumn,
          sortable
        });
      } else {
        this.initialColumns.push({
          Header: this.props.cols[col],
          accessor: this.props.cols[col],
          show: showColumn,
          sortable
        });
      }
    }
  };

  generateTable = () => {
    this.initialColumns = [];
    this.initialData = [];
    this.fillInitialColumns();

    for (var col = 0; col < this.props.cols.length; col++) {
      // if any column needs to be prepopulated then the following code populates it based on the props passed.
      // Fill from Global Variables:
      if (this.props.fillVariable && this.props.fillVariable[this.props.cols[col]]) {
        this.props.variables[this.props.fillVariable[this.props.cols[col]]].forEach(row => {
          if (row.Incentive && row.Plan) {
            if (row.Plan === this.props.currProps.plan && row.Incentive === this.props.currProps.incentive) {
              row.val.forEach(r => {
                let key = Math.random();
                this.initialData.push({
                  id: key,
                  TransactionType: row.TransactionType,
                  Attribute: r
                });
                this.initialData[this.initialData.length - 1][this.props.cols[col]] = [row.TransactionType, r];
              });
            }
          } else {
            if (row.Incentive) {
              if (row.Incentive === this.props.currProps.incentive) {
                row.val.forEach(r => {
                  let key = Math.random();
                  this.initialData.push({
                    id: key,
                    TransactionType: row.TransactionType,
                    Attribute: r
                  });
                  this.initialData[this.initialData.length - 1][this.props.cols[col]] = [row.TransactionType, r];
                });
              }
            }
          }
        });

        // If the current Plan/incentive borrows any credits from other plans, display those also in table.
        const borrowedCreditData = this.props.getBorrowedCredits();
        if (Array.isArray(borrowedCreditData) && borrowedCreditData.length) {
          borrowedCreditData.forEach(creditString => {
            const itemArr = creditString.split(" From ");
            let key = Math.random();
            this.initialData.push({
              id: key,
              Attribute: itemArr[0],
              TransactionType: itemArr[1]
            });
            this.initialData[this.initialData.length - 1][this.props.cols[col]] = [itemArr[1], itemArr[0]];
          });
        }
      }
    }

    if (this.props.defaultData) {
      this.initialData = this.props.defaultData.map(row => {
        row.id = Math.random();
        return row;
      });
    }
    return { columns: this.initialColumns, data: this.initialData };
  };

  refreshTable = () => {
    let table = this.generateTable();
    if (this.props.mergeKey && this.props.currData.data) {
      let allKeys = table.columns.map(row => row.accessor);
      let mergedData = table.data.map(genRow => {
        let newRow = { ...genRow };
        this.props.currData.data.forEach(currRow => {
          const newRowField = Array.isArray(newRow[this.props.mergeKey]) ? newRow[this.props.mergeKey].join("") : newRow[this.props.mergeKey];
          const currRowField = Array.isArray(currRow[this.props.mergeKey]) ? currRow[this.props.mergeKey].join("") : currRow[this.props.mergeKey];
          if (newRowField === currRowField) {
            allKeys.forEach(genKey => {
              if (this.props.editableCols.includes(genKey) && currRow[genKey]) {
                newRow[genKey] = currRow[genKey];
              }
            });
          }
        });
        return newRow;
      });

      table.data = mergedData;
    }

    this.errors = {};
    this.updateParentSubmitButton(false);
    this.saveTable(table.data, table.columns);
  };

  displayData = () => {
    let d = JSON.parse(JSON.stringify(this.state.data));

    let data = d.map(row => {
      Object.keys(row).forEach(key => {
        if (this.props.lists.AllObjects) {
          if (row[key] && Array.isArray(row[key]) && row[key].length) {
            let displayNames = row[key].map(val => (this.props.lists.AllObjects[val] ? this.props.lists.AllObjects[val].name : getNamesForListID(val, this.props.lists.Titles)));
            row[key] = displayNames.join(", ");
          } else {
            row[key] = this.props.lists.AllObjects[row[key]] ? this.props.lists.AllObjects[row[key]].name : getNamesForListID(row[key], this.props.lists.Titles);
          }
        } else {
          row[key] = getNamesForListID(row[key], this.props.lists.Titles);
        }
      });

      return row;
    });

    return data;
  };
}

export default CreditResultsTable;
