import React, { Component } from "react";
import Form from "react-jsonschema-form";
import Server from "./Server";
import { generateID, getIdFromAllObjects } from "./ManageObjectIDs";
import listProps from "./listPropertiesConfig.json";
import { listHeaders, incentStandardFields } from "./util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import LoadingOverlay from "react-loading-overlay";

import Modal from "react-modal";

const server = new Server();
const validations = {
  fieldLengthBounds: {
    Titles: { min: 3, max: 60 },
    Incentives: { min: 1, max: 53 }, //53 limit so that the word "commission" could be added to the incentive name to create the earning group and still remain inside the 64 character limit
    Quotas: { min: 1, max: 60 },
    Plans: { min: 1, max: 60 },
    Sources: { min: 1, max: 60 },
    HRSources: { min: 1, max: 60 },
    SourceSystems: { min: 1, max: 60 },
    QualifierAttributes: { min: 1, max: 64, maxNum: 51 },
    ComputationAttributes: { min: 1, max: 60 },
    AllAttributes: { min: 1, max: 60 },
    SuccessFactors: { min: 1, max: 1000 },
    currency: { min: 3, max: 60 },
    CustomerPlans: { min: 1, max: 60 },
    UnitTypes: { min: 1, max: 60 },
  },
};

const updateAttributes = (lists, variables, selectedList = "QualifierAttributes") => {
  let data = {};
  if (selectedList === "QualifierAttributes") {
    lists.QualifierAttributes.forEach(attr => {
      // for backward compatibility, need to lookup global variables.
      const attrDetails = attr.dataType ? [{ val: [attr.dataType] }] : variables.AttributeDataType ? variables.AttributeDataType.filter(item => item.Attribute === attr.id) : [];
      if (attrDetails.length > 0) {
        let dataType = attrDetails[0].val[0];
        if (dataType === "number") {
          if (lists.ComputationAttributes.filter(item => item.id === attr.id).length === 0) {
            lists.ComputationAttributes.push(attr);
          }
        } else {
          lists.ComputationAttributes.forEach((item, index, arr) => {
            if (item.id === attr.id) {
              arr[index] = attr;
            }
          });
        }
      }
      data[`AllObjects.${attr.id}`] = { type: "QualifierAttributes", name: attr.name };
    });
  } else if (selectedList === "ComputationAttributes") {
    lists.ComputationAttributes.forEach(attr => {
      if (lists.QualifierAttributes.filter(item => item.id === attr.id).length === 0) {
        lists.QualifierAttributes.push(attr);
      } else {
        lists.QualifierAttributes.forEach((item, index, arr) => {
          if (item.id === attr.id) {
            arr[index] = attr;
          }
        });
      }
      data[`AllObjects.${attr.id}`] = { type: "QualifierAttributes", name: attr.name };
    });
  }
  data.QualifierAttributes = lists.QualifierAttributes;
  data.ComputationAttributes = lists.ComputationAttributes;
  data.AllAttributes = [...lists.QualifierAttributes, ...incentStandardFields];
  return data;
};

const validateNewValue = (type, value) => {
  let errors = [];
  let val = value.trim();

  if (type === "QualifierAttributes") {
    if (val.match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
      errors.push(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
    }
  } else {
    if (val.match(/[!@#$%^&*(),.?":{}|<>-]/g) !== null) {
      errors.push(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
    }
  }
  if (val.length < validations.fieldLengthBounds[type].min || val.length > validations.fieldLengthBounds[type].max) {
    errors.push(`Length of each value in the list ${type} should be between ${validations.fieldLengthBounds[type].min} and ${validations.fieldLengthBounds[type].max} characters.`);
  }
  return errors;
};

class UpdateLists_v2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: this.props.selectedList,
      showDeleteModule: false,
      DeletionObjectId: "",
      errorList: [],
      warningList: [],
      allowDelete: false,
      dataReady: false,
    };
    this.deletedItems = [];
    this.listProps = listProps;
    this.AllLists = {};
  }
  componentDidMount() {
    console.log("get latest list snapshot from server");
    server.getProjectDetails(this.props.customerId, this.props.projectId, async response => {
      this.AllLists = response.data.object[0].AllLists;
      this.GlobalVariables = response.data.object[0].AllLists;
      if (this.AllLists["AllObjects"] === undefined) {
        this.AllLists["AllObjects"] = {};
      }
      this.setState({ selectedList: this.props.selectedList, formData: this.props.selectedList });
      this.setState({ dataReady: true });
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedList !== prevState.selectedList) return { selectedList: nextProps.selectedList };
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedList !== this.props.selectedList) {
      this.setState({ selectedList: this.props.selectedList });
    }
  }

  addToList = formData => {
    let reqData = {};
    let data = JSON.parse(JSON.stringify(formData.formData));

    data.forEach(item => {
      if (item.name) {
        if (item.id === undefined) {
          item.id = generateID(this.state.selectedList);
        }
        item.name = item.name.trim();
        reqData[`AllObjects.${item.id}`] = { type: this.state.selectedList, name: item.name };
      } else {
        item = item.trim();
      }
    });

    this.AllLists[this.state.selectedList] = data;

    if (["ComputationAttributes", "QualifierAttributes"].includes(this.state.selectedList)) {
      reqData = updateAttributes(this.AllLists, this.GlobalVariables, this.state.selectedList);
    } else {
      if (this.AllLists.AllAttributes && this.AllLists.AllAttributes.length === 0) {
        reqData.AllAttributes = [...incentStandardFields];
      }
      reqData[this.state.selectedList] = data;
    }
    this.deletedItems.forEach(item => {
      if (item) {
        reqData[`AllObjects.${item}.invalid`] = true;
      }
    });

    server.updateLists(this.props.customerId, this.props.projectId, reqData, response => {
      this.props.saveProjectDetails(response.data.object[0]);
    });
  };

  validateOptions = (formData, errors) => {
    let names = {};
    formData.forEach(item => {
      let charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].max;
      let val = item.name || item;
      val = val.trim();
      if (this.state.selectedList === "QualifierAttributes") {
        if (val.match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
          errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
        }
        if (item.dataType === "number") {
          charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].maxNum;
        }
      } else {
        if (val.match(/[!@#$%^&*(),.?":{}|<>-]/g) !== null) {
          errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
        }
      }
      if (val.length < validations.fieldLengthBounds[this.state.selectedList].min || val.length > charLength_MAX) {
        errors.addError(
          `Length of each value in the list ${this.state.selectedList} should be between ${validations.fieldLengthBounds[this.state.selectedList].min} and ${charLength_MAX} characters.`,
        );
      }
      names[item.name] = names[val] || [];
      names[item.name].push(val);
    });

    for (let name in names) {
      if (names[name].length > 1) {
        errors.addError(`Unique values required. The following value appears more than once: ${name}`);
      }
    }

    return errors;
  };

  ObjectFieldTemplate = props => {
    let field = props.properties.length;
    let hiddenFields = [];
    let disabledFields = [];
    let selectedListRow;
    if (this.AllLists[this.state.selectedList]) {
      selectedListRow = this.AllLists[this.state.selectedList].find(listItem => listItem.id === props.formData.id);
    }
    props.properties.forEach(element => {
      if (props.uiSchema[element.name]) {
        if (props.uiSchema[element.name]["ui:widget"] === "hidden") {
          field = field - 1;
          hiddenFields.push(element.name);
        }
      }
      if (
        selectedListRow &&
        selectedListRow[element.name] &&
        props.schema &&
        props.schema.properties &&
        props.schema.properties[element.name] &&
        props.schema.properties[element.name].disableConditions
      ) {
        if (props.schema.properties[element.name].disableConditions[element.name].includes(selectedListRow[element.name])) {
          disabledFields.push(element.name);
        }
      }
    });
    return (
      <div>
        {props.title}
        {props.description}
        {props.properties.map(element => {
          let divStyle = { display: "inline-block" };
          if (hiddenFields.includes(element.name)) {
            divStyle = { display: "none" };
          } else if (disabledFields.includes(element.name)) {
            divStyle = { display: "inline-block", pointerEvents: "none", opacity: "0.5", cursor: "not-allowed" };
          }
          return (
            <div key={element.name} style={{ ...divStyle, margin: "5px", width: `${90 / field}%` }}>
              {element.content}
            </div>
          );
        })}
      </div>
    );
  };

  deleteListItem = (element, formData) => {
    // if all validations are true and deletion is allowed
    if (element && element.hasOwnProperty("index")) {
      let objectId = formData
        .map((item, index) => {
          if (index === element.index) {
            return item.id;
          }
        })
        .filter(item => item);
      this.AllLists[this.state.selectedList] = formData.filter((item, index) => index !== element.index);
      //this.AllLists.AllObjects[element.index] = undefined;
      this.deletedItems.push(objectId[0]);
    } else {
      //Use the ID instead:
      this.deletedItems.push(element);
      this.AllLists[this.state.selectedList] = this.AllLists[this.state.selectedList].filter(item => item.id !== element);
      //this.AllLists.AllObjects[element] = undefined;
    }

    //<TODO>: Forces state
    this.setState(this.state);
  };

  handleListDeletion = (element, formData) => {
    this.setState({ formData: formData });
    let allowDelete = false;
    let objectId = element.children.props.formData.id;
    let objectName = getIdFromAllObjects(objectId, this.AllLists.AllObjects);
    this.setState({ DeletionObjectId: objectId });
    server.getDependencies(this.props.customerId, this.props.projectId, objectId, async response => {
      let errorArray = response.data.object;
      if (errorArray.length <= 0) {
        allowDelete = true;
        this.setState({ allowDelete: true });
      } else {
        this.setState({ showDeleteModule: true });
        let errorList = [];
        let warningList = [];
        errorArray.forEach(errorItem => {
          if (errorItem.hasOwnProperty("type") && errorItem.type == "error") {
            allowDelete = false;
            errorList.push(errorItem);
          } else {
            warningList.push(errorItem);
          }
          this.setState({ deleteErrorList: errorList, deleteWarningList: warningList });
        });

        if (errorList.length <= 0) {
          this.setState({ allowDelete: true });
        }
        //Special Logic for Plans:
        if (this.state.selectedList === "Plans" && warningList.length > 0) {
          this.setState({ allowDelete: false });
        }
        this.setState({ errorList: errorList, warningList: warningList });
      }

      if (allowDelete) {
        this.setState({ errorList: [], warningList: [], showDeleteModule: false, allowDelete: false, DeletionObjectId: "" });
        this.deleteListItem(element, formData);
      }
    });
  };

  ArrayFieldTemplate = props => {
    let divStyle = { pointerEvents: "none", opacity: "0.5", cursor: "not-allowed" };
    return (
      <div className="form-group field field-array">
        <fieldset className="field field-array field-array-of-object" id="root">
          <legend id="root__title">Add more options to the list</legend>
          <div className={props.className}>
            {props.items &&
              props.items.map(element => {
                return (
                  <div key={element.key} className={element.className}>
                    <div style={element.children.props && element.children.props.formData && element.children.props.formData.disable ? divStyle : {}}>
                      <div class="form-group field field-object" style={{ float: "left", width: "80%", top: "50%" }}>
                        {element.children}
                      </div>
                      <div class="col-xs-3 array-item-toolbox" style={{ float: "right", width: "20%", top: "50%" }}>
                        <div class="btn-group" style={{ display: "flex", justifyContent: "space-around" }}>
                          {element.hasMoveUp && (
                            <button
                              type="button"
                              className="btn btn-default array-item-move-up"
                              tabindex="-1"
                              disabled=""
                              style={{ flex: "1 1 0%", paddingLeft: "6px", paddingRight: "6px", fontWeight: "bold" }}
                              onClick={element.onReorderClick(element.index, element.index - 1)}
                            >
                              <i className="glyphicon glyphicon-arrow-up"></i>
                            </button>
                          )}
                          {element.hasMoveDown && (
                            <button
                              type="button"
                              className="btn btn-default array-item-move-down"
                              tabindex="-1"
                              disabled=""
                              style={{ flex: "1 1 0%", "padding-left": "6px", "padding-right": "6px", fontWeight: "bold" }}
                              onClick={element.onReorderClick(element.index, element.index + 1)}
                            >
                              <i className="glyphicon glyphicon-arrow-down"></i>
                            </button>
                          )}
                          {element.hasRemove && (
                            <button
                              className="btn btn-danger array-item-remove"
                              tabindex="-1"
                              style={{ flex: "1 1 0%", "padding-left": "6px", "padding-right": "6px", fontWeight: "bold" }}
                              onClick={evt => {
                                evt.preventDefault();
                                this.handleListDeletion(element, props.formData);
                              }}
                            >
                              <i className="glyphicon glyphicon-remove"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {props.canAdd && (
              <div className="row">
                <p className="col-xs-3 col-xs-offset-9 text-right array-item-add">
                  <button className="btn btn-info btn-add col-xs-12" onClick={props.onAddClick}>
                    <i className="glyphicon glyphicon-plus"></i>
                  </button>
                </p>
              </div>
            )}
          </div>
        </fieldset>
      </div>
    );
  };

  handleSelect = path => {
    //fix this here:
    this.props.SendQuestionPath(path);
    this.props.closeModal();
    this.setState({ showDeleteModule: false });
  };

  render() {
    if (this.AllLists[this.state.selectedList]) {
      this.AllLists[this.state.selectedList].forEach(listItem => {
        listItem.disable = this.props.isLocked;
      });
    }
    const listSchema = { title: `Update ${listHeaders[this.state.selectedList]} list`, type: "array", uniqueItems: true, default: this.AllLists[this.state.selectedList] };
    listSchema.items = this.props.listWithIDs ? this.listProps.fieldProps[this.state.selectedList] || this.listProps.fieldProps.Default : { type: "string" };
    const listUiSchema = {
      "ui:options": this.listProps.uiProps[this.state.selectedList] ? this.listProps.uiProps[this.state.selectedList].formLevelUi : this.listProps.uiProps.Default.formLevelUi,
      items: this.listProps.uiProps[this.state.selectedList] || this.listProps.uiProps.Default,
      "ui:hint": this.props.isLocked ? "Locked" : "",
    };
    return (
      <div className="SubSection">
        <LoadingOverlay
          active={!this.state.dataReady}
          spinner
          text={`Loading List`}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <SecondaryButton
            icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
            clickHandler={() => {
              this.props.closeModal();
            }}
            iconSize={20}
          />
          <Tooltip id="closeModal">Close</Tooltip>
          <Form
            schema={listSchema}
            uiSchema={listUiSchema}
            ObjectFieldTemplate={this.ObjectFieldTemplate}
            onSubmit={this.addToList}
            ArrayFieldTemplate={this.ArrayFieldTemplate}
            validate={this.validateOptions}
            autocomplete="off"
          >
            <div>
              <button type="submit" className="btn btn-success">
                Save
              </button>
            </div>
          </Form>
          <Modal
            style={{
              content: {
                height: "50%",
                width: "75%",
                marginLeft: "10px",
              },
            }}
            isOpen={this.state.showDeleteModule}
            onRequestClose={() => {
              this.setState({ errorList: [], warningList: [], showDeleteModule: false, allowDelete: false, DeletionObjectId: "" });
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <div>
                <SecondaryButton
                  icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                  clickHandler={() => {
                    this.setState({ errorList: [], warningList: [], showDeleteModule: false, allowDelete: false, DeletionObjectId: "" });
                  }}
                  iconSize={20}
                />
                <Tooltip id="closeModal">Close</Tooltip>
              </div>
              {this.state.errorList.length > 0 ? (
                <div>
                  <h4 style={{ color: "red" }}>{"Errors: "}</h4>
                  <div>{`Please address the following errors before deleting ${getIdFromAllObjects(this.state.DeletionObjectId, this.AllLists.AllObjects)}:`}</div>
                  <span />
                </div>
              ) : (
                <span />
              )}
              {this.state.errorList.length > 0 ? (
                this.state.errorList.map(error => {
                  //<TODO>: How to pass the formData and element here?
                  return (
                    <ul>
                      <li>
                        <a className="Clickable" onClick={e => this.handleSelect(error.link)}>
                          {error.message}
                        </a>
                      </li>
                    </ul>
                  );
                })
              ) : (
                <span />
              )}
              {this.state.warningList.length > 0 && this.state.errorList.length > 0 ? <hr /> : <span />}
              {this.state.warningList.length > 0 ? (
                <div>
                  <h4>{"Warnings: "}</h4>
                  <div>{`Note: Deleting this object will lose any related captured requirements.`}</div>
                  <span />
                </div>
              ) : (
                <span />
              )}
              {this.state.warningList.length > 0 ? (
                this.state.warningList.map(warning => {
                  if (warning.link !== null) {
                    return (
                      <ul>
                        <li>
                          <a className="Clickable" onClick={e => this.handleSelect(warning.link)}>
                            {warning.message}
                          </a>
                        </li>
                      </ul>
                    );
                  } else {
                    return <div>{warning.message}</div>;
                  }
                })
              ) : (
                <span />
              )}
              <hr />
              {this.state.allowDelete ? (
                <button
                  style={{ left: "50%", right: "50%" }}
                  disabled={!this.state.allowDelete}
                  onClick={e => {
                    this.deleteListItem(this.state.DeletionObjectId, this.AllLists[this.state.selectedList]);
                    this.setState({ errorList: [], warningList: [], showDeleteModule: false, allowDelete: false, DeletionObjectId: "" });
                  }}
                >
                  {"Confirm Delete"}
                </button>
              ) : (
                <button style={{ left: "50%", right: "50%", color: "red" }} disabled={!this.state.allowDelete}>
                  {"Cannot Delete"}
                </button>
              )}
            </div>
          </Modal>
        </LoadingOverlay>
      </div>
    );
  }
}

export { validateNewValue, validations, updateAttributes };
export default UpdateLists_v2;
