import React, { Component } from "react";
import Form from "react-jsonschema-form";
import Server from "./Server";
import { generateID } from "./ManageObjectIDs";
const server = new Server();
const validations = {
  fieldLengthBounds: {
    Titles: { min: 3, max: 60 },
    Incentives: { min: 1, max: 53 }, //53 limit so that the word "commission" could be added to the incentive name to create the earning group and still remain inside the 64 character limit
    Quotas: { min: 1, max: 60 },
    Plans: { min: 1, max: 60 },
    Sources: { min: 1, max: 60 },
    HRSources: { min: 1, max: 60 },
    SourceSystems: { min: 1, max: 60 },
    QualifierAttributes: { min: 1, max: 64, maxNum: 51 },
    ComputationAttributes: { min: 1, max: 60 },
    AllAttributes: { min: 1, max: 60 },
    SuccessFactors: { min: 1, max: 1000 },
    currency: { min: 3, max: 60 },
    ReferenceSources: { min: 1, max: 60 },
    UnitTypes: { min: 1, max: 60 }
  }
};

const validateNewValue = (type, value) => {
  let errors = [];
  let val = value.trim();

  if (type === "QualifierAttributes") {
    if (val.match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
      errors.push(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
    }
  } else {
    if (val.match(/[!@#$%^&*(),.?":{}|<>-]/g) !== null) {
      errors.push(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
    }
  }
  if (val.length < validations.fieldLengthBounds[type].min || val.length > validations.fieldLengthBounds[type].max) {
    errors.push(`Length of each value in the list ${type} should be between ${validations.fieldLengthBounds[type].min} and ${validations.fieldLengthBounds[type].max} characters.`);
  }
  return errors;
};

class UpdateLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: this.props.selectedList
    };
    this.AllLists = this.props.AllLists;
    this.GlobalVariables = this.props.projectDetails.GlobalVariables === undefined ? {} : this.props.projectDetails.GlobalVariables;
  }
  componentDidMount() {
    console.log("Old UpdateLists component mounted");
    //console.log(this.props);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedList !== prevState.selectedList) return { selectedList: nextProps.selectedList };
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedList !== this.props.selectedList) {
      this.setState({ selectedList: this.props.selectedList });
    }
  }

  addToList = formData => {
    let data = formData.formData;
    data.forEach(item => {
      if (item.name) {
        if (item.id === undefined) {
          item.id = generateID(this.state.selectedList);
        }
        item.name = item.name.trim();
        this.AllLists["AllObjects"][item.id] = { type: this.state.selectedList, name: item.name };
      } else {
        item = item.trim();
      }
    });
    this.AllLists[this.state.selectedList] = data;

    server.updateProjectDetails(
      this.props.customerId,
      this.props.projectId,
      {
        AllLists: this.AllLists,
        Path: this.props.projectDetails.Path,
        ConfigDetails: this.props.projectDetails.ConfigDetails,
        Loop: this.props.projectDetails.Loop,
        GlobalVariables: this.GlobalVariables,
        QuestionSchemaObj: this.props.projectDetails.QuestionSchemaObj
      },
      response => {
        //console.log("List saved to Database");
        this.props.saveLists(this.AllLists, this.props.projectDetails.ConfigDetails);
      }
    );
  };

  validateOptions = (formData, errors) => {
    //console.log("validating the options");
    //console.log(this.state.selectedList);
    let names = {};
    formData.forEach(item => {
      let charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].max;
      let val = item.name || item;
      val = val.trim();

      //to check against default values coming from schema.
      if (this.state.selectedList === "QualifierAttributes") {
        if (val.match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
          errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
        }
        if (item.dataType === "number") {
          charLength_MAX = validations.fieldLengthBounds[this.state.selectedList].maxNum;
        }
      } else {
        if (val.match(/[!@#$%^&*(),.?":{}|<>-]/g) !== null) {
          errors.addError(`Only aphanumeric characters and underscores are allowed. Edit the following value to match the naming convention: ${val}`);
        }
      }
      if (val.length < validations.fieldLengthBounds[this.state.selectedList].min || val.length > charLength_MAX) {
        errors.addError(
          `Length of each value in the list ${this.state.selectedList} should be between ${validations.fieldLengthBounds[this.state.selectedList].min} and ${charLength_MAX} characters.`
        );
      }
      names[item.name] = names[val] || [];
      names[item.name].push(val);
    });

    for (let name in names) {
      if (names[name].length > 1) {
        errors.addError(`Unique values required. The following value appears more than once: ${name}`);
      }
    }

    return errors;
  };

  render() {
    const schema ={
      title: "Add more options to the list",
      type: "array",
      uniqueItems: true,
      items: this.props.listWithIDs
        ? {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              name: { type: "string", title: " " }
            }
          }
        : {
            type: "string"
          },

      default: this.AllLists[this.state.selectedList]
    } ;
    const uiSchema = {
      "ui:options": {
        orderable: true,
        removable: false
      },
      items: { id: { "ui:widget": "hidden" }, unitType: { "ui:widget": "select" } }
    };
    return (
      <div className="SubSection">
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={this.addToList}
          validate={this.validateOptions}
          autocomplete="off"
        >
          <div>
            <button type="submit" className="btn btn-success">
              Save
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export { validateNewValue, validations };
export default UpdateLists;
