import React, { useState, useEffect } from "react";
import Server from "./Server";
import Form from "react-jsonschema-form";
import store from "store";

const addCustomerSchema = {
  title: "Enter Name and Opportunity ID for the customer",
  type: "object",
  required: ["Name", "Opportunity ID"],
  properties: {
    Name: {
      type: "string"
    },
    "Opportunity ID": {
      type: "string"
    }
  }
};
const server = new Server();

const CustomerModal = props => {
  const [formData, setFormData] = useState();
  const [error, setError] = useState();
  const currentUser = store.get("user")[store.get("currentUser")]["id"];
  console.log(currentUser);

  //useEffect(() => {}, []);

  //<TODO>: How to pass this props back out?
  const addNewCustomer = formData => {
    console.log(formData.formData);
    server.createCustomer({ name: formData.formData["Name"], opportunityId: formData.formData["Opportunity ID"] }, response => {
      if (response.status == 201) {
        let newCustomerId = response.data._id;
        server.assignCustomer(newCustomerId, currentUser, resp => {
          props.handleSubmit();
        });
      } else {
        let error = { message: Object.values(response.originalMessages)[0], alertHeader: "Failed to Create Customer" };
        setError(error);
      }
    });
  };

  return (
    <div>
      <Form
        schema={addCustomerSchema}
        onSubmit={formData => {
          addNewCustomer(formData);
        }}
      >
        <div>
          <button className="btn btn-success" type="submit">
            Create
          </button>
        </div>
      </Form>
      {error && <div>{error.message}</div>}
    </div>
  );
};

export default CustomerModal;
