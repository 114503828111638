import React, { useState, useEffect } from "react";
import store from "store";
import Server from "./Server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { getIdFromAllObjects } from "./ManageObjectIDs";
import LoadingOverlay from "react-loading-overlay";

const server = new Server();
const DeletionModule = props => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [isDataReady, setIsDataReady] = useState(false);
  const allObjects = props.AllLists.AllObjects;

  const [objectId, setObjectId] = useState(props.objectId);
  const [errorList, setErrorList] = useState([]);
  const [warningList, setWarningList] = useState([]);
  const [allowDelete, setAllowDelete] = useState(false);

  useEffect(() => {
    server.getDependencies(customerId, projectId, objectId, async response => {
      let errorArray = response.data.object;
      if (errorArray.length <= 0) {
        props.deleteItem(objectId);
        setErrorList([]);
        setWarningList([]);
        setAllowDelete(false);
        setObjectId("");
        props.setSelectedSource("");
        props.setShowDeleteModal(false);
      } else {
        let errorList = [];
        let warningList = [];
        errorArray.forEach(errorItem => {
          if (errorItem.hasOwnProperty("type") && errorItem.type == "error") {
            errorList.push(errorItem);
          } else {
            warningList.push(errorItem);
          }
          setErrorList(errorList);
          setWarningList(warningList);
        });

        if (errorList.length <= 0) {
          setAllowDelete(true);
        }
      }
      setIsDataReady(true);
    });
  }, []);

  const handleSelect = path => {
    //fix this here:
    setIsDataReady(false);
    props.SendQuestionPath(path);
    props.setShowDeleteModal(false);
  };

  return (
    <LoadingOverlay
      active={!isDataReady}
      spinner
      text={`Loading List`}
      styles={{
        spinner: base => ({
          ...base,
          width: "80px"
        }),

        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)"
        })
      }}
    >
      <div
        style={{
          padding: "20px"
        }}
      >
        <div>
          <SecondaryButton
            icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
            clickHandler={() => {
              setErrorList([]);
              setWarningList([]);
              setAllowDelete(false);
              setObjectId("");
              props.setShowDeleteModal(false);
            }}
            iconSize={20}
          />
          <Tooltip id="closeModal">Close</Tooltip>
        </div>
        {errorList.length > 0 ? (
          <div>
            <h4 style={{ color: "red" }}>{"Errors: "}</h4>
            <div>{`Please address the following errors before deleting ${getIdFromAllObjects(objectId, allObjects)}:`}</div>
            <span />
          </div>
        ) : (
          <span />
        )}
        {errorList.length > 0 ? (
          errorList.map(error => {
            return (
              <ul>
                <li>
                  <a className="Clickable" onClick={e => handleSelect(error.link)}>
                    {error.message}
                  </a>
                </li>
              </ul>
            );
          })
        ) : (
          <span />
        )}
        {warningList.length > 0 && errorList.length > 0 ? <hr /> : <span />}
        {warningList.length > 0 ? (
          <div>
            <h4>{"Warnings: "}</h4>
            <div>{`Note: Deleting this object will lose any related captured requirements.`}</div>
            <span />
          </div>
        ) : (
          <span />
        )}
        {warningList.length > 0 ? (
          warningList.map(warning => {
            if (warning.link !== null) {
              return (
                <ul>
                  <li>
                    <a className="Clickable" onClick={e => handleSelect(warning.link)}>
                      {warning.message}
                    </a>
                  </li>
                </ul>
              );
            } else {
              return <div>{warning.message}</div>;
            }
          })
        ) : (
          <span />
        )}
        <hr />
        {allowDelete ? (
          <button
            style={{ left: "50%", right: "50%" }}
            disabled={!allowDelete}
            onClick={e => {
              setIsDataReady(false);
              props.deleteItem(objectId);
              setErrorList([]);
              setWarningList([]);
              setAllowDelete(false);
              setObjectId("");
              props.setSelectedSource("");
            }}
          >
            {"Confirm Delete"}
          </button>
        ) : (
          <button style={{ left: "50%", right: "50%", color: "red" }} disabled={!allowDelete}>
            {"Cannot Delete"}
          </button>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default DeletionModule;
