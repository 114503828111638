import Questions from "./Questions";
import "./ProjectSelection.css";
import Server from "./Server";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";

import Form from "react-jsonschema-form";

import { Typeahead } from "react-bootstrap-typeahead";
import ProjectLists from "./ProjectLists";

import { FormGroup, Row, ControlLabel, Modal, Button } from "react-bootstrap";

import store from "store";
import update from "store/plugins/update";
import Websocket from "react-websocket";
import { WS_ROOT, NOTIFICATION_TIMEOUT } from "./api-config";
import { store as NotificationStore } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ComponentProvider from "./ComponentProvider";
store.addPlugin(update);

const server = new Server();

const addCustomerSchema = {
  title: "Enter Name and Opportunity ID for the customer",
  type: "object",
  required: ["Name", "Opportunity ID"],
  properties: {
    Name: {
      type: "string",
    },
    "Opportunity ID": {
      type: "string",
    },
  },
};

const addProjectSchema = {
  title: "Enter Name, SOW and short description for the project",
  type: "object",
  required: ["Name", "SOW"],
  properties: {
    Name: {
      type: "string",
    },
    SOW: {
      type: "string",
    },
    Description: {
      type: "string",
    },
  },
};

class Projects extends Component {
  constructor(props) {
    super(props);

    this.projectSelectionDisabled = true;
    this.listSelectionDisabled = true;
    this.ProjectDetails = "";
    this.showAddCustomerForm = false;
    this.showAddProjectForm = false;
    this.showProjectLists = false;
    this.customerInfo = [];
    this.projectInfo = [];
    this.questionSection = "";
    this.ProjectListSection = "";
    this.addCustomerForm = "";
    this.addProjectForm = "";
    this.currentUser = store.get("currentUser");
    this.dynamicComponentMapping = {};
    this.state = {
      dataReady: true,
      projectId: store.get("user")[this.currentUser]["projectId"],
      customerId: store.get("user")[this.currentUser]["customerId"],
      customerName: store.get("user")[this.currentUser]["customerName"], //|| "test",
      projectName: store.get("user")[this.currentUser]["projectName"],
      isAdmin: store.get("user")[this.currentUser]["role"].includes("admin"),
      showAlert: false,
      alertMsg: "",
      alertHeader: "",
    };
  }

  handleError = error => {
    //console.log("Error in API call");
    //console.log(error);
  };
  componentDidUpdate(prevProps, prevState) {
    if ((this.props.qid && prevProps.qid !== this.props.qid) || prevState.qid !== this.state.qid) {
      this.questionSection = (
        <div className="NewSection">
          <Questions
            projectId={this.state.projectId}
            customerId={this.state.customerId}
            projectDetails={this.ProjectDetails}
            user={this.props.user}
            dynamicComponentMapping={this.dynamicComponentMapping}
            saveProjectDetails={projectData => {
              this.ProjectDetails = projectData;
            }}
            qid={this.props.qid || this.state.qid}
            allowedSections={["Organization", "TermsConditions", "Data"]}
            allowParkingLot={true}
            allowNotes={true}
            allowSearch={true}
          />
        </div>
      );
      this.setState(this.state);
    }
  }

  componentDidMount() {
    //console.log("%c Project Selection Component Mounted", "color:orange");
    //Make API Call to get all the customers
    server.getCustomers(response => {
      if (response.status === 200) {
        response.data.object.forEach(cust => {
          this.customerInfo.push({ id: cust._id, name: cust.name });
        });
        if (this.state.projectId && this.state.customerId) {
          this.getProjectLists(this.state.customerId);
          this.handleProjectChange([{ id: this.state.projectId, name: this.state.projectName }]);
        }
        this.setState(this.state);
      } else this.handleError(response);
    });
  }

  displayProjectListSection = () => {
    this.ProjectListSection = "";
    this.showProjectLists = !this.showProjectLists;
    this.setState(this.state);

    if (this.state.projectId)
      if (this.showProjectLists) {
        this.ProjectListSection = (
          <div className="NewSection">
            <ProjectLists
              projectId={this.state.projectId}
              customerId={this.state.customerId}
              allowUpdates={true}
              saveProjectDetails={projectData => {
                this.ProjectDetails = projectData;
              }}
              dynamicComponentMapping={this.dynamicComponentMapping}
              SendQuestionPath={qid => {
                this.setState({ qid: qid }, () => {
                  this.displayProjectListSection();
                });
              }}
            />
          </div>
        );

        this.questionSection = "";
      } else {
        this.questionSection = (
          <div className="NewSection">
            <Questions
              projectId={this.state.projectId}
              customerId={this.state.customerId}
              projectDetails={this.ProjectDetails}
              user={this.props.user}
              dynamicComponentMapping={this.dynamicComponentMapping}
              saveProjectDetails={projectData => {
                this.ProjectDetails = projectData;
              }}
              qid={this.state.qid}
              allowedSections={["Organization", "TermsConditions", "Data"]}
              allowParkingLot={true}
              allowNotes={true}
              allowSearch={true}
            />
          </div>
        );
      }
  };
  exportProjectConfiguration = () => {
    this.setState({
      dataReady: false
    });
    server.exportProjectConfigurations(
      this.state.customerId,
      this.state.projectId,
      (response) => {
        if (response.status) {
          // Decode the base64 string to binary data
          const binaryData = atob(response.object);

          // Convert binary data to a Uint8Array
          const uint8Array = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          // Create a Blob from the binary data with the MIME type for a zip file
          const blob = new Blob([uint8Array], { type: 'application/zip' });

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element for downloading
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data.zip';

          // Simulate a click event to trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        }
        this.setState({
          dataReady: true
        });
      }
    );
  };
  addNewCustomer = ({ formData }) => {
    let data = formData;
    this.setState({
      dataReady: false,
    });
    server.createCustomer({ name: data["Name"], opportunityId: data["Opportunity ID"] }, response => {
      if (response.status == 201) {
        this.addCustomerForm = "";
        this.setState({
          dataReady: true,
        });
        this.showAddCustomerForm = this.showAddCustomerForm ? false : true;
        let newCustomerId = response.data._id;
        //console.log("customer created successfully");

        server.assignCustomer(newCustomerId, this.currentUser, resp => {
          //console.log("customer assigned to current user");
          this.customerInfo = [];
          server.getCustomers(respon => {
            if (respon.status === 200) {
              respon.data.object.forEach(cust => {
                this.customerInfo.push({ id: cust._id, name: cust.name });
              });
              store.update("user", user => {
                user[this.currentUser]["projectId"] = "";
                user[this.currentUser]["projectName"] = "";
              });
              this.handleCustomerChange([{ id: newCustomerId, name: data.Name }]);
            } else this.handleError(respon);
          });
        });
      } else {
        this.handleError(response);
        const error = (response.data && response.data.error && response.data.error.messages) || response.originalMessages;
        this.setState({
          showAlert: true,
          alertMsg: Object.values(error)[0],
          alertHeader: "Failed to Create Customer",
        });
      }
    });
  };

  addNewProject = formData => {
    let data = formData.formData;
    this.setState({
      dataReady: false,
    });
    server.createProject(this.state.customerId, { name: data.Name, sow: data.SOW, desc: data.Description }, response => {
      this.addProjectForm = "";
      this.setState({
        dataReady: true,
      });
      this.showAddProjectForm = this.showAddProjectForm ? false : true;
      let newProjectId = response.data._id;
      //console.log("project created successfully");

      server.assignProject(this.state.customerId, newProjectId, this.currentUser, resp => {
        this.setState({ projectId: newProjectId, projectName: data.Name }, () => {
          this.handleCustomerChange([{ id: this.state.customerId, name: this.state.customerName }]);
          this.handleProjectChange([{ id: this.state.projectId, name: this.state.projectName }]);
        });
      });
    });
  };

  displayAddCustomerForm = () => {
    this.showAddCustomerForm = this.showAddCustomerForm ? false : true;
    this.addCustomerForm = this.showAddCustomerForm ? (
      <div className="NewSection">
        <Form schema={addCustomerSchema} onSubmit={this.addNewCustomer}>
          <div>
            <button className="btn btn-success" type="submit">
              Create
            </button>
          </div>
        </Form>
      </div>
    ) : (
      ""
    );
    this.setState(this.state);
  };

  displayAddProjectForm = () => {
    //toggle add project and questions screen
    this.showAddProjectForm = this.showAddProjectForm ? false : true;

    this.addProjectForm = this.showAddProjectForm ? (
      <div className="NewSection">
        <Form schema={addProjectSchema} onSubmit={this.addNewProject}>
          <div>
            <button className="btn btn-success" type="submit">
              Create
            </button>
          </div>
        </Form>
      </div>
    ) : (
      ""
    );
    this.setState(this.state);
  };

  getProjectLists = customerId => {
    this.projectInfo = [];
    server.getProjects(customerId, response => {
      if (response.status === 200) {
        response.data.object.forEach(proj => {
          this.projectInfo.push({ id: proj._id, name: proj.name });
        });
      } else this.handleError(response);
    });
    this.projectSelectionDisabled = false;
  };

  handleCustomerChange = formData => {
    if (formData.length > 0) {
      //make api call to get all the projects corresponding to the customer.
      this.getProjectLists(formData[0].id);

      // clear the selected project and the rendered question component
      this.refs.projectList.getInstance().clear();
      this.questionSection = "";

      this.setState({
        customerId: formData[0].id,
        customerName: formData[0].name,
      });
      store.remove("lastRendered");
      store.update("user", user => {
        user[this.currentUser]["customerId"] = formData[0].id;
        user[this.currentUser]["customerName"] = formData[0].name;
      });
      store.update("user", user => {
        user[this.currentUser]["projectId"] = "";
        user[this.currentUser]["projectName"] = "";
      });
      this.props.updateProjectMeta();
    } else {
      this.projectSelectionDisabled = true;
      this.questionSection = "";
      this.showProjectLists = false;
      this.listSelectionDisabled = false;
      this.setState(this.state);
    }
  };

  handleProjectChange = async formData => {
    if (formData.length > 0) {
      this.setState({
        dataReady: false,
      });
      server.getProjectDetails(this.state.customerId, formData[0].id, async response => {
        if (response.status === 400) {
          this.setState({
            projectId: "",
            projectName: "",
          });
          store.remove("lastRendered");
          store.update("user", user => {
            user[this.currentUser]["projectId"] = "";
            user[this.currentUser]["projectName"] = "";
          });
        } else {
          this.ProjectDetails = response.data.object[0];
          let quesMetadata = this.ProjectDetails.QuestionSchemaObj.Metadata;

          if (quesMetadata && quesMetadata.componentVersionMapping) {
            await Object.entries(quesMetadata.componentVersionMapping).forEach(async component => {
              this.dynamicComponentMapping[component[0]] = await ComponentProvider.getComponent(component[0], component[1]);
            });
          } else {
            this.dynamicComponentMapping = {};
          }
          this.questionSection = (
            <div className="NewSection">
              <Websocket
                url={`${WS_ROOT}/customers/${this.state.customerId}/projects/${formData[0].id}/jobStatus`}
                onMessage={function (data) {
                  data = JSON.parse(data);
                  let title = "";

                  let message_suffix = data.isComplete ? "completed" + (data.isSuccess ? "." : " with Error.") : "started.";
                  let message = `FRD request for ${data.customerName} / ${data.project.name} (${data.project.sow}) ${message_suffix}`;
                  let type = data.isSuccess ? "info" : "warning";
                  NotificationStore.addNotification({
                    title: title,
                    message: message,
                    type: type,
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: NOTIFICATION_TIMEOUT,
                      onScreen: true,
                      showIcon: true,
                    },
                  });
                }}
                debug={false}
              />
              <Questions
                projectId={formData[0].id}
                customerId={this.state.customerId}
                projectDetails={this.ProjectDetails}
                user={this.props.user}
                dynamicComponentMapping={this.dynamicComponentMapping}
                saveProjectDetails={projectData => {
                  this.ProjectDetails = projectData;
                }}
                allowedSections={["Organization", "TermsConditions", "Data"]}
                allowParkingLot={true}
                allowNotes={true}
                allowSearch={true}
              />
            </div>
          );
          if (formData[0].id !== this.state.projectId) {
            //project changed, set environment specific config visibility to false.
            this.props.setEnvironmentSpecificConfigVisibility(false);
            localStorage.removeItem("showEnvironmentSpecificConfig");
          }
          this.setState({
            dataReady: true,
            projectId: formData[0].id,
            projectName: formData[0].name,
          });
          this.listSelectionDisabled = false;
          store.remove("lastRendered");
          store.update("user", user => {
            user[this.currentUser]["projectId"] = formData[0].id;
            user[this.currentUser]["projectName"] = formData[0].name;
          });
          this.props.updateProjectMeta();
          this.props.showProjectTabs();
          // if (this.ProjectDetails.FRDQuestionSchema) {
          //   this.props.setFRDQuestionVisibility(true);
          //   localStorage.setItem("showFRDSections", true);
          // } else {
          //   localStorage.setItem("showFRDSections", false);
          //   this.props.setFRDQuestionVisibility(false);
          // }
        }
      });
    } else {
      this.listSelectionDisabled = true;
      this.questionSection = "";
      store.update("user", user => {
        user[this.currentUser]["projectId"] = "";
        user[this.currentUser]["projectName"] = "";
      });
      this.props.showProjectTabs();
      this.setState(this.state);
    }
  };
  closeAlert = () => {
    this.setState({
      showAlert: false,
      alertMsg: "",
    });
  };

  render() {
    return (
      <LoadingOverlay
        active={!this.state.dataReady}
        spinner
        text="Loading Data..."
        styles={{
          spinner: base => ({
            ...base,
            width: "80px",
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      >
        <Modal show={this.state.showAlert} size="sm" aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.alertHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.alertMsg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeAlert}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div>
          <div className="container-fluid NewSection">
            <Row>
              <form className="form-inline">
                <ControlLabel>Customer </ControlLabel>
                <FormGroup controlId="customer" bsSize="small" className="proj-select-form-group">
                  <Typeahead
                    key={String(this.customerInfo)}
                    labelKey="name"
                    id="customer"
                    options={this.customerInfo}
                    placeholder="Search a Customer"
                    onChange={this.handleCustomerChange}
                    value={this.state.customerId}
                    defaultSelected={
                      this.state.customerId
                        ? [
                            {
                              id: this.state.customerId,
                              name: this.state.customerName,
                            },
                          ]
                        : []
                    }
                  />
                </FormGroup>

                <ControlLabel>Project</ControlLabel>
                <FormGroup controlId="project" bsSize="small" className="proj-select-form-group">
                  <Typeahead
                    labelKey="name"
                    id="project"
                    options={this.projectInfo}
                    ref="projectList"
                    placeholder="Search a project"
                    onChange={this.handleProjectChange}
                    disabled={this.projectSelectionDisabled}
                    value={this.state.projectId}
                    defaultSelected={
                      this.state.projectId
                        ? [
                            {
                              id: this.state.projectId,
                              name: this.state.projectName,
                            },
                          ]
                        : []
                    }
                  />
                </FormGroup>
              </form>
            </Row>
          </div>
          <div className="container-fluid ">
            {store.get("user")[this.currentUser] &&
            store.get("user")[this.currentUser]["access_map"] &&
            store.get("user")[this.currentUser]["access_map"].hasOwnProperty("POST") &&
            store.get("user")[this.currentUser]["access_map"]["POST"].includes("/customers") ? (
              <button type="button" onClick={this.displayAddCustomerForm} className="btn btn-warning btn-padded">
                Create New Customer
              </button>
            ) : (
              ""
            )}
            {store.get("user")[this.currentUser] &&
            store.get("user")[this.currentUser]["access_map"] &&
            store.get("user")[this.currentUser]["access_map"].hasOwnProperty("POST") &&
            store.get("user")[this.currentUser]["access_map"]["POST"].includes("/customers/:cust_id/projects") ? (
              <button type="button" onClick={this.displayAddProjectForm} className="btn btn-warning btn-padded" disabled={this.projectSelectionDisabled}>
                Create New Project
              </button>
            ) : (
              ""
            )}
            <button type="button" onClick={this.displayProjectListSection} className="btn btn-warning btn-clear btn-padded" disabled={this.listSelectionDisabled}>
              {this.showProjectLists ? "Hide Lists" : "Show Lists"}
            </button>
            {
              this.state.isAdmin &&             <button type="button" onClick={this.exportProjectConfiguration} className="btn btn-warning btn-clear btn-padded" disabled={!this.state.projectId || !this.state.customerId}>
              Export this Project
            </button>
            }
          </div>
          {this.addCustomerForm}
          {this.addProjectForm}
          {this.ProjectListSection}
          {this.questionSection}
        </div>
      </LoadingOverlay>
    );
  }
}
export default Projects;
