import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "./Login.css";
import Server from "./Server";
import store from "store";
import { redirectToIAM } from "../containers/api-config";

const server = new Server();

class Login extends Component {
  constructor(props) {
    super(props);
    // server.authenticate().then(() => {
    //   if (server.status.isAuthenticated) {
    //     this.setRedirect();
    //   }
    // });
    this.state = {
      username: "",
      password: "",
      error: "",
      status: "",
      name: "",
      redirect: false
    };
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }
  setRedirect = () => {
    console.log("setting redirect");
    this.setState({
      redirect: true
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={`${this.props.pathPrefix}/projects`} />;
    } else {
      redirectToIAM(this.props.mode);
    }
  };
  dismissError() {
    this.setState({ error: "" });
  }
  handleSubmit(evt) {
    return new Promise(resolve => {
      evt.preventDefault();
      if (!this.state.username) {
        return this.setState({ error: " Username is required" });
      }
      if (!this.state.password) {
        return this.setState({ error: " Password is required" });
      }

      server.login(this.state.username, this.state.password, this.props.mode).then(res => {
        this.setState({ status: res.status });
        if (res.status === true) {
          this.setState({ name: res.name });
          this.props.userHasAuthenticated(true);
          store.set("currentUser", this.state.username);

          if (store.get("user") && store.get("user")[this.state.username]) {
            console.log("user info exists on browser");
          } else {
            store.set("user", {
              [this.state.username]: {
                name: res.name,
                role: res.roles,
                access_map: res.access_map,
                id: this.state.username
              }
            });
          }
          this.props.setAllowedTabs(res.tabs);
          this.props.setUser(store.get("user")[store.get("currentUser")]["name"]);
          this.props.history.push(`${this.props.pathPrefix}${this.props.modePath}`);
        } else {
          this.setState({ error: res.error });
        }
        resolve(this.state);
      });
    });
  }

  handleUserChange(evt) {
    this.setState({
      username: evt.target.value
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value
    });
  }

  render() {
    return (
      <div className="Login">
        {this.renderRedirect()}
        {/* <form onSubmit={this.handleSubmit}>
          {this.state.error && (
            <h3 data-test="error" onClick={this.dismissError}>
              <button onClick={this.dismissError}>✖</button>
              {this.state.error}
            </h3>
          )}
          <FormGroup controlId="user" bsSize="large">
            <ControlLabel>User</ControlLabel>
            <FormControl autoFocus type="text" value={this.state.user} onChange={this.handleUserChange} />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl value={this.state.password} onChange={this.handlePassChange} type="password" />
          </FormGroup>
          <Button block bsSize="large" type="submit">
            Login
          </Button>
        </form> */}
      </div>
    );
  }
}

export default Login;
